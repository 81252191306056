export const copy = {
  deliveryDate: "When do you want your flowers delivered?",
  deliveryDatePlaceholder: "Select delivery date",
  deliveryDateLabel: "Delivery date",
  firstDeliveryDateLabel: "First Delivery date",
  paymentTerm: "How do you want to pay?",
  paymentTermLabel: "Payment option",
  zipCodeTermLabel: "Zip code (5 digits)",
  paymentTermNotice: "Pick a delivery date first",
  addToCart: "Add to cart",
  add: "Add",
  alreadyInCart: "Good Choice! Your item was added to the shopping bag",
  totalPrice: "Total:",
  youSave: "You save",
  giftEmailLabel: "Recipient email",
  giftEmailPlaceholder: "Optional",
  recipientNameLabel: "Recipient name",
  recipientNamePlaceholder: "Optional",
  giftEmailDetails:
    "* Your recipient will receive a delivery notification and gift message.",
  giftMessageLabel: "Gift Message",
  giftMessagePlaceholder: "Optional",
  giftSaveDetails: "Save gift details",
  giftMessageRemaning: "Characters remaining",
  giftToggleLabel: "Add a gift message",
  giftAutoRenewToggleLabel: "Auto renew?",
  howToCancelTitle: "Easy cancellation",
  howToCancelText:
    "Don't be tied down—cancel your subscription whenever it works for you! Any day, anytime.",
  howToCancelTextDetail:
    "To cancel your subscription, log into your account. Click on the Subscriptions tab on the top left-hand side. Scroll down to the bottom of the page and click “MANAGE” Select “Cancel Subscription.” You can also cancel by emailing us at info@bloomsybox.com or calling us at 1877-422-1316.",
  autoRenewTitle: "About your subscription",
  zipCodeLabel: "Zip code",
  zipCodeExplain:
    "To confirm if Saturday delivery is possible, kindly provide your zip code.",
  zipCodePlaceholder: "Check availability for Saturday delivery",
  verifyZipCode: "Verify zip code",
  verifyZipCodeError: "We couldn't verify your zip code, please try again",
  verifyZipCodeSuccess:
    "Great news! Saturday delivery is available for your zipcode!",
  verifyInvalidZipCode:
    "Saturday delivery is not available for the selected zip code. Please select a different delivery date.",
  zipCodeValidating: "Validating your zip code...",
  isResidentialLabel: "Residential?",
  shipping: "NEXT DAY DELIVERY",
  freeShipping: "FREE SHIPPING",
};
