/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from "react";
import {
  Button as MuiButton,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import tw, { styled, css, TwStyle } from "twin.macro";

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
  },
});
const ButtonStyles = (props: ButtonProps) => {
  const { variant, size, disabled } = props;
  // base
  const styles: (TwStyle | ReturnType<typeof css>)[] = [
    tw`h-auto text-black leading-tight uppercase font-roboto font-bold tracking-wider border-2 border-solid border-transparent rounded hocus:(border-transparent text-inherit)`,
  ];
  if (size === "large") {
    styles.push(tw`px-6 py-2`);
  }
  if (size === "medium") {
    styles.push(tw`px-4 py-1.5`);
  }
  if (size === "small") {
    styles.push(tw`px-4 py-2 h-[50px]`);
  }
  if (variant === "contained") {
    styles.push(
      tw`text-white bg-bloomsy-red border-bloomsy-red hocus:(text-white bg-bloomsy-darkred border-bloomsy-red)`
    );
  }
  if (variant === "outlined") {
    styles.push(
      tw`text-bloomsy-red bg-transparent border-bloomsy-red hocus:(text-black border-black) focus:ring-4 ring-red-500`
    );
  }
  if (variant === "text") {
    styles.push(tw`text-bloomsy-red underline-offset-4 hocus:(bg-transparent outline-2)`);
  }
  if (disabled) {
    styles.push(tw`border-2 border-gray-200`);
  }
  return styles;
};
const StyledButton = styled(MuiButton)([
  ButtonStyles,
  { fontSize: "14px" },
  { "&.MuiButton-outlined": tw`border-2` },
]);
export interface ButtonProps extends MuiButtonProps {
  [x: string]: any; // ...otherProps
}
export const Button = forwardRef<any, ButtonProps>(
  ({ variant = "contained", size = "medium", ...otherProps }, ref: any) => {
    let normalizedVariant = variant;
    // @ts-ignore fixme: delete this
    if (variant === "solid") {
      normalizedVariant = "contained";
    }
    // @ts-ignore fixme: delete this
    if (variant === "outline") {
      normalizedVariant = "outlined";
    }
    // @ts-ignore fixme: delete this
    if (variant === "ghost" || variant === "link") {
      normalizedVariant = "text";
    }
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledButton
            // @ts-ignore fixme: delete this
            variant={normalizedVariant}
            disableRipple
            disableElevation
            size={size}
            ref={ref}
            {...otherProps}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
);
