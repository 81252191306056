/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { ApolloClient, HttpLink, NormalizedCacheObject } from "@apollo/client";
import { cache } from "src/services/datocms/cache";
import wretch from "wretch";

const api = wretch("https://graphql.datocms.com").auth(
  `Bearer ${process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN}`
);
export const datocmsGraphql = async (
  query: string,
  variables?: Record<string, any>
) => {
  let postData: Record<string, any> = { query };
  if (typeof variables !== "undefined" && Object.keys(variables).length) {
    postData = { query, variables };
  }
  return api.post(postData).json();
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient(isServerSide = false) {
  if (!process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN) {
    throw new Error("Missing DatoCMS ENV api token");
  }
  return new ApolloClient({
    cache,
    ssrMode: typeof window === "undefined",
    link: new HttpLink({
      uri: (operation) => {
        const previewMode = operation.getContext().preview ?? false;
        return `https://graphql.datocms.com${previewMode ? "/preview" : ""}`;
      },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: isServerSide ? "no-cache" : "cache-first",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: isServerSide ? "no-cache" : "cache-first",
        errorPolicy: "all",
      },
    },
  });
}

export function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return createApolloClient(true);
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
