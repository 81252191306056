import tw, { styled } from "twin.macro";
import { Tooltip } from "@mui/material";

import { withStyles } from "tss-react/mui";

export const StyledStepLabel = styled.h4([
  tw`uppercase tracking-wider text-sm font-roboto text-black`,
]);
export const StyledStep = styled.div(
  ({ done, active }: { done?: boolean; active?: boolean }) => [
    tw`py-2 my-2`,
    active ? tw`opacity-100` : tw`opacity-25`,
    done && tw`opacity-100`,
  ]
);
export const StyledTooltip = withStyles(Tooltip, {
  tooltip: tw`text-black text-sm bg-white p-4 shadow-lg border border-gray-300`,
  arrow: tw`text-gray-300`,
});
