import { FC, Fragment } from "react";
import { IS_PROD_ENV } from "src/constants";

const GTM_ID = "GTM-NBCJW9J";
const OPTIMIZE_ID = "OPT-T82ZZJK";

export const dataLayer = (obj: unknown) => {
  if (typeof window === "undefined") return;
  window.dataLayer = window.dataLayer || [];
  // console.log("dataLayer", obj);
  window.dataLayer.push(obj);
};

type Props = {
  load: "gtm" | "gtmnoscript";
};
export const Analytics: FC<Props> = ({ load }) => {
  if (!IS_PROD_ENV) return null;
  if (load === "gtm") {
    return (
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
         window.dataLayer = window.dataLayer || []; window.dataLayer.push({ originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search }); 
        `,
          }}
        />
        <script
          src={`https://www.googleoptimize.com/optimize.js?id=${OPTIMIZE_ID}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');
          `,
          }}
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-955723936"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-955723936');
  `,
          }}
        />
      </Fragment>
    );
  }
  if (load === "gtmnoscript") {
    return (
      <noscript>
        <iframe
          title="gtagnoscript"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
    );
  }
  return null;
};
