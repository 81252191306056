import * as React from "react";
import { Button } from "src/shared/components/Button";
import { ProductFieldsFragment } from "src/generated/datocms-types";
import { SuperGiftItem } from "src/features/SuperGift/SuperGiftItem";
import {
  DatePickerClientConfigT,
  DisabledDate,
  loadDatePickerConfig,
} from "src/features/ProductForm/utils";
import tw from "twin.macro";
import {
  useCartBusy,
  useProductExistInCart,
  useProductForm,
} from "src/features/Cart/state";
import { useQuery } from "react-query";
import { useMemo } from "react";
import { ShopifyVariantNode } from "src/shared/types/shopify";
import BloomsyLoader from "src/shared/components/BloomsyLoader";
import { copy } from "src/features/ProductForm/constants";
import { parseDatepickerDateStr } from "src/shared/utils/products";
import { FiCheckCircle } from "react-icons/fi";
import { AttributeInput } from "../Cart/types";
import {
  IS_NEXT_DAY_PRODUCT_PROP,
  IS_SUPER_GIFT_PROP,
} from "../Cart/constants";

export type InvalidDate = {
  disabledDate: DisabledDate;
  itemIndex: number;
};
export type SuperGiftState = SuperGiftItemT[];
export type GetItemDataFn = (index: number) => SuperGiftItemT;
export type SaveItemDataFn = (index: number, newItem: SuperGiftItemT) => void;
export type ResetItemDataFn = (index: number) => void;
export type SuperGiftItemT = {
  deliveryDate: string; // mm-dd-yyyy
  giftMessage: string;
};
const initialItemsLength = 3;
export const initialSuperGiftItem = { deliveryDate: "", giftMessage: "" };
const initialState: SuperGiftState = Array.from(
  { length: initialItemsLength },
  () => initialSuperGiftItem
);
export const SUPERGIFT_PROP = "SuperGiftItem";

type Props = {
  product: ProductFieldsFragment;
};
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line react/function-component-definition
export const SuperGiftProductForm: React.FC<Props> = ({ product }) => {
  const baseVariant: ShopifyVariantNode =
    product.shopifySubscriptionProducts?.length > 0 &&
    product.shopifySubscriptionProducts[0].variants?.edges[0].node;
  const { addToCart, setVariant, variant, setDeliveryDate } = useProductForm({
    product,
  });
  const cartIsBusy = useCartBusy();
  const productExistInCart = useProductExistInCart(product.id);
  const [items, setItems] = React.useState<SuperGiftState>(initialState);
  const saveItemData = React.useCallback<SaveItemDataFn>(
    (index, newItem) => {
      const newItems = [
        ...items.slice(0, index),
        newItem,
        ...items.slice(index + 1),
      ];
      setItems(newItems);
    },
    [items, setItems]
  );

  const query = useQuery<DatePickerClientConfigT>(
    [
      {
        productId: product.id,
        isSubscription: product.isSubscription,
      },
      "datepicker",
    ],
    () =>
      loadDatePickerConfig({
        productId: product.id,
        isSubscription: product.isSubscription,
        userDate: new Date().toISOString(),
      })
  );

  const datePickerConfig = useMemo(
    () => query.data ?? ({} as DatePickerClientConfigT),
    [query.data]
  );

  const handleAddToCart = React.useCallback(() => {
    const properties: AttributeInput[] = [];
    items.forEach((item, idx) => {
      const prefix = `${SUPERGIFT_PROP}#${idx + 1}#`;
      const deliveryDateProp = {
        key: `${prefix}_Delivery_Date`,
        value: item.deliveryDate,
      };
      const giftMessageProp = {
        key: `${prefix}_Gift_Message`,
        value: item.giftMessage,
      };
      if (deliveryDateProp.value.length > 0) {
        properties.push(deliveryDateProp);
      }
      if (giftMessageProp.value.length > 0) {
        properties.push(giftMessageProp);
      }
    });
    addToCart({
      attributes: [
        ...properties,
        {
          key: IS_NEXT_DAY_PRODUCT_PROP,
          value: datePickerConfig.nextDayShippingConfig.enabled.toString(),
        },
        {
          key: IS_SUPER_GIFT_PROP,
          value: product.isSuperGift ? "true" : "false",
        },
      ],
      sellingPlanAllocation: {
        sellingPlan: {
          id:
            variant?.sellingPlanAllocations?.edges[0]?.node?.sellingPlan.id ??
            "",
        },
      },
    });
    setItems(initialState);
  }, [
    addToCart,
    items,
    variant?.sellingPlanAllocations?.edges,
    product.isSuperGift,
    datePickerConfig,
  ]);
  React.useEffect(() => {
    if (variant?.id !== baseVariant?.id) {
      setVariant(baseVariant);
    }
  }, [variant, baseVariant, setVariant]);
  React.useEffect(() => {
    if ((items.length ?? 0) > 0)
    {
      setDeliveryDate(items[0].deliveryDate);
      }
  }, [items, setDeliveryDate]);
  const disabledDates: (DisabledDate | null)[] = items.map((item) => {
    const { month, day, year } = parseDatepickerDateStr(item.deliveryDate);
    return month && day && year ? { month, day, year } : null;
  });
  const invalidDates: InvalidDate[] = disabledDates
    .map((disabledDate, idx) => {
      if (!disabledDate) return null;
      const prev = disabledDates[idx - 1];
      if (!prev) return null;
      const { month, day, year } = disabledDate;

      if (year && prev.year) {
        if (year > prev.year) return null;
        if (month > prev.month && year === prev.year) return null;
      }
      if (month === prev.month && day > prev.day) return null;
      return { disabledDate, itemIndex: idx };
    })
    // @ts-expect-error
    .filter<InvalidDate>(Boolean);
  const disabledDatesFiltered = disabledDates.filter(Boolean);
  const hasValidDates =
    invalidDates.length === 0 &&
    disabledDatesFiltered.length > 0 &&
    disabledDatesFiltered.length === initialItemsLength;
  if (productExistInCart) {
    return (
      <div
        css={tw`flex items-center justify-center my-2 py-2 bg-green-100 text-green-700 font-bold`}
      >
        <FiCheckCircle css={tw`w-4 h-4`} />
        <p css={tw`ml-1`}>{copy.alreadyInCart}</p>
      </div>
    );
  }
  return (
    <div css={tw`text-sm font-roboto`}>
      {items.map((initialItem, idx) => {
        const itemData = items[idx] || initialItem;
        const deliveryDate = parseDatepickerDateStr(itemData.deliveryDate);
        return (
          <SuperGiftItem
            key={idx}
            itemIndex={idx}
            product={product}
            invalidDate={invalidDates.find((id) => id.itemIndex === idx)}
            disabledDates={disabledDates
              // @ts-expect-error
              .filter<DisabledDate>(Boolean)
              .filter((dt) => {
                return (
                  dt.month !== deliveryDate.month && dt.day !== deliveryDate.day
                );
              })}
            itemData={itemData}
            saveItemData={saveItemData}
          />
        );
      })}
      <div css={tw`mt-2`}>
        <Button
          disabled={!hasValidDates}
          onClick={handleAddToCart}
          size="small"
          fullWidth
        >
          {cartIsBusy ? (
            <BloomsyLoader css={tw`w-4 h-4`} />
          ) : (
            <span css={tw`whitespace-nowrap`}>{copy.addToCart}</span>
          )}
        </Button>
      </div>
    </div>
  );
};
