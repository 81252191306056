export const GIFT_MESSAGE_MAX_LEN = 200;
export const DELIVERY_DATE_PROP = "Customer_Delivery_Date";
export const GIFT_MESSAGE_PROP = "Gift_Message";
export const DELIVERY_DATE_LABEL = "Delivery Date";
export const FIRST_DELIVERY_DATE_LABEL = "First Delivery Date";
export const ESTIMATED_DELIVERY_DATE_LABEL = "Next Estimated Delivery Dates";
export const cartCookieConfig = {
  name: "__bloomsybox_cart",
  expires: 1,
};
export const IS_SUPER_GIFT_PROP = "_Is_Super_Gift";
export const IS_GIFT_PROP = "_Is_Gift";
export const AUTO_RENEW_PROP = "_Auto_Renew";
export const RECIPIENT_EMAIL_PROP = "_Recipient_Email";
export const RECIPIENT_NAME_PROP = "_Recipient_Name";
export const CMS_PRODUCT_ID_PROP = "_CmsProductId";
export const CMS_PRODUCT_ID_PROP_R = "CmsProductId";
export const ONE_TIME_VARIANT_PROP = "_One_Time_Variant";
export const ONE_TIME_VARIANT_ID_PROP = "_One_Time_Variant_Id";
export const ONE_TIME_VARIANT_EXCLUDED_IDS_PROP =
  "_One_Time_Variant_Excluded_Id";
export const VARIANT_TITLE_PROP = "_Variant_Title";
export const IS_SUBSCRIPTION_PRODUCT_PROP = "_Is_Subscription_Product";
export const SYSTEM_NOTE_PROP = "_System_Note";
export const PRODUCT_TAGS_PROP = "_Product_Tags";
export const THIRD_PARTY_PROP = "_Third_Party";
export const IS_NEXT_DAY_PRODUCT_PROP = "_Is_Next_Day_Product";
export const SATURDAY_NEXTDAY_DELIVERY_PROP = "_Saturday_Next_Day_Delivery";
export const SATURDAY_DELIVERY_PROP = "_Saturday_Delivery";
export const NEXTDAY_DELIVERY_PROP = "_Next_Day_Delivery";

export const VIRTUAL_ADD_ON = "HG35VM";
