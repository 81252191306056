/* eslint-disable no-console */
/* eslint-disable no-empty */
import {
  GetUpSellingCartProductsDocument,
  GetUpSellingCartProductsQuery,
  GetUpSellingProductsDocument,
  GetUpSellingProductsQuery,
  ProductFieldsFragment,
  ProductFieldsShortCartFragment,
} from "src/generated/datocms-types";
import { cartOperations } from "src/features/Cart/state";
import { getCmsItemIdFromCheckoutData } from "src/features/Cart/utils";
import { initializeApollo } from "src/services/datocms";
import { DELIVERY_DATE_PROP } from "src/features/Cart/constants";
import produce from "immer";
import { KeyValueProp } from "src/shared/types";
import { ShopifyCartData, ShopifyLineItem } from "src/shared/types/shopify";

export const getUpsellingProducts = async (preview = false) => {
  const apolloClient = initializeApollo();
  const { data: upSellingProductsData } =
    await apolloClient.query<GetUpSellingProductsQuery>({
      query: GetUpSellingProductsDocument,
      context: {
        preview,
      },
    });

  return upSellingProductsData.allProducts as ProductFieldsFragment[];
};

export const getUpSellingCartProducts = async (preview = false) => {
  const apolloClient = initializeApollo();
  const { data: upSellingProductsData } =
    await apolloClient.query<GetUpSellingCartProductsQuery>({
      query: GetUpSellingCartProductsDocument,
      context: {
        preview,
      },
    });
  return upSellingProductsData.allProducts as ProductFieldsShortCartFragment[];
};
export const updateUpsellingProducts = async (cartData: ShopifyCartData) => {
  const checkout = cartData;
  try {
    const upSellingProducts = await getUpSellingCartProducts(false);
    const upsellingProductCart: ShopifyLineItem[] = [];
    const noUpsellingProductCart: ShopifyLineItem[] = [];
    const upsellingProductNextDayDisabledList: ShopifyLineItem[] = [];
    const upSellingProductsToUpdate: ShopifyLineItem[] = [];

    checkout?.cart?.lines?.edges?.forEach((li) => {
      const cmsId = getCmsItemIdFromCheckoutData(li.node);
      const upsellingProd = upSellingProducts.find((u) => u.id === cmsId);
      const isUpsellingProductNextDayDisabled = upSellingProducts.find(
        (u) => u.id === cmsId && u.hasDisabledNextDayDelivery
      );
      if (upsellingProd) {
        upsellingProductCart.push(li.node);
      } else {
        noUpsellingProductCart.push(li.node);
      }
      if (isUpsellingProductNextDayDisabled) {
        upsellingProductNextDayDisabledList.push(li.node);
      }
    });
    const hasUpsellingProducts =
      upsellingProductCart && upsellingProductCart.length > 0;
    const hasNoUpsellingProducts =
      noUpsellingProductCart && noUpsellingProductCart.length > 0;

    if (hasUpsellingProducts && hasNoUpsellingProducts) {
      if (noUpsellingProductCart) {
        const product = noUpsellingProductCart.shift();
        const deliveryDateProp = product?.attributes?.find(
          (prop) => prop.key === DELIVERY_DATE_PROP
        );
        upsellingProductCart.forEach((up: ShopifyLineItem) => {
          const isANonNextDayUpselling =
            upsellingProductNextDayDisabledList.find(
              (u) => u.merchandise?.product?.id === up.merchandise?.product?.id
            );
          if (!isANonNextDayUpselling) {
            const upsellingDeliveryDateProp = up?.attributes?.find(
              (prop) => prop.key === DELIVERY_DATE_PROP
            );
            if (deliveryDateProp?.value !== upsellingDeliveryDateProp?.value) {
              const upsellingDeliveryDatePropIndex =
                up?.attributes?.findIndex(
                  (prop) => prop.key === DELIVERY_DATE_PROP
                ) ?? -1;
              if (
                upsellingDeliveryDatePropIndex !== -1 &&
                up?.attributes &&
                deliveryDateProp
              ) {
                const newProps: KeyValueProp[] = [...(up.attributes ?? [])];
                newProps[upsellingDeliveryDatePropIndex] = {
                  key: DELIVERY_DATE_PROP,
                  value: deliveryDateProp?.value,
                };
                upSellingProductsToUpdate.push(
                  produce(up, (draft) => {
                    // eslint-disable-next-line no-param-reassign
                    draft.attributes = newProps;
                  })
                );
              }
            }
          }
        });
        return cartOperations.editItem(upSellingProductsToUpdate);
      }
    }
  } catch (err) {}
};
