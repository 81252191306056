import { Global } from "@emotion/react";
import tw, { GlobalStyles as BaseStyles } from "twin.macro";

const globalStyles = {
  body: tw`antialiased`,
  ".flatpickr-monthDropdown-months": tw`font-bold`,
  ".flatpickr-day.selected": tw`bg-bloomsy-red border-bloomsy-red hover:(bg-bloomsy-darkred border-bloomsy-darkred)`,
  ".INDDesktop #INDmenu-btn": {
    bottom: "14px !important",
    top: "unset !important",
  },
  ".MuiPickersDay-daySelected": {
    backgroundColor: "#0e9f6e !important",
  },
};

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={globalStyles} />
  </>
);

export default GlobalStyles;
