// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
/* prettier-ignore */
// @ts-nocheck

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BlockCtaModelButtonsField": [
      "ExternalLinkRecord",
      "InternalLinkRecord"
    ],
    "BlockHeroAnimatedTextModelButtonsField": [
      "ExternalLinkRecord",
      "InternalLinkRecord"
    ],
    "BlockHeroModelButtonsField": [
      "ExternalLinkRecord",
      "InternalLinkRecord"
    ],
    "BlockHerobanner2columnModelButtonsField": [
      "ExternalLinkRecord",
      "InternalLinkRecord"
    ],
    "BlockMainFeatureModelButtonsField": [
      "ExternalLinkRecord",
      "InternalLinkRecord"
    ],
    "ContentSectionModelContentField": [
      "BlockCtaRecord",
      "BlockFeatTestimonialsCompaniesSliderRecord",
      "BlockFeatureContentOverlayRecord",
      "BlockFeatureDiscountCardRecord",
      "BlockFeatureImageCardRecord",
      "BlockFeatureImageFullRecord",
      "BlockFeatureImageTextRecord",
      "BlockFeatureImageTitleRecord",
      "BlockFeatureInstagramFullRecord",
      "BlockFeatureInstagramProductRecord",
      "BlockFeatureInstagramSliderRecord",
      "BlockFeatureLinkCollectionRecord",
      "BlockFeatureQuoteRecord",
      "BlockFeatureReviewCollectionRecord",
      "BlockFeatureRowImageCardRecord",
      "BlockFeatureTestimonialRecord",
      "BlockFeatureText2imagesButtonRecord",
      "BlockFeatureTextIconRecord",
      "BlockFeatureTextImageModuleRecord",
      "BlockFeatureVideoRecord",
      "BlockFeaturedInRecord",
      "BlockFlowerCollectionRecord",
      "BlockHeroAnimatedTextRecord",
      "BlockHeroRecord",
      "BlockHerobanner2columnRecord",
      "BlockImageGridRecord",
      "BlockMainFeatureContainerRecord",
      "BlockMainFeatureRecord",
      "BlockPlantCollectionRecord",
      "BlockProductCollectionRecord",
      "BlockProseColumnRecord",
      "BlockProseColumnTitleBgimageRecord",
      "BlockProseRecord",
      "BlockRowCardImageTitleRecord",
      "EmbeddedFormRecord",
      "FaqsCollectionRecord",
      "ProductBestSellerRecord",
      "ProductCollectionSliderRecord"
    ],
    "DiscountCardModelTestField": [
      "FeatureImageTitleRecord",
      "FeatureTestimonialRecord"
    ],
    "FeatureImageCardModelLinkField": [
      "FlowerPageRecord",
      "FlowerShopPageRecord",
      "PageRecord",
      "PlantShopPageRecord",
      "ProductRecord",
      "SubscribePageRecord"
    ],
    "FeatureImageCardOptionalModelLinkField": [
      "FlowerShopPageRecord",
      "PageRecord",
      "PlantShopPageRecord",
      "ProductRecord",
      "SubscribePageRecord"
    ],
    "FileFieldInterface": [
      "FileField"
    ],
    "FlowerCollectionModelFlowerListField": [
      "FlowerItemMonthlyRecord",
      "FlowerItemWeeklyRecord"
    ],
    "InternalLinkModelLinkField": [
      "BlogPostRecord",
      "FlowerShopPageRecord",
      "NestedPageRecord",
      "PageRecord",
      "PlantShopPageRecord",
      "ProductRecord",
      "SubscribePageRecord"
    ],
    "LinkCollectionItemModelInternalLinkField": [
      "FlowerShopPageRecord",
      "PageRecord",
      "PlantShopPageRecord",
      "ProductRecord",
      "SubscribePageRecord"
    ],
    "LinkModelInternalLinkField": [
      "BlogPostRecord",
      "FlowerShopPageRecord",
      "NestedPageRecord",
      "PageRecord",
      "PlantShopPageRecord",
      "ProductRecord",
      "SubscribePageRecord"
    ],
    "ProductCollectionModelProductsField": [
      "ProductRecord",
      "PromotionalCardRecord"
    ],
    "RecordInterface": [
      "BlockCtaRecord",
      "BlockFeatTestimonialsCompaniesSliderRecord",
      "BlockFeatureContentOverlayRecord",
      "BlockFeatureCtaComponentRecord",
      "BlockFeatureDiscountCardRecord",
      "BlockFeatureImageCardRecord",
      "BlockFeatureImageFullRecord",
      "BlockFeatureImageTextRecord",
      "BlockFeatureImageTitleRecord",
      "BlockFeatureInstagramFullRecord",
      "BlockFeatureInstagramProductRecord",
      "BlockFeatureInstagramSliderRecord",
      "BlockFeatureLinkCollectionRecord",
      "BlockFeatureQuoteRecord",
      "BlockFeatureReviewCollectionRecord",
      "BlockFeatureRowImageCardRecord",
      "BlockFeatureTestimonialRecord",
      "BlockFeatureText2imagesButtonRecord",
      "BlockFeatureTextIconRecord",
      "BlockFeatureTextImageModuleRecord",
      "BlockFeatureVideoRecord",
      "BlockFeaturedInRecord",
      "BlockFlowerCollectionRecord",
      "BlockHeroAnimatedTextRecord",
      "BlockHeroRecord",
      "BlockHerobanner2columnRecord",
      "BlockImageGridRecord",
      "BlockMainFeatureContainerRecord",
      "BlockMainFeatureRecord",
      "BlockPlantCollectionRecord",
      "BlockProductCollectionRecord",
      "BlockProseColumnRecord",
      "BlockProseColumnTitleBgimageRecord",
      "BlockProseRecord",
      "BlockRowCardImageTitleRecord",
      "BlogCategoryRecord",
      "BlogPageRecord",
      "BlogPostRecord",
      "ConfigDatesPerProductCopy1Record",
      "ConfigDatesPerProductRecord",
      "ContactUsPageRecord",
      "ContentSectionRecord",
      "CutoffDateRecord",
      "CutoffDatesPerProductCopy1Record",
      "CutoffDatesPerProductRecord",
      "DatepickerConfigRecord",
      "DisabledMonthDayRecord",
      "DisabledWeekdayRecord",
      "DiscountCardRecord",
      "EmbeddedFormRecord",
      "EnabledWeekdayRecord",
      "ExternalLinkRecord",
      "FaqsCollectionRecord",
      "FaqsItemRecord",
      "FaqsPageRecord",
      "FeatureEmbeddedVideoRecord",
      "FeatureImageCardOptionalRecord",
      "FeatureImageCardRecord",
      "FeatureImageFullRecord",
      "FeatureImageTitleDescriptionRecord",
      "FeatureImageTitleRecord",
      "FeatureTestimonialRecord",
      "FlowerCenterRecord",
      "FlowerCollectionRecord",
      "FlowerItemMonthlyRecord",
      "FlowerItemWeeklyRecord",
      "FlowerPageRecord",
      "FlowerShopPageRecord",
      "FooterRecord",
      "GlobalDatepickerConfigRecord",
      "GoogleProductRecord",
      "GoogleShoppingRecord",
      "HeaderRecord",
      "HomepageRecord",
      "HtmlContentRecord",
      "ImageTestimonialRecord",
      "ImageTitleBackgroundColorRecord",
      "InternalLinkRecord",
      "LinkCollectionItemRecord",
      "LinkRecord",
      "NestedPageRecord",
      "NextDayCutoffBlockRecord",
      "NextDayShippingPerProductRecord",
      "NextdayCutoffConfigRecord",
      "PageRecord",
      "PlantCenterRecord",
      "PlantCollectionRecord",
      "PlantPageRecord",
      "PlantShopPageRecord",
      "PressArticleRecord",
      "PressPageRecord",
      "ProductBestSellerRecord",
      "ProductCollectionRecord",
      "ProductCollectionSliderRecord",
      "ProductDateRuleRecord",
      "ProductRecord",
      "ProductTagRecord",
      "ProductsDateRuleRecord",
      "ProductsDisabledDateRecord",
      "PromotionalCardRecord",
      "ProseRecord",
      "ReviewRecord",
      "SecondaryHeroBannerRecord",
      "SubMenuItemRecord",
      "SubMenuSettingRecord",
      "SubscribePageRecord",
      "SubscriptionPlanRecord",
      "SweepstakeRecord",
      "TextRecord",
      "TitleTextRecord",
      "VariantRecord"
    ],
    "SecondaryHeroBannerModelButtonsField": [
      "ExternalLinkRecord",
      "InternalLinkRecord"
    ]
  }
};
      export default result;
    