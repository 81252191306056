// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
/* prettier-ignore */
// @ts-nocheck
import { gql } from '@apollo/client';
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BooleanType: any;
  CustomData: any;
  Date: any;
  DateTime: any;
  FloatType: any;
  IntType: any;
  ItemId: any;
  JsonField: any;
  MetaTagAttributes: any;
  UploadId: any;
};

export type BlockCtaModelButtonsField = ExternalLinkRecord | InternalLinkRecord;

/** Linking fields */
export enum BlockCtaModelFieldsReferencingProductModel {
  BlockCtaButtons = "blockCta_buttons",
  BlockCtaButtonsInternalLinkLink = "blockCta_buttons__internalLink_link",
}

export type BlockCtaModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockCtaModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockCtaModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  colorButton: InputMaybe<ColorFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasLightTheme: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  text: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  variant: InputMaybe<StringFilter>;
};

export enum BlockCtaModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasLightThemeAsc = "hasLightTheme_ASC",
  HasLightThemeDesc = "hasLightTheme_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  VariantAsc = "variant_ASC",
  VariantDesc = "variant_DESC",
}

/** Record of type Block CTA (block_cta) */
export type BlockCtaRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  buttons: Array<BlockCtaModelButtonsField>;
  colorButton: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  hasLightTheme: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  text: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  variant: Maybe<Scalars["String"]>;
};

/** Record of type Block CTA (block_cta) */
export type BlockCtaRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feat Testimonials/Companies/Slider (block_feat_testimonials_companies_slider) */
export type BlockFeatTestimonialsCompaniesSliderRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Array<ImageTestimonialRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feat Testimonials/Companies/Slider (block_feat_testimonials_companies_slider) */
export type BlockFeatTestimonialsCompaniesSliderRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum BlockFeatureContentOverlayModelFieldsReferencingProductModel {
  BlockFeatureContentOverlayButton = "blockFeatureContentOverlay_button",
  BlockFeatureContentOverlayButtonInternalLinkLink = "blockFeatureContentOverlay_button__internalLink_link",
  BlockFeatureContentOverlayMainButton = "blockFeatureContentOverlay_mainButton",
  BlockFeatureContentOverlayMainButtonInternalLinkLink = "blockFeatureContentOverlay_mainButton__internalLink_link",
}

export type BlockFeatureContentOverlayModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureContentOverlayModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureContentOverlayModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  backgroundImage: InputMaybe<FileFilter>;
  color: InputMaybe<ColorFilter>;
  contentMobileImage: InputMaybe<FileFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  description: InputMaybe<StringFilter>;
  firstTextFooter: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  mainDescription: InputMaybe<StringFilter>;
  mainTitle: InputMaybe<StringFilter>;
  mobileImage: InputMaybe<FileFilter>;
  secondTextFooter: InputMaybe<StringFilter>;
  text: InputMaybe<TextFilter>;
  thirdText: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureContentOverlayModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  FirstTextFooterAsc = "firstTextFooter_ASC",
  FirstTextFooterDesc = "firstTextFooter_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  MainDescriptionAsc = "mainDescription_ASC",
  MainDescriptionDesc = "mainDescription_DESC",
  MainTitleAsc = "mainTitle_ASC",
  MainTitleDesc = "mainTitle_DESC",
  SecondTextFooterAsc = "secondTextFooter_ASC",
  SecondTextFooterDesc = "secondTextFooter_DESC",
  ThirdTextAsc = "thirdText_ASC",
  ThirdTextDesc = "thirdText_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature- Content Overlay (block_feature_content_overlay) */
export type BlockFeatureContentOverlayRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundImage: Maybe<FileField>;
  button: Array<InternalLinkRecord>;
  color: Maybe<ColorField>;
  contentMobileImage: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  firstTextFooter: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  mainButton: Array<InternalLinkRecord>;
  mainDescription: Maybe<Scalars["String"]>;
  mainTitle: Maybe<Scalars["String"]>;
  mobileImage: Maybe<FileField>;
  secondTextFooter: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  thirdText: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature- Content Overlay (block_feature_content_overlay) */
export type BlockFeatureContentOverlayRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature- Content Overlay (block_feature_content_overlay) */
export type BlockFeatureContentOverlayRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockFeatureCtaComponentModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureCtaComponentModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureCtaComponentModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureCtaComponentModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block Feature - CTA component (block_feature_cta_component) */
export type BlockFeatureCtaComponentRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block Feature - CTA component (block_feature_cta_component) */
export type BlockFeatureCtaComponentRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockFeatureDiscountCardModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureDiscountCardModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureDiscountCardModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureDiscountCardModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature-Discount Card (block_feature_discount_card) */
export type BlockFeatureDiscountCardRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  couponCard: Array<DiscountCardRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature-Discount Card (block_feature_discount_card) */
export type BlockFeatureDiscountCardRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum BlockFeatureImageCardModelFieldsReferencingProductModel {
  BlockFeatureImageCardContent = "blockFeatureImageCard_content",
  BlockFeatureImageCardContentFeatureImageCardLink = "blockFeatureImageCard_content__featureImageCard_link",
}

export type BlockFeatureImageCardModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureImageCardModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureImageCardModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasLargerCardWithoutBorder: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureImageCardModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasLargerCardWithoutBorderAsc = "hasLargerCardWithoutBorder_ASC",
  HasLargerCardWithoutBorderDesc = "hasLargerCardWithoutBorder_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - 3col Image/Title/Link Card (block_feature_image_card) */
export type BlockFeatureImageCardRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Array<FeatureImageCardRecord>;
  createdAt: Scalars["DateTime"];
  hasLargerCardWithoutBorder: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - 3col Image/Title/Link Card (block_feature_image_card) */
export type BlockFeatureImageCardRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum BlockFeatureImageFullModelFieldsReferencingProductModel {
  BlockFeatureImageFullPageLinkUrl = "blockFeatureImageFull_pageLinkUrl",
  BlockFeatureImageFullPageLinkUrlInternalLinkLink = "blockFeatureImageFull_pageLinkUrl__internalLink_link",
}

export type BlockFeatureImageFullModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureImageFullModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureImageFullModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasDividers: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  subtitleFont: InputMaybe<StringFilter>;
  titleFont: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureImageFullModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasDividersAsc = "hasDividers_ASC",
  HasDividersDesc = "hasDividers_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleFontAsc = "subtitleFont_ASC",
  SubtitleFontDesc = "subtitleFont_DESC",
  TitleFontAsc = "titleFont_ASC",
  TitleFontDesc = "titleFont_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - 3col Image/Title/Text (block_feature_image_full) */
export type BlockFeatureImageFullRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  content: Array<FeatureImageFullRecord>;
  createdAt: Scalars["DateTime"];
  hasDividers: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  pageLinkUrl: Array<InternalLinkRecord>;
  subtitleFont: Maybe<Scalars["String"]>;
  titleFont: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - 3col Image/Title/Text (block_feature_image_full) */
export type BlockFeatureImageFullRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockFeatureImageTextModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureImageTextModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureImageTextModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  backgroundImage: InputMaybe<FileFilter>;
  bigText: InputMaybe<TextFilter>;
  bigTextPosition: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasBigText: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  mobileImage: InputMaybe<FileFilter>;
  title: InputMaybe<StringFilter>;
  titleDescription: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureImageTextModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  BigTextPositionAsc = "bigTextPosition_ASC",
  BigTextPositionDesc = "bigTextPosition_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasBigTextAsc = "hasBigText_ASC",
  HasBigTextDesc = "hasBigText_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleDescriptionAsc = "titleDescription_ASC",
  TitleDescriptionDesc = "titleDescription_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - Image/Text (block_feature_image_text) */
export type BlockFeatureImageTextRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundImage: Maybe<FileField>;
  bigText: Maybe<Scalars["String"]>;
  bigTextPosition: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  descriptionContent: Array<TitleTextRecord>;
  hasBigText: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  leftBulletPoints: Array<TitleTextRecord>;
  mobileImage: Maybe<FileField>;
  rightBulletPoints: Array<TitleTextRecord>;
  title: Maybe<Scalars["String"]>;
  titleDescription: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - Image/Text (block_feature_image_text) */
export type BlockFeatureImageTextRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature - Image/Text (block_feature_image_text) */
export type BlockFeatureImageTextRecordBigTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockFeatureImageTitleModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureImageTitleModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureImageTitleModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureImageTitleModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - 4col Image/Title (block_feature_image_title) */
export type BlockFeatureImageTitleRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Array<FeatureImageTitleRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - 4col Image/Title (block_feature_image_title) */
export type BlockFeatureImageTitleRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature - Instagram Full (block_feature_instagram_full) */
export type BlockFeatureInstagramFullRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
  version: Maybe<Scalars["String"]>;
};

/** Record of type Block: Feature - Instagram Full (block_feature_instagram_full) */
export type BlockFeatureInstagramFullRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature - Instagram Product (block_feature_instagram_product) */
export type BlockFeatureInstagramProductRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  version: Maybe<Scalars["String"]>;
};

/** Record of type Block: Feature - Instagram Product (block_feature_instagram_product) */
export type BlockFeatureInstagramProductRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature - Instagram Slider (block_feature_instagram_slider) */
export type BlockFeatureInstagramSliderRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  version: Maybe<Scalars["String"]>;
};

/** Record of type Block: Feature - Instagram Slider (block_feature_instagram_slider) */
export type BlockFeatureInstagramSliderRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum BlockFeatureLinkCollectionModelFieldsReferencingProductModel {
  BlockFeatureLinkCollectionLinkCollection = "blockFeatureLinkCollection_linkCollection",
  BlockFeatureLinkCollectionLinkCollectionLinkInternalLink = "blockFeatureLinkCollection_linkCollection__link_internalLink",
}

export type BlockFeatureLinkCollectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureLinkCollectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureLinkCollectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  applyFilterGrayscaleToCardImage: InputMaybe<BooleanFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  title: InputMaybe<StringFilter>;
  typeOfLinks: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureLinkCollectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  ApplyFilterGrayscaleToCardImageAsc = "applyFilterGrayscaleToCardImage_ASC",
  ApplyFilterGrayscaleToCardImageDesc = "applyFilterGrayscaleToCardImage_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  TypeOfLinksAsc = "typeOfLinks_ASC",
  TypeOfLinksDesc = "typeOfLinks_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - Link Collection (block_feature_link_collection) */
export type BlockFeatureLinkCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  applyFilterGrayscaleToCardImage: Maybe<Scalars["BooleanType"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  linkCollection: Array<LinkRecord>;
  title: Maybe<Scalars["String"]>;
  typeOfLinks: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - Link Collection (block_feature_link_collection) */
export type BlockFeatureLinkCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockFeatureQuoteModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureQuoteModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureQuoteModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  author: InputMaybe<StringFilter>;
  blockSpacing: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  quote: InputMaybe<TextFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureQuoteModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AuthorAsc = "author_ASC",
  AuthorDesc = "author_DESC",
  BlockSpacingAsc = "blockSpacing_ASC",
  BlockSpacingDesc = "blockSpacing_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature Quote (block_feature_quote) */
export type BlockFeatureQuoteRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  author: Maybe<Scalars["String"]>;
  blockSpacing: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  quote: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature Quote (block_feature_quote) */
export type BlockFeatureQuoteRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature Quote (block_feature_quote) */
export type BlockFeatureQuoteRecordQuoteArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockFeatureReviewCollectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureReviewCollectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureReviewCollectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  reviewCollection: InputMaybe<LinksFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitleHtml: InputMaybe<TextFilter>;
  title: InputMaybe<StringFilter>;
  titleHtml: InputMaybe<TextFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureReviewCollectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature-Review Collection (block_feature_review_collection) */
export type BlockFeatureReviewCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  reviewCollection: Array<ReviewRecord>;
  subtitle: Maybe<Scalars["String"]>;
  subtitleHtml: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleHtml: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature-Review Collection (block_feature_review_collection) */
export type BlockFeatureReviewCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature-Review Collection (block_feature_review_collection) */
export type BlockFeatureReviewCollectionRecordSubtitleHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block: Feature-Review Collection (block_feature_review_collection) */
export type BlockFeatureReviewCollectionRecordTitleHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Linking fields */
export enum BlockFeatureRowImageCardModelFieldsReferencingProductModel {
  BlockFeatureRowImageCardContent = "blockFeatureRowImageCard_content",
  BlockFeatureRowImageCardContentFeatureImageCardOptionalLink = "blockFeatureRowImageCard_content__featureImageCardOptional_link",
}

export type BlockFeatureRowImageCardModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureRowImageCardModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureRowImageCardModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureRowImageCardModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block:Feature-Row-3col Image/Title/Optional LinkCard (block_feature_row_image_card) */
export type BlockFeatureRowImageCardRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Array<FeatureImageCardOptionalRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block:Feature-Row-3col Image/Title/Optional LinkCard (block_feature_row_image_card) */
export type BlockFeatureRowImageCardRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockFeatureTestimonialModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureTestimonialModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureTestimonialModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasTestimonyWithImage: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureTestimonialModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasTestimonyWithImageAsc = "hasTestimonyWithImage_ASC",
  HasTestimonyWithImageDesc = "hasTestimonyWithImage_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - Testimonials (block_feature_testimonial) */
export type BlockFeatureTestimonialRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  content: Array<FeatureTestimonialRecord>;
  createdAt: Scalars["DateTime"];
  hasTestimonyWithImage: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  testimony: Array<FeatureTestimonialRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - Testimonials (block_feature_testimonial) */
export type BlockFeatureTestimonialRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum BlockFeatureText2imagesButtonModelFieldsReferencingProductModel {
  BlockFeatureText2imagesButtonButton = "blockFeatureText2imagesButton_button",
  BlockFeatureText2imagesButtonButtonInternalLinkLink = "blockFeatureText2imagesButton_button__internalLink_link",
}

export type BlockFeatureText2imagesButtonModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureText2imagesButtonModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureText2imagesButtonModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  background: InputMaybe<ColorFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  description: InputMaybe<StringFilter>;
  descriptionHtml: InputMaybe<TextFilter>;
  hasAnImageAndText: InputMaybe<BooleanFilter>;
  hasButton: InputMaybe<BooleanFilter>;
  hasImages: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  paragraph: InputMaybe<TextFilter>;
  subtitlePosition: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  titleHtml: InputMaybe<TextFilter>;
  titleLogo: InputMaybe<FileFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureText2imagesButtonModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  HasAnImageAndTextAsc = "hasAnImageAndText_ASC",
  HasAnImageAndTextDesc = "hasAnImageAndText_DESC",
  HasButtonAsc = "hasButton_ASC",
  HasButtonDesc = "hasButton_DESC",
  HasImagesAsc = "hasImages_ASC",
  HasImagesDesc = "hasImages_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitlePositionAsc = "subtitlePosition_ASC",
  SubtitlePositionDesc = "subtitlePosition_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature/Text-2Images-Button (block_feature_text2images_button) */
export type BlockFeatureText2imagesButtonRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  background: Maybe<ColorField>;
  button: Array<InternalLinkRecord>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  descriptionHtml: Maybe<Scalars["String"]>;
  externalButton: Array<ExternalLinkRecord>;
  hasAnImageAndText: Maybe<Scalars["BooleanType"]>;
  hasButton: Maybe<Scalars["BooleanType"]>;
  hasImages: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  images: Array<FeatureImageTitleDescriptionRecord>;
  paragraph: Maybe<Scalars["String"]>;
  subtitlePosition: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleHtml: Maybe<Scalars["String"]>;
  titleLogo: Maybe<FileField>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature/Text-2Images-Button (block_feature_text2images_button) */
export type BlockFeatureText2imagesButtonRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature/Text-2Images-Button (block_feature_text2images_button) */
export type BlockFeatureText2imagesButtonRecordDescriptionHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block: Feature/Text-2Images-Button (block_feature_text2images_button) */
export type BlockFeatureText2imagesButtonRecordParagraphArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block: Feature/Text-2Images-Button (block_feature_text2images_button) */
export type BlockFeatureText2imagesButtonRecordTitleHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockFeatureTextIconModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureTextIconModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureTextIconModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureTextIconModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - Text-Icon (block_feature_text_icon) */
export type BlockFeatureTextIconRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  content: Array<FeatureImageFullRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - Text-Icon (block_feature_text_icon) */
export type BlockFeatureTextIconRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum BlockFeatureTextImageModuleModelFieldsReferencingProductModel {
  BlockFeatureTextImageModulePageLink = "blockFeatureTextImageModule_pageLink",
  BlockFeatureTextImageModulePageLinkInternalLinkLink = "blockFeatureTextImageModule_pageLink__internalLink_link",
}

export type BlockFeatureTextImageModuleModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureTextImageModuleModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureTextImageModuleModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  backgroundColor: InputMaybe<ColorFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  description: InputMaybe<TextFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  imageMobile: InputMaybe<FileFilter>;
  isMiniBanner: InputMaybe<BooleanFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureTextImageModuleModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsMiniBannerAsc = "isMiniBanner_ASC",
  IsMiniBannerDesc = "isMiniBanner_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - Text/Image-Module (block_feature_text_image_module) */
export type BlockFeatureTextImageModuleRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundColor: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  imageMobile: Maybe<FileField>;
  isMiniBanner: Maybe<Scalars["BooleanType"]>;
  pageLink: Array<InternalLinkRecord>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - Text/Image-Module (block_feature_text_image_module) */
export type BlockFeatureTextImageModuleRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature - Text/Image-Module (block_feature_text_image_module) */
export type BlockFeatureTextImageModuleRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockFeatureVideoModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeatureVideoModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeatureVideoModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeatureVideoModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature/Video (block_feature_video) */
export type BlockFeatureVideoRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Array<FeatureEmbeddedVideoRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature/Video (block_feature_video) */
export type BlockFeatureVideoRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum BlockFeaturedInModelFieldsReferencingProductModel {
  BlockFeaturedInGalleryImages = "blockFeaturedIn_galleryImages",
  BlockFeaturedInGalleryImagesLinkInternalLink = "blockFeaturedIn_galleryImages__link_internalLink",
}

export type BlockFeaturedInModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFeaturedInModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFeaturedInModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  blockSpacing: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  logos: InputMaybe<GalleryFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFeaturedInModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  BlockSpacingAsc = "blockSpacing_ASC",
  BlockSpacingDesc = "blockSpacing_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Featured In Logos (block_featured_in) */
export type BlockFeaturedInRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  blockSpacing: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  galleryImages: Array<LinkRecord>;
  id: Scalars["ItemId"];
  logos: Array<FileField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Featured In Logos (block_featured_in) */
export type BlockFeaturedInRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockFlowerCollectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockFlowerCollectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockFlowerCollectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  collection: InputMaybe<LinkFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockFlowerCollectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Flower Collection (block_flower_collection) */
export type BlockFlowerCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  collection: Maybe<FlowerCollectionRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Flower Collection (block_flower_collection) */
export type BlockFlowerCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockHeroAnimatedTextModelButtonsField =
  | ExternalLinkRecord
  | InternalLinkRecord;

/** Linking fields */
export enum BlockHeroAnimatedTextModelFieldsReferencingProductModel {
  BlockHeroAnimatedTextButtons = "blockHeroAnimatedText_buttons",
  BlockHeroAnimatedTextButtonsInternalLinkLink = "blockHeroAnimatedText_buttons__internalLink_link",
}

export type BlockHeroAnimatedTextModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockHeroAnimatedTextModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockHeroAnimatedTextModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  bgImage: InputMaybe<FileFilter>;
  centered: InputMaybe<BooleanFilter>;
  contentWidth: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  mobileImage: InputMaybe<FileFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitleAfter: InputMaybe<BooleanFilter>;
  text: InputMaybe<TextFilter>;
  theme: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  titleFontFamily: InputMaybe<StringFilter>;
  titleLogo: InputMaybe<FileFilter>;
  titleLogoAfter: InputMaybe<BooleanFilter>;
  titleSubtitleFontSize: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockHeroAnimatedTextModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CenteredAsc = "centered_ASC",
  CenteredDesc = "centered_DESC",
  ContentWidthAsc = "contentWidth_ASC",
  ContentWidthDesc = "contentWidth_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAfterAsc = "subtitleAfter_ASC",
  SubtitleAfterDesc = "subtitleAfter_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  ThemeAsc = "theme_ASC",
  ThemeDesc = "theme_DESC",
  TitleFontFamilyAsc = "titleFontFamily_ASC",
  TitleFontFamilyDesc = "titleFontFamily_DESC",
  TitleLogoAfterAsc = "titleLogoAfter_ASC",
  TitleLogoAfterDesc = "titleLogoAfter_DESC",
  TitleSubtitleFontSizeAsc = "titleSubtitleFontSize_ASC",
  TitleSubtitleFontSizeDesc = "titleSubtitleFontSize_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Hero Banner/Animated text (block_hero_animated_text) */
export type BlockHeroAnimatedTextRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  animatedText: Array<TextRecord>;
  bgImage: Maybe<FileField>;
  buttons: Array<BlockHeroAnimatedTextModelButtonsField>;
  centered: Maybe<Scalars["BooleanType"]>;
  contentWidth: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  mobileImage: Maybe<FileField>;
  subtitle: Maybe<Scalars["String"]>;
  subtitleAfter: Maybe<Scalars["BooleanType"]>;
  text: Maybe<Scalars["String"]>;
  theme: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleFontFamily: Maybe<Scalars["String"]>;
  titleLogo: Maybe<FileField>;
  titleLogoAfter: Maybe<Scalars["BooleanType"]>;
  titleSubtitleFontSize: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Hero Banner/Animated text (block_hero_animated_text) */
export type BlockHeroAnimatedTextRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Hero Banner/Animated text (block_hero_animated_text) */
export type BlockHeroAnimatedTextRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockHeroModelButtonsField =
  | ExternalLinkRecord
  | InternalLinkRecord;

/** Linking fields */
export enum BlockHeroModelFieldsReferencingProductModel {
  BlockHeroButtons = "blockHero_buttons",
  BlockHeroButtonsInternalLinkLink = "blockHero_buttons__internalLink_link",
}

export type BlockHeroModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockHeroModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockHeroModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  bgImage: InputMaybe<FileFilter>;
  bgVideo: InputMaybe<FileFilter>;
  bgVideoPosterImage: InputMaybe<FileFilter>;
  buttonTitle: InputMaybe<StringFilter>;
  centered: InputMaybe<BooleanFilter>;
  centeredMobile: InputMaybe<BooleanFilter>;
  colorButton: InputMaybe<ColorFilter>;
  contentWidth: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasAButtonToScrollToAComponent: InputMaybe<BooleanFilter>;
  hasBiggerButton: InputMaybe<BooleanFilter>;
  hasLightTheme: InputMaybe<BooleanFilter>;
  hideInMobileVersion: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  isBigVersionLogo: InputMaybe<BooleanFilter>;
  isShortHeight: InputMaybe<BooleanFilter>;
  isTitleFontBig: InputMaybe<BooleanFilter>;
  isTitleH1: InputMaybe<BooleanFilter>;
  mobileImage: InputMaybe<FileFilter>;
  scrollTo: InputMaybe<StringFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitleAfter: InputMaybe<BooleanFilter>;
  subtitleFontFamily: InputMaybe<StringFilter>;
  tagType: InputMaybe<StringFilter>;
  text: InputMaybe<TextFilter>;
  textMobile: InputMaybe<TextFilter>;
  theme: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  titleFontFamily: InputMaybe<StringFilter>;
  titleLogo: InputMaybe<FileFilter>;
  titleLogoAfter: InputMaybe<BooleanFilter>;
  titleLogoMobile: InputMaybe<FileFilter>;
  titlePadded: InputMaybe<BooleanFilter>;
  titleSubtitleFontSize: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  variantStyle: InputMaybe<StringFilter>;
};

export enum BlockHeroModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  ButtonTitleAsc = "buttonTitle_ASC",
  ButtonTitleDesc = "buttonTitle_DESC",
  CenteredMobileAsc = "centeredMobile_ASC",
  CenteredMobileDesc = "centeredMobile_DESC",
  CenteredAsc = "centered_ASC",
  CenteredDesc = "centered_DESC",
  ContentWidthAsc = "contentWidth_ASC",
  ContentWidthDesc = "contentWidth_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasAButtonToScrollToAComponentAsc = "hasAButtonToScrollToAComponent_ASC",
  HasAButtonToScrollToAComponentDesc = "hasAButtonToScrollToAComponent_DESC",
  HasBiggerButtonAsc = "hasBiggerButton_ASC",
  HasBiggerButtonDesc = "hasBiggerButton_DESC",
  HasLightThemeAsc = "hasLightTheme_ASC",
  HasLightThemeDesc = "hasLightTheme_DESC",
  HideInMobileVersionAsc = "hideInMobileVersion_ASC",
  HideInMobileVersionDesc = "hideInMobileVersion_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsBigVersionLogoAsc = "isBigVersionLogo_ASC",
  IsBigVersionLogoDesc = "isBigVersionLogo_DESC",
  IsShortHeightAsc = "isShortHeight_ASC",
  IsShortHeightDesc = "isShortHeight_DESC",
  IsTitleFontBigAsc = "isTitleFontBig_ASC",
  IsTitleFontBigDesc = "isTitleFontBig_DESC",
  IsTitleH1Asc = "isTitleH1_ASC",
  IsTitleH1Desc = "isTitleH1_DESC",
  ScrollToAsc = "scrollTo_ASC",
  ScrollToDesc = "scrollTo_DESC",
  SubtitleAfterAsc = "subtitleAfter_ASC",
  SubtitleAfterDesc = "subtitleAfter_DESC",
  SubtitleFontFamilyAsc = "subtitleFontFamily_ASC",
  SubtitleFontFamilyDesc = "subtitleFontFamily_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TagTypeAsc = "tagType_ASC",
  TagTypeDesc = "tagType_DESC",
  ThemeAsc = "theme_ASC",
  ThemeDesc = "theme_DESC",
  TitleFontFamilyAsc = "titleFontFamily_ASC",
  TitleFontFamilyDesc = "titleFontFamily_DESC",
  TitleLogoAfterAsc = "titleLogoAfter_ASC",
  TitleLogoAfterDesc = "titleLogoAfter_DESC",
  TitlePaddedAsc = "titlePadded_ASC",
  TitlePaddedDesc = "titlePadded_DESC",
  TitleSubtitleFontSizeAsc = "titleSubtitleFontSize_ASC",
  TitleSubtitleFontSizeDesc = "titleSubtitleFontSize_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  VariantStyleAsc = "variantStyle_ASC",
  VariantStyleDesc = "variantStyle_DESC",
}

/** Record of type Block: Hero Banner (block_hero) */
export type BlockHeroRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  bgImage: Maybe<FileField>;
  bgVideo: Maybe<FileField>;
  bgVideoPosterImage: Maybe<FileField>;
  buttonTitle: Maybe<Scalars["String"]>;
  buttons: Array<BlockHeroModelButtonsField>;
  centered: Maybe<Scalars["BooleanType"]>;
  centeredMobile: Maybe<Scalars["BooleanType"]>;
  colorButton: Maybe<ColorField>;
  contentWidth: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  hasAButtonToScrollToAComponent: Maybe<Scalars["BooleanType"]>;
  hasBiggerButton: Maybe<Scalars["BooleanType"]>;
  hasLightTheme: Maybe<Scalars["BooleanType"]>;
  hideInMobileVersion: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  isBigVersionLogo: Maybe<Scalars["BooleanType"]>;
  isShortHeight: Maybe<Scalars["BooleanType"]>;
  isTitleFontBig: Maybe<Scalars["BooleanType"]>;
  isTitleH1: Maybe<Scalars["BooleanType"]>;
  mobileImage: Maybe<FileField>;
  scrollTo: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  subtitleAfter: Maybe<Scalars["BooleanType"]>;
  subtitleFontFamily: Maybe<Scalars["String"]>;
  tagType: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  textMobile: Maybe<Scalars["String"]>;
  theme: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleFontFamily: Maybe<Scalars["String"]>;
  titleLogo: Maybe<FileField>;
  titleLogoAfter: Maybe<Scalars["BooleanType"]>;
  titleLogoMobile: Maybe<FileField>;
  titlePadded: Maybe<Scalars["BooleanType"]>;
  titleSubtitleFontSize: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  variantStyle: Maybe<Scalars["String"]>;
};

/** Record of type Block: Hero Banner (block_hero) */
export type BlockHeroRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Hero Banner (block_hero) */
export type BlockHeroRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block: Hero Banner (block_hero) */
export type BlockHeroRecordTextMobileArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockHerobanner2columnModelButtonsField =
  | ExternalLinkRecord
  | InternalLinkRecord;

/** Linking fields */
export enum BlockHerobanner2columnModelFieldsReferencingProductModel {
  BlockHerobanner2columnButtons = "blockHerobanner2column_buttons",
  BlockHerobanner2columnButtonsInternalLinkLink = "blockHerobanner2column_buttons__internalLink_link",
  BlockHerobanner2columnProducts = "blockHerobanner2column_products",
}

export type BlockHerobanner2columnModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockHerobanner2columnModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockHerobanner2columnModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  bgImage: InputMaybe<FileFilter>;
  buttonTitle: InputMaybe<StringFilter>;
  colorButton: InputMaybe<ColorFilter>;
  contentPosition: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasLightTheme: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  imagePosition: InputMaybe<StringFilter>;
  mobileImage: InputMaybe<FileFilter>;
  products: InputMaybe<LinksFilter>;
  subTitleHtml: InputMaybe<TextFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitleAfter: InputMaybe<BooleanFilter>;
  subtitleFontFamily: InputMaybe<StringFilter>;
  theme: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  titleFontFamily: InputMaybe<StringFilter>;
  titleHtml: InputMaybe<TextFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  variantStyle: InputMaybe<StringFilter>;
};

export enum BlockHerobanner2columnModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  ButtonTitleAsc = "buttonTitle_ASC",
  ButtonTitleDesc = "buttonTitle_DESC",
  ContentPositionAsc = "contentPosition_ASC",
  ContentPositionDesc = "contentPosition_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasLightThemeAsc = "hasLightTheme_ASC",
  HasLightThemeDesc = "hasLightTheme_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ImagePositionAsc = "imagePosition_ASC",
  ImagePositionDesc = "imagePosition_DESC",
  SubtitleAfterAsc = "subtitleAfter_ASC",
  SubtitleAfterDesc = "subtitleAfter_DESC",
  SubtitleFontFamilyAsc = "subtitleFontFamily_ASC",
  SubtitleFontFamilyDesc = "subtitleFontFamily_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  ThemeAsc = "theme_ASC",
  ThemeDesc = "theme_DESC",
  TitleFontFamilyAsc = "titleFontFamily_ASC",
  TitleFontFamilyDesc = "titleFontFamily_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  VariantStyleAsc = "variantStyle_ASC",
  VariantStyleDesc = "variantStyle_DESC",
}

/** Record of type Block HeroBanner-2Columns (block_herobanner2column) */
export type BlockHerobanner2columnRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  bgImage: Maybe<FileField>;
  buttonTitle: Maybe<Scalars["String"]>;
  buttons: Array<BlockHerobanner2columnModelButtonsField>;
  colorButton: Maybe<ColorField>;
  contentPosition: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  hasLightTheme: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  imagePosition: Maybe<Scalars["String"]>;
  mobileImage: Maybe<FileField>;
  products: Array<ProductRecord>;
  subTitleHtml: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  subtitleAfter: Maybe<Scalars["BooleanType"]>;
  subtitleFontFamily: Maybe<Scalars["String"]>;
  theme: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleFontFamily: Maybe<Scalars["String"]>;
  titleHtml: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  variantStyle: Maybe<Scalars["String"]>;
};

/** Record of type Block HeroBanner-2Columns (block_herobanner2column) */
export type BlockHerobanner2columnRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block HeroBanner-2Columns (block_herobanner2column) */
export type BlockHerobanner2columnRecordSubTitleHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block HeroBanner-2Columns (block_herobanner2column) */
export type BlockHerobanner2columnRecordTitleHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockImageGridModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockImageGridModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockImageGridModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  blockSpacing: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  images: InputMaybe<GalleryFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockImageGridModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  BlockSpacingAsc = "blockSpacing_ASC",
  BlockSpacingDesc = "blockSpacing_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Image Grid (block_image_grid) */
export type BlockImageGridRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  blockSpacing: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  images: Array<FileField>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Image Grid (block_image_grid) */
export type BlockImageGridRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockMainFeatureContainerModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockMainFeatureContainerModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockMainFeatureContainerModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  content: InputMaybe<LinksFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockMainFeatureContainerModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Main Feature Container (block_main_feature_container) */
export type BlockMainFeatureContainerRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Array<BlockMainFeatureRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Main Feature Container (block_main_feature_container) */
export type BlockMainFeatureContainerRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockMainFeatureModelButtonsField =
  | ExternalLinkRecord
  | InternalLinkRecord;

/** Linking fields */
export enum BlockMainFeatureModelFieldsReferencingProductModel {
  BlockMainFeatureButtons = "blockMainFeature_buttons",
  BlockMainFeatureButtonsInternalLinkLink = "blockMainFeature_buttons__internalLink_link",
  BlockMainFeatureProduct = "blockMainFeature_product",
}

export type BlockMainFeatureModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockMainFeatureModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockMainFeatureModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  backgroundColor: InputMaybe<ColorFilter>;
  colorText: InputMaybe<ColorFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  fontFamily: InputMaybe<StringFilter>;
  gallery: InputMaybe<GalleryFilter>;
  hasBulletPoints: InputMaybe<BooleanFilter>;
  hasDescriptionAsTable: InputMaybe<BooleanFilter>;
  hasPersonalizedDescription: InputMaybe<BooleanFilter>;
  hasSlider: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  imagePosition: InputMaybe<StringFilter>;
  imageWidth: InputMaybe<StringFilter>;
  isTitleH1: InputMaybe<BooleanFilter>;
  product: InputMaybe<LinkFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitlePosition: InputMaybe<StringFilter>;
  text: InputMaybe<TextFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockMainFeatureModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  FontFamilyAsc = "fontFamily_ASC",
  FontFamilyDesc = "fontFamily_DESC",
  HasBulletPointsAsc = "hasBulletPoints_ASC",
  HasBulletPointsDesc = "hasBulletPoints_DESC",
  HasDescriptionAsTableAsc = "hasDescriptionAsTable_ASC",
  HasDescriptionAsTableDesc = "hasDescriptionAsTable_DESC",
  HasPersonalizedDescriptionAsc = "hasPersonalizedDescription_ASC",
  HasPersonalizedDescriptionDesc = "hasPersonalizedDescription_DESC",
  HasSliderAsc = "hasSlider_ASC",
  HasSliderDesc = "hasSlider_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ImagePositionAsc = "imagePosition_ASC",
  ImagePositionDesc = "imagePosition_DESC",
  ImageWidthAsc = "imageWidth_ASC",
  ImageWidthDesc = "imageWidth_DESC",
  IsTitleH1Asc = "isTitleH1_ASC",
  IsTitleH1Desc = "isTitleH1_DESC",
  SubtitlePositionAsc = "subtitlePosition_ASC",
  SubtitlePositionDesc = "subtitlePosition_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Main Feature (block_main_feature) */
export type BlockMainFeatureRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundColor: Maybe<ColorField>;
  bulletPoints: Array<TitleTextRecord>;
  buttons: Array<BlockMainFeatureModelButtonsField>;
  colorText: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  fontFamily: Maybe<Scalars["String"]>;
  gallery: Array<FileField>;
  hasBulletPoints: Maybe<Scalars["BooleanType"]>;
  hasDescriptionAsTable: Maybe<Scalars["BooleanType"]>;
  hasPersonalizedDescription: Maybe<Scalars["BooleanType"]>;
  hasSlider: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  imagePosition: Maybe<Scalars["String"]>;
  imageWidth: Maybe<Scalars["String"]>;
  isTitleH1: Maybe<Scalars["BooleanType"]>;
  product: Maybe<ProductRecord>;
  rows: Array<TextRecord>;
  subtitle: Maybe<Scalars["String"]>;
  subtitlePosition: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Main Feature (block_main_feature) */
export type BlockMainFeatureRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Main Feature (block_main_feature) */
export type BlockMainFeatureRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block: Plant Collection (block_plant_collection) */
export type BlockPlantCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  collection: Maybe<PlantCollectionRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Plant Collection (block_plant_collection) */
export type BlockPlantCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockProductCollectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockProductCollectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockProductCollectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  collection: InputMaybe<LinkFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockProductCollectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Product Collection (block_product_collection) */
export type BlockProductCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  collection: Maybe<ProductCollectionRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Product Collection (block_product_collection) */
export type BlockProductCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlockProseColumnModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockProseColumnModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockProseColumnModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  firstColumn: InputMaybe<TextFilter>;
  id: InputMaybe<ItemIdFilter>;
  secondColumn: InputMaybe<TextFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockProseColumnModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature - 2Col/Prose/HTML Content (block_prose_column) */
export type BlockProseColumnRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  firstColumn: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  secondColumn: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature - 2Col/Prose/HTML Content (block_prose_column) */
export type BlockProseColumnRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Feature - 2Col/Prose/HTML Content (block_prose_column) */
export type BlockProseColumnRecordFirstColumnArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block: Feature - 2Col/Prose/HTML Content (block_prose_column) */
export type BlockProseColumnRecordSecondColumnArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockProseColumnTitleBgimageModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockProseColumnTitleBgimageModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockProseColumnTitleBgimageModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  bgImage: InputMaybe<FileFilter>;
  columnWidth: InputMaybe<StringFilter>;
  contentPosition: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  firstColumn: InputMaybe<TextFilter>;
  fontFamily: InputMaybe<StringFilter>;
  fontSize: InputMaybe<StringFilter>;
  fontSizeFirstTitle: InputMaybe<StringFilter>;
  fontSizeSecondTitle: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  mainSubtitle: InputMaybe<StringFilter>;
  mainTitle: InputMaybe<StringFilter>;
  mobileImage: InputMaybe<FileFilter>;
  secondColumn: InputMaybe<TextFilter>;
  subtitlePosition: InputMaybe<StringFilter>;
  textFontFamily: InputMaybe<StringFilter>;
  titleFirstCol: InputMaybe<StringFilter>;
  titleFontFamily: InputMaybe<StringFilter>;
  titleSecondCol: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockProseColumnTitleBgimageModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  ColumnWidthAsc = "columnWidth_ASC",
  ColumnWidthDesc = "columnWidth_DESC",
  ContentPositionAsc = "contentPosition_ASC",
  ContentPositionDesc = "contentPosition_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  FontFamilyAsc = "fontFamily_ASC",
  FontFamilyDesc = "fontFamily_DESC",
  FontSizeFirstTitleAsc = "fontSizeFirstTitle_ASC",
  FontSizeFirstTitleDesc = "fontSizeFirstTitle_DESC",
  FontSizeSecondTitleAsc = "fontSizeSecondTitle_ASC",
  FontSizeSecondTitleDesc = "fontSizeSecondTitle_DESC",
  FontSizeAsc = "fontSize_ASC",
  FontSizeDesc = "fontSize_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  MainSubtitleAsc = "mainSubtitle_ASC",
  MainSubtitleDesc = "mainSubtitle_DESC",
  MainTitleAsc = "mainTitle_ASC",
  MainTitleDesc = "mainTitle_DESC",
  SubtitlePositionAsc = "subtitlePosition_ASC",
  SubtitlePositionDesc = "subtitlePosition_DESC",
  TextFontFamilyAsc = "textFontFamily_ASC",
  TextFontFamilyDesc = "textFontFamily_DESC",
  TitleFirstColAsc = "titleFirstCol_ASC",
  TitleFirstColDesc = "titleFirstCol_DESC",
  TitleFontFamilyAsc = "titleFontFamily_ASC",
  TitleFontFamilyDesc = "titleFontFamily_DESC",
  TitleSecondColAsc = "titleSecondCol_ASC",
  TitleSecondColDesc = "titleSecondCol_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: 2Col-Title/Prose/HTML Content/Bg Image (block_prose_column_title_bgimage) */
export type BlockProseColumnTitleBgimageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  bgImage: Maybe<FileField>;
  columnWidth: Maybe<Scalars["String"]>;
  contentPosition: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  firstColumn: Maybe<Scalars["String"]>;
  fontFamily: Maybe<Scalars["String"]>;
  fontSize: Maybe<Scalars["String"]>;
  fontSizeFirstTitle: Maybe<Scalars["String"]>;
  fontSizeSecondTitle: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  mainSubtitle: Maybe<Scalars["String"]>;
  mainTitle: Maybe<Scalars["String"]>;
  mobileImage: Maybe<FileField>;
  secondColumn: Maybe<Scalars["String"]>;
  subtitlePosition: Maybe<Scalars["String"]>;
  textFontFamily: Maybe<Scalars["String"]>;
  titleFirstCol: Maybe<Scalars["String"]>;
  titleFontFamily: Maybe<Scalars["String"]>;
  titleSecondCol: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: 2Col-Title/Prose/HTML Content/Bg Image (block_prose_column_title_bgimage) */
export type BlockProseColumnTitleBgimageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: 2Col-Title/Prose/HTML Content/Bg Image (block_prose_column_title_bgimage) */
export type BlockProseColumnTitleBgimageRecordFirstColumnArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Block: 2Col-Title/Prose/HTML Content/Bg Image (block_prose_column_title_bgimage) */
export type BlockProseColumnTitleBgimageRecordSecondColumnArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockProseModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockProseModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockProseModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  largeTypography: InputMaybe<BooleanFilter>;
  proseContent: InputMaybe<TextFilter>;
  showOnMobileVersion: InputMaybe<BooleanFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockProseModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  LargeTypographyAsc = "largeTypography_ASC",
  LargeTypographyDesc = "largeTypography_DESC",
  ShowOnMobileVersionAsc = "showOnMobileVersion_ASC",
  ShowOnMobileVersionDesc = "showOnMobileVersion_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Prose/HTML Content (block_prose) */
export type BlockProseRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  largeTypography: Maybe<Scalars["BooleanType"]>;
  proseContent: Maybe<Scalars["String"]>;
  showOnMobileVersion: Maybe<Scalars["BooleanType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Prose/HTML Content (block_prose) */
export type BlockProseRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Block: Prose/HTML Content (block_prose) */
export type BlockProseRecordProseContentArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type BlockRowCardImageTitleModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlockRowCardImageTitleModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlockRowCardImageTitleModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  description: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  showSubtitleAfterTitle: InputMaybe<BooleanFilter>;
  subtitle: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlockRowCardImageTitleModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ShowSubtitleAfterTitleAsc = "showSubtitleAfterTitle_ASC",
  ShowSubtitleAfterTitleDesc = "showSubtitleAfterTitle_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Block: Feature Row-1Card-2Image/Title (block_row_card_image_title) */
export type BlockRowCardImageTitleRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Array<ImageTitleBackgroundColorRecord>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  showSubtitleAfterTitle: Maybe<Scalars["BooleanType"]>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Block: Feature Row-1Card-2Image/Title (block_row_card_image_title) */
export type BlockRowCardImageTitleRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type BlogCategoryModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlogCategoryModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlogCategoryModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  categoryDescription: InputMaybe<TextFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  description: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  seo: InputMaybe<SeoFilter>;
  slug: InputMaybe<SlugFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  visibleInBlogPage: InputMaybe<BooleanFilter>;
};

export enum BlogCategoryModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  VisibleInBlogPageAsc = "visibleInBlogPage_ASC",
  VisibleInBlogPageDesc = "visibleInBlogPage_DESC",
}

/** Record of type Blog Category (blog_category) */
export type BlogCategoryRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  categoryDescription: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  visibleInBlogPage: Maybe<Scalars["BooleanType"]>;
};

/** Record of type Blog Category (blog_category) */
export type BlogCategoryRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Blog Category (blog_category) */
export type BlogCategoryRecordCategoryDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Page (blog_page) */
export type BlogPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Blog Page (blog_page) */
export type BlogPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Blog Page (blog_page) */
export type BlogPageRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Linking fields */
export enum BlogPostModelFieldsReferencingProductModel {
  BlogPostButton = "blogPost_button",
  BlogPostButtonInternalLinkLink = "blogPost_button__internalLink_link",
  BlogPostImageCardFirstBlock = "blogPost_imageCardFirstBlock",
  BlogPostImageCardFirstBlockFeatureImageCardLink = "blogPost_imageCardFirstBlock__featureImageCard_link",
  BlogPostImageCardSecondBlock = "blogPost_imageCardSecondBlock",
  BlogPostImageCardSecondBlockFeatureImageCardLink = "blogPost_imageCardSecondBlock__featureImageCard_link",
}

export type BlogPostModelFilter = {
  AND: InputMaybe<Array<InputMaybe<BlogPostModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BlogPostModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  category: InputMaybe<LinkFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  firstText: InputMaybe<TextFilter>;
  hasInfoChip: InputMaybe<BooleanFilter>;
  hasNewTemplate: InputMaybe<BooleanFilter>;
  hideFromSearchEngines: InputMaybe<BooleanFilter>;
  highlightedText: InputMaybe<TextFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  preview: InputMaybe<JsonFilter>;
  primaryImage: InputMaybe<FileFilter>;
  publicationDate: InputMaybe<DateFilter>;
  revalidate: InputMaybe<JsonFilter>;
  secondText: InputMaybe<TextFilter>;
  secondTextBeforeImages: InputMaybe<TextFilter>;
  secondaryImage: InputMaybe<FileFilter>;
  sections: InputMaybe<LinksFilter>;
  seo: InputMaybe<SeoFilter>;
  slug: InputMaybe<SlugFilter>;
  subCategory: InputMaybe<LinkFilter>;
  text: InputMaybe<TextFilter>;
  textAfterImages: InputMaybe<TextFilter>;
  textBeforeImages: InputMaybe<TextFilter>;
  thirdText: InputMaybe<TextFilter>;
  title: InputMaybe<StringFilter>;
  titleNewTemplate: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum BlogPostModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasInfoChipAsc = "hasInfoChip_ASC",
  HasInfoChipDesc = "hasInfoChip_DESC",
  HasNewTemplateAsc = "hasNewTemplate_ASC",
  HasNewTemplateDesc = "hasNewTemplate_DESC",
  HideFromSearchEnginesAsc = "hideFromSearchEngines_ASC",
  HideFromSearchEnginesDesc = "hideFromSearchEngines_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  PublicationDateAsc = "publicationDate_ASC",
  PublicationDateDesc = "publicationDate_DESC",
  TitleNewTemplateAsc = "titleNewTemplate_ASC",
  TitleNewTemplateDesc = "titleNewTemplate_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Blog Post (blog_post) */
export type BlogPostRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  button: Array<InternalLinkRecord>;
  category: Maybe<BlogCategoryRecord>;
  createdAt: Scalars["DateTime"];
  firstText: Maybe<Scalars["String"]>;
  hasInfoChip: Maybe<Scalars["BooleanType"]>;
  hasNewTemplate: Maybe<Scalars["BooleanType"]>;
  hideFromSearchEngines: Maybe<Scalars["BooleanType"]>;
  highlightedText: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  imageCardFirstBlock: Array<FeatureImageCardRecord>;
  imageCardSecondBlock: Array<FeatureImageCardRecord>;
  infoChip: Array<FeatureImageFullRecord>;
  preview: Maybe<Scalars["JsonField"]>;
  primaryImage: Maybe<FileField>;
  publicationDate: Maybe<Scalars["Date"]>;
  revalidate: Maybe<Scalars["JsonField"]>;
  secondText: Maybe<Scalars["String"]>;
  secondTextBeforeImages: Maybe<Scalars["String"]>;
  secondaryImage: Maybe<FileField>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subCategory: Maybe<BlogCategoryRecord>;
  text: Maybe<Scalars["String"]>;
  textAfterImages: Maybe<Scalars["String"]>;
  textBeforeImages: Maybe<Scalars["String"]>;
  thirdText: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleNewTemplate: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordFirstTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordHighlightedTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordSecondTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordSecondTextBeforeImagesArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordTextAfterImagesArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordTextBeforeImagesArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Blog Post (blog_post) */
export type BlogPostRecordThirdTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq: InputMaybe<Scalars["BooleanType"]>;
};

export type CollectionMetadata = {
  count: Scalars["IntType"];
};

export enum ColorBucketType {
  Black = "black",
  Blue = "blue",
  Brown = "brown",
  Cyan = "cyan",
  Green = "green",
  Grey = "grey",
  Orange = "orange",
  Pink = "pink",
  Purple = "purple",
  Red = "red",
  White = "white",
  Yellow = "yellow",
}

export type ColorField = {
  alpha: Scalars["IntType"];
  blue: Scalars["IntType"];
  cssRgb: Scalars["String"];
  green: Scalars["IntType"];
  hex: Scalars["String"];
  red: Scalars["IntType"];
};

/** Specifies how to filter Color fields */
export type ColorFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
};

/** Block of type Config Dates Product (config_dates_per_product_copy1) */
export type ConfigDatesPerProductCopy1Record = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  applyTo: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  disabledWeekdays: Array<DisabledWeekdayRecord>;
  enabledWeekdays: Array<EnabledWeekdayRecord>;
  id: Scalars["ItemId"];
  maximumDateInDays: Maybe<Scalars["IntType"]>;
  minimumDateInDays: Maybe<Scalars["IntType"]>;
  note: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Config Dates Product (config_dates_per_product_copy1) */
export type ConfigDatesPerProductCopy1Record_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Config Dates Per Product (config_dates_per_product) */
export type ConfigDatesPerProductRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  disabledWeekdays: Array<DisabledWeekdayRecord>;
  enabledWeekdays: Array<EnabledWeekdayRecord>;
  id: Scalars["ItemId"];
  maximumDateInDays: Maybe<Scalars["IntType"]>;
  minimumDateInDays: Maybe<Scalars["IntType"]>;
  note: Maybe<Scalars["String"]>;
  products: Array<ProductRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Config Dates Per Product (config_dates_per_product) */
export type ConfigDatesPerProductRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Contact Us (contact_us_page) */
export type ContactUsPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  contactBlock: Array<TitleTextRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Contact Us (contact_us_page) */
export type ContactUsPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Contact Us (contact_us_page) */
export type ContactUsPageRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type ContentSectionModelContentField =
  | BlockCtaRecord
  | BlockFeatTestimonialsCompaniesSliderRecord
  | BlockFeatureContentOverlayRecord
  | BlockFeatureDiscountCardRecord
  | BlockFeatureImageCardRecord
  | BlockFeatureImageFullRecord
  | BlockFeatureImageTextRecord
  | BlockFeatureImageTitleRecord
  | BlockFeatureInstagramFullRecord
  | BlockFeatureInstagramProductRecord
  | BlockFeatureInstagramSliderRecord
  | BlockFeatureLinkCollectionRecord
  | BlockFeatureQuoteRecord
  | BlockFeatureReviewCollectionRecord
  | BlockFeatureRowImageCardRecord
  | BlockFeatureTestimonialRecord
  | BlockFeatureText2imagesButtonRecord
  | BlockFeatureTextIconRecord
  | BlockFeatureTextImageModuleRecord
  | BlockFeatureVideoRecord
  | BlockFeaturedInRecord
  | BlockFlowerCollectionRecord
  | BlockHeroAnimatedTextRecord
  | BlockHeroRecord
  | BlockHerobanner2columnRecord
  | BlockImageGridRecord
  | BlockMainFeatureContainerRecord
  | BlockMainFeatureRecord
  | BlockPlantCollectionRecord
  | BlockProductCollectionRecord
  | BlockProseColumnRecord
  | BlockProseColumnTitleBgimageRecord
  | BlockProseRecord
  | BlockRowCardImageTitleRecord
  | EmbeddedFormRecord
  | FaqsCollectionRecord
  | ProductBestSellerRecord
  | ProductCollectionSliderRecord;

export type ContentSectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<ContentSectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ContentSectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  bgColor: InputMaybe<StringFilter>;
  bgImage: InputMaybe<FileFilter>;
  content: InputMaybe<LinksFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  fontFamily: InputMaybe<StringFilter>;
  fontSize: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  isTitleH1: InputMaybe<BooleanFilter>;
  sectionPadded: InputMaybe<BooleanFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitlePos: InputMaybe<StringFilter>;
  text: InputMaybe<TextFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum ContentSectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  BgColorAsc = "bgColor_ASC",
  BgColorDesc = "bgColor_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  FontFamilyAsc = "fontFamily_ASC",
  FontFamilyDesc = "fontFamily_DESC",
  FontSizeAsc = "fontSize_ASC",
  FontSizeDesc = "fontSize_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsTitleH1Asc = "isTitleH1_ASC",
  IsTitleH1Desc = "isTitleH1_DESC",
  SectionPaddedAsc = "sectionPadded_ASC",
  SectionPaddedDesc = "sectionPadded_DESC",
  SubtitlePosAsc = "subtitlePos_ASC",
  SubtitlePosDesc = "subtitlePos_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Content Section (content_section) */
export type ContentSectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  bgColor: Maybe<Scalars["String"]>;
  bgImage: Maybe<FileField>;
  content: Array<ContentSectionModelContentField>;
  createdAt: Scalars["DateTime"];
  fontFamily: Maybe<Scalars["String"]>;
  fontSize: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  isTitleH1: Maybe<Scalars["BooleanType"]>;
  sectionPadded: Maybe<Scalars["BooleanType"]>;
  subtitle: Maybe<Scalars["String"]>;
  subtitlePos: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Content Section (content_section) */
export type ContentSectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Content Section (content_section) */
export type ContentSectionRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq: InputMaybe<Scalars["DateTime"]>;
};

/** Block of type Cutoff Dates (cutoff_date) */
export type CutoffDateRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  maxDeliveryDate: Maybe<Scalars["Date"]>;
  minDeliveryDate: Maybe<Scalars["Date"]>;
  orderDate: Maybe<Scalars["Date"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Cutoff Dates (cutoff_date) */
export type CutoffDateRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Cutoff Dates Per Product (copy #1) (cutoff_dates_per_product_copy1) */
export type CutoffDatesPerProductCopy1Record = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  applyTo: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  cutoffDates: Array<CutoffDateRecord>;
  id: Scalars["ItemId"];
  note: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Cutoff Dates Per Product (copy #1) (cutoff_dates_per_product_copy1) */
export type CutoffDatesPerProductCopy1Record_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Cutoff Dates Per Product (cutoff_dates_per_product) */
export type CutoffDatesPerProductRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  cutoffDates: Array<CutoffDateRecord>;
  id: Scalars["ItemId"];
  note: Maybe<Scalars["String"]>;
  products: Array<ProductRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Cutoff Dates Per Product (cutoff_dates_per_product) */
export type CutoffDatesPerProductRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Date fields */
export type DateFilter = {
  /** Search for records with an exact match */
  eq: InputMaybe<Scalars["Date"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt: InputMaybe<Scalars["Date"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte: InputMaybe<Scalars["Date"]>;
  /** Filter records with a value that's less than the one specified */
  lt: InputMaybe<Scalars["Date"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte: InputMaybe<Scalars["Date"]>;
  /** Exclude records with an exact match */
  neq: InputMaybe<Scalars["Date"]>;
};

/** Linking fields */
export enum DatepickerConfigModelFieldsReferencingProductModel {
  DatepickerConfigConfigDatesPerProduct = "datepickerConfig_configDatesPerProduct",
  DatepickerConfigConfigDatesPerProductConfigDatesPerProductProducts = "datepickerConfig_configDatesPerProduct__configDatesPerProduct_products",
  DatepickerConfigCutoffDatesPerProduct = "datepickerConfig_cutoffDatesPerProduct",
  DatepickerConfigCutoffDatesPerProductCutoffDatesPerProductProducts = "datepickerConfig_cutoffDatesPerProduct__cutoffDatesPerProduct_products",
  DatepickerConfigDisabledDatesPerProduct = "datepickerConfig_disabledDatesPerProduct",
  DatepickerConfigDisabledDatesPerProductProductsDateRuleProducts = "datepickerConfig_disabledDatesPerProduct__productsDateRule_products",
  DatepickerConfigEnabledDatesPerProduct = "datepickerConfig_enabledDatesPerProduct",
  DatepickerConfigEnabledDatesPerProductProductsDateRuleProducts = "datepickerConfig_enabledDatesPerProduct__productsDateRule_products",
}

export type DatepickerConfigModelFilter = {
  AND: InputMaybe<Array<InputMaybe<DatepickerConfigModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<DatepickerConfigModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  maximumDateDays: InputMaybe<IntegerFilter>;
  minimumDateDays: InputMaybe<IntegerFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum DatepickerConfigModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  MaximumDateDaysAsc = "maximumDateDays_ASC",
  MaximumDateDaysDesc = "maximumDateDays_DESC",
  MinimumDateDaysAsc = "minimumDateDays_ASC",
  MinimumDateDaysDesc = "minimumDateDays_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Datepicker Config (datepicker_config) */
export type DatepickerConfigRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  configDatesPerProduct: Array<ConfigDatesPerProductRecord>;
  createdAt: Scalars["DateTime"];
  cutoffDatesPerProduct: Array<CutoffDatesPerProductRecord>;
  disabledDates: Array<DisabledMonthDayRecord>;
  disabledDatesPerProduct: Array<ProductsDateRuleRecord>;
  disabledWeekdays: Array<DisabledWeekdayRecord>;
  enabledDatesPerProduct: Array<ProductsDateRuleRecord>;
  id: Scalars["ItemId"];
  maximumDateDays: Maybe<Scalars["IntType"]>;
  minimumDateDays: Maybe<Scalars["IntType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Datepicker Config (datepicker_config) */
export type DatepickerConfigRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Disable Month & Day (disabled_month_day) */
export type DisabledMonthDayRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminNote: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  day: Maybe<Scalars["IntType"]>;
  id: Scalars["ItemId"];
  month: Maybe<Scalars["IntType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Disable Month & Day (disabled_month_day) */
export type DisabledMonthDayRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Disable Weekday (disabled_weekday) */
export type DisabledWeekdayRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
  weekday: Maybe<Scalars["IntType"]>;
};

/** Block of type Disable Weekday (disabled_weekday) */
export type DisabledWeekdayRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type DiscountCardModelTestField =
  | FeatureImageTitleRecord
  | FeatureTestimonialRecord;

/** Block of type Discount Card (discount_card) */
export type DiscountCardRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  disclaimer: Maybe<Scalars["String"]>;
  discountCode: Maybe<Scalars["String"]>;
  discountToApply: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  productName: Maybe<Scalars["String"]>;
  test: Array<DiscountCardModelTestField>;
  text: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Discount Card (discount_card) */
export type DiscountCardRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type EmbeddedFormModelFilter = {
  AND: InputMaybe<Array<InputMaybe<EmbeddedFormModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<EmbeddedFormModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  embedHtml: InputMaybe<TextFilter>;
  formType: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum EmbeddedFormModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  FormTypeAsc = "formType_ASC",
  FormTypeDesc = "formType_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Embedded Form (embedded_form) */
export type EmbeddedFormRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  embedHtml: Maybe<Scalars["String"]>;
  formType: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type Embedded Form (embedded_form) */
export type EmbeddedFormRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Embedded Form (embedded_form) */
export type EmbeddedFormRecordEmbedHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Block of type Enable Weekday (enabled_weekday) */
export type EnabledWeekdayRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
  weekday: Maybe<Scalars["IntType"]>;
};

/** Block of type Enable Weekday (enabled_weekday) */
export type EnabledWeekdayRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type External Link (external_link) */
export type ExternalLinkRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  url: Maybe<Scalars["String"]>;
  variant: Maybe<Scalars["String"]>;
};

/** Block of type External Link (external_link) */
export type ExternalLinkRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type FaqsCollectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<FaqsCollectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FaqsCollectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hideFaqPage: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  position: InputMaybe<PositionFilter>;
  questionList: InputMaybe<LinksFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum FaqsCollectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HideFaqPageAsc = "hideFaqPage_ASC",
  HideFaqPageDesc = "hideFaqPage_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  PositionAsc = "position_ASC",
  PositionDesc = "position_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type FAQs Collection (faqs_collection) */
export type FaqsCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  hideFaqPage: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  position: Maybe<Scalars["IntType"]>;
  questionList: Array<FaqsItemRecord>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type FAQs Collection (faqs_collection) */
export type FaqsCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type FaqsItemModelFilter = {
  AND: InputMaybe<Array<InputMaybe<FaqsItemModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FaqsItemModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  answer: InputMaybe<TextFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  position: InputMaybe<PositionFilter>;
  question: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum FaqsItemModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  PositionAsc = "position_ASC",
  PositionDesc = "position_DESC",
  QuestionAsc = "question_ASC",
  QuestionDesc = "question_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type FAQs Item (faqs_item) */
export type FaqsItemRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  answer: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  position: Maybe<Scalars["IntType"]>;
  question: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type FAQs Item (faqs_item) */
export type FaqsItemRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type FAQs Item (faqs_item) */
export type FaqsItemRecordAnswerArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type FAQs Page (faqs_page) */
export type FaqsPageRecord = RecordInterface & {
  _allBackgroundImageLocales: Maybe<Array<FileFieldMultiLocaleField>>;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundImage: Maybe<FileField>;
  content: Array<FaqsCollectionRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type FAQs Page (faqs_page) */
export type FaqsPageRecord_AllBackgroundImageLocalesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** Record of type FAQs Page (faqs_page) */
export type FaqsPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type FAQs Page (faqs_page) */
export type FaqsPageRecordBackgroundImageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export enum FaviconType {
  AppleTouchIcon = "appleTouchIcon",
  Icon = "icon",
  MsApplication = "msApplication",
}

/** Block of type Embedded Video (feature_embedded_video) */
export type FeatureEmbeddedVideoRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  embeddedVideo: Maybe<VideoField>;
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Block of type Embedded Video (feature_embedded_video) */
export type FeatureEmbeddedVideoRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type FeatureImageCardModelLinkField =
  | FlowerPageRecord
  | FlowerShopPageRecord
  | PageRecord
  | PlantShopPageRecord
  | ProductRecord
  | SubscribePageRecord;

export type FeatureImageCardOptionalModelLinkField =
  | FlowerShopPageRecord
  | PageRecord
  | PlantShopPageRecord
  | ProductRecord
  | SubscribePageRecord;

/** Block of type Image/Title/Optional Link Card (feature_image_card_optional) */
export type FeatureImageCardOptionalRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  link: Maybe<FeatureImageCardOptionalModelLinkField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image/Title/Optional Link Card (feature_image_card_optional) */
export type FeatureImageCardOptionalRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Image/Title/Link Card (feature_image_card) */
export type FeatureImageCardRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  link: Maybe<FeatureImageCardModelLinkField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image/Title/Link Card (feature_image_card) */
export type FeatureImageCardRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Image/Title/Text (feature_image_full) */
export type FeatureImageFullRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  text: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image/Title/Text (feature_image_full) */
export type FeatureImageFullRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Image/Title/Description (feature_image_title_description) */
export type FeatureImageTitleDescriptionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image/Title/Description (feature_image_title_description) */
export type FeatureImageTitleDescriptionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Image/Title/Description (feature_image_title_description) */
export type FeatureImageTitleDescriptionRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Block of type Image/Title (feature_image_title) */
export type FeatureImageTitleRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image/Title (feature_image_title) */
export type FeatureImageTitleRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Testimonial (feature_testimonial) */
export type FeatureTestimonialRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  author: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  numberStars: Maybe<Scalars["IntType"]>;
  quote: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Testimonial (feature_testimonial) */
export type FeatureTestimonialRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type FileField = FileFieldInterface & {
  _createdAt: Scalars["DateTime"];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl: Maybe<Scalars["String"]>;
  _updatedAt: Scalars["DateTime"];
  alt: Maybe<Scalars["String"]>;
  author: Maybe<Scalars["String"]>;
  basename: Scalars["String"];
  blurUpThumb: Maybe<Scalars["String"]>;
  blurhash: Maybe<Scalars["String"]>;
  colors: Array<ColorField>;
  copyright: Maybe<Scalars["String"]>;
  customData: Scalars["CustomData"];
  exifInfo: Scalars["CustomData"];
  filename: Scalars["String"];
  focalPoint: Maybe<FocalPoint>;
  format: Scalars["String"];
  height: Maybe<Scalars["IntType"]>;
  id: Scalars["UploadId"];
  md5: Scalars["String"];
  mimeType: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  responsiveImage: Maybe<ResponsiveImage>;
  size: Scalars["IntType"];
  smartTags: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  thumbhash: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  video: Maybe<UploadVideoField>;
  width: Maybe<Scalars["IntType"]>;
};

export type FileFieldAltArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldBlurUpThumbArgs = {
  imgixParams: InputMaybe<ImgixParams>;
  punch?: Scalars["Float"];
  quality?: Scalars["Int"];
  size?: Scalars["Int"];
};

export type FileFieldCustomDataArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldFocalPointArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldResponsiveImageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  imgixParams: InputMaybe<ImgixParams>;
  locale: InputMaybe<SiteLocale>;
  sizes: InputMaybe<Scalars["String"]>;
};

export type FileFieldTitleArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldUrlArgs = {
  imgixParams: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars["DateTime"];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl: Maybe<Scalars["String"]>;
  _updatedAt: Scalars["DateTime"];
  alt: Maybe<Scalars["String"]>;
  author: Maybe<Scalars["String"]>;
  basename: Scalars["String"];
  blurUpThumb: Maybe<Scalars["String"]>;
  blurhash: Maybe<Scalars["String"]>;
  colors: Array<ColorField>;
  copyright: Maybe<Scalars["String"]>;
  customData: Scalars["CustomData"];
  exifInfo: Scalars["CustomData"];
  filename: Scalars["String"];
  focalPoint: Maybe<FocalPoint>;
  format: Scalars["String"];
  height: Maybe<Scalars["IntType"]>;
  id: Scalars["UploadId"];
  md5: Scalars["String"];
  mimeType: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  responsiveImage: Maybe<ResponsiveImage>;
  size: Scalars["IntType"];
  smartTags: Array<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  thumbhash: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  url: Scalars["String"];
  video: Maybe<UploadVideoField>;
  width: Maybe<Scalars["IntType"]>;
};

export type FileFieldInterfaceAltArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams: InputMaybe<ImgixParams>;
  punch?: Scalars["Float"];
  quality?: Scalars["Int"];
  size?: Scalars["Int"];
};

export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  imgixParams: InputMaybe<ImgixParams>;
  locale: InputMaybe<SiteLocale>;
  sizes: InputMaybe<Scalars["String"]>;
};

export type FileFieldInterfaceTitleArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceUrlArgs = {
  imgixParams: InputMaybe<ImgixParams>;
};

export type FileFieldMultiLocaleField = {
  locale: Maybe<SiteLocale>;
  value: Maybe<FileField>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq: InputMaybe<Scalars["UploadId"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records that have one of the specified uploads */
  in: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq: InputMaybe<Scalars["UploadId"]>;
  /** Filter records that do not have one of the specified uploads */
  notIn: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
};

/** Record of type Flower Center (flower_center) */
export type FlowerCenterRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Flower Center (flower_center) */
export type FlowerCenterRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Flower Center (flower_center) */
export type FlowerCenterRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type FlowerCollectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<FlowerCollectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FlowerCollectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  month: InputMaybe<StringFilter>;
  position: InputMaybe<PositionFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export type FlowerCollectionModelFlowerListField =
  | FlowerItemMonthlyRecord
  | FlowerItemWeeklyRecord;

export enum FlowerCollectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  MonthAsc = "month_ASC",
  MonthDesc = "month_DESC",
  PositionAsc = "position_ASC",
  PositionDesc = "position_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Flower Collections (flower_collection) */
export type FlowerCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  flowerList: Array<FlowerCollectionModelFlowerListField>;
  id: Scalars["ItemId"];
  month: Maybe<Scalars["String"]>;
  position: Maybe<Scalars["IntType"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Flower Collections (flower_collection) */
export type FlowerCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Monthly Flower Item (flower_item_monthly) */
export type FlowerItemMonthlyRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  flower: Maybe<FlowerPageRecord>;
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Block of type Monthly Flower Item (flower_item_monthly) */
export type FlowerItemMonthlyRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Weekly Flower Item (flower_item_weekly) */
export type FlowerItemWeeklyRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  flower: Maybe<FlowerPageRecord>;
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
  weekDate: Maybe<Scalars["String"]>;
  weekNumber: Maybe<Scalars["IntType"]>;
};

/** Block of type Weekly Flower Item (flower_item_weekly) */
export type FlowerItemWeeklyRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum FlowerPageModelFieldsReferencingProductModel {
  FlowerPageProduct = "flowerPage_product",
}

export type FlowerPageModelFilter = {
  AND: InputMaybe<Array<InputMaybe<FlowerPageModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FlowerPageModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  badge: InputMaybe<FileFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  detailText: InputMaybe<TextFilter>;
  endingText: InputMaybe<TextFilter>;
  hasBadge: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  introText: InputMaybe<TextFilter>;
  name: InputMaybe<StringFilter>;
  primaryImage: InputMaybe<FileFilter>;
  product: InputMaybe<LinkFilter>;
  secondaryImage: InputMaybe<FileFilter>;
  sections: InputMaybe<LinksFilter>;
  seo: InputMaybe<SeoFilter>;
  slug: InputMaybe<SlugFilter>;
  subtitle: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum FlowerPageModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasBadgeAsc = "hasBadge_ASC",
  HasBadgeDesc = "hasBadge_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Flower Pages (flower_page) */
export type FlowerPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  badge: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  detailText: Maybe<Scalars["String"]>;
  endingText: Maybe<Scalars["String"]>;
  hasBadge: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  infoChip: Array<FeatureImageFullRecord>;
  introText: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  primaryImage: Maybe<FileField>;
  product: Maybe<ProductRecord>;
  secondaryImage: Maybe<FileField>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Flower Pages (flower_page) */
export type FlowerPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Flower Pages (flower_page) */
export type FlowerPageRecordDetailTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Flower Pages (flower_page) */
export type FlowerPageRecordEndingTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Flower Pages (flower_page) */
export type FlowerPageRecordIntroTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Flower Shop Page (flower_shop_page) */
export type FlowerShopPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  preview: Maybe<Scalars["JsonField"]>;
  productCollections: Array<ProductCollectionRecord>;
  revalidate: Maybe<Scalars["JsonField"]>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Flower Shop Page (flower_shop_page) */
export type FlowerShopPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Flower Shop Page (flower_shop_page) */
export type FlowerShopPageRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Linking fields */
export enum FooterModelFieldsReferencingProductModel {
  FooterExtraItemsLinks = "footer_extraItemsLinks",
  FooterExtraItemsLinksLinkInternalLink = "footer_extraItemsLinks__link_internalLink",
  FooterLeftMenuLinks = "footer_leftMenuLinks",
  FooterLeftMenuLinksLinkInternalLink = "footer_leftMenuLinks__link_internalLink",
  FooterMiddleMenuLinks = "footer_middleMenuLinks",
  FooterMiddleMenuLinksLinkInternalLink = "footer_middleMenuLinks__link_internalLink",
  FooterRightMenuLinks = "footer_rightMenuLinks",
  FooterRightMenuLinksLinkInternalLink = "footer_rightMenuLinks__link_internalLink",
}

export type FooterModelFilter = {
  AND: InputMaybe<Array<InputMaybe<FooterModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FooterModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  extraMenuTitle: InputMaybe<StringFilter>;
  facebook: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  instagram: InputMaybe<StringFilter>;
  leftMenuTitle: InputMaybe<StringFilter>;
  middleMenuTitle: InputMaybe<StringFilter>;
  pinterest: InputMaybe<StringFilter>;
  rightMenuTitle: InputMaybe<StringFilter>;
  twitter: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  youtube: InputMaybe<StringFilter>;
};

export enum FooterModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  ExtraMenuTitleAsc = "extraMenuTitle_ASC",
  ExtraMenuTitleDesc = "extraMenuTitle_DESC",
  FacebookAsc = "facebook_ASC",
  FacebookDesc = "facebook_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  InstagramAsc = "instagram_ASC",
  InstagramDesc = "instagram_DESC",
  LeftMenuTitleAsc = "leftMenuTitle_ASC",
  LeftMenuTitleDesc = "leftMenuTitle_DESC",
  MiddleMenuTitleAsc = "middleMenuTitle_ASC",
  MiddleMenuTitleDesc = "middleMenuTitle_DESC",
  PinterestAsc = "pinterest_ASC",
  PinterestDesc = "pinterest_DESC",
  RightMenuTitleAsc = "rightMenuTitle_ASC",
  RightMenuTitleDesc = "rightMenuTitle_DESC",
  TwitterAsc = "twitter_ASC",
  TwitterDesc = "twitter_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  YoutubeAsc = "youtube_ASC",
  YoutubeDesc = "youtube_DESC",
}

/** Record of type Footer Settings (footer) */
export type FooterRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  extraItemsLinks: Array<LinkRecord>;
  extraMenuTitle: Maybe<Scalars["String"]>;
  facebook: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  instagram: Maybe<Scalars["String"]>;
  leftMenuLinks: Array<LinkRecord>;
  leftMenuTitle: Maybe<Scalars["String"]>;
  middleMenuLinks: Array<LinkRecord>;
  middleMenuTitle: Maybe<Scalars["String"]>;
  pinterest: Maybe<Scalars["String"]>;
  rightMenuLinks: Array<LinkRecord>;
  rightMenuTitle: Maybe<Scalars["String"]>;
  twitter: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  youtube: Maybe<Scalars["String"]>;
};

/** Record of type Footer Settings (footer) */
export type FooterRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Multiple files/images field */
export type GalleryFilter = {
  /** Filter records that have all of the specified uploads. The specified values must be Upload IDs */
  allIn: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Filter records that have one of the specified uploads. The specified values must be Upload IDs */
  anyIn: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Search for records with an exact match. The specified values must be Upload IDs */
  eq: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records that do not have any of the specified uploads. The specified values must be Upload IDs */
  notIn: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
};

/** Record of type Global Datepicker Config (global_datepicker_config) */
export type GlobalDatepickerConfigRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  disabledDates: Array<DisabledMonthDayRecord>;
  disabledWeekdays: Array<DisabledWeekdayRecord>;
  id: Scalars["ItemId"];
  maximumDateDays: Maybe<Scalars["String"]>;
  minimumDateDays: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Global Datepicker Config (global_datepicker_config) */
export type GlobalDatepickerConfigRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type GlobalSeoField = {
  facebookPageUrl: Maybe<Scalars["String"]>;
  fallbackSeo: Maybe<SeoField>;
  siteName: Maybe<Scalars["String"]>;
  titleSuffix: Maybe<Scalars["String"]>;
  twitterAccount: Maybe<Scalars["String"]>;
};

export type GoogleProductModelFilter = {
  AND: InputMaybe<Array<InputMaybe<GoogleProductModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<GoogleProductModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  backgroundColor: InputMaybe<ColorFilter>;
  bouquetSize: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  description: InputMaybe<TextFilter>;
  gallery: InputMaybe<GalleryFilter>;
  id: InputMaybe<ItemIdFilter>;
  isSubscription: InputMaybe<BooleanFilter>;
  preview: InputMaybe<JsonFilter>;
  revalidate: InputMaybe<JsonFilter>;
  sections: InputMaybe<LinksFilter>;
  seo: InputMaybe<SeoFilter>;
  shopifyProduct: InputMaybe<JsonFilter>;
  shortDescription: InputMaybe<TextFilter>;
  slug: InputMaybe<SlugFilter>;
  tags: InputMaybe<LinksFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum GoogleProductModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  BouquetSizeAsc = "bouquetSize_ASC",
  BouquetSizeDesc = "bouquetSize_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsSubscriptionAsc = "isSubscription_ASC",
  IsSubscriptionDesc = "isSubscription_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Google Product (google_product) */
export type GoogleProductRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundColor: Maybe<ColorField>;
  bouquetSize: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  gallery: Array<FileField>;
  id: Scalars["ItemId"];
  isSubscription: Maybe<Scalars["BooleanType"]>;
  preview: Maybe<Scalars["JsonField"]>;
  revalidate: Maybe<Scalars["JsonField"]>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  shopifyProduct: Maybe<Scalars["JsonField"]>;
  shortDescription: Maybe<Scalars["String"]>;
  slug: Maybe<Scalars["String"]>;
  tags: Array<ProductTagRecord>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Google Product (google_product) */
export type GoogleProductRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Google Product (google_product) */
export type GoogleProductRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Google Product (google_product) */
export type GoogleProductRecordShortDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Linking fields */
export enum GoogleShoppingModelFieldsReferencingProductModel {
  GoogleShoppingProducts = "googleShopping_products",
}

export type GoogleShoppingModelFilter = {
  AND: InputMaybe<Array<InputMaybe<GoogleShoppingModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<GoogleShoppingModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  products: InputMaybe<LinksFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum GoogleShoppingModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Google Shopping (google_shopping) */
export type GoogleShoppingRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  products: Array<ProductRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Google Shopping (google_shopping) */
export type GoogleShoppingRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum HeaderModelFieldsReferencingProductModel {
  HeaderMenuItem = "header_menuItem",
  HeaderMenuItemLinkCollectionItemInternalLink = "header_menuItem__linkCollectionItem_internalLink",
  HeaderMenuLinks = "header_menuLinks",
  HeaderMenuLinksLinkInternalLink = "header_menuLinks__link_internalLink",
}

export type HeaderModelFilter = {
  AND: InputMaybe<Array<InputMaybe<HeaderModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<HeaderModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  announcementLinkText: InputMaybe<StringFilter>;
  announcementLinkUrl: InputMaybe<StringFilter>;
  announcementMessage: InputMaybe<StringFilter>;
  announcementTheme: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  isStickyAnnouncement: InputMaybe<BooleanFilter>;
  preview: InputMaybe<JsonFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum HeaderModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AnnouncementLinkTextAsc = "announcementLinkText_ASC",
  AnnouncementLinkTextDesc = "announcementLinkText_DESC",
  AnnouncementLinkUrlAsc = "announcementLinkUrl_ASC",
  AnnouncementLinkUrlDesc = "announcementLinkUrl_DESC",
  AnnouncementMessageAsc = "announcementMessage_ASC",
  AnnouncementMessageDesc = "announcementMessage_DESC",
  AnnouncementThemeAsc = "announcementTheme_ASC",
  AnnouncementThemeDesc = "announcementTheme_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsStickyAnnouncementAsc = "isStickyAnnouncement_ASC",
  IsStickyAnnouncementDesc = "isStickyAnnouncement_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Header Settings (header) */
export type HeaderRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  announcementLinkText: Maybe<Scalars["String"]>;
  announcementLinkUrl: Maybe<Scalars["String"]>;
  announcementMessage: Maybe<Scalars["String"]>;
  announcementTheme: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  isStickyAnnouncement: Maybe<Scalars["BooleanType"]>;
  menuItem: Array<LinkCollectionItemRecord>;
  menuLinks: Array<LinkRecord>;
  preview: Maybe<Scalars["JsonField"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Header Settings (header) */
export type HeaderRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Homepage (homepage) */
export type HomepageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  preview: Maybe<Scalars["JsonField"]>;
  revalidate: Maybe<Scalars["JsonField"]>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Homepage (homepage) */
export type HomepageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type HtmlContentModelFilter = {
  AND: InputMaybe<Array<InputMaybe<HtmlContentModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<HtmlContentModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  htmlContent: InputMaybe<TextFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum HtmlContentModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type HTML Content (html_content) */
export type HtmlContentRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  htmlContent: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Record of type HTML Content (html_content) */
export type HtmlContentRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type HTML Content (html_content) */
export type HtmlContentRecordHtmlContentArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Block of type Image/Quote (image_testimonial) */
export type ImageTestimonialRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  quote: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image/Quote (image_testimonial) */
export type ImageTestimonialRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Image/Title/Background-Color (image_title_background_color) */
export type ImageTitleBackgroundColorRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundColor: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Image/Title/Background-Color (image_title_background_color) */
export type ImageTitleBackgroundColorRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar: InputMaybe<Scalars["String"]>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg: InputMaybe<Scalars["String"]>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemove: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemoveFallback: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplace: InputMaybe<Scalars["String"]>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplaceFallback: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace-neg-prompt)
   */
  bgReplaceNegPrompt: InputMaybe<Scalars["String"]>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend: InputMaybe<Scalars["String"]>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor: InputMaybe<Scalars["String"]>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH: InputMaybe<Scalars["FloatType"]>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW: InputMaybe<Scalars["FloatType"]>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX: InputMaybe<Scalars["IntType"]>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY: InputMaybe<Scalars["IntType"]>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border: InputMaybe<Scalars["String"]>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft: InputMaybe<Scalars["IntType"]>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius: InputMaybe<Scalars["String"]>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner: InputMaybe<Scalars["String"]>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight: InputMaybe<Scalars["IntType"]>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop: InputMaybe<Scalars["IntType"]>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri: InputMaybe<Scalars["IntType"]>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub: InputMaybe<Scalars["IntType"]>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant: InputMaybe<Scalars["IntType"]>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors: InputMaybe<Scalars["IntType"]>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con: InputMaybe<Scalars["IntType"]>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius: InputMaybe<Scalars["String"]>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl: InputMaybe<Scalars["String"]>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi: InputMaybe<Scalars["IntType"]>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr: InputMaybe<Scalars["FloatType"]>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone: InputMaybe<Scalars["String"]>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha: InputMaybe<Scalars["IntType"]>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp: InputMaybe<Scalars["IntType"]>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires: InputMaybe<Scalars["IntType"]>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex: InputMaybe<Scalars["IntType"]>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad: InputMaybe<Scalars["FloatType"]>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces: InputMaybe<Scalars["IntType"]>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor: InputMaybe<Scalars["String"]>;
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-fallback)
   */
  fillGenFallback: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-neg-prompt)
   */
  fillGenNegPrompt: InputMaybe<Scalars["String"]>;
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-pos)
   */
  fillGenPos: InputMaybe<Array<ImgixParamsFillGenPos>>;
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-prompt)
   */
  fillGenPrompt: InputMaybe<Scalars["String"]>;
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-seed)
   */
  fillGenSeed: InputMaybe<Scalars["IntType"]>;
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-cs)
   */
  fillGradientCs: InputMaybe<ImgixParamsFillGradientCs>;
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear)
   */
  fillGradientLinear: InputMaybe<Scalars["String"]>;
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection: InputMaybe<
    Array<ImgixParamsFillGradientLinearDirection>
  >;
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial)
   */
  fillGradientRadial: InputMaybe<Scalars["String"]>;
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius: InputMaybe<Scalars["String"]>;
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX: InputMaybe<Scalars["FloatType"]>;
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY: InputMaybe<Scalars["FloatType"]>;
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-type)
   */
  fillGradientType: InputMaybe<ImgixParamsFillGradientType>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX: InputMaybe<Scalars["FloatType"]>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY: InputMaybe<Scalars["FloatType"]>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ: InputMaybe<Scalars["FloatType"]>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps: InputMaybe<Scalars["IntType"]>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame: InputMaybe<Scalars["String"]>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam: InputMaybe<Scalars["IntType"]>;
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   */
  gifQ: InputMaybe<Scalars["IntType"]>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors: InputMaybe<Scalars["String"]>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize: InputMaybe<Scalars["IntType"]>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h: InputMaybe<Scalars["FloatType"]>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high: InputMaybe<Scalars["IntType"]>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn: InputMaybe<Scalars["IntType"]>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue: InputMaybe<Scalars["IntType"]>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval: InputMaybe<Scalars["IntType"]>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc: InputMaybe<ImgixParamsIptc>;
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   */
  jpgProgressive: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop: InputMaybe<Scalars["IntType"]>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark: InputMaybe<Scalars["String"]>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase: InputMaybe<Scalars["String"]>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW: InputMaybe<Scalars["FloatType"]>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX: InputMaybe<Scalars["IntType"]>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY: InputMaybe<Scalars["IntType"]>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask: InputMaybe<Scalars["String"]>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg: InputMaybe<Scalars["String"]>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH: InputMaybe<Scalars["IntType"]>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW: InputMaybe<Scalars["IntType"]>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH: InputMaybe<Scalars["IntType"]>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW: InputMaybe<Scalars["IntType"]>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome: InputMaybe<Scalars["String"]>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr: InputMaybe<Scalars["IntType"]>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs: InputMaybe<Scalars["IntType"]>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight: InputMaybe<Scalars["IntType"]>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop: InputMaybe<Scalars["IntType"]>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page: InputMaybe<Scalars["IntType"]>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix: InputMaybe<Scalars["String"]>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px: InputMaybe<Scalars["IntType"]>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q: InputMaybe<Scalars["IntType"]>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect: InputMaybe<Scalars["String"]>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot: InputMaybe<Scalars["FloatType"]>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat: InputMaybe<Scalars["IntType"]>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia: InputMaybe<Scalars["IntType"]>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad: InputMaybe<Scalars["FloatType"]>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp: InputMaybe<Scalars["FloatType"]>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip: InputMaybe<Scalars["IntType"]>;
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   */
  svgSanitize: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor: InputMaybe<Scalars["String"]>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd: InputMaybe<Scalars["FloatType"]>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad: InputMaybe<Scalars["IntType"]>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd: InputMaybe<Scalars["FloatType"]>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol: InputMaybe<Scalars["FloatType"]>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt: InputMaybe<Scalars["String"]>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor: InputMaybe<Scalars["String"]>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont: InputMaybe<Scalars["String"]>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor: InputMaybe<Scalars["String"]>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad: InputMaybe<Scalars["FloatType"]>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth: InputMaybe<Scalars["IntType"]>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX: InputMaybe<Scalars["IntType"]>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY: InputMaybe<Scalars["IntType"]>;
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale)
   */
  upscale: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale-fallback)
   */
  upscaleFallback: InputMaybe<Scalars["BooleanType"]>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm: InputMaybe<Scalars["IntType"]>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad: InputMaybe<Scalars["FloatType"]>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib: InputMaybe<Scalars["IntType"]>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w: InputMaybe<Scalars["FloatType"]>;
};

export enum ImgixParamsAuto {
  Compress = "compress",
  Enhance = "enhance",
  Format = "format",
  Redeye = "redeye",
}

export enum ImgixParamsBlendAlign {
  Bottom = "bottom",
  Center = "center",
  Left = "left",
  Middle = "middle",
  Right = "right",
  Top = "top",
}

export enum ImgixParamsBlendCrop {
  Bottom = "bottom",
  Faces = "faces",
  Left = "left",
  Right = "right",
  Top = "top",
}

export enum ImgixParamsBlendFit {
  Clamp = "clamp",
  Clip = "clip",
  Crop = "crop",
  Max = "max",
  Scale = "scale",
}

export enum ImgixParamsBlendMode {
  Burn = "burn",
  Color = "color",
  Darken = "darken",
  Difference = "difference",
  Dodge = "dodge",
  Exclusion = "exclusion",
  Hardlight = "hardlight",
  Hue = "hue",
  Lighten = "lighten",
  Luminosity = "luminosity",
  Multiply = "multiply",
  Normal = "normal",
  Overlay = "overlay",
  Saturation = "saturation",
  Screen = "screen",
  Softlight = "softlight",
}

export enum ImgixParamsBlendSize {
  Inherit = "inherit",
}

export enum ImgixParamsCh {
  Dpr = "dpr",
  SaveData = "saveData",
  Width = "width",
}

export enum ImgixParamsCrop {
  Bottom = "bottom",
  Edges = "edges",
  Entropy = "entropy",
  Faces = "faces",
  Focalpoint = "focalpoint",
  Left = "left",
  Right = "right",
  Top = "top",
}

export enum ImgixParamsCs {
  Adobergb1998 = "adobergb1998",
  Srgb = "srgb",
  Strip = "strip",
  Tinysrgb = "tinysrgb",
}

export enum ImgixParamsFill {
  Blur = "blur",
  Gen = "gen",
  Generative = "generative",
  Gradient = "gradient",
  Solid = "solid",
}

export enum ImgixParamsFillGenPos {
  Bottom = "bottom",
  Center = "center",
  Left = "left",
  Middle = "middle",
  Right = "right",
  Top = "top",
}

export enum ImgixParamsFillGradientCs {
  Hsl = "hsl",
  Lch = "lch",
  Linear = "linear",
  Oklab = "oklab",
  Srgb = "srgb",
}

export enum ImgixParamsFillGradientLinearDirection {
  Bottom = "bottom",
  Left = "left",
  Right = "right",
  Top = "top",
}

export enum ImgixParamsFillGradientType {
  Linear = "linear",
  Radial = "radial",
}

export enum ImgixParamsFit {
  Clamp = "clamp",
  Clip = "clip",
  Crop = "crop",
  Facearea = "facearea",
  Fill = "fill",
  Fillmax = "fillmax",
  Max = "max",
  Min = "min",
  Scale = "scale",
}

export enum ImgixParamsFlip {
  H = "h",
  Hv = "hv",
  V = "v",
}

export enum ImgixParamsFm {
  Avif = "avif",
  Blurhash = "blurhash",
  Gif = "gif",
  Jp2 = "jp2",
  Jpg = "jpg",
  Json = "json",
  Jxr = "jxr",
  Mp4 = "mp4",
  Pjpg = "pjpg",
  Png = "png",
  Png8 = "png8",
  Png32 = "png32",
  Webm = "webm",
  Webp = "webp",
}

export enum ImgixParamsIptc {
  Allow = "allow",
  Block = "block",
}

export enum ImgixParamsMarkAlign {
  Bottom = "bottom",
  Center = "center",
  Left = "left",
  Middle = "middle",
  Right = "right",
  Top = "top",
}

export enum ImgixParamsMarkFit {
  Clip = "clip",
  Crop = "crop",
  Fill = "fill",
  Max = "max",
  Scale = "scale",
}

export enum ImgixParamsMarkTile {
  Grid = "grid",
}

export enum ImgixParamsPalette {
  Css = "css",
  Json = "json",
}

export enum ImgixParamsTransparency {
  Grid = "grid",
}

export enum ImgixParamsTrim {
  Auto = "auto",
  Color = "color",
}

export enum ImgixParamsTxtAlign {
  Bottom = "bottom",
  Center = "center",
  Left = "left",
  Middle = "middle",
  Right = "right",
  Top = "top",
}

export enum ImgixParamsTxtClip {
  Ellipsis = "ellipsis",
  End = "end",
  Middle = "middle",
  Start = "start",
}

export enum ImgixParamsTxtFit {
  Max = "max",
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq: InputMaybe<Scalars["BooleanType"]>;
};

/** Specifies how to filter Integer fields */
export type IntegerFilter = {
  /** Search for records with an exact match */
  eq: InputMaybe<Scalars["IntType"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less than the one specified */
  lt: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte: InputMaybe<Scalars["IntType"]>;
  /** Exclude records with an exact match */
  neq: InputMaybe<Scalars["IntType"]>;
};

export type InternalLinkModelLinkField =
  | BlogPostRecord
  | FlowerShopPageRecord
  | NestedPageRecord
  | PageRecord
  | PlantShopPageRecord
  | ProductRecord
  | SubscribePageRecord;

/** Block of type Internal Link (internal_link) */
export type InternalLinkRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  link: Maybe<InternalLinkModelLinkField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  variant: Maybe<Scalars["String"]>;
};

/** Block of type Internal Link (internal_link) */
export type InternalLinkRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockCtaAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<BlockCtaModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<BlockCtaModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeatureContentOverlayAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockFeatureContentOverlayModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockFeatureContentOverlayModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeatureImageCardAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockFeatureImageCardModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockFeatureImageCardModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeatureImageFullAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockFeatureImageFullModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockFeatureImageFullModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeatureLinkCollectionAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockFeatureLinkCollectionModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockFeatureLinkCollectionModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeatureRowImageCardAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockFeatureRowImageCardModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockFeatureRowImageCardModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeatureText2imagesButtonAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockFeatureText2imagesButtonModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockFeatureText2imagesButtonModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeatureTextImageModuleAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockFeatureTextImageModuleModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockFeatureTextImageModuleModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockFeaturedInAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<BlockFeaturedInModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<BlockFeaturedInModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockHeroAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<BlockHeroModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<BlockHeroModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockHeroAnimatedTextAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockHeroAnimatedTextModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockHeroAnimatedTextModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockHerobanner2columnAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<BlockHerobanner2columnModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<BlockHerobanner2columnModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlockMainFeatureAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<BlockMainFeatureModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<BlockMainFeatureModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenBlogPostAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<BlogPostModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<BlogPostModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenDatepickerConfigAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<DatepickerConfigModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<DatepickerConfigModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenFlowerPageAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<FlowerPageModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<FlowerPageModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenFooterAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<FooterModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<FooterModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenGoogleShoppingAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<GoogleShoppingModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<GoogleShoppingModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenHeaderAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<HeaderModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<HeaderModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenPlantPageAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<PlantPageModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<PlantPageModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenProductAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<ProductModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<ProductModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenProductBestSellerAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<ProductBestSellerModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<ProductBestSellerModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenProductCollectionAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<ProductCollectionModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<ProductCollectionModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenProductCollectionSliderAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<ProductCollectionSliderModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<ProductCollectionSliderModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenPromotionalCardAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<PromotionalCardModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<PromotionalCardModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenSecondaryHeroBannerAndProduct =
  {
    /** Filter linking records that reference current record in at least one of the specified fields */
    anyIn: InputMaybe<
      Array<SecondaryHeroBannerModelFieldsReferencingProductModel>
    >;
    /** Filter linking records that do not reference current record in any of the specified fields */
    notIn: InputMaybe<
      Array<SecondaryHeroBannerModelFieldsReferencingProductModel>
    >;
  };

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenSubMenuItemAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<SubMenuItemModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<SubMenuItemModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenSubMenuSettingAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<SubMenuSettingModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<SubMenuSettingModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenSubscribePageAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<SubscribePageModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<SubscribePageModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenSubscriptionPlanAndProduct = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn: InputMaybe<Array<SubscriptionPlanModelFieldsReferencingProductModel>>;
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn: InputMaybe<Array<SubscriptionPlanModelFieldsReferencingProductModel>>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockCtaAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockCtaAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeatureContentOverlayAndProduct =
  {
    /** Specifies how to filter by linking fields */
    fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeatureContentOverlayAndProduct>;
    /** Specifies how to filter by linking locales */
    locales: InputMaybe<LinkingLocalesFilter>;
  };

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeatureImageCardAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeatureImageCardAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeatureImageFullAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeatureImageFullAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeatureLinkCollectionAndProduct =
  {
    /** Specifies how to filter by linking fields */
    fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeatureLinkCollectionAndProduct>;
    /** Specifies how to filter by linking locales */
    locales: InputMaybe<LinkingLocalesFilter>;
  };

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeatureRowImageCardAndProduct =
  {
    /** Specifies how to filter by linking fields */
    fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeatureRowImageCardAndProduct>;
    /** Specifies how to filter by linking locales */
    locales: InputMaybe<LinkingLocalesFilter>;
  };

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeatureText2imagesButtonAndProduct =
  {
    /** Specifies how to filter by linking fields */
    fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeatureText2imagesButtonAndProduct>;
    /** Specifies how to filter by linking locales */
    locales: InputMaybe<LinkingLocalesFilter>;
  };

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeatureTextImageModuleAndProduct =
  {
    /** Specifies how to filter by linking fields */
    fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeatureTextImageModuleAndProduct>;
    /** Specifies how to filter by linking locales */
    locales: InputMaybe<LinkingLocalesFilter>;
  };

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockFeaturedInAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockFeaturedInAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockHeroAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockHeroAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockHeroAnimatedTextAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockHeroAnimatedTextAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockHerobanner2columnAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockHerobanner2columnAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlockMainFeatureAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlockMainFeatureAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenBlogPostAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenBlogPostAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenDatepickerConfigAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenDatepickerConfigAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenFlowerPageAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenFlowerPageAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenFooterAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenFooterAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenGoogleShoppingAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenGoogleShoppingAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenHeaderAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenHeaderAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenPlantPageAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenPlantPageAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenProductAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenProductAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenProductBestSellerAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenProductBestSellerAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenProductCollectionAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenProductCollectionAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenProductCollectionSliderAndProduct =
  {
    /** Specifies how to filter by linking fields */
    fields: InputMaybe<InverseRelationshipFieldFilterBetweenProductCollectionSliderAndProduct>;
    /** Specifies how to filter by linking locales */
    locales: InputMaybe<LinkingLocalesFilter>;
  };

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenPromotionalCardAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenPromotionalCardAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenSecondaryHeroBannerAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenSecondaryHeroBannerAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenSubMenuItemAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenSubMenuItemAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenSubMenuSettingAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenSubMenuSettingAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenSubscribePageAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenSubscribePageAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenSubscriptionPlanAndProduct = {
  /** Specifies how to filter by linking fields */
  fields: InputMaybe<InverseRelationshipFieldFilterBetweenSubscriptionPlanAndProduct>;
  /** Specifies how to filter by linking locales */
  locales: InputMaybe<LinkingLocalesFilter>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq: InputMaybe<Scalars["ItemId"]>;
  /** Search records with the specified IDs */
  in: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Exclude the record with the specified ID */
  neq: InputMaybe<Scalars["ItemId"]>;
  /** Search records that do not have the specified IDs */
  notIn: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

export enum ItemStatus {
  Draft = "draft",
  Published = "published",
  Updated = "updated",
}

/** Specifies how to filter JSON fields */
export type JsonFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
};

export type LinkCollectionItemModelInternalLinkField =
  | FlowerShopPageRecord
  | PageRecord
  | PlantShopPageRecord
  | ProductRecord
  | SubscribePageRecord;

/** Block of type Link/Collection Item (link_collection_item) */
export type LinkCollectionItemRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  internalLink: Maybe<LinkCollectionItemModelInternalLinkField>;
  isExternal: Maybe<Scalars["BooleanType"]>;
  subMenuItem: Array<SubMenuSettingRecord>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  url: Maybe<Scalars["String"]>;
};

/** Block of type Link/Collection Item (link_collection_item) */
export type LinkCollectionItemRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq: InputMaybe<Scalars["ItemId"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records linked to one of the specified records */
  in: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq: InputMaybe<Scalars["ItemId"]>;
  /** Filter records not linked to one of the specified records */
  notIn: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

export type LinkModelInternalLinkField =
  | BlogPostRecord
  | FlowerShopPageRecord
  | NestedPageRecord
  | PageRecord
  | PlantShopPageRecord
  | ProductRecord
  | SubscribePageRecord;

/** Block of type Link (link) */
export type LinkRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  coverImage: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  internalLink: Maybe<LinkModelInternalLinkField>;
  isExternal: Maybe<Scalars["BooleanType"]>;
  shortDescription: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  url: Maybe<Scalars["String"]>;
};

/** Block of type Link (link) */
export type LinkRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking locales */
export enum LinkingLocale {
  NonLocalized = "_nonLocalized",
  En = "en",
}

/** Specifies how to filter by linking locales */
export type LinkingLocalesFilter = {
  /** Filter linking records that link to current record in at least one of the specified locales */
  anyIn: InputMaybe<Array<LinkingLocale>>;
  /** Filter linking records that do not link to current record in any of the specified locales */
  notIn: InputMaybe<Array<LinkingLocale>>;
};

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
  /** Filter records linked to all of the specified records. The specified values must be Record IDs */
  allIn: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
  anyIn: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Search for records with an exact match. The specified values must be Record IDs */
  eq: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
  notIn: InputMaybe<Array<InputMaybe<Scalars["ItemId"]>>>;
};

export enum MuxThumbnailFormatType {
  Gif = "gif",
  Jpg = "jpg",
  Png = "png",
}

export type NestedPageModelFilter = {
  AND: InputMaybe<Array<InputMaybe<NestedPageModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<NestedPageModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  coverImage: InputMaybe<FileFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasCustomLogo: InputMaybe<BooleanFilter>;
  hideFromSearchEngines: InputMaybe<BooleanFilter>;
  hideTitle: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  introParagraph: InputMaybe<TextFilter>;
  isTitleH1: InputMaybe<BooleanFilter>;
  logo: InputMaybe<FileFilter>;
  pageHeaderType: InputMaybe<StringFilter>;
  parent: InputMaybe<ParentFilter>;
  position: InputMaybe<PositionFilter>;
  preview: InputMaybe<JsonFilter>;
  revalidate: InputMaybe<JsonFilter>;
  sectionsAfter: InputMaybe<LinksFilter>;
  sectionsBefore: InputMaybe<LinksFilter>;
  seo: InputMaybe<SeoFilter>;
  shortDescription: InputMaybe<StringFilter>;
  showPageFooter: InputMaybe<BooleanFilter>;
  showPageHeader: InputMaybe<BooleanFilter>;
  slug: InputMaybe<SlugFilter>;
  subtitle: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum NestedPageModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasCustomLogoAsc = "hasCustomLogo_ASC",
  HasCustomLogoDesc = "hasCustomLogo_DESC",
  HideFromSearchEnginesAsc = "hideFromSearchEngines_ASC",
  HideFromSearchEnginesDesc = "hideFromSearchEngines_DESC",
  HideTitleAsc = "hideTitle_ASC",
  HideTitleDesc = "hideTitle_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsTitleH1Asc = "isTitleH1_ASC",
  IsTitleH1Desc = "isTitleH1_DESC",
  PageHeaderTypeAsc = "pageHeaderType_ASC",
  PageHeaderTypeDesc = "pageHeaderType_DESC",
  PositionAsc = "position_ASC",
  PositionDesc = "position_DESC",
  ShortDescriptionAsc = "shortDescription_ASC",
  ShortDescriptionDesc = "shortDescription_DESC",
  ShowPageFooterAsc = "showPageFooter_ASC",
  ShowPageFooterDesc = "showPageFooter_DESC",
  ShowPageHeaderAsc = "showPageHeader_ASC",
  ShowPageHeaderDesc = "showPageHeader_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Nested Landing Page (nested_page) */
export type NestedPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  children: Array<NestedPageRecord>;
  coverImage: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  hasCustomLogo: Maybe<Scalars["BooleanType"]>;
  hideFromSearchEngines: Maybe<Scalars["BooleanType"]>;
  hideTitle: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  introParagraph: Maybe<Scalars["String"]>;
  isTitleH1: Maybe<Scalars["BooleanType"]>;
  logo: Maybe<FileField>;
  pageHeaderType: Maybe<Scalars["String"]>;
  parent: Maybe<NestedPageRecord>;
  position: Maybe<Scalars["IntType"]>;
  preview: Maybe<Scalars["JsonField"]>;
  revalidate: Maybe<Scalars["JsonField"]>;
  sectionsAfter: Array<ContentSectionRecord>;
  sectionsBefore: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  shortDescription: Maybe<Scalars["String"]>;
  showPageFooter: Maybe<Scalars["BooleanType"]>;
  showPageHeader: Maybe<Scalars["BooleanType"]>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Nested Landing Page (nested_page) */
export type NestedPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Nested Landing Page (nested_page) */
export type NestedPageRecordIntroParagraphArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Block of type Next Day CutOff Block (next_day_cutoff_block) */
export type NextDayCutoffBlockRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  cutoffHour: Maybe<Scalars["IntType"]>;
  cutoffMinutes: Maybe<Scalars["IntType"]>;
  id: Scalars["ItemId"];
  timezone: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Next Day CutOff Block (next_day_cutoff_block) */
export type NextDayCutoffBlockRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Next Day Shipping Per Product (next_day_shipping_per_product) */
export type NextDayShippingPerProductRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  cutoffHour: Maybe<Scalars["IntType"]>;
  id: Scalars["ItemId"];
  noteAdmin: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  products: Array<ProductRecord>;
  timezone: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Next Day Shipping Per Product (next_day_shipping_per_product) */
export type NextDayShippingPerProductRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Next Day Shipping Per Product (next_day_shipping_per_product) */
export type NextDayShippingPerProductRecordNotesArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type NextdayCutoffConfigModelFilter = {
  AND: InputMaybe<Array<InputMaybe<NextdayCutoffConfigModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<NextdayCutoffConfigModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  noteAdmin: InputMaybe<StringFilter>;
  notes: InputMaybe<TextFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum NextdayCutoffConfigModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NoteAdminAsc = "noteAdmin_ASC",
  NoteAdminDesc = "noteAdmin_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type NextDay CutOff Config (nextday_cutoff_config) */
export type NextdayCutoffConfigRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  nextDayShippingPerProduct: Array<NextDayCutoffBlockRecord>;
  noteAdmin: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type NextDay CutOff Config (nextday_cutoff_config) */
export type NextdayCutoffConfigRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type NextDay CutOff Config (nextday_cutoff_config) */
export type NextdayCutoffConfigRecordNotesArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq: InputMaybe<UploadOrientation>;
};

export type PageModelFilter = {
  AND: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasCustomLogo: InputMaybe<BooleanFilter>;
  hideFromSearchEngines: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  logo: InputMaybe<FileFilter>;
  pageHeaderType: InputMaybe<StringFilter>;
  preview: InputMaybe<JsonFilter>;
  revalidate: InputMaybe<JsonFilter>;
  sections: InputMaybe<LinksFilter>;
  seo: InputMaybe<SeoFilter>;
  showPageFooter: InputMaybe<BooleanFilter>;
  showPageHeader: InputMaybe<BooleanFilter>;
  slug: InputMaybe<SlugFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum PageModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasCustomLogoAsc = "hasCustomLogo_ASC",
  HasCustomLogoDesc = "hasCustomLogo_DESC",
  HideFromSearchEnginesAsc = "hideFromSearchEngines_ASC",
  HideFromSearchEnginesDesc = "hideFromSearchEngines_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  PageHeaderTypeAsc = "pageHeaderType_ASC",
  PageHeaderTypeDesc = "pageHeaderType_DESC",
  ShowPageFooterAsc = "showPageFooter_ASC",
  ShowPageFooterDesc = "showPageFooter_DESC",
  ShowPageHeaderAsc = "showPageHeader_ASC",
  ShowPageHeaderDesc = "showPageHeader_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Page (page) */
export type PageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  hasCustomLogo: Maybe<Scalars["BooleanType"]>;
  hideFromSearchEngines: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  logo: Maybe<FileField>;
  pageHeaderType: Maybe<Scalars["String"]>;
  preview: Maybe<Scalars["JsonField"]>;
  revalidate: Maybe<Scalars["JsonField"]>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  showPageFooter: Maybe<Scalars["BooleanType"]>;
  showPageHeader: Maybe<Scalars["BooleanType"]>;
  slug: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Page (page) */
export type PageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by parent (tree-like collections only) */
export type ParentFilter = {
  /** Filter records children of the specified record. Value must be a Record ID */
  eq: InputMaybe<Scalars["ItemId"]>;
  /** Filter records with a parent record or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
};

/** Record of type Plant Center (plant_center) */
export type PlantCenterRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Plant Center (plant_center) */
export type PlantCenterRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Plant Center (plant_center) */
export type PlantCenterRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Plant Collection (plant_collection) */
export type PlantCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  plantList: Array<PlantPageRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Plant Collection (plant_collection) */
export type PlantCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum PlantPageModelFieldsReferencingProductModel {
  PlantPageProduct = "plantPage_product",
}

export type PlantPageModelFilter = {
  AND: InputMaybe<Array<InputMaybe<PlantPageModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<PlantPageModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  badge: InputMaybe<FileFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  detailText: InputMaybe<TextFilter>;
  endingText: InputMaybe<TextFilter>;
  hasBadge: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  introText: InputMaybe<TextFilter>;
  name: InputMaybe<StringFilter>;
  primaryImage: InputMaybe<FileFilter>;
  product: InputMaybe<LinkFilter>;
  secondaryImage: InputMaybe<FileFilter>;
  sections: InputMaybe<LinksFilter>;
  seo: InputMaybe<SeoFilter>;
  slug: InputMaybe<SlugFilter>;
  subtitle: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum PlantPageModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasBadgeAsc = "hasBadge_ASC",
  HasBadgeDesc = "hasBadge_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Plant Pages (plant_page) */
export type PlantPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  badge: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  detailText: Maybe<Scalars["String"]>;
  endingText: Maybe<Scalars["String"]>;
  hasBadge: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  infoChip: Array<FeatureImageFullRecord>;
  introText: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  primaryImage: Maybe<FileField>;
  product: Maybe<ProductRecord>;
  secondaryImage: Maybe<FileField>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Plant Pages (plant_page) */
export type PlantPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Plant Pages (plant_page) */
export type PlantPageRecordDetailTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Plant Pages (plant_page) */
export type PlantPageRecordEndingTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Plant Pages (plant_page) */
export type PlantPageRecordIntroTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Plant Shop Page (plant_shop_page) */
export type PlantShopPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  preview: Maybe<Scalars["JsonField"]>;
  productCollections: Array<ProductCollectionRecord>;
  revalidate: Maybe<Scalars["JsonField"]>;
  sections: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Plant Shop Page (plant_shop_page) */
export type PlantShopPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Plant Shop Page (plant_shop_page) */
export type PlantShopPageRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
  /** Search for records with an exact match */
  eq: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less than the one specified */
  lt: InputMaybe<Scalars["IntType"]>;
  /** Filter records with a value that's less or equal than the one specified */
  lte: InputMaybe<Scalars["IntType"]>;
  /** Exclude records with an exact match */
  neq: InputMaybe<Scalars["IntType"]>;
};

export type PressArticleModelFilter = {
  AND: InputMaybe<Array<InputMaybe<PressArticleModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<PressArticleModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  date: InputMaybe<DateFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  isActive: InputMaybe<BooleanFilter>;
  link: InputMaybe<StringFilter>;
  shortDescription: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum PressArticleModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DateAsc = "date_ASC",
  DateDesc = "date_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsActiveAsc = "isActive_ASC",
  IsActiveDesc = "isActive_DESC",
  LinkAsc = "link_ASC",
  LinkDesc = "link_DESC",
  ShortDescriptionAsc = "shortDescription_ASC",
  ShortDescriptionDesc = "shortDescription_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Press Article (press_article) */
export type PressArticleRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  date: Maybe<Scalars["Date"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  isActive: Maybe<Scalars["BooleanType"]>;
  link: Maybe<Scalars["String"]>;
  shortDescription: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Press Article (press_article) */
export type PressArticleRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Press Page (press_page) */
export type PressPageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  contact: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Press Page (press_page) */
export type PressPageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum ProductBestSellerModelFieldsReferencingProductModel {
  ProductBestSellerPageLinkUrl = "productBestSeller_pageLinkUrl",
  ProductBestSellerPageLinkUrlInternalLinkLink = "productBestSeller_pageLinkUrl__internalLink_link",
  ProductBestSellerProductBestSeller = "productBestSeller_productBestSeller",
}

export type ProductBestSellerModelFilter = {
  AND: InputMaybe<Array<InputMaybe<ProductBestSellerModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ProductBestSellerModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasslider: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  productBestSeller: InputMaybe<LinksFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum ProductBestSellerModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HassliderAsc = "hasslider_ASC",
  HassliderDesc = "hasslider_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Product - Best Seller (product_best_seller) */
export type ProductBestSellerRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  hasslider: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  pageLinkUrl: Array<InternalLinkRecord>;
  productBestSeller: Array<ProductRecord>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Product - Best Seller (product_best_seller) */
export type ProductBestSellerRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum ProductCollectionModelFieldsReferencingProductModel {
  ProductCollectionProducts = "productCollection_products",
}

export type ProductCollectionModelFilter = {
  AND: InputMaybe<Array<InputMaybe<ProductCollectionModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ProductCollectionModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  centered: InputMaybe<BooleanFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  description: InputMaybe<TextFilter>;
  hasFilterComponent: InputMaybe<BooleanFilter>;
  hasSlider: InputMaybe<BooleanFilter>;
  hideBreadcrumb: InputMaybe<BooleanFilter>;
  hideTitle: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  isTitleH1: InputMaybe<BooleanFilter>;
  landingPageLink: InputMaybe<LinkFilter>;
  productTags: InputMaybe<LinksFilter>;
  products: InputMaybe<LinksFilter>;
  showProductAs: InputMaybe<StringFilter>;
  slug: InputMaybe<SlugFilter>;
  title: InputMaybe<StringFilter>;
  titleSubtitleFontSize: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  visibleInShop: InputMaybe<BooleanFilter>;
};

export enum ProductCollectionModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CenteredAsc = "centered_ASC",
  CenteredDesc = "centered_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasFilterComponentAsc = "hasFilterComponent_ASC",
  HasFilterComponentDesc = "hasFilterComponent_DESC",
  HasSliderAsc = "hasSlider_ASC",
  HasSliderDesc = "hasSlider_DESC",
  HideBreadcrumbAsc = "hideBreadcrumb_ASC",
  HideBreadcrumbDesc = "hideBreadcrumb_DESC",
  HideTitleAsc = "hideTitle_ASC",
  HideTitleDesc = "hideTitle_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsTitleH1Asc = "isTitleH1_ASC",
  IsTitleH1Desc = "isTitleH1_DESC",
  ShowProductAsAsc = "showProductAs_ASC",
  ShowProductAsDesc = "showProductAs_DESC",
  TitleSubtitleFontSizeAsc = "titleSubtitleFontSize_ASC",
  TitleSubtitleFontSizeDesc = "titleSubtitleFontSize_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  VisibleInShopAsc = "visibleInShop_ASC",
  VisibleInShopDesc = "visibleInShop_DESC",
}

export type ProductCollectionModelProductsField =
  | ProductRecord
  | PromotionalCardRecord;

/** Record of type Product Collection (product_collection) */
export type ProductCollectionRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  centered: Maybe<Scalars["BooleanType"]>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  hasFilterComponent: Maybe<Scalars["BooleanType"]>;
  hasSlider: Maybe<Scalars["BooleanType"]>;
  hideBreadcrumb: Maybe<Scalars["BooleanType"]>;
  hideTitle: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  isTitleH1: Maybe<Scalars["BooleanType"]>;
  landingPageLink: Maybe<PageRecord>;
  productTags: Array<ProductTagRecord>;
  products: Array<ProductCollectionModelProductsField>;
  showProductAs: Maybe<Scalars["String"]>;
  slug: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleSubtitleFontSize: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  visibleInShop: Maybe<Scalars["BooleanType"]>;
};

/** Record of type Product Collection (product_collection) */
export type ProductCollectionRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Product Collection (product_collection) */
export type ProductCollectionRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Linking fields */
export enum ProductCollectionSliderModelFieldsReferencingProductModel {
  ProductCollectionSliderPageLinkUrl = "productCollectionSlider_pageLinkUrl",
  ProductCollectionSliderPageLinkUrlInternalLinkLink = "productCollectionSlider_pageLinkUrl__internalLink_link",
}

export type ProductCollectionSliderModelFilter = {
  AND: InputMaybe<Array<InputMaybe<ProductCollectionSliderModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ProductCollectionSliderModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  bgColor: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  productColletionLink: InputMaybe<LinksFilter>;
  showSideTextOnDesktop: InputMaybe<BooleanFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitleHtml: InputMaybe<TextFilter>;
  title: InputMaybe<StringFilter>;
  titleHtml: InputMaybe<TextFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum ProductCollectionSliderModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  BgColorAsc = "bgColor_ASC",
  BgColorDesc = "bgColor_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ShowSideTextOnDesktopAsc = "showSideTextOnDesktop_ASC",
  ShowSideTextOnDesktopDesc = "showSideTextOnDesktop_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Product Collection Slider (product_collection_slider) */
export type ProductCollectionSliderRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  bgColor: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  pageLinkUrl: Array<InternalLinkRecord>;
  productColletionLink: Array<ProductCollectionRecord>;
  showSideTextOnDesktop: Maybe<Scalars["BooleanType"]>;
  subtitle: Maybe<Scalars["String"]>;
  subtitleHtml: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleHtml: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Product Collection Slider (product_collection_slider) */
export type ProductCollectionSliderRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Product Collection Slider (product_collection_slider) */
export type ProductCollectionSliderRecordSubtitleHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product Collection Slider (product_collection_slider) */
export type ProductCollectionSliderRecordTitleHtmlArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Block of type Product Date Rule (product_date_rule) */
export type ProductDateRuleRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  applyTo: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  endDate: Maybe<Scalars["Date"]>;
  id: Scalars["ItemId"];
  note: Maybe<Scalars["String"]>;
  startDate: Maybe<Scalars["Date"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Product Date Rule (product_date_rule) */
export type ProductDateRuleRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum ProductModelFieldsReferencingProductModel {
  ProductAddAddOnAutomatically = "product_addAddOnAutomatically",
  ProductExcludeNextdayProducts = "product_excludeNextdayProducts",
  ProductListUpsellingToExclude = "product_listUpsellingToExclude",
  ProductUpsellingProducts = "product_upsellingProducts",
  ProductVariants = "product_variants",
  ProductVariantsVariantListUpsellingExcludePerVariant = "product_variants__variant_listUpsellingExcludePerVariant",
}

export type ProductModelFilter = {
  AND: InputMaybe<Array<InputMaybe<ProductModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ProductModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  addAddOnAutomatically: InputMaybe<LinksFilter>;
  adminTitle: InputMaybe<StringFilter>;
  backgroundColor: InputMaybe<ColorFilter>;
  bouquetSize: InputMaybe<StringFilter>;
  cardType: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  customLabel0: InputMaybe<StringFilter>;
  customLabel1: InputMaybe<StringFilter>;
  customLabel2: InputMaybe<StringFilter>;
  customLabel3: InputMaybe<StringFilter>;
  customLabel4: InputMaybe<StringFilter>;
  cutOffTime: InputMaybe<LinkFilter>;
  deliveryOption: InputMaybe<StringFilter>;
  description: InputMaybe<TextFilter>;
  disableDatePicker: InputMaybe<BooleanFilter>;
  excludeNextdayProducts: InputMaybe<LinksFilter>;
  farm: InputMaybe<StringFilter>;
  fbProductCategory: InputMaybe<StringFilter>;
  gallery: InputMaybe<GalleryFilter>;
  globalDatepickerConfig: InputMaybe<LinkFilter>;
  googleProductCategory: InputMaybe<StringFilter>;
  hasColumnLayout: InputMaybe<BooleanFilter>;
  hasDisabledNextDayDelivery: InputMaybe<BooleanFilter>;
  hasNotBreadcrumb: InputMaybe<BooleanFilter>;
  hasVariant: InputMaybe<BooleanFilter>;
  hideFromSearchEngines: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  importantInfo: InputMaybe<TextFilter>;
  isAvailableForNextday: InputMaybe<BooleanFilter>;
  isDescriptionAfterTitle: InputMaybe<BooleanFilter>;
  isFacebookProduct: InputMaybe<BooleanFilter>;
  isGoogleProduct: InputMaybe<BooleanFilter>;
  isSubscription: InputMaybe<BooleanFilter>;
  isSuperGift: InputMaybe<BooleanFilter>;
  isTitleDescriptionFirst: InputMaybe<BooleanFilter>;
  isUpselling: InputMaybe<BooleanFilter>;
  listUpsellingToExclude: InputMaybe<LinksFilter>;
  noPdp: InputMaybe<BooleanFilter>;
  pageHeaderType: InputMaybe<StringFilter>;
  prefixFrecuency: InputMaybe<StringFilter>;
  preview: InputMaybe<JsonFilter>;
  priceRange: InputMaybe<StringFilter>;
  priorityUpselling: InputMaybe<IntegerFilter>;
  revalidate: InputMaybe<JsonFilter>;
  sectionTitle: InputMaybe<StringFilter>;
  sections: InputMaybe<LinksFilter>;
  sectionsBeforeSimilarProducts: InputMaybe<LinksFilter>;
  seo: InputMaybe<SeoFilter>;
  shopifyProduct: InputMaybe<JsonFilter>;
  shopifySubscriptionProducts: InputMaybe<JsonFilter>;
  shortDescription: InputMaybe<TextFilter>;
  showPageFooter: InputMaybe<BooleanFilter>;
  showPageHeader: InputMaybe<BooleanFilter>;
  sku: InputMaybe<StringFilter>;
  slug: InputMaybe<SlugFilter>;
  tagFilter: InputMaybe<LinksFilter>;
  tags: InputMaybe<LinksFilter>;
  thirdParty: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  upsellingProducts: InputMaybe<LinksFilter>;
  variantType: InputMaybe<StringFilter>;
};

export enum ProductModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  BouquetSizeAsc = "bouquetSize_ASC",
  BouquetSizeDesc = "bouquetSize_DESC",
  CardTypeAsc = "cardType_ASC",
  CardTypeDesc = "cardType_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  CustomLabel0Asc = "customLabel0_ASC",
  CustomLabel0Desc = "customLabel0_DESC",
  CustomLabel1Asc = "customLabel1_ASC",
  CustomLabel1Desc = "customLabel1_DESC",
  CustomLabel2Asc = "customLabel2_ASC",
  CustomLabel2Desc = "customLabel2_DESC",
  CustomLabel3Asc = "customLabel3_ASC",
  CustomLabel3Desc = "customLabel3_DESC",
  CustomLabel4Asc = "customLabel4_ASC",
  CustomLabel4Desc = "customLabel4_DESC",
  DeliveryOptionAsc = "deliveryOption_ASC",
  DeliveryOptionDesc = "deliveryOption_DESC",
  DisableDatePickerAsc = "disableDatePicker_ASC",
  DisableDatePickerDesc = "disableDatePicker_DESC",
  FarmAsc = "farm_ASC",
  FarmDesc = "farm_DESC",
  FbProductCategoryAsc = "fbProductCategory_ASC",
  FbProductCategoryDesc = "fbProductCategory_DESC",
  GoogleProductCategoryAsc = "googleProductCategory_ASC",
  GoogleProductCategoryDesc = "googleProductCategory_DESC",
  HasColumnLayoutAsc = "hasColumnLayout_ASC",
  HasColumnLayoutDesc = "hasColumnLayout_DESC",
  HasDisabledNextDayDeliveryAsc = "hasDisabledNextDayDelivery_ASC",
  HasDisabledNextDayDeliveryDesc = "hasDisabledNextDayDelivery_DESC",
  HasNotBreadcrumbAsc = "hasNotBreadcrumb_ASC",
  HasNotBreadcrumbDesc = "hasNotBreadcrumb_DESC",
  HasVariantAsc = "hasVariant_ASC",
  HasVariantDesc = "hasVariant_DESC",
  HideFromSearchEnginesAsc = "hideFromSearchEngines_ASC",
  HideFromSearchEnginesDesc = "hideFromSearchEngines_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsAvailableForNextdayAsc = "isAvailableForNextday_ASC",
  IsAvailableForNextdayDesc = "isAvailableForNextday_DESC",
  IsDescriptionAfterTitleAsc = "isDescriptionAfterTitle_ASC",
  IsDescriptionAfterTitleDesc = "isDescriptionAfterTitle_DESC",
  IsFacebookProductAsc = "isFacebookProduct_ASC",
  IsFacebookProductDesc = "isFacebookProduct_DESC",
  IsGoogleProductAsc = "isGoogleProduct_ASC",
  IsGoogleProductDesc = "isGoogleProduct_DESC",
  IsSubscriptionAsc = "isSubscription_ASC",
  IsSubscriptionDesc = "isSubscription_DESC",
  IsSuperGiftAsc = "isSuperGift_ASC",
  IsSuperGiftDesc = "isSuperGift_DESC",
  IsTitleDescriptionFirstAsc = "isTitleDescriptionFirst_ASC",
  IsTitleDescriptionFirstDesc = "isTitleDescriptionFirst_DESC",
  IsUpsellingAsc = "isUpselling_ASC",
  IsUpsellingDesc = "isUpselling_DESC",
  NoPdpAsc = "noPdp_ASC",
  NoPdpDesc = "noPdp_DESC",
  PageHeaderTypeAsc = "pageHeaderType_ASC",
  PageHeaderTypeDesc = "pageHeaderType_DESC",
  PrefixFrecuencyAsc = "prefixFrecuency_ASC",
  PrefixFrecuencyDesc = "prefixFrecuency_DESC",
  PriceRangeAsc = "priceRange_ASC",
  PriceRangeDesc = "priceRange_DESC",
  PriorityUpsellingAsc = "priorityUpselling_ASC",
  PriorityUpsellingDesc = "priorityUpselling_DESC",
  SectionTitleAsc = "sectionTitle_ASC",
  SectionTitleDesc = "sectionTitle_DESC",
  ShowPageFooterAsc = "showPageFooter_ASC",
  ShowPageFooterDesc = "showPageFooter_DESC",
  ShowPageHeaderAsc = "showPageHeader_ASC",
  ShowPageHeaderDesc = "showPageHeader_DESC",
  SkuAsc = "sku_ASC",
  SkuDesc = "sku_DESC",
  ThirdPartyAsc = "thirdParty_ASC",
  ThirdPartyDesc = "thirdParty_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  VariantTypeAsc = "variantType_ASC",
  VariantTypeDesc = "variantType_DESC",
}

/** Record of type Product (product) */
export type ProductRecord = RecordInterface & {
  _allReferencingBlockCtas: Array<BlockCtaRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockCtasMeta: CollectionMetadata;
  _allReferencingBlockFeatureContentOverlays: Array<BlockFeatureContentOverlayRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeatureContentOverlaysMeta: CollectionMetadata;
  _allReferencingBlockFeatureImageCards: Array<BlockFeatureImageCardRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeatureImageCardsMeta: CollectionMetadata;
  _allReferencingBlockFeatureImageFulls: Array<BlockFeatureImageFullRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeatureImageFullsMeta: CollectionMetadata;
  _allReferencingBlockFeatureLinkCollections: Array<BlockFeatureLinkCollectionRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeatureLinkCollectionsMeta: CollectionMetadata;
  _allReferencingBlockFeatureRowImageCards: Array<BlockFeatureRowImageCardRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeatureRowImageCardsMeta: CollectionMetadata;
  _allReferencingBlockFeatureText2imagesButtons: Array<BlockFeatureText2imagesButtonRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeatureText2imagesButtonsMeta: CollectionMetadata;
  _allReferencingBlockFeatureTextImageModules: Array<BlockFeatureTextImageModuleRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeatureTextImageModulesMeta: CollectionMetadata;
  _allReferencingBlockFeaturedIns: Array<BlockFeaturedInRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockFeaturedInsMeta: CollectionMetadata;
  _allReferencingBlockHeroAnimatedTexts: Array<BlockHeroAnimatedTextRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockHeroAnimatedTextsMeta: CollectionMetadata;
  _allReferencingBlockHerobanner2columns: Array<BlockHerobanner2columnRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockHerobanner2columnsMeta: CollectionMetadata;
  _allReferencingBlockHeros: Array<BlockHeroRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockHerosMeta: CollectionMetadata;
  _allReferencingBlockMainFeatures: Array<BlockMainFeatureRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlockMainFeaturesMeta: CollectionMetadata;
  _allReferencingBlogPosts: Array<BlogPostRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingBlogPostsMeta: CollectionMetadata;
  _allReferencingDatepickerConfigs: Array<DatepickerConfigRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingDatepickerConfigsMeta: CollectionMetadata;
  _allReferencingFlowerPages: Array<FlowerPageRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingFlowerPagesMeta: CollectionMetadata;
  _allReferencingFooters: Array<FooterRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingFootersMeta: CollectionMetadata;
  _allReferencingGoogleShoppings: Array<GoogleShoppingRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingGoogleShoppingsMeta: CollectionMetadata;
  _allReferencingHeaders: Array<HeaderRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingHeadersMeta: CollectionMetadata;
  _allReferencingPlantPages: Array<PlantPageRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingPlantPagesMeta: CollectionMetadata;
  _allReferencingProductBestSellers: Array<ProductBestSellerRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingProductBestSellersMeta: CollectionMetadata;
  _allReferencingProductCollectionSliders: Array<ProductCollectionSliderRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingProductCollectionSlidersMeta: CollectionMetadata;
  _allReferencingProductCollections: Array<ProductCollectionRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingProductCollectionsMeta: CollectionMetadata;
  _allReferencingProducts: Array<ProductRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingProductsMeta: CollectionMetadata;
  _allReferencingPromotionalCards: Array<PromotionalCardRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingPromotionalCardsMeta: CollectionMetadata;
  _allReferencingSecondaryHeroBanners: Array<SecondaryHeroBannerRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingSecondaryHeroBannersMeta: CollectionMetadata;
  _allReferencingSubMenuItems: Array<SubMenuItemRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingSubMenuItemsMeta: CollectionMetadata;
  _allReferencingSubMenuSettings: Array<SubMenuSettingRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingSubMenuSettingsMeta: CollectionMetadata;
  _allReferencingSubscribePages: Array<SubscribePageRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingSubscribePagesMeta: CollectionMetadata;
  _allReferencingSubscriptionPlans: Array<SubscriptionPlanRecord>;
  /** Returns meta information regarding a record collection */
  _allReferencingSubscriptionPlansMeta: CollectionMetadata;
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  addAddOnAutomatically: Array<ProductRecord>;
  adminTitle: Maybe<Scalars["String"]>;
  backgroundColor: Maybe<ColorField>;
  bouquetSize: Maybe<Scalars["String"]>;
  cardType: Maybe<Scalars["String"]>;
  configDatesPerProduct: Array<ConfigDatesPerProductCopy1Record>;
  createdAt: Scalars["DateTime"];
  customLabel0: Maybe<Scalars["String"]>;
  customLabel1: Maybe<Scalars["String"]>;
  customLabel2: Maybe<Scalars["String"]>;
  customLabel3: Maybe<Scalars["String"]>;
  customLabel4: Maybe<Scalars["String"]>;
  cutOffTime: Maybe<NextdayCutoffConfigRecord>;
  cutoffDatesPerProduct: Array<CutoffDatesPerProductCopy1Record>;
  deliveryOption: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  disableDatePicker: Maybe<Scalars["BooleanType"]>;
  disabledDatesPerProduct: Array<ProductDateRuleRecord>;
  enabledDatesPerProduct: Array<ProductDateRuleRecord>;
  excludeNextdayProducts: Array<ProductRecord>;
  farm: Maybe<Scalars["String"]>;
  fbProductCategory: Maybe<Scalars["String"]>;
  gallery: Array<FileField>;
  globalDatepickerConfig: Maybe<GlobalDatepickerConfigRecord>;
  googleProductCategory: Maybe<Scalars["String"]>;
  hasColumnLayout: Maybe<Scalars["BooleanType"]>;
  hasDisabledNextDayDelivery: Maybe<Scalars["BooleanType"]>;
  hasNotBreadcrumb: Maybe<Scalars["BooleanType"]>;
  hasVariant: Maybe<Scalars["BooleanType"]>;
  hideFromSearchEngines: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  importantInfo: Maybe<Scalars["String"]>;
  isAvailableForNextday: Maybe<Scalars["BooleanType"]>;
  isDescriptionAfterTitle: Maybe<Scalars["BooleanType"]>;
  isFacebookProduct: Maybe<Scalars["BooleanType"]>;
  isGoogleProduct: Maybe<Scalars["BooleanType"]>;
  isSubscription: Maybe<Scalars["BooleanType"]>;
  isSuperGift: Maybe<Scalars["BooleanType"]>;
  isTitleDescriptionFirst: Maybe<Scalars["BooleanType"]>;
  isUpselling: Maybe<Scalars["BooleanType"]>;
  listUpsellingToExclude: Array<ProductRecord>;
  noPdp: Maybe<Scalars["BooleanType"]>;
  pageHeaderType: Maybe<Scalars["String"]>;
  prefixFrecuency: Maybe<Scalars["String"]>;
  preview: Maybe<Scalars["JsonField"]>;
  priceRange: Maybe<Scalars["String"]>;
  priorityUpselling: Maybe<Scalars["IntType"]>;
  productDetailItems: Array<TitleTextRecord>;
  revalidate: Maybe<Scalars["JsonField"]>;
  sectionTitle: Maybe<Scalars["String"]>;
  sections: Array<ContentSectionRecord>;
  sectionsBeforeSimilarProducts: Array<ContentSectionRecord>;
  seo: Maybe<SeoField>;
  shopifyProduct: Maybe<Scalars["JsonField"]>;
  shopifySubscriptionProducts: Maybe<Scalars["JsonField"]>;
  shortDescription: Maybe<Scalars["String"]>;
  showPageFooter: Maybe<Scalars["BooleanType"]>;
  showPageHeader: Maybe<Scalars["BooleanType"]>;
  sku: Maybe<Scalars["String"]>;
  slug: Maybe<Scalars["String"]>;
  tagFilter: Array<ProductTagRecord>;
  tags: Array<ProductTagRecord>;
  thirdParty: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  upsellingProducts: Array<ProductRecord>;
  variantType: Maybe<Scalars["String"]>;
  variants: Array<VariantRecord>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockCtasArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockCtaModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockCtaModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockCtaAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockCtasMetaArgs = {
  filter: InputMaybe<BlockCtaModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockCtaAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureContentOverlaysArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureContentOverlayModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureContentOverlayModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureContentOverlayAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureContentOverlaysMetaArgs = {
  filter: InputMaybe<BlockFeatureContentOverlayModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureContentOverlayAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureImageCardsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageCardModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageCardModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureImageCardAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureImageCardsMetaArgs = {
  filter: InputMaybe<BlockFeatureImageCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureImageCardAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureImageFullsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageFullModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageFullModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureImageFullAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureImageFullsMetaArgs = {
  filter: InputMaybe<BlockFeatureImageFullModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureImageFullAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureLinkCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureLinkCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureLinkCollectionModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureLinkCollectionAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureLinkCollectionsMetaArgs = {
  filter: InputMaybe<BlockFeatureLinkCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureLinkCollectionAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureRowImageCardsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureRowImageCardModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureRowImageCardModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureRowImageCardAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureRowImageCardsMetaArgs = {
  filter: InputMaybe<BlockFeatureRowImageCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureRowImageCardAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureText2imagesButtonsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureText2imagesButtonModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureText2imagesButtonModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureText2imagesButtonAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureText2imagesButtonsMetaArgs =
  {
    filter: InputMaybe<BlockFeatureText2imagesButtonModelFilter>;
    locale: InputMaybe<SiteLocale>;
    through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureText2imagesButtonAndProduct>;
  };

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureTextImageModulesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTextImageModuleModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureTextImageModuleModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureTextImageModuleAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeatureTextImageModulesMetaArgs = {
  filter: InputMaybe<BlockFeatureTextImageModuleModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeatureTextImageModuleAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeaturedInsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeaturedInModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeaturedInModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeaturedInAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockFeaturedInsMetaArgs = {
  filter: InputMaybe<BlockFeaturedInModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockFeaturedInAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockHeroAnimatedTextsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroAnimatedTextModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHeroAnimatedTextModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockHeroAnimatedTextAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockHeroAnimatedTextsMetaArgs = {
  filter: InputMaybe<BlockHeroAnimatedTextModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockHeroAnimatedTextAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockHerobanner2columnsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHerobanner2columnModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHerobanner2columnModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockHerobanner2columnAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockHerobanner2columnsMetaArgs = {
  filter: InputMaybe<BlockHerobanner2columnModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockHerobanner2columnAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockHerosArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHeroModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockHeroAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockHerosMetaArgs = {
  filter: InputMaybe<BlockHeroModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockHeroAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockMainFeaturesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockMainFeatureModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockMainFeatureModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockMainFeatureAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlockMainFeaturesMetaArgs = {
  filter: InputMaybe<BlockMainFeatureModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlockMainFeatureAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlogPostsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlogPostModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlogPostModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlogPostAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingBlogPostsMetaArgs = {
  filter: InputMaybe<BlogPostModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenBlogPostAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingDatepickerConfigsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<DatepickerConfigModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<DatepickerConfigModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenDatepickerConfigAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingDatepickerConfigsMetaArgs = {
  filter: InputMaybe<DatepickerConfigModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenDatepickerConfigAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingFlowerPagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FlowerPageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FlowerPageModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenFlowerPageAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingFlowerPagesMetaArgs = {
  filter: InputMaybe<FlowerPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenFlowerPageAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingFootersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FooterModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FooterModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenFooterAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingFootersMetaArgs = {
  filter: InputMaybe<FooterModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenFooterAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingGoogleShoppingsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<GoogleShoppingModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GoogleShoppingModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenGoogleShoppingAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingGoogleShoppingsMetaArgs = {
  filter: InputMaybe<GoogleShoppingModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenGoogleShoppingAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingHeadersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<HeaderModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<HeaderModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenHeaderAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingHeadersMetaArgs = {
  filter: InputMaybe<HeaderModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenHeaderAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingPlantPagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PlantPageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PlantPageModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenPlantPageAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingPlantPagesMetaArgs = {
  filter: InputMaybe<PlantPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenPlantPageAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductBestSellersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductBestSellerModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductBestSellerModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductBestSellerAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductBestSellersMetaArgs = {
  filter: InputMaybe<ProductBestSellerModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductBestSellerAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductCollectionSlidersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionSliderModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductCollectionSliderModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductCollectionSliderAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductCollectionSlidersMetaArgs = {
  filter: InputMaybe<ProductCollectionSliderModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductCollectionSliderAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductCollectionModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductCollectionAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductCollectionsMetaArgs = {
  filter: InputMaybe<ProductCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductCollectionAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingProductsMetaArgs = {
  filter: InputMaybe<ProductModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenProductAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingPromotionalCardsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PromotionalCardModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PromotionalCardModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenPromotionalCardAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingPromotionalCardsMetaArgs = {
  filter: InputMaybe<PromotionalCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenPromotionalCardAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSecondaryHeroBannersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SecondaryHeroBannerModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SecondaryHeroBannerModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenSecondaryHeroBannerAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSecondaryHeroBannersMetaArgs = {
  filter: InputMaybe<SecondaryHeroBannerModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenSecondaryHeroBannerAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubMenuItemsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuItemModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubMenuItemModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubMenuItemAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubMenuItemsMetaArgs = {
  filter: InputMaybe<SubMenuItemModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubMenuItemAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubMenuSettingsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuSettingModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubMenuSettingModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubMenuSettingAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubMenuSettingsMetaArgs = {
  filter: InputMaybe<SubMenuSettingModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubMenuSettingAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubscribePagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubscribePageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubscribePageModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubscribePageAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubscribePagesMetaArgs = {
  filter: InputMaybe<SubscribePageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubscribePageAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubscriptionPlansArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubscriptionPlanModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubscriptionPlanModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubscriptionPlanAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_AllReferencingSubscriptionPlansMetaArgs = {
  filter: InputMaybe<SubscriptionPlanModelFilter>;
  locale: InputMaybe<SiteLocale>;
  through: InputMaybe<InverseRelationshipFilterBetweenSubscriptionPlanAndProduct>;
};

/** Record of type Product (product) */
export type ProductRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Product (product) */
export type ProductRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecordImportantInfoArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Product (product) */
export type ProductRecordShortDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type ProductTagModelFilter = {
  AND: InputMaybe<Array<InputMaybe<ProductTagModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ProductTagModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  alwaysVisible: InputMaybe<BooleanFilter>;
  backgroundColor: InputMaybe<ColorFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  filterType: InputMaybe<StringFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  isAFlowerPlantCenterCollection: InputMaybe<BooleanFilter>;
  maxPrice: InputMaybe<StringFilter>;
  minPrice: InputMaybe<StringFilter>;
  priority: InputMaybe<IntegerFilter>;
  slug: InputMaybe<SlugFilter>;
  tagMessage: InputMaybe<StringFilter>;
  tagType: InputMaybe<StringFilter>;
  textColor: InputMaybe<ColorFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum ProductTagModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AlwaysVisibleAsc = "alwaysVisible_ASC",
  AlwaysVisibleDesc = "alwaysVisible_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  FilterTypeAsc = "filterType_ASC",
  FilterTypeDesc = "filterType_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsAFlowerPlantCenterCollectionAsc = "isAFlowerPlantCenterCollection_ASC",
  IsAFlowerPlantCenterCollectionDesc = "isAFlowerPlantCenterCollection_DESC",
  MaxPriceAsc = "maxPrice_ASC",
  MaxPriceDesc = "maxPrice_DESC",
  MinPriceAsc = "minPrice_ASC",
  MinPriceDesc = "minPrice_DESC",
  PriorityAsc = "priority_ASC",
  PriorityDesc = "priority_DESC",
  TagMessageAsc = "tagMessage_ASC",
  TagMessageDesc = "tagMessage_DESC",
  TagTypeAsc = "tagType_ASC",
  TagTypeDesc = "tagType_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Product Tag (product_tag) */
export type ProductTagRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  alwaysVisible: Maybe<Scalars["BooleanType"]>;
  backgroundColor: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  filterType: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  isAFlowerPlantCenterCollection: Maybe<Scalars["BooleanType"]>;
  maxPrice: Maybe<Scalars["String"]>;
  minPrice: Maybe<Scalars["String"]>;
  priority: Maybe<Scalars["IntType"]>;
  slug: Maybe<Scalars["String"]>;
  tagMessage: Maybe<Scalars["String"]>;
  tagType: Maybe<Scalars["String"]>;
  textColor: Maybe<ColorField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Product Tag (product_tag) */
export type ProductTagRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Products Date Rule (products_date_rule) */
export type ProductsDateRuleRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  endDate: Maybe<Scalars["Date"]>;
  id: Scalars["ItemId"];
  note: Maybe<Scalars["String"]>;
  products: Array<ProductRecord>;
  startDate: Maybe<Scalars["Date"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Products Date Rule (products_date_rule) */
export type ProductsDateRuleRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Products Disabled Rule (products_disabled_date) */
export type ProductsDisabledDateRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  endDate: Maybe<Scalars["Date"]>;
  id: Scalars["ItemId"];
  products: Array<ProductRecord>;
  startDate: Maybe<Scalars["Date"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Products Disabled Rule (products_disabled_date) */
export type ProductsDisabledDateRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum PromotionalCardModelFieldsReferencingProductModel {
  PromotionalCardPageLinkUrl = "promotionalCard_pageLinkUrl",
  PromotionalCardPageLinkUrlInternalLinkLink = "promotionalCard_pageLinkUrl__internalLink_link",
}

export type PromotionalCardModelFilter = {
  AND: InputMaybe<Array<InputMaybe<PromotionalCardModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<PromotionalCardModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  image: InputMaybe<FileFilter>;
  shopifyProduct: InputMaybe<JsonFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum PromotionalCardModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Temporary name (promotional_card) */
export type PromotionalCardRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  image: Maybe<FileField>;
  pageLinkUrl: Array<InternalLinkRecord>;
  shopifyProduct: Maybe<Scalars["JsonField"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Temporary name (promotional_card) */
export type PromotionalCardRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Prose (prose) */
export type ProseRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  content: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  updatedAt: Scalars["DateTime"];
};

/** Block of type Prose (prose) */
export type ProseRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Prose (prose) */
export type ProseRecordContentArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq: InputMaybe<Scalars["DateTime"]>;
};

/** The query root for this schema */
export type Query = {
  /** Returns meta information regarding a record collection */
  _allBlockCtasMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureContentOverlaysMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureCtaComponentsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureDiscountCardsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureImageCardsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureImageFullsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureImageTextsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureImageTitlesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureLinkCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureQuotesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureReviewCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureRowImageCardsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureTestimonialsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureText2imagesButtonsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureTextIconsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureTextImageModulesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeatureVideosMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFeaturedInsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockFlowerCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockHeroAnimatedTextsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockHerobanner2columnsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockHerosMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockImageGridsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockMainFeatureContainersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockMainFeaturesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockProductCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockProseColumnTitleBgimagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockProseColumnsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockProsesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlockRowCardImageTitlesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlogCategoriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBlogPostsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allContentSectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allEmbeddedFormsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFaqsCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFaqsItemsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFlowerCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFlowerPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allGoogleProductsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allHtmlContentsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNestedPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNextdayCutoffConfigsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPlantPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPressArticlesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductBestSellersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductCollectionSlidersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductCollectionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductTagsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPromotionalCardsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allReviewsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSecondaryHeroBannersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSubMenuItemsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSubMenuSettingsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSubscriptionPlansMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns a collection of records */
  allBlockCtas: Array<BlockCtaRecord>;
  /** Returns a collection of records */
  allBlockFeatureContentOverlays: Array<BlockFeatureContentOverlayRecord>;
  /** Returns a collection of records */
  allBlockFeatureCtaComponents: Array<BlockFeatureCtaComponentRecord>;
  /** Returns a collection of records */
  allBlockFeatureDiscountCards: Array<BlockFeatureDiscountCardRecord>;
  /** Returns a collection of records */
  allBlockFeatureImageCards: Array<BlockFeatureImageCardRecord>;
  /** Returns a collection of records */
  allBlockFeatureImageFulls: Array<BlockFeatureImageFullRecord>;
  /** Returns a collection of records */
  allBlockFeatureImageTexts: Array<BlockFeatureImageTextRecord>;
  /** Returns a collection of records */
  allBlockFeatureImageTitles: Array<BlockFeatureImageTitleRecord>;
  /** Returns a collection of records */
  allBlockFeatureLinkCollections: Array<BlockFeatureLinkCollectionRecord>;
  /** Returns a collection of records */
  allBlockFeatureQuotes: Array<BlockFeatureQuoteRecord>;
  /** Returns a collection of records */
  allBlockFeatureReviewCollections: Array<BlockFeatureReviewCollectionRecord>;
  /** Returns a collection of records */
  allBlockFeatureRowImageCards: Array<BlockFeatureRowImageCardRecord>;
  /** Returns a collection of records */
  allBlockFeatureTestimonials: Array<BlockFeatureTestimonialRecord>;
  /** Returns a collection of records */
  allBlockFeatureText2imagesButtons: Array<BlockFeatureText2imagesButtonRecord>;
  /** Returns a collection of records */
  allBlockFeatureTextIcons: Array<BlockFeatureTextIconRecord>;
  /** Returns a collection of records */
  allBlockFeatureTextImageModules: Array<BlockFeatureTextImageModuleRecord>;
  /** Returns a collection of records */
  allBlockFeatureVideos: Array<BlockFeatureVideoRecord>;
  /** Returns a collection of records */
  allBlockFeaturedIns: Array<BlockFeaturedInRecord>;
  /** Returns a collection of records */
  allBlockFlowerCollections: Array<BlockFlowerCollectionRecord>;
  /** Returns a collection of records */
  allBlockHeroAnimatedTexts: Array<BlockHeroAnimatedTextRecord>;
  /** Returns a collection of records */
  allBlockHerobanner2columns: Array<BlockHerobanner2columnRecord>;
  /** Returns a collection of records */
  allBlockHeros: Array<BlockHeroRecord>;
  /** Returns a collection of records */
  allBlockImageGrids: Array<BlockImageGridRecord>;
  /** Returns a collection of records */
  allBlockMainFeatureContainers: Array<BlockMainFeatureContainerRecord>;
  /** Returns a collection of records */
  allBlockMainFeatures: Array<BlockMainFeatureRecord>;
  /** Returns a collection of records */
  allBlockProductCollections: Array<BlockProductCollectionRecord>;
  /** Returns a collection of records */
  allBlockProseColumnTitleBgimages: Array<BlockProseColumnTitleBgimageRecord>;
  /** Returns a collection of records */
  allBlockProseColumns: Array<BlockProseColumnRecord>;
  /** Returns a collection of records */
  allBlockProses: Array<BlockProseRecord>;
  /** Returns a collection of records */
  allBlockRowCardImageTitles: Array<BlockRowCardImageTitleRecord>;
  /** Returns a collection of records */
  allBlogCategories: Array<BlogCategoryRecord>;
  /** Returns a collection of records */
  allBlogPosts: Array<BlogPostRecord>;
  /** Returns a collection of records */
  allContentSections: Array<ContentSectionRecord>;
  /** Returns a collection of records */
  allEmbeddedForms: Array<EmbeddedFormRecord>;
  /** Returns a collection of records */
  allFaqsCollections: Array<FaqsCollectionRecord>;
  /** Returns a collection of records */
  allFaqsItems: Array<FaqsItemRecord>;
  /** Returns a collection of records */
  allFlowerCollections: Array<FlowerCollectionRecord>;
  /** Returns a collection of records */
  allFlowerPages: Array<FlowerPageRecord>;
  /** Returns a collection of records */
  allGoogleProducts: Array<GoogleProductRecord>;
  /** Returns a collection of records */
  allHtmlContents: Array<HtmlContentRecord>;
  /** Returns a collection of records */
  allNestedPages: Array<NestedPageRecord>;
  /** Returns a collection of records */
  allNextdayCutoffConfigs: Array<NextdayCutoffConfigRecord>;
  /** Returns a collection of records */
  allPages: Array<PageRecord>;
  /** Returns a collection of records */
  allPlantPages: Array<PlantPageRecord>;
  /** Returns a collection of records */
  allPressArticles: Array<PressArticleRecord>;
  /** Returns a collection of records */
  allProductBestSellers: Array<ProductBestSellerRecord>;
  /** Returns a collection of records */
  allProductCollectionSliders: Array<ProductCollectionSliderRecord>;
  /** Returns a collection of records */
  allProductCollections: Array<ProductCollectionRecord>;
  /** Returns a collection of records */
  allProductTags: Array<ProductTagRecord>;
  /** Returns a collection of records */
  allProducts: Array<ProductRecord>;
  /** Returns a collection of records */
  allPromotionalCards: Array<PromotionalCardRecord>;
  /** Returns a collection of records */
  allReviews: Array<ReviewRecord>;
  /** Returns a collection of records */
  allSecondaryHeroBanners: Array<SecondaryHeroBannerRecord>;
  /** Returns a collection of records */
  allSubMenuItems: Array<SubMenuItemRecord>;
  /** Returns a collection of records */
  allSubMenuSettings: Array<SubMenuSettingRecord>;
  /** Returns a collection of records */
  allSubscriptionPlans: Array<SubscriptionPlanRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns a specific record */
  blockCta: Maybe<BlockCtaRecord>;
  /** Returns the single instance record */
  blockFeatTestimonialsCompaniesSlider: Maybe<BlockFeatTestimonialsCompaniesSliderRecord>;
  /** Returns a specific record */
  blockFeatureContentOverlay: Maybe<BlockFeatureContentOverlayRecord>;
  /** Returns a specific record */
  blockFeatureCtaComponent: Maybe<BlockFeatureCtaComponentRecord>;
  /** Returns a specific record */
  blockFeatureDiscountCard: Maybe<BlockFeatureDiscountCardRecord>;
  /** Returns a specific record */
  blockFeatureImageCard: Maybe<BlockFeatureImageCardRecord>;
  /** Returns a specific record */
  blockFeatureImageFull: Maybe<BlockFeatureImageFullRecord>;
  /** Returns a specific record */
  blockFeatureImageText: Maybe<BlockFeatureImageTextRecord>;
  /** Returns a specific record */
  blockFeatureImageTitle: Maybe<BlockFeatureImageTitleRecord>;
  /** Returns the single instance record */
  blockFeatureInstagramFull: Maybe<BlockFeatureInstagramFullRecord>;
  /** Returns the single instance record */
  blockFeatureInstagramProduct: Maybe<BlockFeatureInstagramProductRecord>;
  /** Returns the single instance record */
  blockFeatureInstagramSlider: Maybe<BlockFeatureInstagramSliderRecord>;
  /** Returns a specific record */
  blockFeatureLinkCollection: Maybe<BlockFeatureLinkCollectionRecord>;
  /** Returns a specific record */
  blockFeatureQuote: Maybe<BlockFeatureQuoteRecord>;
  /** Returns a specific record */
  blockFeatureReviewCollection: Maybe<BlockFeatureReviewCollectionRecord>;
  /** Returns a specific record */
  blockFeatureRowImageCard: Maybe<BlockFeatureRowImageCardRecord>;
  /** Returns a specific record */
  blockFeatureTestimonial: Maybe<BlockFeatureTestimonialRecord>;
  /** Returns a specific record */
  blockFeatureText2imagesButton: Maybe<BlockFeatureText2imagesButtonRecord>;
  /** Returns a specific record */
  blockFeatureTextIcon: Maybe<BlockFeatureTextIconRecord>;
  /** Returns a specific record */
  blockFeatureTextImageModule: Maybe<BlockFeatureTextImageModuleRecord>;
  /** Returns a specific record */
  blockFeatureVideo: Maybe<BlockFeatureVideoRecord>;
  /** Returns a specific record */
  blockFeaturedIn: Maybe<BlockFeaturedInRecord>;
  /** Returns a specific record */
  blockFlowerCollection: Maybe<BlockFlowerCollectionRecord>;
  /** Returns a specific record */
  blockHero: Maybe<BlockHeroRecord>;
  /** Returns a specific record */
  blockHeroAnimatedText: Maybe<BlockHeroAnimatedTextRecord>;
  /** Returns a specific record */
  blockHerobanner2column: Maybe<BlockHerobanner2columnRecord>;
  /** Returns a specific record */
  blockImageGrid: Maybe<BlockImageGridRecord>;
  /** Returns a specific record */
  blockMainFeature: Maybe<BlockMainFeatureRecord>;
  /** Returns a specific record */
  blockMainFeatureContainer: Maybe<BlockMainFeatureContainerRecord>;
  /** Returns the single instance record */
  blockPlantCollection: Maybe<BlockPlantCollectionRecord>;
  /** Returns a specific record */
  blockProductCollection: Maybe<BlockProductCollectionRecord>;
  /** Returns a specific record */
  blockProse: Maybe<BlockProseRecord>;
  /** Returns a specific record */
  blockProseColumn: Maybe<BlockProseColumnRecord>;
  /** Returns a specific record */
  blockProseColumnTitleBgimage: Maybe<BlockProseColumnTitleBgimageRecord>;
  /** Returns a specific record */
  blockRowCardImageTitle: Maybe<BlockRowCardImageTitleRecord>;
  /** Returns a specific record */
  blogCategory: Maybe<BlogCategoryRecord>;
  /** Returns the single instance record */
  blogPage: Maybe<BlogPageRecord>;
  /** Returns a specific record */
  blogPost: Maybe<BlogPostRecord>;
  /** Returns the single instance record */
  contactUsPage: Maybe<ContactUsPageRecord>;
  /** Returns a specific record */
  contentSection: Maybe<ContentSectionRecord>;
  /** Returns the single instance record */
  datepickerConfig: Maybe<DatepickerConfigRecord>;
  /** Returns a specific record */
  embeddedForm: Maybe<EmbeddedFormRecord>;
  /** Returns a specific record */
  faqsCollection: Maybe<FaqsCollectionRecord>;
  /** Returns a specific record */
  faqsItem: Maybe<FaqsItemRecord>;
  /** Returns the single instance record */
  faqsPage: Maybe<FaqsPageRecord>;
  /** Returns the single instance record */
  flowerCenter: Maybe<FlowerCenterRecord>;
  /** Returns a specific record */
  flowerCollection: Maybe<FlowerCollectionRecord>;
  /** Returns a specific record */
  flowerPage: Maybe<FlowerPageRecord>;
  /** Returns the single instance record */
  flowerShopPage: Maybe<FlowerShopPageRecord>;
  /** Returns the single instance record */
  footer: Maybe<FooterRecord>;
  /** Returns the single instance record */
  globalDatepickerConfig: Maybe<GlobalDatepickerConfigRecord>;
  /** Returns a specific record */
  googleProduct: Maybe<GoogleProductRecord>;
  /** Returns the single instance record */
  googleShopping: Maybe<GoogleShoppingRecord>;
  /** Returns the single instance record */
  header: Maybe<HeaderRecord>;
  /** Returns the single instance record */
  homepage: Maybe<HomepageRecord>;
  /** Returns a specific record */
  htmlContent: Maybe<HtmlContentRecord>;
  /** Returns a specific record */
  nestedPage: Maybe<NestedPageRecord>;
  /** Returns a specific record */
  nextdayCutoffConfig: Maybe<NextdayCutoffConfigRecord>;
  /** Returns a specific record */
  page: Maybe<PageRecord>;
  /** Returns the single instance record */
  plantCenter: Maybe<PlantCenterRecord>;
  /** Returns the single instance record */
  plantCollection: Maybe<PlantCollectionRecord>;
  /** Returns a specific record */
  plantPage: Maybe<PlantPageRecord>;
  /** Returns the single instance record */
  plantShopPage: Maybe<PlantShopPageRecord>;
  /** Returns a specific record */
  pressArticle: Maybe<PressArticleRecord>;
  /** Returns the single instance record */
  pressPage: Maybe<PressPageRecord>;
  /** Returns a specific record */
  product: Maybe<ProductRecord>;
  /** Returns a specific record */
  productBestSeller: Maybe<ProductBestSellerRecord>;
  /** Returns a specific record */
  productCollection: Maybe<ProductCollectionRecord>;
  /** Returns a specific record */
  productCollectionSlider: Maybe<ProductCollectionSliderRecord>;
  /** Returns a specific record */
  productTag: Maybe<ProductTagRecord>;
  /** Returns a specific record */
  promotionalCard: Maybe<PromotionalCardRecord>;
  /** Returns a specific record */
  review: Maybe<ReviewRecord>;
  /** Returns a specific record */
  secondaryHeroBanner: Maybe<SecondaryHeroBannerRecord>;
  /** Returns a specific record */
  subMenuItem: Maybe<SubMenuItemRecord>;
  /** Returns a specific record */
  subMenuSetting: Maybe<SubMenuSettingRecord>;
  /** Returns the single instance record */
  subscribePage: Maybe<SubscribePageRecord>;
  /** Returns a specific record */
  subscriptionPlan: Maybe<SubscriptionPlanRecord>;
  /** Returns the single instance record */
  sweepstake: Maybe<SweepstakeRecord>;
  /** Returns a specific asset */
  upload: Maybe<FileField>;
};

/** The query root for this schema */
export type Query_AllBlockCtasMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockCtaModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureContentOverlaysMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureContentOverlayModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureCtaComponentsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureCtaComponentModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureDiscountCardsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureDiscountCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureImageCardsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureImageFullsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageFullModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureImageTextsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageTextModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureImageTitlesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageTitleModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureLinkCollectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureLinkCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureQuotesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureQuoteModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureReviewCollectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureReviewCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureRowImageCardsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureRowImageCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureTestimonialsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTestimonialModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureText2imagesButtonsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureText2imagesButtonModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureTextIconsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTextIconModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureTextImageModulesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTextImageModuleModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeatureVideosMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureVideoModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFeaturedInsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeaturedInModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockFlowerCollectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFlowerCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockHeroAnimatedTextsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroAnimatedTextModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockHerobanner2columnsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHerobanner2columnModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockHerosMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockImageGridsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockImageGridModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockMainFeatureContainersMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockMainFeatureContainerModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockMainFeaturesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockMainFeatureModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockProductCollectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProductCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockProseColumnTitleBgimagesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseColumnTitleBgimageModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockProseColumnsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseColumnModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockProsesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlockRowCardImageTitlesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockRowCardImageTitleModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlogCategoriesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlogCategoryModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllBlogPostsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlogPostModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllContentSectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ContentSectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllEmbeddedFormsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<EmbeddedFormModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllFaqsCollectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FaqsCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllFaqsItemsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FaqsItemModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllFlowerCollectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FlowerCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllFlowerPagesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FlowerPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllGoogleProductsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<GoogleProductModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllHtmlContentsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<HtmlContentModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllNestedPagesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<NestedPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllNextdayCutoffConfigsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<NextdayCutoffConfigModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllPagesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PageModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllPlantPagesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PlantPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllPressArticlesMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PressArticleModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductBestSellersMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductBestSellerModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductCollectionSlidersMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionSliderModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductCollectionsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductTagsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductTagModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllPromotionalCardsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PromotionalCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllReviewsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ReviewModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllSecondaryHeroBannersMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SecondaryHeroBannerModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllSubMenuItemsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuItemModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllSubMenuSettingsMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuSettingModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllSubscriptionPlansMetaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubscriptionPlanModelFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter: InputMaybe<UploadFilter>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAllBlockCtasArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockCtaModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockCtaModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureContentOverlaysArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureContentOverlayModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureContentOverlayModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureCtaComponentsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureCtaComponentModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureCtaComponentModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureDiscountCardsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureDiscountCardModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureDiscountCardModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureImageCardsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageCardModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageCardModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureImageFullsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageFullModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageFullModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureImageTextsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageTextModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageTextModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureImageTitlesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageTitleModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageTitleModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureLinkCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureLinkCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureLinkCollectionModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureQuotesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureQuoteModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureQuoteModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureReviewCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureReviewCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureReviewCollectionModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureRowImageCardsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureRowImageCardModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureRowImageCardModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureTestimonialsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTestimonialModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureTestimonialModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureText2imagesButtonsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureText2imagesButtonModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureText2imagesButtonModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureTextIconsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTextIconModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureTextIconModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureTextImageModulesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTextImageModuleModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureTextImageModuleModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeatureVideosArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureVideoModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureVideoModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFeaturedInsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeaturedInModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeaturedInModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockFlowerCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFlowerCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFlowerCollectionModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockHeroAnimatedTextsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroAnimatedTextModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHeroAnimatedTextModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockHerobanner2columnsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHerobanner2columnModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHerobanner2columnModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockHerosArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHeroModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockImageGridsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockImageGridModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockImageGridModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockMainFeatureContainersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockMainFeatureContainerModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockMainFeatureContainerModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockMainFeaturesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockMainFeatureModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockMainFeatureModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockProductCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProductCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockProductCollectionModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockProseColumnTitleBgimagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseColumnTitleBgimageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockProseColumnTitleBgimageModelOrderBy>>
  >;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockProseColumnsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseColumnModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockProseColumnModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockProsesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockProseModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlockRowCardImageTitlesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockRowCardImageTitleModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockRowCardImageTitleModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlogCategoriesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlogCategoryModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlogCategoryModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllBlogPostsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlogPostModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlogPostModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllContentSectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ContentSectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentSectionModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllEmbeddedFormsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<EmbeddedFormModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<EmbeddedFormModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllFaqsCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FaqsCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqsCollectionModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllFaqsItemsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FaqsItemModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqsItemModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllFlowerCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FlowerCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FlowerCollectionModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllFlowerPagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FlowerPageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FlowerPageModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllGoogleProductsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<GoogleProductModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GoogleProductModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllHtmlContentsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<HtmlContentModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<HtmlContentModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllNestedPagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<NestedPageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NestedPageModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllNextdayCutoffConfigsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<NextdayCutoffConfigModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NextdayCutoffConfigModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllPagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllPlantPagesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PlantPageModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PlantPageModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllPressArticlesArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PressArticleModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PressArticleModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllProductBestSellersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductBestSellerModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductBestSellerModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllProductCollectionSlidersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionSliderModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductCollectionSliderModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllProductCollectionsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductCollectionModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllProductTagsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductTagModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductTagModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllProductsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllPromotionalCardsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PromotionalCardModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PromotionalCardModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllReviewsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ReviewModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ReviewModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllSecondaryHeroBannersArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SecondaryHeroBannerModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SecondaryHeroBannerModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllSubMenuItemsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuItemModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubMenuItemModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllSubMenuSettingsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuSettingModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubMenuSettingModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllSubscriptionPlansArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubscriptionPlanModelFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubscriptionPlanModelOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars["IntType"]>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip: InputMaybe<Scalars["IntType"]>;
};

/** The query root for this schema */
export type QueryBlockCtaArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockCtaModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockCtaModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatTestimonialsCompaniesSliderArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryBlockFeatureContentOverlayArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureContentOverlayModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureContentOverlayModelOrderBy>>
  >;
};

/** The query root for this schema */
export type QueryBlockFeatureCtaComponentArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureCtaComponentModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureCtaComponentModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureDiscountCardArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureDiscountCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureDiscountCardModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureImageCardArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageCardModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureImageFullArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageFullModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageFullModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureImageTextArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageTextModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageTextModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureImageTitleArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureImageTitleModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureImageTitleModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureInstagramFullArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryBlockFeatureInstagramProductArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryBlockFeatureInstagramSliderArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryBlockFeatureLinkCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureLinkCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureLinkCollectionModelOrderBy>>
  >;
};

/** The query root for this schema */
export type QueryBlockFeatureQuoteArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureQuoteModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureQuoteModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureReviewCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureReviewCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureReviewCollectionModelOrderBy>>
  >;
};

/** The query root for this schema */
export type QueryBlockFeatureRowImageCardArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureRowImageCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureRowImageCardModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureTestimonialArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTestimonialModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureTestimonialModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureText2imagesButtonArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureText2imagesButtonModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureText2imagesButtonModelOrderBy>>
  >;
};

/** The query root for this schema */
export type QueryBlockFeatureTextIconArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTextIconModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureTextIconModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeatureTextImageModuleArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureTextImageModuleModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockFeatureTextImageModuleModelOrderBy>>
  >;
};

/** The query root for this schema */
export type QueryBlockFeatureVideoArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeatureVideoModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeatureVideoModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFeaturedInArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFeaturedInModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFeaturedInModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockFlowerCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockFlowerCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockFlowerCollectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockHeroArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHeroModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockHeroAnimatedTextArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHeroAnimatedTextModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHeroAnimatedTextModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockHerobanner2columnArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockHerobanner2columnModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockHerobanner2columnModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockImageGridArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockImageGridModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockImageGridModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockMainFeatureArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockMainFeatureModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockMainFeatureModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockMainFeatureContainerArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockMainFeatureContainerModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockMainFeatureContainerModelOrderBy>>
  >;
};

/** The query root for this schema */
export type QueryBlockPlantCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryBlockProductCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProductCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockProductCollectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockProseArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockProseModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockProseColumnArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseColumnModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockProseColumnModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlockProseColumnTitleBgimageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockProseColumnTitleBgimageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<
    Array<InputMaybe<BlockProseColumnTitleBgimageModelOrderBy>>
  >;
};

/** The query root for this schema */
export type QueryBlockRowCardImageTitleArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlockRowCardImageTitleModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlockRowCardImageTitleModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlogCategoryArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlogCategoryModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlogCategoryModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryBlogPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryBlogPostArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<BlogPostModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlogPostModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryContactUsPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryContentSectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ContentSectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentSectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryDatepickerConfigArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryEmbeddedFormArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<EmbeddedFormModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<EmbeddedFormModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryFaqsCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FaqsCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqsCollectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryFaqsItemArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FaqsItemModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqsItemModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryFaqsPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryFlowerCenterArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryFlowerCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FlowerCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FlowerCollectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryFlowerPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<FlowerPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FlowerPageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryFlowerShopPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryFooterArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryGlobalDatepickerConfigArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryGoogleProductArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<GoogleProductModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<GoogleProductModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryGoogleShoppingArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryHeaderArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryHomepageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryHtmlContentArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<HtmlContentModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<HtmlContentModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryNestedPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<NestedPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NestedPageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryNextdayCutoffConfigArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<NextdayCutoffConfigModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NextdayCutoffConfigModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPlantCenterArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryPlantCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryPlantPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PlantPageModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PlantPageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPlantShopPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryPressArticleArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PressArticleModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PressArticleModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPressPageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryProductArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryProductBestSellerArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductBestSellerModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductBestSellerModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryProductCollectionArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductCollectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryProductCollectionSliderArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductCollectionSliderModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductCollectionSliderModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryProductTagArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ProductTagModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductTagModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryPromotionalCardArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<PromotionalCardModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PromotionalCardModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryReviewArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<ReviewModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ReviewModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySecondaryHeroBannerArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SecondaryHeroBannerModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SecondaryHeroBannerModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySubMenuItemArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuItemModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubMenuItemModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySubMenuSettingArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubMenuSettingModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubMenuSettingModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySubscribePageArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QuerySubscriptionPlanArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<SubscriptionPlanModelFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SubscriptionPlanModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySweepstakeArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  filter: InputMaybe<UploadFilter>;
  locale: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};

export type RecordInterface = {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
};

export type RecordInterface_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  Icon = "icon",
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export type ResponsiveImage = {
  alt: Maybe<Scalars["String"]>;
  aspectRatio: Scalars["FloatType"];
  base64: Maybe<Scalars["String"]>;
  bgColor: Maybe<Scalars["String"]>;
  height: Scalars["IntType"];
  sizes: Scalars["String"];
  src: Scalars["String"];
  srcSet: Scalars["String"];
  title: Maybe<Scalars["String"]>;
  webpSrcSet: Scalars["String"];
  width: Scalars["IntType"];
};

export type ReviewModelFilter = {
  AND: InputMaybe<Array<InputMaybe<ReviewModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ReviewModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  adminTitle: InputMaybe<StringFilter>;
  cardType: InputMaybe<StringFilter>;
  cardsColor: InputMaybe<StringFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasDefaultCardPerView: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum ReviewModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  AdminTitleAsc = "adminTitle_ASC",
  AdminTitleDesc = "adminTitle_DESC",
  CardTypeAsc = "cardType_ASC",
  CardTypeDesc = "cardType_DESC",
  CardsColorAsc = "cardsColor_ASC",
  CardsColorDesc = "cardsColor_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasDefaultCardPerViewAsc = "hasDefaultCardPerView_ASC",
  HasDefaultCardPerViewDesc = "hasDefaultCardPerView_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Reviews (review) */
export type ReviewRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  adminTitle: Maybe<Scalars["String"]>;
  cardType: Maybe<Scalars["String"]>;
  cardsColor: Maybe<Scalars["String"]>;
  content: Array<FeatureTestimonialRecord>;
  createdAt: Scalars["DateTime"];
  hasDefaultCardPerView: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  sixCardPerViewContent: Array<FeatureTestimonialRecord>;
  threeCardPerViewContent: Array<FeatureTestimonialRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Reviews (review) */
export type ReviewRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type SecondaryHeroBannerModelButtonsField =
  | ExternalLinkRecord
  | InternalLinkRecord;

/** Linking fields */
export enum SecondaryHeroBannerModelFieldsReferencingProductModel {
  SecondaryHeroBannerButtons = "secondaryHeroBanner_buttons",
  SecondaryHeroBannerButtonsInternalLinkLink = "secondaryHeroBanner_buttons__internalLink_link",
}

export type SecondaryHeroBannerModelFilter = {
  AND: InputMaybe<Array<InputMaybe<SecondaryHeroBannerModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SecondaryHeroBannerModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  bgImage: InputMaybe<FileFilter>;
  buttonTitle: InputMaybe<StringFilter>;
  colorButton: InputMaybe<ColorFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  hasLightTheme: InputMaybe<BooleanFilter>;
  id: InputMaybe<ItemIdFilter>;
  mobileImage: InputMaybe<FileFilter>;
  showSubtitleAfterTitle: InputMaybe<BooleanFilter>;
  subtitle: InputMaybe<StringFilter>;
  subtitleFontFamily: InputMaybe<StringFilter>;
  text: InputMaybe<TextFilter>;
  textMobile: InputMaybe<TextFilter>;
  theme: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  titleFontFamily: InputMaybe<StringFilter>;
  titleSubtitleFontSize: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
  variantStyle: InputMaybe<StringFilter>;
};

export enum SecondaryHeroBannerModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  ButtonTitleAsc = "buttonTitle_ASC",
  ButtonTitleDesc = "buttonTitle_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  HasLightThemeAsc = "hasLightTheme_ASC",
  HasLightThemeDesc = "hasLightTheme_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ShowSubtitleAfterTitleAsc = "showSubtitleAfterTitle_ASC",
  ShowSubtitleAfterTitleDesc = "showSubtitleAfterTitle_DESC",
  SubtitleFontFamilyAsc = "subtitleFontFamily_ASC",
  SubtitleFontFamilyDesc = "subtitleFontFamily_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  ThemeAsc = "theme_ASC",
  ThemeDesc = "theme_DESC",
  TitleFontFamilyAsc = "titleFontFamily_ASC",
  TitleFontFamilyDesc = "titleFontFamily_DESC",
  TitleSubtitleFontSizeAsc = "titleSubtitleFontSize_ASC",
  TitleSubtitleFontSizeDesc = "titleSubtitleFontSize_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  VariantStyleAsc = "variantStyle_ASC",
  VariantStyleDesc = "variantStyle_DESC",
}

/** Record of type Secondary-Hero Banner (secondary_hero_banner) */
export type SecondaryHeroBannerRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  bgImage: Maybe<FileField>;
  buttonTitle: Maybe<Scalars["String"]>;
  buttons: Array<SecondaryHeroBannerModelButtonsField>;
  colorButton: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  hasLightTheme: Maybe<Scalars["BooleanType"]>;
  id: Scalars["ItemId"];
  mobileImage: Maybe<FileField>;
  showSubtitleAfterTitle: Maybe<Scalars["BooleanType"]>;
  subtitle: Maybe<Scalars["String"]>;
  subtitleFontFamily: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  textMobile: Maybe<Scalars["String"]>;
  theme: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  titleFontFamily: Maybe<Scalars["String"]>;
  titleSubtitleFontSize: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  variantStyle: Maybe<Scalars["String"]>;
};

/** Record of type Secondary-Hero Banner (secondary_hero_banner) */
export type SecondaryHeroBannerRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Secondary-Hero Banner (secondary_hero_banner) */
export type SecondaryHeroBannerRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Secondary-Hero Banner (secondary_hero_banner) */
export type SecondaryHeroBannerRecordTextMobileArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type SeoField = {
  description: Maybe<Scalars["String"]>;
  image: Maybe<FileField>;
  noIndex: Maybe<Scalars["BooleanType"]>;
  title: Maybe<Scalars["String"]>;
  twitterCard: Maybe<Scalars["String"]>;
};

/** Specifies how to filter SEO meta tags fields */
export type SeoFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
};

export type Site = {
  favicon: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
  noIndex: Maybe<Scalars["BooleanType"]>;
};

export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};

export type SiteGlobalSeoArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export enum SiteLocale {
  En = "en",
}

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq: InputMaybe<Scalars["String"]>;
  /** Filter records that have one of the specified slugs */
  in: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude records with an exact match */
  neq: InputMaybe<Scalars["String"]>;
  /** Filter records that do have one of the specified slugs */
  notIn: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq: InputMaybe<Scalars["String"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records that equal one of the specified values */
  in: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq: InputMaybe<Scalars["String"]>;
  /** Filter records that do not equal one of the specified values */
  notIn: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude records based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive: InputMaybe<Scalars["BooleanType"]>;
  pattern: Scalars["String"];
  regexp: InputMaybe<Scalars["BooleanType"]>;
};

/** Linking fields */
export enum SubMenuItemModelFieldsReferencingProductModel {
  SubMenuItemItem = "subMenuItem_item",
  SubMenuItemItemLinkInternalLink = "subMenuItem_item__link_internalLink",
}

export type SubMenuItemModelFilter = {
  AND: InputMaybe<Array<InputMaybe<SubMenuItemModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SubMenuItemModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum SubMenuItemModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Sub Menu Item (sub_menu_item) */
export type SubMenuItemRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  item: Array<LinkRecord>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Sub Menu Item (sub_menu_item) */
export type SubMenuItemRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum SubMenuSettingModelFieldsReferencingProductModel {
  SubMenuSettingLeftTitle = "subMenuSetting_leftTitle",
  SubMenuSettingLeftTitleLinkInternalLink = "subMenuSetting_leftTitle__link_internalLink",
  SubMenuSettingRightLink = "subMenuSetting_rightLink",
  SubMenuSettingRightLinkLinkInternalLink = "subMenuSetting_rightLink__link_internalLink",
}

export type SubMenuSettingModelFilter = {
  AND: InputMaybe<Array<InputMaybe<SubMenuSettingModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SubMenuSettingModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  item: InputMaybe<StringFilter>;
  middleSubMenuItem: InputMaybe<LinksFilter>;
  rightImage: InputMaybe<FileFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum SubMenuSettingModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ItemAsc = "item_ASC",
  ItemDesc = "item_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Sub Menu Settings (sub_menu_setting) */
export type SubMenuSettingRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  item: Maybe<Scalars["String"]>;
  leftTitle: Array<LinkRecord>;
  middleSubMenuItem: Array<SubMenuItemRecord>;
  rightImage: Maybe<FileField>;
  rightLink: Array<LinkRecord>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Sub Menu Settings (sub_menu_setting) */
export type SubMenuSettingRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Linking fields */
export enum SubscribePageModelFieldsReferencingProductModel {
  SubscribePageProducts = "subscribePage_products",
}

export type SubscribePageModelFilter = {
  AND: InputMaybe<Array<InputMaybe<SubscribePageModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SubscribePageModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  contentSections: InputMaybe<LinksFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  preview: InputMaybe<JsonFilter>;
  products: InputMaybe<LinksFilter>;
  revalidate: InputMaybe<JsonFilter>;
  seo: InputMaybe<SeoFilter>;
  slug: InputMaybe<SlugFilter>;
  subscribeContent: InputMaybe<LinksFilter>;
  subtitle: InputMaybe<StringFilter>;
  text: InputMaybe<TextFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum SubscribePageModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type Subscribe Page (subscribe_page) */
export type SubscribePageRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  contentSections: Array<ContentSectionRecord>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  preview: Maybe<Scalars["JsonField"]>;
  products: Array<ProductRecord>;
  revalidate: Maybe<Scalars["JsonField"]>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subscribeContent: Array<SubscriptionPlanRecord>;
  subtitle: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Subscribe Page (subscribe_page) */
export type SubscribePageRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Subscribe Page (subscribe_page) */
export type SubscribePageRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Linking fields */
export enum SubscriptionPlanModelFieldsReferencingProductModel {
  SubscriptionPlanProducts = "subscriptionPlan_products",
}

export type SubscriptionPlanModelFilter = {
  AND: InputMaybe<Array<InputMaybe<SubscriptionPlanModelFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SubscriptionPlanModelFilter>>>;
  _createdAt: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt: InputMaybe<PublishedAtFilter>;
  _isValid: InputMaybe<BooleanFilter>;
  _publicationScheduledAt: InputMaybe<PublishedAtFilter>;
  _publishedAt: InputMaybe<PublishedAtFilter>;
  _status: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt: InputMaybe<PublishedAtFilter>;
  _updatedAt: InputMaybe<UpdatedAtFilter>;
  bgColor: InputMaybe<StringFilter>;
  bgImage: InputMaybe<FileFilter>;
  createdAt: InputMaybe<CreatedAtFilter>;
  id: InputMaybe<ItemIdFilter>;
  isTitleH1: InputMaybe<BooleanFilter>;
  position: InputMaybe<PositionFilter>;
  products: InputMaybe<LinksFilter>;
  subtitle: InputMaybe<StringFilter>;
  text: InputMaybe<TextFilter>;
  textColor: InputMaybe<StringFilter>;
  title: InputMaybe<StringFilter>;
  updatedAt: InputMaybe<UpdatedAtFilter>;
};

export enum SubscriptionPlanModelOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  FirstPublishedAtAsc = "_firstPublishedAt_ASC",
  FirstPublishedAtDesc = "_firstPublishedAt_DESC",
  IsValidAsc = "_isValid_ASC",
  IsValidDesc = "_isValid_DESC",
  PublicationScheduledAtAsc = "_publicationScheduledAt_ASC",
  PublicationScheduledAtDesc = "_publicationScheduledAt_DESC",
  PublishedAtAsc = "_publishedAt_ASC",
  PublishedAtDesc = "_publishedAt_DESC",
  StatusAsc = "_status_ASC",
  StatusDesc = "_status_DESC",
  UnpublishingScheduledAtAsc = "_unpublishingScheduledAt_ASC",
  UnpublishingScheduledAtDesc = "_unpublishingScheduledAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  BgColorAsc = "bgColor_ASC",
  BgColorDesc = "bgColor_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsTitleH1Asc = "isTitleH1_ASC",
  IsTitleH1Desc = "isTitleH1_DESC",
  PositionAsc = "position_ASC",
  PositionDesc = "position_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  TextColorAsc = "textColor_ASC",
  TextColorDesc = "textColor_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** Record of type subscription plan (subscription_plan) */
export type SubscriptionPlanRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  bgColor: Maybe<Scalars["String"]>;
  bgImage: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  isTitleH1: Maybe<Scalars["BooleanType"]>;
  position: Maybe<Scalars["IntType"]>;
  products: Array<ProductRecord>;
  subtitle: Maybe<Scalars["String"]>;
  text: Maybe<Scalars["String"]>;
  textColor: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type subscription plan (subscription_plan) */
export type SubscriptionPlanRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type subscription plan (subscription_plan) */
export type SubscriptionPlanRecordTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Sweepstake (sweepstake) */
export type SweepstakeRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  backgroundImage: Maybe<FileField>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  isActive: Maybe<Scalars["BooleanType"]>;
  mobileImage: Maybe<FileField>;
  preview: Maybe<Scalars["JsonField"]>;
  secondaryText: Maybe<Scalars["String"]>;
  seo: Maybe<SeoField>;
  slug: Maybe<Scalars["String"]>;
  subtitle: Maybe<Scalars["String"]>;
  termsAndConditions: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Record of type Sweepstake (sweepstake) */
export type SweepstakeRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Record of type Sweepstake (sweepstake) */
export type SweepstakeRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Sweepstake (sweepstake) */
export type SweepstakeRecordSecondaryTextArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Record of type Sweepstake (sweepstake) */
export type SweepstakeRecordTermsAndConditionsArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

export type Tag = {
  attributes: Maybe<Scalars["MetaTagAttributes"]>;
  content: Maybe<Scalars["String"]>;
  tag: Scalars["String"];
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

/** Block of type Text (text) */
export type TextRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ItemId"];
  text: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Text (text) */
export type TextRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Title/Text (title_text) */
export type TitleTextRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

/** Block of type Title/Text (title_text) */
export type TitleTextRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

/** Block of type Title/Text (title_text) */
export type TitleTextRecordDescriptionArgs = {
  markdown: InputMaybe<Scalars["Boolean"]>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn: InputMaybe<Array<InputMaybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte: InputMaybe<Scalars["DateTime"]>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq: InputMaybe<Scalars["String"]>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Search uploads with the specified values as default alt */
  in: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq: InputMaybe<Scalars["String"]>;
  /** Search uploads that do not have the specified values as default alt */
  notIn: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte: InputMaybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq: InputMaybe<Scalars["DateTime"]>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

export type UploadFilter = {
  AND: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  OR: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  _createdAt: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt: InputMaybe<UploadUpdatedAtFilter>;
  alt: InputMaybe<UploadAltFilter>;
  author: InputMaybe<UploadAuthorFilter>;
  basename: InputMaybe<UploadBasenameFilter>;
  colors: InputMaybe<UploadColorsFilter>;
  copyright: InputMaybe<UploadCopyrightFilter>;
  filename: InputMaybe<UploadFilenameFilter>;
  format: InputMaybe<UploadFormatFilter>;
  height: InputMaybe<UploadHeightFilter>;
  id: InputMaybe<UploadIdFilter>;
  inUse: InputMaybe<InUseFilter>;
  md5: InputMaybe<UploadMd5Filter>;
  mimeType: InputMaybe<UploadMimeTypeFilter>;
  notes: InputMaybe<UploadNotesFilter>;
  orientation: InputMaybe<OrientationFilter>;
  resolution: InputMaybe<ResolutionFilter>;
  size: InputMaybe<UploadSizeFilter>;
  smartTags: InputMaybe<UploadTagsFilter>;
  tags: InputMaybe<UploadTagsFilter>;
  title: InputMaybe<UploadTitleFilter>;
  type: InputMaybe<TypeFilter>;
  width: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified formats */
  in: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude the asset with the specified format */
  neq: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified formats */
  notIn: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified height */
  gt: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  gte: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified height */
  lt: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified height */
  lte: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified height */
  neq: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq: InputMaybe<Scalars["UploadId"]>;
  /** Search assets with the specified IDs */
  in: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
  /** Exclude the asset with the specified ID */
  neq: InputMaybe<Scalars["UploadId"]>;
  /** Search assets that do not have the specified IDs */
  notIn: InputMaybe<Array<InputMaybe<Scalars["UploadId"]>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified MD5s */
  in: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude the asset with the specified MD5 */
  neq: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified MD5s */
  notIn: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq: InputMaybe<Scalars["String"]>;
  /** Search assets with the specified mime types */
  in: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified mime types */
  notIn: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

export enum UploadOrderBy {
  CreatedAtAsc = "_createdAt_ASC",
  CreatedAtDesc = "_createdAt_DESC",
  UpdatedAtAsc = "_updatedAt_ASC",
  UpdatedAtDesc = "_updatedAt_DESC",
  BasenameAsc = "basename_ASC",
  BasenameDesc = "basename_DESC",
  FilenameAsc = "filename_ASC",
  FilenameDesc = "filename_DESC",
  FormatAsc = "format_ASC",
  FormatDesc = "format_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  MimeTypeAsc = "mimeType_ASC",
  MimeTypeDesc = "mimeType_DESC",
  ResolutionAsc = "resolution_ASC",
  ResolutionDesc = "resolution_DESC",
  SizeAsc = "size_ASC",
  SizeDesc = "size_DESC",
}

export enum UploadOrientation {
  Landscape = "landscape",
  Portrait = "portrait",
  Square = "square",
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified size (in bytes) */
  gt: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified size (in bytes) */
  neq: InputMaybe<Scalars["IntType"]>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads linked to the specified tag */
  contains: InputMaybe<Scalars["String"]>;
  /** Search for uploads with an exact match */
  eq: InputMaybe<Array<Scalars["String"]>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn: InputMaybe<Array<Scalars["String"]>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq: InputMaybe<Scalars["String"]>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists: InputMaybe<Scalars["BooleanType"]>;
  /** Search assets with the specified as default title */
  in: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Filter uploads based on a regular expression */
  matches: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq: InputMaybe<Scalars["String"]>;
  /** Search assets that do not have the specified as default title */
  notIn: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude uploads based on a regular expression */
  notMatches: InputMaybe<StringMatchesFilter>;
};

export enum UploadType {
  Archive = "archive",
  Audio = "audio",
  Image = "image",
  Pdfdocument = "pdfdocument",
  Presentation = "presentation",
  Richtext = "richtext",
  Spreadsheet = "spreadsheet",
  Video = "video",
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less than the one specified */
  lt: InputMaybe<Scalars["DateTime"]>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte: InputMaybe<Scalars["DateTime"]>;
  /** Exclude uploads with an exact match */
  neq: InputMaybe<Scalars["DateTime"]>;
};

export type UploadVideoField = {
  alt: Maybe<Scalars["String"]>;
  blurUpThumb: Maybe<Scalars["String"]>;
  blurhash: Maybe<Scalars["String"]>;
  duration: Maybe<Scalars["Int"]>;
  framerate: Maybe<Scalars["Int"]>;
  height: Scalars["IntType"];
  mp4Url: Maybe<Scalars["String"]>;
  muxAssetId: Scalars["String"];
  muxPlaybackId: Scalars["String"];
  streamingUrl: Scalars["String"];
  thumbhash: Maybe<Scalars["String"]>;
  thumbnailUrl: Scalars["String"];
  title: Maybe<Scalars["String"]>;
  width: Scalars["IntType"];
};

export type UploadVideoFieldAltArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

export type UploadVideoFieldBlurUpThumbArgs = {
  imgixParams: InputMaybe<ImgixParams>;
  punch?: Scalars["Float"];
  quality?: Scalars["Int"];
  size?: Scalars["Int"];
};

export type UploadVideoFieldMp4UrlArgs = {
  exactRes: InputMaybe<VideoMp4Res>;
  res: InputMaybe<VideoMp4Res>;
};

export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};

export type UploadVideoFieldTitleArgs = {
  fallbackLocales: InputMaybe<Array<SiteLocale>>;
  locale: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger than the specified width */
  gt: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  gte: InputMaybe<Scalars["IntType"]>;
  /** Search all assets smaller than the specified width */
  lt: InputMaybe<Scalars["IntType"]>;
  /** Search all assets larger or equal to the specified width */
  lte: InputMaybe<Scalars["IntType"]>;
  /** Search assets that do not have the specified width */
  neq: InputMaybe<Scalars["IntType"]>;
};

/** Block of type Variant (variant) */
export type VariantRecord = RecordInterface & {
  _createdAt: Scalars["DateTime"];
  /** Editing URL */
  _editingUrl: Maybe<Scalars["String"]>;
  _firstPublishedAt: Maybe<Scalars["DateTime"]>;
  _isValid: Scalars["BooleanType"];
  _modelApiKey: Scalars["String"];
  _publicationScheduledAt: Maybe<Scalars["DateTime"]>;
  _publishedAt: Maybe<Scalars["DateTime"]>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt: Maybe<Scalars["DateTime"]>;
  _updatedAt: Scalars["DateTime"];
  additionalText: Maybe<Scalars["String"]>;
  color: Maybe<ColorField>;
  createdAt: Scalars["DateTime"];
  footerText: Maybe<Scalars["String"]>;
  gallery: Array<FileField>;
  headerText: Maybe<Scalars["String"]>;
  id: Scalars["ItemId"];
  listUpsellingExcludePerVariant: Array<ProductRecord>;
  thumbnail: Maybe<FileField>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  value: Maybe<Scalars["String"]>;
};

/** Block of type Variant (variant) */
export type VariantRecord_SeoMetaTagsArgs = {
  locale: InputMaybe<SiteLocale>;
};

export type VideoField = {
  height: Scalars["IntType"];
  provider: Scalars["String"];
  providerUid: Scalars["String"];
  thumbnailUrl: Scalars["String"];
  title: Scalars["String"];
  url: Scalars["String"];
  width: Scalars["IntType"];
};

export enum VideoMp4Res {
  High = "high",
  Low = "low",
  Medium = "medium",
}

export type FocalPoint = {
  x: Scalars["FloatType"];
  y: Scalars["FloatType"];
};

export type GetBlogCategoriesCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBlogCategoriesCountQuery = { meta: { count: any } };

export type GetAllBlogCategoriesSlugsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllBlogCategoriesSlugsQuery = {
  records: Array<{ slug: string | null }>;
};

export type GetBlogCategoryBySlugQueryVariables = Exact<{
  slug: InputMaybe<Scalars["String"]>;
}>;

export type GetBlogCategoryBySlugQuery = {
  blogCategory: {
    slug: string | null;
    description: string | null;
    id: any;
    visibleInBlogPage: any | null;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetAllBlogCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllBlogCategoriesQuery = {
  allBlogCategories: Array<{
    id: any;
    description: string | null;
    slug: string | null;
    visibleInBlogPage: any | null;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  }>;
};

export type BlogPageFieldsFragment = {
  id: any;
  slug: string | null;
  title: string | null;
  subtitle: string | null;
  description: string | null;
  seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
};

export type GetBlogPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetBlogPageQuery = {
  blogPage: {
    id: any;
    slug: string | null;
    title: string | null;
    subtitle: string | null;
    description: string | null;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetAllBlogPostsCountQueryVariables = Exact<{
  categoryId: InputMaybe<Scalars["ItemId"]>;
}>;

export type GetAllBlogPostsCountQuery = { meta: { count: any } };

export type GetAllBlogPostsSlugsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllBlogPostsSlugsQuery = {
  records: Array<{ slug: string | null }>;
};

export type BlogPostPrimaryFieldsFragment = {
  slug: string | null;
  title: string | null;
  id: any;
  createdAt: any;
  publicationDate: any | null;
  hideFromSearchEngines: any | null;
  hasNewTemplate: any | null;
  highlighText: string | null;
  category: {
    id: any;
    _modelApiKey: string;
    description: string | null;
    visibleInBlogPage: any | null;
    slug: string | null;
  } | null;
  subCategory: {
    id: any;
    _modelApiKey: string;
    description: string | null;
    visibleInBlogPage: any | null;
    slug: string | null;
  } | null;
  primaryImage: { url: string; alt: string | null } | null;
};

export type BlogPostSecondaryFieldsFragment = {
  text: string | null;
  titleNewTemplate: string | null;
  firstText: string | null;
  secondText: string | null;
  textBeforeImages: string | null;
  thirdText: string | null;
  highlightedText: string | null;
  secondTextBeforeImages: string | null;
  textAfterImages: string | null;
  sections: Array<{
    id: any;
    _modelApiKey: string;
    title: string | null;
    isTitleH1: any | null;
    subtitle: string | null;
    subtitlePos: string | null;
    bgColor: string | null;
    sectionPadded: any | null;
    fontFamily: string | null;
    fontSize: string | null;
    text: string | null;
    bgImage: { url: string; alt: string | null } | null;
    content: Array<
      | {
          id: any;
          _modelApiKey: string;
          text: string | null;
          variant: string | null;
          hasLightTheme: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            quote: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainDescription: string | null;
          firstTextFooter: string | null;
          secondTextFooter: string | null;
          text: string | null;
          title: string | null;
          description: string | null;
          thirdText: string | null;
          color: { hex: string } | null;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          contentMobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mainButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          couponCard: Array<{
            _modelApiKey: string;
            id: any;
            productName: string | null;
            discountCode: string | null;
            discountToApply: string | null;
            text: string | null;
            disclaimer: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasLargerCardWithoutBorder: any | null;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          subtitleFont: string | null;
          titleFont: string | null;
          hasDividers: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          title: string | null;
          _modelApiKey: string;
          hasBigText: any | null;
          bigText: string | null;
          bigTextPosition: string | null;
          titleDescription: string | null;
          leftBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rightBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          descriptionContent: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | { id: any; _modelApiKey: string }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          typeOfLinks: string | null;
          applyFilterGrayscaleToCardImage: any | null;
          title: string | null;
          linkCollection: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          quote: string | null;
          author: string | null;
          blockSpacing: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          reviewCollection: Array<{
            id: any;
            _modelApiKey: string;
            hasDefaultCardPerView: any | null;
            cardsColor: string | null;
            cardType: string | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            sixCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            threeCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasTestimonyWithImage: any | null;
          content: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          testimony: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitlePosition: string | null;
          description: string | null;
          descriptionHtml: string | null;
          paragraph: string | null;
          hasButton: any | null;
          hasImages: any | null;
          hasAnImageAndText: any | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          externalButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            url: string | null;
          }>;
          images: Array<{
            id: any;
            title: string | null;
            description: string | null;
            image: {
              id: any;
              title: string | null;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
          background: { hex: string } | null;
          image: {
            url: string;
            alt: string | null;
            width: any | null;
            height: any | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          description: string | null;
          isMiniBanner: any | null;
          backgroundColor: { hex: string } | null;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          pageLink: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          imageMobile: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            embeddedVideo: {
              height: any;
              provider: string;
              providerUid: string;
              thumbnailUrl: string;
              title: string;
              url: string;
              width: any;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          title: string | null;
          logos: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
          galleryImages: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            month: string | null;
            flowerList: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  weekNumber: any | null;
                  weekDate: string | null;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
            >;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          title: string | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          animatedText: Array<{ id: any; text: string | null }>;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          centeredMobile: any | null;
          titlePadded: any | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          textMobile: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          isBigVersionLogo: any | null;
          isTitleFontBig: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          subtitleFontFamily: string | null;
          hasLightTheme: any | null;
          hasBiggerButton: any | null;
          hasAButtonToScrollToAComponent: any | null;
          hideInMobileVersion: any | null;
          variantStyle: string | null;
          buttonTitle: string | null;
          scrollTo: string | null;
          isShortHeight: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          bgVideo: { id: any; url: string } | null;
          bgVideoPosterImage: { url: string; alt: string | null } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogoMobile: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          titleFontFamily: string | null;
          subTitleHtml: string | null;
          subtitleAfter: any | null;
          subtitleFontFamily: string | null;
          contentPosition: string | null;
          theme: string | null;
          hasLightTheme: any | null;
          imagePosition: string | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          products: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          images: Array<{ url: string; alt: string | null }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          mainFeatureContainerContent: Array<{
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          fontFamily: string | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitlePosition: string | null;
          hasPersonalizedDescription: any | null;
          hasDescriptionAsTable: any | null;
          hasBulletPoints: any | null;
          text: string | null;
          imagePosition: string | null;
          imageWidth: string | null;
          hasSlider: any | null;
          backgroundColor: { hex: string } | null;
          colorText: { hex: string } | null;
          bulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rows: Array<{ id: any; text: string | null }>;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          product: {
            id: any;
            shopifyProduct: any | null;
            bouquetSize: string | null;
            prefixFrecuency: string | null;
            title: string | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
          } | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          gallery: Array<{
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            plantList: Array<{
              id: any;
              name: string | null;
              subtitle: string | null;
              introText: string | null;
              endingText: string | null;
              detailText: string | null;
              hasBadge: any | null;
              slug: string | null;
              badge: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              primaryImage: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              product: {
                id: any;
                slug: string | null;
                title: string | null;
                description: string | null;
                gallery: Array<{ id: any; url: string; alt: string | null }>;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  isAFlowerPlantCenterCollection: any | null;
                }>;
              } | null;
            }>;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            hasSlider: any | null;
            showProductAs: string | null;
            slug: string | null;
            title: string | null;
            centered: any | null;
            titleSubtitleFontSize: string | null;
            hideTitle: any | null;
            isTitleH1: any | null;
            description: string | null;
            hasFilterComponent: any | null;
            hideBreadcrumb: any | null;
            products: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  slug: string | null;
                  sku: string | null;
                  title: string | null;
                  thirdParty: string | null;
                  isSubscription: any | null;
                  disableDatePicker: any | null;
                  bouquetSize: string | null;
                  shopifyProduct: any | null;
                  shopifySubscriptionProducts: any | null;
                  shortDescription: string | null;
                  cardType: string | null;
                  isSuperGift: any | null;
                  isGoogleProduct: any | null;
                  isFacebookProduct: any | null;
                  hideFromSearchEngines: any | null;
                  hasNotBreadcrumb: any | null;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    tagType: string | null;
                    image: { url: string; alt: string | null } | null;
                    backgroundColor: { hex: string } | null;
                    textColor: { hex: string } | null;
                  }>;
                  tagFilter: Array<{
                    id: any;
                    title: string | null;
                    tagMessage: string | null;
                    filterType: string | null;
                    minPrice: string | null;
                    maxPrice: string | null;
                    backgroundColor: { hex: string } | null;
                  }>;
                  gallery: Array<{
                    id: any;
                    format: string;
                    url: string;
                    alt: string | null;
                  }>;
                }
              | {
                  _modelApiKey: string;
                  shopifyProduct: any | null;
                  id: any;
                  image: {
                    url: string;
                    alt: string | null;
                    responsiveImage: {
                      srcSet: string;
                      webpSrcSet: string;
                      sizes: string;
                      src: string;
                      width: any;
                      height: any;
                      aspectRatio: any;
                      alt: string | null;
                      title: string | null;
                      base64: string | null;
                    } | null;
                  } | null;
                  pageLinkUrl: Array<{
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }>;
                }
            >;
            productTags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              filterType: string | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
            image: { url: string } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          firstColumn: string | null;
          secondColumn: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainSubtitle: string | null;
          subtitlePosition: string | null;
          fontSize: string | null;
          fontFamily: string | null;
          titleFirstCol: string | null;
          firstColumn: string | null;
          titleSecondCol: string | null;
          secondColumn: string | null;
          columnWidth: string | null;
          contentPosition: string | null;
          fontSizeFirstTitle: string | null;
          fontSizeSecondTitle: string | null;
          titleFontFamily: string | null;
          textFontFamily: string | null;
          bgImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          proseContent: string | null;
          largeTypography: any | null;
          showOnMobileVersion: any | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
          description: string | null;
          showSubtitleAfterTitle: any | null;
          content: Array<{
            id: any;
            title: string | null;
            backgroundColor: { hex: string } | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          embedHtml: string | null;
          formType: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          hideFaqPage: any | null;
          title: string | null;
          questionList: Array<{
            id: any;
            answer: string | null;
            question: string | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          hasslider: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productBestSeller: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          title: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          showSideTextOnDesktop: any | null;
          bgColor: string | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productColletionLink: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            image: { url: string; alt: string | null } | null;
            landingPageLink: { slug: string | null } | null;
          }>;
        }
    >;
  }>;
  image: { url: string; alt: string | null } | null;
  imageCardFirstBlock: Array<{
    id: any;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
  imageCardSecondBlock: Array<{
    id: any;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
  secondaryImage: { url: string; alt: string | null } | null;
  button: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
};

export type GetBlogPostBySlugQueryVariables = Exact<{
  slug: InputMaybe<Scalars["String"]>;
}>;

export type GetBlogPostBySlugQuery = {
  blogPost: {
    slug: string | null;
    title: string | null;
    id: any;
    createdAt: any;
    publicationDate: any | null;
    hideFromSearchEngines: any | null;
    hasNewTemplate: any | null;
    text: string | null;
    titleNewTemplate: string | null;
    firstText: string | null;
    secondText: string | null;
    textBeforeImages: string | null;
    thirdText: string | null;
    highlightedText: string | null;
    secondTextBeforeImages: string | null;
    textAfterImages: string | null;
    highlighText: string | null;
    category: {
      id: any;
      _modelApiKey: string;
      description: string | null;
      visibleInBlogPage: any | null;
      slug: string | null;
    } | null;
    subCategory: {
      id: any;
      _modelApiKey: string;
      description: string | null;
      visibleInBlogPage: any | null;
      slug: string | null;
    } | null;
    primaryImage: { url: string; alt: string | null } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    image: { url: string; alt: string | null } | null;
    imageCardFirstBlock: Array<{
      id: any;
      title: string | null;
      link:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
      image: {
        format: string;
        url: string;
        alt: string | null;
        responsiveImage: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
      } | null;
    }>;
    imageCardSecondBlock: Array<{
      id: any;
      title: string | null;
      link:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
      image: {
        format: string;
        url: string;
        alt: string | null;
        responsiveImage: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
      } | null;
    }>;
    secondaryImage: { url: string; alt: string | null } | null;
    button: Array<{
      id: any;
      _modelApiKey: string;
      variant: string | null;
      title: string | null;
      link:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetBlogPostsByPageQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
  categoryId: InputMaybe<Scalars["ItemId"]>;
}>;

export type GetBlogPostsByPageQuery = {
  allBlogPosts: Array<{
    slug: string | null;
    title: string | null;
    id: any;
    createdAt: any;
    publicationDate: any | null;
    hideFromSearchEngines: any | null;
    hasNewTemplate: any | null;
    highlighText: string | null;
    category: {
      id: any;
      _modelApiKey: string;
      description: string | null;
      visibleInBlogPage: any | null;
      slug: string | null;
    } | null;
    subCategory: {
      id: any;
      _modelApiKey: string;
      description: string | null;
      visibleInBlogPage: any | null;
      slug: string | null;
    } | null;
    primaryImage: { url: string; alt: string | null } | null;
  }>;
};

export type GetUpSellingProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUpSellingProductsQuery = {
  allProducts: Array<{
    id: any;
    sku: string | null;
    disableDatePicker: any | null;
    _modelApiKey: string;
    isTitleDescriptionFirst: any | null;
    slug: string | null;
    title: string | null;
    thirdParty: string | null;
    hasColumnLayout: any | null;
    shortDescription: string | null;
    isDescriptionAfterTitle: any | null;
    description: string | null;
    importantInfo: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    isSubscription: any | null;
    bouquetSize: string | null;
    isSuperGift: any | null;
    isGoogleProduct: any | null;
    isFacebookProduct: any | null;
    hideFromSearchEngines: any | null;
    hasNotBreadcrumb: any | null;
    hasVariant: any | null;
    variantType: string | null;
    prefixFrecuency: string | null;
    isUpselling: any | null;
    hasDisabledNextDayDelivery: any | null;
    noPdp: any | null;
    isAvailableForNextday: any | null;
    priorityUpselling: any | null;
    deliveryOption: string | null;
    pageHeaderType: string | null;
    showPageHeader: any | null;
    showPageFooter: any | null;
    priceRange: string | null;
    seoDescription: string | null;
    backgroundColor: { hex: string } | null;
    productDetailItems: Array<{
      id: any;
      title: string | null;
      description: string | null;
    }>;
    variants: Array<{
      id: any;
      title: string | null;
      value: string | null;
      headerText: string | null;
      additionalText: string | null;
      footerText: string | null;
      listUpsellingExcludePerVariant: Array<{
        id: any;
        title: string | null;
        shopifyProduct: any | null;
        shopifySubscriptionProducts: any | null;
      }>;
      color: { hex: string } | null;
      thumbnail: { url: string; alt: string | null } | null;
      gallery: Array<{
        id: any;
        format: string;
        url: string;
        alt: string | null;
        responsiveImage: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        thumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        mobileThumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
      }>;
    }>;
    excludeNextdayProducts: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    upsellingProducts: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    listUpsellingToExclude: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    tagFilter: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagType: string | null;
    }>;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
      thumbUrl: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
    sectionsBeforeSimilarProducts: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  }>;
};

export type GetUpSellingCartProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUpSellingCartProductsQuery = {
  allProducts: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    thirdParty: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    isSubscription: any | null;
    isUpselling: any | null;
    hasDisabledNextDayDelivery: any | null;
  }>;
};

export type ContentSectionFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  isTitleH1: any | null;
  subtitle: string | null;
  subtitlePos: string | null;
  bgColor: string | null;
  sectionPadded: any | null;
  fontFamily: string | null;
  fontSize: string | null;
  text: string | null;
  bgImage: { url: string; alt: string | null } | null;
  content: Array<
    | {
        id: any;
        _modelApiKey: string;
        text: string | null;
        variant: string | null;
        hasLightTheme: any | null;
        buttons: Array<
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }
        >;
        colorButton: { hex: string } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        content: Array<{
          id: any;
          quote: string | null;
          image: {
            id: any;
            alt: string | null;
            format: string;
            url: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        mainTitle: string | null;
        mainDescription: string | null;
        firstTextFooter: string | null;
        secondTextFooter: string | null;
        text: string | null;
        title: string | null;
        description: string | null;
        thirdText: string | null;
        color: { hex: string } | null;
        backgroundImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        mobileImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        image: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        contentMobileImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        mainButton: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
        button: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        couponCard: Array<{
          _modelApiKey: string;
          id: any;
          productName: string | null;
          discountCode: string | null;
          discountToApply: string | null;
          text: string | null;
          disclaimer: string | null;
          image: {
            id: any;
            alt: string | null;
            format: string;
            url: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        hasLargerCardWithoutBorder: any | null;
        content: Array<{
          id: any;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
          image: {
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        subtitleFont: string | null;
        titleFont: string | null;
        hasDividers: any | null;
        pageLinkUrl: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
        content: Array<{
          id: any;
          title: string | null;
          text: string | null;
          image: {
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        id: any;
        title: string | null;
        _modelApiKey: string;
        hasBigText: any | null;
        bigText: string | null;
        bigTextPosition: string | null;
        titleDescription: string | null;
        leftBulletPoints: Array<{
          id: any;
          title: string | null;
          description: string | null;
        }>;
        rightBulletPoints: Array<{
          id: any;
          title: string | null;
          description: string | null;
        }>;
        descriptionContent: Array<{
          id: any;
          title: string | null;
          description: string | null;
        }>;
        backgroundImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        mobileImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        content: Array<{
          id: any;
          title: string | null;
          image: {
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | { id: any; _modelApiKey: string }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        subtitle: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        subtitle: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        typeOfLinks: string | null;
        applyFilterGrayscaleToCardImage: any | null;
        title: string | null;
        linkCollection: Array<{
          id: any;
          title: string | null;
          shortDescription: string | null;
          url: string | null;
          isExternal: any | null;
          coverImage: {
            id: any;
            url: string;
            alt: string | null;
            format: string;
          } | null;
          internalLink:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        quote: string | null;
        author: string | null;
        blockSpacing: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        titleHtml: string | null;
        subtitle: string | null;
        subtitleHtml: string | null;
        reviewCollection: Array<{
          id: any;
          _modelApiKey: string;
          hasDefaultCardPerView: any | null;
          cardsColor: string | null;
          cardType: string | null;
          content: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
          sixCardPerViewContent: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
          threeCardPerViewContent: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        content: Array<{
          id: any;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
          image: {
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        hasTestimonyWithImage: any | null;
        content: Array<{
          id: any;
          quote: string | null;
          author: string | null;
          numberStars: any | null;
        }>;
        image: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        testimony: Array<{
          id: any;
          quote: string | null;
          author: string | null;
          numberStars: any | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        titleHtml: string | null;
        subtitlePosition: string | null;
        description: string | null;
        descriptionHtml: string | null;
        paragraph: string | null;
        hasButton: any | null;
        hasImages: any | null;
        hasAnImageAndText: any | null;
        titleLogo: {
          url: string;
          alt: string | null;
          format: string;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        button: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
        externalButton: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          url: string | null;
        }>;
        images: Array<{
          id: any;
          title: string | null;
          description: string | null;
          image: {
            id: any;
            title: string | null;
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
        background: { hex: string } | null;
        image: {
          url: string;
          alt: string | null;
          width: any | null;
          height: any | null;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        content: Array<{
          id: any;
          title: string | null;
          text: string | null;
          image: {
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        description: string | null;
        isMiniBanner: any | null;
        backgroundColor: { hex: string } | null;
        image: {
          id: any;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        pageLink: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
        imageMobile: {
          id: any;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        content: Array<{
          id: any;
          embeddedVideo: {
            height: any;
            provider: string;
            providerUid: string;
            thumbnailUrl: string;
            title: string;
            url: string;
            width: any;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        blockSpacing: string | null;
        title: string | null;
        logos: Array<{
          id: any;
          format: string;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        }>;
        galleryImages: Array<{
          id: any;
          title: string | null;
          shortDescription: string | null;
          url: string | null;
          isExternal: any | null;
          coverImage: {
            id: any;
            url: string;
            alt: string | null;
            format: string;
          } | null;
          internalLink:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        collection: {
          id: any;
          _modelApiKey: string;
          month: string | null;
          flowerList: Array<
            | {
                id: any;
                _modelApiKey: string;
                flower: {
                  id: any;
                  name: string | null;
                  hasBadge: any | null;
                  slug: string | null;
                  badge: {
                    url: string;
                    alt: string | null;
                    width: any | null;
                    height: any | null;
                    focalPoint: { x: any; y: any } | null;
                  } | null;
                  primaryImage: {
                    url: string;
                    alt: string | null;
                    width: any | null;
                    height: any | null;
                    focalPoint: { x: any; y: any } | null;
                  } | null;
                  product: { title: string | null } | null;
                } | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                weekNumber: any | null;
                weekDate: string | null;
                flower: {
                  id: any;
                  name: string | null;
                  hasBadge: any | null;
                  slug: string | null;
                  badge: {
                    url: string;
                    alt: string | null;
                    width: any | null;
                    height: any | null;
                    focalPoint: { x: any; y: any } | null;
                  } | null;
                  primaryImage: {
                    url: string;
                    alt: string | null;
                    width: any | null;
                    height: any | null;
                    focalPoint: { x: any; y: any } | null;
                  } | null;
                  product: { title: string | null } | null;
                } | null;
              }
          >;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        centered: any | null;
        title: string | null;
        subtitle: string | null;
        subtitleAfter: any | null;
        text: string | null;
        theme: string | null;
        titleLogoAfter: any | null;
        contentWidth: string | null;
        titleSubtitleFontSize: string | null;
        titleFontFamily: string | null;
        animatedText: Array<{ id: any; text: string | null }>;
        buttons: Array<
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }
        >;
        bgImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        mobileImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        titleLogo: {
          url: string;
          alt: string | null;
          format: string;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        centered: any | null;
        centeredMobile: any | null;
        titlePadded: any | null;
        title: string | null;
        isTitleH1: any | null;
        subtitle: string | null;
        subtitleAfter: any | null;
        text: string | null;
        textMobile: string | null;
        theme: string | null;
        titleLogoAfter: any | null;
        isBigVersionLogo: any | null;
        isTitleFontBig: any | null;
        contentWidth: string | null;
        titleSubtitleFontSize: string | null;
        titleFontFamily: string | null;
        subtitleFontFamily: string | null;
        hasLightTheme: any | null;
        hasBiggerButton: any | null;
        hasAButtonToScrollToAComponent: any | null;
        hideInMobileVersion: any | null;
        variantStyle: string | null;
        buttonTitle: string | null;
        scrollTo: string | null;
        isShortHeight: any | null;
        buttons: Array<
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }
        >;
        colorButton: { hex: string } | null;
        bgImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        mobileImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        bgVideo: { id: any; url: string } | null;
        bgVideoPosterImage: { url: string; alt: string | null } | null;
        titleLogo: {
          url: string;
          alt: string | null;
          format: string;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        titleLogoMobile: {
          url: string;
          alt: string | null;
          format: string;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        titleHtml: string | null;
        titleFontFamily: string | null;
        subTitleHtml: string | null;
        subtitleAfter: any | null;
        subtitleFontFamily: string | null;
        contentPosition: string | null;
        theme: string | null;
        hasLightTheme: any | null;
        imagePosition: string | null;
        buttons: Array<
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }
        >;
        colorButton: { hex: string } | null;
        bgImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        mobileImage: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        products: Array<{
          id: any;
          _modelApiKey: string;
          shopifyProduct: any | null;
          slug: string | null;
          title: string | null;
          shortDescription: string | null;
          description: string | null;
          bouquetSize: string | null;
          isSubscription: any | null;
          seoDescription: string | null;
          gallery: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
          }>;
          tags: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            tagMessage: string | null;
          }>;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        blockSpacing: string | null;
        images: Array<{ url: string; alt: string | null }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        mainFeatureContainerContent: Array<{
          id: any;
          _modelApiKey: string;
          fontFamily: string | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitlePosition: string | null;
          hasPersonalizedDescription: any | null;
          hasDescriptionAsTable: any | null;
          hasBulletPoints: any | null;
          text: string | null;
          imagePosition: string | null;
          imageWidth: string | null;
          hasSlider: any | null;
          backgroundColor: { hex: string } | null;
          colorText: { hex: string } | null;
          bulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rows: Array<{ id: any; text: string | null }>;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          product: {
            id: any;
            shopifyProduct: any | null;
            bouquetSize: string | null;
            prefixFrecuency: string | null;
            title: string | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
          } | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          gallery: Array<{
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        fontFamily: string | null;
        title: string | null;
        isTitleH1: any | null;
        subtitle: string | null;
        subtitlePosition: string | null;
        hasPersonalizedDescription: any | null;
        hasDescriptionAsTable: any | null;
        hasBulletPoints: any | null;
        text: string | null;
        imagePosition: string | null;
        imageWidth: string | null;
        hasSlider: any | null;
        backgroundColor: { hex: string } | null;
        colorText: { hex: string } | null;
        bulletPoints: Array<{
          id: any;
          title: string | null;
          description: string | null;
        }>;
        rows: Array<{ id: any; text: string | null }>;
        image: {
          id: any;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        product: {
          id: any;
          shopifyProduct: any | null;
          bouquetSize: string | null;
          prefixFrecuency: string | null;
          title: string | null;
          tags: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            tagMessage: string | null;
            tagType: string | null;
            image: { url: string; alt: string | null } | null;
            backgroundColor: { hex: string } | null;
            textColor: { hex: string } | null;
          }>;
        } | null;
        buttons: Array<
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }
          | {
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }
        >;
        gallery: Array<{
          id: any;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        collection: {
          id: any;
          _modelApiKey: string;
          plantList: Array<{
            id: any;
            name: string | null;
            subtitle: string | null;
            introText: string | null;
            endingText: string | null;
            detailText: string | null;
            hasBadge: any | null;
            slug: string | null;
            badge: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            primaryImage: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            product: {
              id: any;
              slug: string | null;
              title: string | null;
              description: string | null;
              gallery: Array<{ id: any; url: string; alt: string | null }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                isAFlowerPlantCenterCollection: any | null;
              }>;
            } | null;
          }>;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        collection: {
          id: any;
          hasSlider: any | null;
          showProductAs: string | null;
          slug: string | null;
          title: string | null;
          centered: any | null;
          titleSubtitleFontSize: string | null;
          hideTitle: any | null;
          isTitleH1: any | null;
          description: string | null;
          hasFilterComponent: any | null;
          hideBreadcrumb: any | null;
          products: Array<
            | {
                id: any;
                _modelApiKey: string;
                slug: string | null;
                sku: string | null;
                title: string | null;
                thirdParty: string | null;
                isSubscription: any | null;
                disableDatePicker: any | null;
                bouquetSize: string | null;
                shopifyProduct: any | null;
                shopifySubscriptionProducts: any | null;
                shortDescription: string | null;
                cardType: string | null;
                isSuperGift: any | null;
                isGoogleProduct: any | null;
                isFacebookProduct: any | null;
                hideFromSearchEngines: any | null;
                hasNotBreadcrumb: any | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
                tagFilter: Array<{
                  id: any;
                  title: string | null;
                  tagMessage: string | null;
                  filterType: string | null;
                  minPrice: string | null;
                  maxPrice: string | null;
                  backgroundColor: { hex: string } | null;
                }>;
                gallery: Array<{
                  id: any;
                  format: string;
                  url: string;
                  alt: string | null;
                }>;
              }
            | {
                _modelApiKey: string;
                shopifyProduct: any | null;
                id: any;
                image: {
                  url: string;
                  alt: string | null;
                  responsiveImage: {
                    srcSet: string;
                    webpSrcSet: string;
                    sizes: string;
                    src: string;
                    width: any;
                    height: any;
                    aspectRatio: any;
                    alt: string | null;
                    title: string | null;
                    base64: string | null;
                  } | null;
                } | null;
                pageLinkUrl: Array<{
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }>;
              }
          >;
          productTags: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            tagMessage: string | null;
            filterType: string | null;
            backgroundColor: { hex: string } | null;
            textColor: { hex: string } | null;
          }>;
          image: { url: string } | null;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        firstColumn: string | null;
        secondColumn: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        mainTitle: string | null;
        mainSubtitle: string | null;
        subtitlePosition: string | null;
        fontSize: string | null;
        fontFamily: string | null;
        titleFirstCol: string | null;
        firstColumn: string | null;
        titleSecondCol: string | null;
        secondColumn: string | null;
        columnWidth: string | null;
        contentPosition: string | null;
        fontSizeFirstTitle: string | null;
        fontSizeSecondTitle: string | null;
        titleFontFamily: string | null;
        textFontFamily: string | null;
        bgImage: {
          id: any;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        mobileImage: {
          id: any;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        proseContent: string | null;
        largeTypography: any | null;
        showOnMobileVersion: any | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        subtitle: string | null;
        description: string | null;
        showSubtitleAfterTitle: any | null;
        content: Array<{
          id: any;
          title: string | null;
          backgroundColor: { hex: string } | null;
          image: {
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        embedHtml: string | null;
        formType: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        hideFaqPage: any | null;
        title: string | null;
        questionList: Array<{
          id: any;
          answer: string | null;
          question: string | null;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        title: string | null;
        hasslider: any | null;
        pageLinkUrl: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
        productBestSeller: Array<{
          id: any;
          _modelApiKey: string;
          shopifyProduct: any | null;
          slug: string | null;
          title: string | null;
          shortDescription: string | null;
          description: string | null;
          bouquetSize: string | null;
          isSubscription: any | null;
          seoDescription: string | null;
          gallery: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
          }>;
          tags: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            tagMessage: string | null;
          }>;
        }>;
      }
    | {
        id: any;
        _modelApiKey: string;
        titleHtml: string | null;
        title: string | null;
        subtitle: string | null;
        subtitleHtml: string | null;
        showSideTextOnDesktop: any | null;
        bgColor: string | null;
        pageLinkUrl: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
        productColletionLink: Array<{
          id: any;
          slug: string | null;
          title: string | null;
          image: { url: string; alt: string | null } | null;
          landingPageLink: { slug: string | null } | null;
        }>;
      }
  >;
};

type ContentModules_BlockCtaRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  text: string | null;
  variant: string | null;
  hasLightTheme: any | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  colorButton: { hex: string } | null;
};

type ContentModules_BlockFeatTestimonialsCompaniesSliderRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    quote: string | null;
    image: {
      id: any;
      alt: string | null;
      format: string;
      url: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_BlockFeatureContentOverlayRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  mainTitle: string | null;
  mainDescription: string | null;
  firstTextFooter: string | null;
  secondTextFooter: string | null;
  text: string | null;
  title: string | null;
  description: string | null;
  thirdText: string | null;
  color: { hex: string } | null;
  backgroundImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  image: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  contentMobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mainButton: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  button: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

type ContentModules_BlockFeatureDiscountCardRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  couponCard: Array<{
    _modelApiKey: string;
    id: any;
    productName: string | null;
    discountCode: string | null;
    discountToApply: string | null;
    text: string | null;
    disclaimer: string | null;
    image: {
      id: any;
      alt: string | null;
      format: string;
      url: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_BlockFeatureImageCardRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  hasLargerCardWithoutBorder: any | null;
  content: Array<{
    id: any;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_BlockFeatureImageFullRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  subtitleFont: string | null;
  titleFont: string | null;
  hasDividers: any | null;
  pageLinkUrl: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  content: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_BlockFeatureImageTextRecord_Fragment = {
  id: any;
  title: string | null;
  _modelApiKey: string;
  hasBigText: any | null;
  bigText: string | null;
  bigTextPosition: string | null;
  titleDescription: string | null;
  leftBulletPoints: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  rightBulletPoints: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  descriptionContent: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  backgroundImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

type ContentModules_BlockFeatureImageTitleRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    title: string | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_BlockFeatureInstagramFullRecord_Fragment = {
  id: any;
  _modelApiKey: string;
};

type ContentModules_BlockFeatureInstagramProductRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  subtitle: string | null;
};

type ContentModules_BlockFeatureInstagramSliderRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  subtitle: string | null;
};

type ContentModules_BlockFeatureLinkCollectionRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  typeOfLinks: string | null;
  applyFilterGrayscaleToCardImage: any | null;
  title: string | null;
  linkCollection: Array<{
    id: any;
    title: string | null;
    shortDescription: string | null;
    url: string | null;
    isExternal: any | null;
    coverImage: {
      id: any;
      url: string;
      alt: string | null;
      format: string;
    } | null;
    internalLink:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

type ContentModules_BlockFeatureQuoteRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  quote: string | null;
  author: string | null;
  blockSpacing: string | null;
};

type ContentModules_BlockFeatureReviewCollectionRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  titleHtml: string | null;
  subtitle: string | null;
  subtitleHtml: string | null;
  reviewCollection: Array<{
    id: any;
    _modelApiKey: string;
    hasDefaultCardPerView: any | null;
    cardsColor: string | null;
    cardType: string | null;
    content: Array<{
      id: any;
      quote: string | null;
      author: string | null;
      numberStars: any | null;
    }>;
    sixCardPerViewContent: Array<{
      id: any;
      quote: string | null;
      author: string | null;
      numberStars: any | null;
    }>;
    threeCardPerViewContent: Array<{
      id: any;
      quote: string | null;
      author: string | null;
      numberStars: any | null;
    }>;
  }>;
};

type ContentModules_BlockFeatureRowImageCardRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_BlockFeatureTestimonialRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  hasTestimonyWithImage: any | null;
  content: Array<{
    id: any;
    quote: string | null;
    author: string | null;
    numberStars: any | null;
  }>;
  image: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  testimony: Array<{
    id: any;
    quote: string | null;
    author: string | null;
    numberStars: any | null;
  }>;
};

type ContentModules_BlockFeatureText2imagesButtonRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  titleHtml: string | null;
  subtitlePosition: string | null;
  description: string | null;
  descriptionHtml: string | null;
  paragraph: string | null;
  hasButton: any | null;
  hasImages: any | null;
  hasAnImageAndText: any | null;
  titleLogo: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  button: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  externalButton: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    url: string | null;
  }>;
  images: Array<{
    id: any;
    title: string | null;
    description: string | null;
    image: {
      id: any;
      title: string | null;
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
  background: { hex: string } | null;
  image: {
    url: string;
    alt: string | null;
    width: any | null;
    height: any | null;
  } | null;
};

type ContentModules_BlockFeatureTextIconRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_BlockFeatureTextImageModuleRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  description: string | null;
  isMiniBanner: any | null;
  backgroundColor: { hex: string } | null;
  image: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  pageLink: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  imageMobile: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

type ContentModules_BlockFeatureVideoRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    embeddedVideo: {
      height: any;
      provider: string;
      providerUid: string;
      thumbnailUrl: string;
      title: string;
      url: string;
      width: any;
    } | null;
  }>;
};

type ContentModules_BlockFeaturedInRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  blockSpacing: string | null;
  title: string | null;
  logos: Array<{
    id: any;
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  }>;
  galleryImages: Array<{
    id: any;
    title: string | null;
    shortDescription: string | null;
    url: string | null;
    isExternal: any | null;
    coverImage: {
      id: any;
      url: string;
      alt: string | null;
      format: string;
    } | null;
    internalLink:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

type ContentModules_BlockFlowerCollectionRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  collection: {
    id: any;
    _modelApiKey: string;
    month: string | null;
    flowerList: Array<
      | {
          id: any;
          _modelApiKey: string;
          flower: {
            id: any;
            name: string | null;
            hasBadge: any | null;
            slug: string | null;
            badge: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            primaryImage: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            product: { title: string | null } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          weekNumber: any | null;
          weekDate: string | null;
          flower: {
            id: any;
            name: string | null;
            hasBadge: any | null;
            slug: string | null;
            badge: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            primaryImage: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            product: { title: string | null } | null;
          } | null;
        }
    >;
  } | null;
};

type ContentModules_BlockHeroAnimatedTextRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  centered: any | null;
  title: string | null;
  subtitle: string | null;
  subtitleAfter: any | null;
  text: string | null;
  theme: string | null;
  titleLogoAfter: any | null;
  contentWidth: string | null;
  titleSubtitleFontSize: string | null;
  titleFontFamily: string | null;
  animatedText: Array<{ id: any; text: string | null }>;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  bgImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  titleLogo: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

type ContentModules_BlockHeroRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  centered: any | null;
  centeredMobile: any | null;
  titlePadded: any | null;
  title: string | null;
  isTitleH1: any | null;
  subtitle: string | null;
  subtitleAfter: any | null;
  text: string | null;
  textMobile: string | null;
  theme: string | null;
  titleLogoAfter: any | null;
  isBigVersionLogo: any | null;
  isTitleFontBig: any | null;
  contentWidth: string | null;
  titleSubtitleFontSize: string | null;
  titleFontFamily: string | null;
  subtitleFontFamily: string | null;
  hasLightTheme: any | null;
  hasBiggerButton: any | null;
  hasAButtonToScrollToAComponent: any | null;
  hideInMobileVersion: any | null;
  variantStyle: string | null;
  buttonTitle: string | null;
  scrollTo: string | null;
  isShortHeight: any | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  colorButton: { hex: string } | null;
  bgImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  bgVideo: { id: any; url: string } | null;
  bgVideoPosterImage: { url: string; alt: string | null } | null;
  titleLogo: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  titleLogoMobile: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

type ContentModules_BlockHerobanner2columnRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  titleHtml: string | null;
  titleFontFamily: string | null;
  subTitleHtml: string | null;
  subtitleAfter: any | null;
  subtitleFontFamily: string | null;
  contentPosition: string | null;
  theme: string | null;
  hasLightTheme: any | null;
  imagePosition: string | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  colorButton: { hex: string } | null;
  bgImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  products: Array<{
    id: any;
    _modelApiKey: string;
    shopifyProduct: any | null;
    slug: string | null;
    title: string | null;
    shortDescription: string | null;
    description: string | null;
    bouquetSize: string | null;
    isSubscription: any | null;
    seoDescription: string | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
    }>;
  }>;
};

type ContentModules_BlockImageGridRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  blockSpacing: string | null;
  images: Array<{ url: string; alt: string | null }>;
};

type ContentModules_BlockMainFeatureContainerRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  mainFeatureContainerContent: Array<{
    id: any;
    _modelApiKey: string;
    fontFamily: string | null;
    title: string | null;
    isTitleH1: any | null;
    subtitle: string | null;
    subtitlePosition: string | null;
    hasPersonalizedDescription: any | null;
    hasDescriptionAsTable: any | null;
    hasBulletPoints: any | null;
    text: string | null;
    imagePosition: string | null;
    imageWidth: string | null;
    hasSlider: any | null;
    backgroundColor: { hex: string } | null;
    colorText: { hex: string } | null;
    bulletPoints: Array<{
      id: any;
      title: string | null;
      description: string | null;
    }>;
    rows: Array<{ id: any; text: string | null }>;
    image: {
      id: any;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
    product: {
      id: any;
      shopifyProduct: any | null;
      bouquetSize: string | null;
      prefixFrecuency: string | null;
      title: string | null;
      tags: Array<{
        id: any;
        slug: string | null;
        title: string | null;
        tagMessage: string | null;
        tagType: string | null;
        image: { url: string; alt: string | null } | null;
        backgroundColor: { hex: string } | null;
        textColor: { hex: string } | null;
      }>;
    } | null;
    buttons: Array<
      | {
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          url: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }
    >;
    gallery: Array<{
      id: any;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
  }>;
};

type ContentModules_BlockMainFeatureRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  fontFamily: string | null;
  title: string | null;
  isTitleH1: any | null;
  subtitle: string | null;
  subtitlePosition: string | null;
  hasPersonalizedDescription: any | null;
  hasDescriptionAsTable: any | null;
  hasBulletPoints: any | null;
  text: string | null;
  imagePosition: string | null;
  imageWidth: string | null;
  hasSlider: any | null;
  backgroundColor: { hex: string } | null;
  colorText: { hex: string } | null;
  bulletPoints: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  rows: Array<{ id: any; text: string | null }>;
  image: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  product: {
    id: any;
    shopifyProduct: any | null;
    bouquetSize: string | null;
    prefixFrecuency: string | null;
    title: string | null;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
  } | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  gallery: Array<{
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  }>;
};

type ContentModules_BlockPlantCollectionRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  collection: {
    id: any;
    _modelApiKey: string;
    plantList: Array<{
      id: any;
      name: string | null;
      subtitle: string | null;
      introText: string | null;
      endingText: string | null;
      detailText: string | null;
      hasBadge: any | null;
      slug: string | null;
      badge: {
        url: string;
        alt: string | null;
        width: any | null;
        height: any | null;
        focalPoint: { x: any; y: any } | null;
      } | null;
      primaryImage: {
        url: string;
        alt: string | null;
        width: any | null;
        height: any | null;
        focalPoint: { x: any; y: any } | null;
      } | null;
      product: {
        id: any;
        slug: string | null;
        title: string | null;
        description: string | null;
        gallery: Array<{ id: any; url: string; alt: string | null }>;
        tags: Array<{
          id: any;
          slug: string | null;
          title: string | null;
          tagMessage: string | null;
          isAFlowerPlantCenterCollection: any | null;
        }>;
      } | null;
    }>;
  } | null;
};

type ContentModules_BlockProductCollectionRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  collection: {
    id: any;
    hasSlider: any | null;
    showProductAs: string | null;
    slug: string | null;
    title: string | null;
    centered: any | null;
    titleSubtitleFontSize: string | null;
    hideTitle: any | null;
    isTitleH1: any | null;
    description: string | null;
    hasFilterComponent: any | null;
    hideBreadcrumb: any | null;
    products: Array<
      | {
          id: any;
          _modelApiKey: string;
          slug: string | null;
          sku: string | null;
          title: string | null;
          thirdParty: string | null;
          isSubscription: any | null;
          disableDatePicker: any | null;
          bouquetSize: string | null;
          shopifyProduct: any | null;
          shopifySubscriptionProducts: any | null;
          shortDescription: string | null;
          cardType: string | null;
          isSuperGift: any | null;
          isGoogleProduct: any | null;
          isFacebookProduct: any | null;
          hideFromSearchEngines: any | null;
          hasNotBreadcrumb: any | null;
          tags: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            tagMessage: string | null;
            tagType: string | null;
            image: { url: string; alt: string | null } | null;
            backgroundColor: { hex: string } | null;
            textColor: { hex: string } | null;
          }>;
          tagFilter: Array<{
            id: any;
            title: string | null;
            tagMessage: string | null;
            filterType: string | null;
            minPrice: string | null;
            maxPrice: string | null;
            backgroundColor: { hex: string } | null;
          }>;
          gallery: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
          }>;
        }
      | {
          _modelApiKey: string;
          shopifyProduct: any | null;
          id: any;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
    >;
    productTags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      filterType: string | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    image: { url: string } | null;
  } | null;
};

type ContentModules_BlockProseColumnRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  firstColumn: string | null;
  secondColumn: string | null;
};

type ContentModules_BlockProseColumnTitleBgimageRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  mainTitle: string | null;
  mainSubtitle: string | null;
  subtitlePosition: string | null;
  fontSize: string | null;
  fontFamily: string | null;
  titleFirstCol: string | null;
  firstColumn: string | null;
  titleSecondCol: string | null;
  secondColumn: string | null;
  columnWidth: string | null;
  contentPosition: string | null;
  fontSizeFirstTitle: string | null;
  fontSizeSecondTitle: string | null;
  titleFontFamily: string | null;
  textFontFamily: string | null;
  bgImage: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

type ContentModules_BlockProseRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  proseContent: string | null;
  largeTypography: any | null;
  showOnMobileVersion: any | null;
};

type ContentModules_BlockRowCardImageTitleRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  subtitle: string | null;
  description: string | null;
  showSubtitleAfterTitle: any | null;
  content: Array<{
    id: any;
    title: string | null;
    backgroundColor: { hex: string } | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

type ContentModules_EmbeddedFormRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  embedHtml: string | null;
  formType: string | null;
};

type ContentModules_FaqsCollectionRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  hideFaqPage: any | null;
  title: string | null;
  questionList: Array<{
    id: any;
    answer: string | null;
    question: string | null;
  }>;
};

type ContentModules_ProductBestSellerRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  hasslider: any | null;
  pageLinkUrl: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  productBestSeller: Array<{
    id: any;
    _modelApiKey: string;
    shopifyProduct: any | null;
    slug: string | null;
    title: string | null;
    shortDescription: string | null;
    description: string | null;
    bouquetSize: string | null;
    isSubscription: any | null;
    seoDescription: string | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
    }>;
  }>;
};

type ContentModules_ProductCollectionSliderRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  titleHtml: string | null;
  title: string | null;
  subtitle: string | null;
  subtitleHtml: string | null;
  showSideTextOnDesktop: any | null;
  bgColor: string | null;
  pageLinkUrl: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  productColletionLink: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    image: { url: string; alt: string | null } | null;
    landingPageLink: { slug: string | null } | null;
  }>;
};

export type ContentModulesFragment =
  | ContentModules_BlockCtaRecord_Fragment
  | ContentModules_BlockFeatTestimonialsCompaniesSliderRecord_Fragment
  | ContentModules_BlockFeatureContentOverlayRecord_Fragment
  | ContentModules_BlockFeatureDiscountCardRecord_Fragment
  | ContentModules_BlockFeatureImageCardRecord_Fragment
  | ContentModules_BlockFeatureImageFullRecord_Fragment
  | ContentModules_BlockFeatureImageTextRecord_Fragment
  | ContentModules_BlockFeatureImageTitleRecord_Fragment
  | ContentModules_BlockFeatureInstagramFullRecord_Fragment
  | ContentModules_BlockFeatureInstagramProductRecord_Fragment
  | ContentModules_BlockFeatureInstagramSliderRecord_Fragment
  | ContentModules_BlockFeatureLinkCollectionRecord_Fragment
  | ContentModules_BlockFeatureQuoteRecord_Fragment
  | ContentModules_BlockFeatureReviewCollectionRecord_Fragment
  | ContentModules_BlockFeatureRowImageCardRecord_Fragment
  | ContentModules_BlockFeatureTestimonialRecord_Fragment
  | ContentModules_BlockFeatureText2imagesButtonRecord_Fragment
  | ContentModules_BlockFeatureTextIconRecord_Fragment
  | ContentModules_BlockFeatureTextImageModuleRecord_Fragment
  | ContentModules_BlockFeatureVideoRecord_Fragment
  | ContentModules_BlockFeaturedInRecord_Fragment
  | ContentModules_BlockFlowerCollectionRecord_Fragment
  | ContentModules_BlockHeroAnimatedTextRecord_Fragment
  | ContentModules_BlockHeroRecord_Fragment
  | ContentModules_BlockHerobanner2columnRecord_Fragment
  | ContentModules_BlockImageGridRecord_Fragment
  | ContentModules_BlockMainFeatureContainerRecord_Fragment
  | ContentModules_BlockMainFeatureRecord_Fragment
  | ContentModules_BlockPlantCollectionRecord_Fragment
  | ContentModules_BlockProductCollectionRecord_Fragment
  | ContentModules_BlockProseColumnRecord_Fragment
  | ContentModules_BlockProseColumnTitleBgimageRecord_Fragment
  | ContentModules_BlockProseRecord_Fragment
  | ContentModules_BlockRowCardImageTitleRecord_Fragment
  | ContentModules_EmbeddedFormRecord_Fragment
  | ContentModules_FaqsCollectionRecord_Fragment
  | ContentModules_ProductBestSellerRecord_Fragment
  | ContentModules_ProductCollectionSliderRecord_Fragment;

export type GetContactUsPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactUsPageQuery = {
  contactUsPage: {
    id: any;
    title: string | null;
    text: string | null;
    slug: string | null;
    contactBlock: Array<{
      id: any;
      title: string | null;
      description: string | null;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type TitleTextContactFragment = {
  id: any;
  title: string | null;
  description: string | null;
};

export type FaqsCollectionFieldsFragment = {
  id: any;
  _modelApiKey: string;
  hideFaqPage: any | null;
  title: string | null;
  questionList: Array<{
    id: any;
    answer: string | null;
    question: string | null;
  }>;
};

export type GetFaQsCollectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetFaQsCollectionQuery = {
  allFaqsCollections: Array<{
    id: any;
    _modelApiKey: string;
    hideFaqPage: any | null;
    title: string | null;
    questionList: Array<{
      id: any;
      answer: string | null;
      question: string | null;
    }>;
  }>;
};

export type GetfaqsPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetfaqsPageQuery = {
  faqsPage: {
    id: any;
    title: string | null;
    subtitle: string | null;
    slug: string | null;
    backgroundImage: {
      id: any;
      url: string;
      alt: string | null;
      height: any | null;
      width: any | null;
    } | null;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetFlowerCenterQueryVariables = Exact<{ [key: string]: never }>;

export type GetFlowerCenterQuery = {
  flowerCenter: {
    id: any;
    title: string | null;
    subtitle: string | null;
    description: string | null;
    slug: string | null;
    image: { url: string; alt: string | null } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type InfoChipFragment = {
  infoChip: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: { url: string; alt: string | null } | null;
  }>;
};

export type FlowerFieldsFragment = {
  __typename: "FlowerPageRecord";
  id: any;
  slug: string | null;
  introText: string | null;
  detailText: string | null;
  endingText: string | null;
  name: string | null;
  subtitle: string | null;
  primaryImage: { url: string; alt: string | null } | null;
  product: { title: string | null } | null;
  secondaryImage: { url: string; alt: string | null } | null;
  sections: Array<{
    id: any;
    _modelApiKey: string;
    title: string | null;
    isTitleH1: any | null;
    subtitle: string | null;
    subtitlePos: string | null;
    bgColor: string | null;
    sectionPadded: any | null;
    fontFamily: string | null;
    fontSize: string | null;
    text: string | null;
    bgImage: { url: string; alt: string | null } | null;
    content: Array<
      | {
          id: any;
          _modelApiKey: string;
          text: string | null;
          variant: string | null;
          hasLightTheme: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            quote: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainDescription: string | null;
          firstTextFooter: string | null;
          secondTextFooter: string | null;
          text: string | null;
          title: string | null;
          description: string | null;
          thirdText: string | null;
          color: { hex: string } | null;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          contentMobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mainButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          couponCard: Array<{
            _modelApiKey: string;
            id: any;
            productName: string | null;
            discountCode: string | null;
            discountToApply: string | null;
            text: string | null;
            disclaimer: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasLargerCardWithoutBorder: any | null;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          subtitleFont: string | null;
          titleFont: string | null;
          hasDividers: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          title: string | null;
          _modelApiKey: string;
          hasBigText: any | null;
          bigText: string | null;
          bigTextPosition: string | null;
          titleDescription: string | null;
          leftBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rightBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          descriptionContent: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | { id: any; _modelApiKey: string }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          typeOfLinks: string | null;
          applyFilterGrayscaleToCardImage: any | null;
          title: string | null;
          linkCollection: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          quote: string | null;
          author: string | null;
          blockSpacing: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          reviewCollection: Array<{
            id: any;
            _modelApiKey: string;
            hasDefaultCardPerView: any | null;
            cardsColor: string | null;
            cardType: string | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            sixCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            threeCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasTestimonyWithImage: any | null;
          content: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          testimony: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitlePosition: string | null;
          description: string | null;
          descriptionHtml: string | null;
          paragraph: string | null;
          hasButton: any | null;
          hasImages: any | null;
          hasAnImageAndText: any | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          externalButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            url: string | null;
          }>;
          images: Array<{
            id: any;
            title: string | null;
            description: string | null;
            image: {
              id: any;
              title: string | null;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
          background: { hex: string } | null;
          image: {
            url: string;
            alt: string | null;
            width: any | null;
            height: any | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          description: string | null;
          isMiniBanner: any | null;
          backgroundColor: { hex: string } | null;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          pageLink: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          imageMobile: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            embeddedVideo: {
              height: any;
              provider: string;
              providerUid: string;
              thumbnailUrl: string;
              title: string;
              url: string;
              width: any;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          title: string | null;
          logos: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
          galleryImages: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            month: string | null;
            flowerList: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  weekNumber: any | null;
                  weekDate: string | null;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
            >;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          title: string | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          animatedText: Array<{ id: any; text: string | null }>;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          centeredMobile: any | null;
          titlePadded: any | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          textMobile: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          isBigVersionLogo: any | null;
          isTitleFontBig: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          subtitleFontFamily: string | null;
          hasLightTheme: any | null;
          hasBiggerButton: any | null;
          hasAButtonToScrollToAComponent: any | null;
          hideInMobileVersion: any | null;
          variantStyle: string | null;
          buttonTitle: string | null;
          scrollTo: string | null;
          isShortHeight: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          bgVideo: { id: any; url: string } | null;
          bgVideoPosterImage: { url: string; alt: string | null } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogoMobile: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          titleFontFamily: string | null;
          subTitleHtml: string | null;
          subtitleAfter: any | null;
          subtitleFontFamily: string | null;
          contentPosition: string | null;
          theme: string | null;
          hasLightTheme: any | null;
          imagePosition: string | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          products: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          images: Array<{ url: string; alt: string | null }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          mainFeatureContainerContent: Array<{
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          fontFamily: string | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitlePosition: string | null;
          hasPersonalizedDescription: any | null;
          hasDescriptionAsTable: any | null;
          hasBulletPoints: any | null;
          text: string | null;
          imagePosition: string | null;
          imageWidth: string | null;
          hasSlider: any | null;
          backgroundColor: { hex: string } | null;
          colorText: { hex: string } | null;
          bulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rows: Array<{ id: any; text: string | null }>;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          product: {
            id: any;
            shopifyProduct: any | null;
            bouquetSize: string | null;
            prefixFrecuency: string | null;
            title: string | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
          } | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          gallery: Array<{
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            plantList: Array<{
              id: any;
              name: string | null;
              subtitle: string | null;
              introText: string | null;
              endingText: string | null;
              detailText: string | null;
              hasBadge: any | null;
              slug: string | null;
              badge: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              primaryImage: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              product: {
                id: any;
                slug: string | null;
                title: string | null;
                description: string | null;
                gallery: Array<{ id: any; url: string; alt: string | null }>;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  isAFlowerPlantCenterCollection: any | null;
                }>;
              } | null;
            }>;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            hasSlider: any | null;
            showProductAs: string | null;
            slug: string | null;
            title: string | null;
            centered: any | null;
            titleSubtitleFontSize: string | null;
            hideTitle: any | null;
            isTitleH1: any | null;
            description: string | null;
            hasFilterComponent: any | null;
            hideBreadcrumb: any | null;
            products: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  slug: string | null;
                  sku: string | null;
                  title: string | null;
                  thirdParty: string | null;
                  isSubscription: any | null;
                  disableDatePicker: any | null;
                  bouquetSize: string | null;
                  shopifyProduct: any | null;
                  shopifySubscriptionProducts: any | null;
                  shortDescription: string | null;
                  cardType: string | null;
                  isSuperGift: any | null;
                  isGoogleProduct: any | null;
                  isFacebookProduct: any | null;
                  hideFromSearchEngines: any | null;
                  hasNotBreadcrumb: any | null;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    tagType: string | null;
                    image: { url: string; alt: string | null } | null;
                    backgroundColor: { hex: string } | null;
                    textColor: { hex: string } | null;
                  }>;
                  tagFilter: Array<{
                    id: any;
                    title: string | null;
                    tagMessage: string | null;
                    filterType: string | null;
                    minPrice: string | null;
                    maxPrice: string | null;
                    backgroundColor: { hex: string } | null;
                  }>;
                  gallery: Array<{
                    id: any;
                    format: string;
                    url: string;
                    alt: string | null;
                  }>;
                }
              | {
                  _modelApiKey: string;
                  shopifyProduct: any | null;
                  id: any;
                  image: {
                    url: string;
                    alt: string | null;
                    responsiveImage: {
                      srcSet: string;
                      webpSrcSet: string;
                      sizes: string;
                      src: string;
                      width: any;
                      height: any;
                      aspectRatio: any;
                      alt: string | null;
                      title: string | null;
                      base64: string | null;
                    } | null;
                  } | null;
                  pageLinkUrl: Array<{
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }>;
                }
            >;
            productTags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              filterType: string | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
            image: { url: string } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          firstColumn: string | null;
          secondColumn: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainSubtitle: string | null;
          subtitlePosition: string | null;
          fontSize: string | null;
          fontFamily: string | null;
          titleFirstCol: string | null;
          firstColumn: string | null;
          titleSecondCol: string | null;
          secondColumn: string | null;
          columnWidth: string | null;
          contentPosition: string | null;
          fontSizeFirstTitle: string | null;
          fontSizeSecondTitle: string | null;
          titleFontFamily: string | null;
          textFontFamily: string | null;
          bgImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          proseContent: string | null;
          largeTypography: any | null;
          showOnMobileVersion: any | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
          description: string | null;
          showSubtitleAfterTitle: any | null;
          content: Array<{
            id: any;
            title: string | null;
            backgroundColor: { hex: string } | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          embedHtml: string | null;
          formType: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          hideFaqPage: any | null;
          title: string | null;
          questionList: Array<{
            id: any;
            answer: string | null;
            question: string | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          hasslider: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productBestSeller: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          title: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          showSideTextOnDesktop: any | null;
          bgColor: string | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productColletionLink: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            image: { url: string; alt: string | null } | null;
            landingPageLink: { slug: string | null } | null;
          }>;
        }
    >;
  }>;
  seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  infoChip: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: { url: string; alt: string | null } | null;
  }>;
};

export type GetAllFlowersCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFlowersCountQuery = { meta: { count: any } };

export type GetAllFlowersSlugsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllFlowersSlugsQuery = {
  records: Array<{ slug: string | null }>;
};

export type GetFlowerBySlugQueryVariables = Exact<{
  slug: InputMaybe<Scalars["String"]>;
}>;

export type GetFlowerBySlugQuery = {
  flowerPage: {
    __typename: "FlowerPageRecord";
    id: any;
    slug: string | null;
    introText: string | null;
    detailText: string | null;
    endingText: string | null;
    name: string | null;
    subtitle: string | null;
    primaryImage: { url: string; alt: string | null } | null;
    product: { title: string | null } | null;
    secondaryImage: { url: string; alt: string | null } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
    infoChip: Array<{
      id: any;
      title: string | null;
      text: string | null;
      image: { url: string; alt: string | null } | null;
    }>;
  } | null;
};

export type GetAllFlowersQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllFlowersQuery = {
  allFlowerPages: Array<{
    __typename: "FlowerPageRecord";
    id: any;
    slug: string | null;
    introText: string | null;
    detailText: string | null;
    endingText: string | null;
    name: string | null;
    subtitle: string | null;
    primaryImage: { url: string; alt: string | null } | null;
    product: { title: string | null } | null;
    secondaryImage: { url: string; alt: string | null } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
    infoChip: Array<{
      id: any;
      title: string | null;
      text: string | null;
      image: { url: string; alt: string | null } | null;
    }>;
  }>;
};

export type ProductCollectionFieldsFragment = {
  id: any;
  hasSlider: any | null;
  showProductAs: string | null;
  slug: string | null;
  title: string | null;
  centered: any | null;
  titleSubtitleFontSize: string | null;
  hideTitle: any | null;
  isTitleH1: any | null;
  description: string | null;
  hasFilterComponent: any | null;
  hideBreadcrumb: any | null;
  products: Array<
    | {
        id: any;
        _modelApiKey: string;
        slug: string | null;
        sku: string | null;
        title: string | null;
        thirdParty: string | null;
        isSubscription: any | null;
        disableDatePicker: any | null;
        bouquetSize: string | null;
        shopifyProduct: any | null;
        shopifySubscriptionProducts: any | null;
        shortDescription: string | null;
        cardType: string | null;
        isSuperGift: any | null;
        isGoogleProduct: any | null;
        isFacebookProduct: any | null;
        hideFromSearchEngines: any | null;
        hasNotBreadcrumb: any | null;
        tags: Array<{
          id: any;
          slug: string | null;
          title: string | null;
          tagMessage: string | null;
          tagType: string | null;
          image: { url: string; alt: string | null } | null;
          backgroundColor: { hex: string } | null;
          textColor: { hex: string } | null;
        }>;
        tagFilter: Array<{
          id: any;
          title: string | null;
          tagMessage: string | null;
          filterType: string | null;
          minPrice: string | null;
          maxPrice: string | null;
          backgroundColor: { hex: string } | null;
        }>;
        gallery: Array<{
          id: any;
          format: string;
          url: string;
          alt: string | null;
        }>;
      }
    | {
        _modelApiKey: string;
        shopifyProduct: any | null;
        id: any;
        image: {
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        pageLinkUrl: Array<{
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
      }
  >;
  productTags: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    tagMessage: string | null;
    filterType: string | null;
    backgroundColor: { hex: string } | null;
    textColor: { hex: string } | null;
  }>;
  image: { url: string } | null;
};

export type GetFlowerShopPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetFlowerShopPageQuery = {
  flowerShopPage: {
    id: any;
    title: string | null;
    slug: string | null;
    text: string | null;
    productCollections: Array<{
      id: any;
      hasSlider: any | null;
      showProductAs: string | null;
      slug: string | null;
      title: string | null;
      centered: any | null;
      titleSubtitleFontSize: string | null;
      hideTitle: any | null;
      isTitleH1: any | null;
      description: string | null;
      hasFilterComponent: any | null;
      hideBreadcrumb: any | null;
      products: Array<
        | {
            id: any;
            _modelApiKey: string;
            slug: string | null;
            sku: string | null;
            title: string | null;
            thirdParty: string | null;
            isSubscription: any | null;
            disableDatePicker: any | null;
            bouquetSize: string | null;
            shopifyProduct: any | null;
            shopifySubscriptionProducts: any | null;
            shortDescription: string | null;
            cardType: string | null;
            isSuperGift: any | null;
            isGoogleProduct: any | null;
            isFacebookProduct: any | null;
            hideFromSearchEngines: any | null;
            hasNotBreadcrumb: any | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
            tagFilter: Array<{
              id: any;
              title: string | null;
              tagMessage: string | null;
              filterType: string | null;
              minPrice: string | null;
              maxPrice: string | null;
              backgroundColor: { hex: string } | null;
            }>;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
          }
        | {
            _modelApiKey: string;
            shopifyProduct: any | null;
            id: any;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
      >;
      productTags: Array<{
        id: any;
        slug: string | null;
        title: string | null;
        tagMessage: string | null;
        filterType: string | null;
        backgroundColor: { hex: string } | null;
        textColor: { hex: string } | null;
      }>;
      image: { url: string } | null;
    }>;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetFlowerShopCollectionProductsByTagQueryVariables = Exact<{
  tagIds: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type GetFlowerShopCollectionProductsByTagQuery = {
  allProducts: Array<{
    id: any;
    _modelApiKey: string;
    slug: string | null;
    sku: string | null;
    title: string | null;
    thirdParty: string | null;
    isSubscription: any | null;
    disableDatePicker: any | null;
    bouquetSize: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    shortDescription: string | null;
    cardType: string | null;
    isSuperGift: any | null;
    isGoogleProduct: any | null;
    isFacebookProduct: any | null;
    hideFromSearchEngines: any | null;
    hasNotBreadcrumb: any | null;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    tagFilter: Array<{
      id: any;
      title: string | null;
      tagMessage: string | null;
      filterType: string | null;
      minPrice: string | null;
      maxPrice: string | null;
      backgroundColor: { hex: string } | null;
    }>;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
    }>;
  }>;
};

export type GetHomepageDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomepageDataQuery = {
  homepage: {
    id: any;
    title: string | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetAllPagesCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPagesCountQuery = { meta: { count: any } };

export type GetAllPagesSlugsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllPagesSlugsQuery = { records: Array<{ slug: string | null }> };

export type GetNonLandingPagesSlugsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNonLandingPagesSlugsQuery = {
  flowerShopPage: { slug: string | null } | null;
  plantShopPage: { slug: string | null } | null;
  subscribePage: { slug: string | null } | null;
  pressPage: { slug: string | null } | null;
  flowerCenter: { slug: string | null } | null;
  faqsPage: { slug: string | null } | null;
  plantCenter: { slug: string | null } | null;
  contactUsPage: { slug: string | null } | null;
};

export type GetPageBySlugQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetPageBySlugQuery = {
  page: {
    id: any;
    slug: string | null;
    hideFromSearchEngines: any | null;
    title: string | null;
    pageHeaderType: string | null;
    showPageHeader: any | null;
    showPageFooter: any | null;
    hasCustomLogo: any | null;
    logo: {
      url: string;
      alt: string | null;
      format: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetFilterTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFilterTagsQuery = {
  filters: Array<{
    id: any;
    title: string | null;
    tagMessage: string | null;
    filterType: string | null;
    minPrice: string | null;
    maxPrice: string | null;
    priority: any | null;
    alwaysVisible: any | null;
    backgroundColor: { hex: string } | null;
  }>;
};

export type GetLayoutDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetLayoutDataQuery = {
  site: {
    faviconMetaTags: Array<{
      attributes: any | null;
      content: string | null;
      tag: string;
    }>;
  };
  footer: {
    extraMenuTitle: string | null;
    instagram: string | null;
    pinterest: string | null;
    facebook: string | null;
    twitter: string | null;
    youtube: string | null;
    rightMenuTitle: string | null;
    leftMenuTitle: string | null;
    middleMenuTitle: string | null;
    extraItemsLinks: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
    rightMenuLinks: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
    leftMenuLinks: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
    middleMenuLinks: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
  } | null;
  header: {
    announcementMessage: string | null;
    announcementTheme: string | null;
    announcementLinkText: string | null;
    announcementLinkUrl: string | null;
    isStickyAnnouncement: any | null;
    menuLinks: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
    menuItem: Array<{
      id: any;
      title: string | null;
      url: string | null;
      isExternal: any | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
      subMenuItem: Array<{
        id: any;
        item: string | null;
        _modelApiKey: string;
        leftTitle: Array<{
          id: any;
          title: string | null;
          shortDescription: string | null;
          url: string | null;
          isExternal: any | null;
          coverImage: {
            id: any;
            url: string;
            alt: string | null;
            format: string;
          } | null;
          internalLink:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
        middleSubMenuItem: Array<{
          id: any;
          title: string | null;
          item: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }>;
        rightImage: {
          id: any;
          url: string;
          alt: string | null;
          responsiveImage: {
            srcSet: string;
            webpSrcSet: string;
            sizes: string;
            src: string;
            width: any;
            height: any;
            aspectRatio: any;
            alt: string | null;
            title: string | null;
            base64: string | null;
          } | null;
        } | null;
        rightLink: Array<{
          id: any;
          title: string | null;
          shortDescription: string | null;
          url: string | null;
          isExternal: any | null;
          coverImage: {
            id: any;
            url: string;
            alt: string | null;
            format: string;
          } | null;
          internalLink:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }>;
      }>;
    }>;
  } | null;
  subscribePage: { slug: string | null } | null;
  flowerShopPage: { slug: string | null } | null;
};

export type NestedPageChildrenFieldsFragment = {
  title: string | null;
  subtitle: string | null;
  slug: string | null;
  shortDescription: string | null;
  coverImage: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type GetAllNestedPagesCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllNestedPagesCountQuery = { meta: { count: any } };

export type GetAllNestedPagesSlugsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllNestedPagesSlugsQuery = {
  records: Array<{ slug: string | null }>;
};

export type GetNestedPageBySlugQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetNestedPageBySlugQuery = {
  nestedPage: {
    id: any;
    slug: string | null;
    hideFromSearchEngines: any | null;
    title: string | null;
    hideTitle: any | null;
    isTitleH1: any | null;
    subtitle: string | null;
    introParagraph: string | null;
    pageHeaderType: string | null;
    showPageHeader: any | null;
    showPageFooter: any | null;
    hasCustomLogo: any | null;
    logo: {
      url: string;
      alt: string | null;
      format: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
    sectionsBefore: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    children: Array<{
      title: string | null;
      subtitle: string | null;
      slug: string | null;
      shortDescription: string | null;
      coverImage: {
        url: string;
        alt: string | null;
        format: string;
        responsiveImage: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
      } | null;
    }>;
    sectionsAfter: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetPlantCenterQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlantCenterQuery = {
  plantCenter: {
    id: any;
    slug: string | null;
    title: string | null;
    subtitle: string | null;
    description: string | null;
    image: { url: string; alt: string | null } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type InfoChipPlantFragment = {
  infoChip: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: { url: string; alt: string | null } | null;
  }>;
};

export type PlantFieldsFragment = {
  __typename: "PlantPageRecord";
  id: any;
  slug: string | null;
  introText: string | null;
  detailText: string | null;
  endingText: string | null;
  name: string | null;
  subtitle: string | null;
  primaryImage: { url: string; alt: string | null } | null;
  product: {
    title: string | null;
    gallery: Array<{ id: any; url: string; alt: string | null }>;
  } | null;
  secondaryImage: { url: string; alt: string | null } | null;
  sections: Array<{
    id: any;
    _modelApiKey: string;
    title: string | null;
    isTitleH1: any | null;
    subtitle: string | null;
    subtitlePos: string | null;
    bgColor: string | null;
    sectionPadded: any | null;
    fontFamily: string | null;
    fontSize: string | null;
    text: string | null;
    bgImage: { url: string; alt: string | null } | null;
    content: Array<
      | {
          id: any;
          _modelApiKey: string;
          text: string | null;
          variant: string | null;
          hasLightTheme: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            quote: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainDescription: string | null;
          firstTextFooter: string | null;
          secondTextFooter: string | null;
          text: string | null;
          title: string | null;
          description: string | null;
          thirdText: string | null;
          color: { hex: string } | null;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          contentMobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mainButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          couponCard: Array<{
            _modelApiKey: string;
            id: any;
            productName: string | null;
            discountCode: string | null;
            discountToApply: string | null;
            text: string | null;
            disclaimer: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasLargerCardWithoutBorder: any | null;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          subtitleFont: string | null;
          titleFont: string | null;
          hasDividers: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          title: string | null;
          _modelApiKey: string;
          hasBigText: any | null;
          bigText: string | null;
          bigTextPosition: string | null;
          titleDescription: string | null;
          leftBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rightBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          descriptionContent: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | { id: any; _modelApiKey: string }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          typeOfLinks: string | null;
          applyFilterGrayscaleToCardImage: any | null;
          title: string | null;
          linkCollection: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          quote: string | null;
          author: string | null;
          blockSpacing: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          reviewCollection: Array<{
            id: any;
            _modelApiKey: string;
            hasDefaultCardPerView: any | null;
            cardsColor: string | null;
            cardType: string | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            sixCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            threeCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasTestimonyWithImage: any | null;
          content: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          testimony: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitlePosition: string | null;
          description: string | null;
          descriptionHtml: string | null;
          paragraph: string | null;
          hasButton: any | null;
          hasImages: any | null;
          hasAnImageAndText: any | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          externalButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            url: string | null;
          }>;
          images: Array<{
            id: any;
            title: string | null;
            description: string | null;
            image: {
              id: any;
              title: string | null;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
          background: { hex: string } | null;
          image: {
            url: string;
            alt: string | null;
            width: any | null;
            height: any | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          description: string | null;
          isMiniBanner: any | null;
          backgroundColor: { hex: string } | null;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          pageLink: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          imageMobile: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            embeddedVideo: {
              height: any;
              provider: string;
              providerUid: string;
              thumbnailUrl: string;
              title: string;
              url: string;
              width: any;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          title: string | null;
          logos: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
          galleryImages: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            month: string | null;
            flowerList: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  weekNumber: any | null;
                  weekDate: string | null;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
            >;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          title: string | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          animatedText: Array<{ id: any; text: string | null }>;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          centeredMobile: any | null;
          titlePadded: any | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          textMobile: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          isBigVersionLogo: any | null;
          isTitleFontBig: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          subtitleFontFamily: string | null;
          hasLightTheme: any | null;
          hasBiggerButton: any | null;
          hasAButtonToScrollToAComponent: any | null;
          hideInMobileVersion: any | null;
          variantStyle: string | null;
          buttonTitle: string | null;
          scrollTo: string | null;
          isShortHeight: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          bgVideo: { id: any; url: string } | null;
          bgVideoPosterImage: { url: string; alt: string | null } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogoMobile: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          titleFontFamily: string | null;
          subTitleHtml: string | null;
          subtitleAfter: any | null;
          subtitleFontFamily: string | null;
          contentPosition: string | null;
          theme: string | null;
          hasLightTheme: any | null;
          imagePosition: string | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          products: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          images: Array<{ url: string; alt: string | null }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          mainFeatureContainerContent: Array<{
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          fontFamily: string | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitlePosition: string | null;
          hasPersonalizedDescription: any | null;
          hasDescriptionAsTable: any | null;
          hasBulletPoints: any | null;
          text: string | null;
          imagePosition: string | null;
          imageWidth: string | null;
          hasSlider: any | null;
          backgroundColor: { hex: string } | null;
          colorText: { hex: string } | null;
          bulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rows: Array<{ id: any; text: string | null }>;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          product: {
            id: any;
            shopifyProduct: any | null;
            bouquetSize: string | null;
            prefixFrecuency: string | null;
            title: string | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
          } | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          gallery: Array<{
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            plantList: Array<{
              id: any;
              name: string | null;
              subtitle: string | null;
              introText: string | null;
              endingText: string | null;
              detailText: string | null;
              hasBadge: any | null;
              slug: string | null;
              badge: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              primaryImage: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              product: {
                id: any;
                slug: string | null;
                title: string | null;
                description: string | null;
                gallery: Array<{ id: any; url: string; alt: string | null }>;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  isAFlowerPlantCenterCollection: any | null;
                }>;
              } | null;
            }>;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            hasSlider: any | null;
            showProductAs: string | null;
            slug: string | null;
            title: string | null;
            centered: any | null;
            titleSubtitleFontSize: string | null;
            hideTitle: any | null;
            isTitleH1: any | null;
            description: string | null;
            hasFilterComponent: any | null;
            hideBreadcrumb: any | null;
            products: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  slug: string | null;
                  sku: string | null;
                  title: string | null;
                  thirdParty: string | null;
                  isSubscription: any | null;
                  disableDatePicker: any | null;
                  bouquetSize: string | null;
                  shopifyProduct: any | null;
                  shopifySubscriptionProducts: any | null;
                  shortDescription: string | null;
                  cardType: string | null;
                  isSuperGift: any | null;
                  isGoogleProduct: any | null;
                  isFacebookProduct: any | null;
                  hideFromSearchEngines: any | null;
                  hasNotBreadcrumb: any | null;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    tagType: string | null;
                    image: { url: string; alt: string | null } | null;
                    backgroundColor: { hex: string } | null;
                    textColor: { hex: string } | null;
                  }>;
                  tagFilter: Array<{
                    id: any;
                    title: string | null;
                    tagMessage: string | null;
                    filterType: string | null;
                    minPrice: string | null;
                    maxPrice: string | null;
                    backgroundColor: { hex: string } | null;
                  }>;
                  gallery: Array<{
                    id: any;
                    format: string;
                    url: string;
                    alt: string | null;
                  }>;
                }
              | {
                  _modelApiKey: string;
                  shopifyProduct: any | null;
                  id: any;
                  image: {
                    url: string;
                    alt: string | null;
                    responsiveImage: {
                      srcSet: string;
                      webpSrcSet: string;
                      sizes: string;
                      src: string;
                      width: any;
                      height: any;
                      aspectRatio: any;
                      alt: string | null;
                      title: string | null;
                      base64: string | null;
                    } | null;
                  } | null;
                  pageLinkUrl: Array<{
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }>;
                }
            >;
            productTags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              filterType: string | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
            image: { url: string } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          firstColumn: string | null;
          secondColumn: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainSubtitle: string | null;
          subtitlePosition: string | null;
          fontSize: string | null;
          fontFamily: string | null;
          titleFirstCol: string | null;
          firstColumn: string | null;
          titleSecondCol: string | null;
          secondColumn: string | null;
          columnWidth: string | null;
          contentPosition: string | null;
          fontSizeFirstTitle: string | null;
          fontSizeSecondTitle: string | null;
          titleFontFamily: string | null;
          textFontFamily: string | null;
          bgImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          proseContent: string | null;
          largeTypography: any | null;
          showOnMobileVersion: any | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
          description: string | null;
          showSubtitleAfterTitle: any | null;
          content: Array<{
            id: any;
            title: string | null;
            backgroundColor: { hex: string } | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          embedHtml: string | null;
          formType: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          hideFaqPage: any | null;
          title: string | null;
          questionList: Array<{
            id: any;
            answer: string | null;
            question: string | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          hasslider: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productBestSeller: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          title: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          showSideTextOnDesktop: any | null;
          bgColor: string | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productColletionLink: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            image: { url: string; alt: string | null } | null;
            landingPageLink: { slug: string | null } | null;
          }>;
        }
    >;
  }>;
  seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  infoChip: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: { url: string; alt: string | null } | null;
  }>;
};

export type GetAllPlantsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPlantsCountQuery = { meta: { count: any } };

export type GetAllPlantsSlugsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllPlantsSlugsQuery = {
  records: Array<{ slug: string | null }>;
};

export type GetPlantBySlugQueryVariables = Exact<{
  slug: InputMaybe<Scalars["String"]>;
}>;

export type GetPlantBySlugQuery = {
  plantPage: {
    __typename: "PlantPageRecord";
    id: any;
    slug: string | null;
    introText: string | null;
    detailText: string | null;
    endingText: string | null;
    name: string | null;
    subtitle: string | null;
    primaryImage: { url: string; alt: string | null } | null;
    product: {
      title: string | null;
      gallery: Array<{ id: any; url: string; alt: string | null }>;
    } | null;
    secondaryImage: { url: string; alt: string | null } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
    infoChip: Array<{
      id: any;
      title: string | null;
      text: string | null;
      image: { url: string; alt: string | null } | null;
    }>;
  } | null;
};

export type GetAllPlantsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllPlantsQuery = {
  allPlantPages: Array<{
    __typename: "PlantPageRecord";
    id: any;
    slug: string | null;
    introText: string | null;
    detailText: string | null;
    endingText: string | null;
    name: string | null;
    subtitle: string | null;
    primaryImage: { url: string; alt: string | null } | null;
    product: {
      title: string | null;
      gallery: Array<{ id: any; url: string; alt: string | null }>;
    } | null;
    secondaryImage: { url: string; alt: string | null } | null;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
    infoChip: Array<{
      id: any;
      title: string | null;
      text: string | null;
      image: { url: string; alt: string | null } | null;
    }>;
  }>;
};

export type GetPlantShopPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlantShopPageQuery = {
  plantShopPage: {
    id: any;
    title: string | null;
    slug: string | null;
    text: string | null;
    productCollections: Array<{
      id: any;
      hasSlider: any | null;
      showProductAs: string | null;
      slug: string | null;
      title: string | null;
      centered: any | null;
      titleSubtitleFontSize: string | null;
      hideTitle: any | null;
      isTitleH1: any | null;
      description: string | null;
      hasFilterComponent: any | null;
      hideBreadcrumb: any | null;
      products: Array<
        | {
            id: any;
            _modelApiKey: string;
            slug: string | null;
            sku: string | null;
            title: string | null;
            thirdParty: string | null;
            isSubscription: any | null;
            disableDatePicker: any | null;
            bouquetSize: string | null;
            shopifyProduct: any | null;
            shopifySubscriptionProducts: any | null;
            shortDescription: string | null;
            cardType: string | null;
            isSuperGift: any | null;
            isGoogleProduct: any | null;
            isFacebookProduct: any | null;
            hideFromSearchEngines: any | null;
            hasNotBreadcrumb: any | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
            tagFilter: Array<{
              id: any;
              title: string | null;
              tagMessage: string | null;
              filterType: string | null;
              minPrice: string | null;
              maxPrice: string | null;
              backgroundColor: { hex: string } | null;
            }>;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
          }
        | {
            _modelApiKey: string;
            shopifyProduct: any | null;
            id: any;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
      >;
      productTags: Array<{
        id: any;
        slug: string | null;
        title: string | null;
        tagMessage: string | null;
        filterType: string | null;
        backgroundColor: { hex: string } | null;
        textColor: { hex: string } | null;
      }>;
      image: { url: string } | null;
    }>;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type PressArticlesFieldsFragment = {
  id: any;
  date: any | null;
  title: string | null;
  link: string | null;
  shortDescription: string | null;
  image: { url: string; alt: string | null } | null;
};

export type GetPressAticlesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPressAticlesQuery = {
  allPressArticles: Array<{
    id: any;
    date: any | null;
    title: string | null;
    link: string | null;
    shortDescription: string | null;
    image: { url: string; alt: string | null } | null;
  }>;
};

export type GetPressPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetPressPageQuery = {
  pressPage: {
    id: any;
    title: string | null;
    subtitle: string | null;
    contact: string | null;
    slug: string | null;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type ProductFieldsHeaderFragment = {
  id: any;
  slug: string | null;
  title: string | null;
  shopifyProduct: any | null;
  shopifySubscriptionProducts: any | null;
  tags: Array<{ slug: string | null }>;
};

export type ProductFieldsShortFragment = {
  id: any;
  _modelApiKey: string;
  slug: string | null;
  sku: string | null;
  title: string | null;
  thirdParty: string | null;
  isSubscription: any | null;
  disableDatePicker: any | null;
  bouquetSize: string | null;
  shopifyProduct: any | null;
  shopifySubscriptionProducts: any | null;
  shortDescription: string | null;
  cardType: string | null;
  isSuperGift: any | null;
  isGoogleProduct: any | null;
  isFacebookProduct: any | null;
  hideFromSearchEngines: any | null;
  hasNotBreadcrumb: any | null;
  tags: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    tagMessage: string | null;
    tagType: string | null;
    image: { url: string; alt: string | null } | null;
    backgroundColor: { hex: string } | null;
    textColor: { hex: string } | null;
  }>;
  tagFilter: Array<{
    id: any;
    title: string | null;
    tagMessage: string | null;
    filterType: string | null;
    minPrice: string | null;
    maxPrice: string | null;
    backgroundColor: { hex: string } | null;
  }>;
  gallery: Array<{ id: any; format: string; url: string; alt: string | null }>;
};

export type ProductFieldsFragment = {
  id: any;
  sku: string | null;
  disableDatePicker: any | null;
  _modelApiKey: string;
  isTitleDescriptionFirst: any | null;
  slug: string | null;
  title: string | null;
  thirdParty: string | null;
  hasColumnLayout: any | null;
  shortDescription: string | null;
  isDescriptionAfterTitle: any | null;
  description: string | null;
  importantInfo: string | null;
  shopifyProduct: any | null;
  shopifySubscriptionProducts: any | null;
  isSubscription: any | null;
  bouquetSize: string | null;
  isSuperGift: any | null;
  isGoogleProduct: any | null;
  isFacebookProduct: any | null;
  hideFromSearchEngines: any | null;
  hasNotBreadcrumb: any | null;
  hasVariant: any | null;
  variantType: string | null;
  prefixFrecuency: string | null;
  isUpselling: any | null;
  hasDisabledNextDayDelivery: any | null;
  noPdp: any | null;
  isAvailableForNextday: any | null;
  priorityUpselling: any | null;
  deliveryOption: string | null;
  pageHeaderType: string | null;
  showPageHeader: any | null;
  showPageFooter: any | null;
  priceRange: string | null;
  seoDescription: string | null;
  backgroundColor: { hex: string } | null;
  productDetailItems: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  variants: Array<{
    id: any;
    title: string | null;
    value: string | null;
    headerText: string | null;
    additionalText: string | null;
    footerText: string | null;
    listUpsellingExcludePerVariant: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    color: { hex: string } | null;
    thumbnail: { url: string; alt: string | null } | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      mobileThumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
  }>;
  excludeNextdayProducts: Array<{
    id: any;
    title: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
  }>;
  upsellingProducts: Array<{
    id: any;
    title: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
  }>;
  listUpsellingToExclude: Array<{
    id: any;
    title: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
  }>;
  tags: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    tagMessage: string | null;
    tagType: string | null;
    image: { url: string; alt: string | null } | null;
    backgroundColor: { hex: string } | null;
    textColor: { hex: string } | null;
  }>;
  tagFilter: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    tagType: string | null;
  }>;
  gallery: Array<{
    id: any;
    format: string;
    url: string;
    alt: string | null;
    thumbUrl: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
    thumbResponsive: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  }>;
  sectionsBeforeSimilarProducts: Array<{
    id: any;
    _modelApiKey: string;
    title: string | null;
    isTitleH1: any | null;
    subtitle: string | null;
    subtitlePos: string | null;
    bgColor: string | null;
    sectionPadded: any | null;
    fontFamily: string | null;
    fontSize: string | null;
    text: string | null;
    bgImage: { url: string; alt: string | null } | null;
    content: Array<
      | {
          id: any;
          _modelApiKey: string;
          text: string | null;
          variant: string | null;
          hasLightTheme: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            quote: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainDescription: string | null;
          firstTextFooter: string | null;
          secondTextFooter: string | null;
          text: string | null;
          title: string | null;
          description: string | null;
          thirdText: string | null;
          color: { hex: string } | null;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          contentMobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mainButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          couponCard: Array<{
            _modelApiKey: string;
            id: any;
            productName: string | null;
            discountCode: string | null;
            discountToApply: string | null;
            text: string | null;
            disclaimer: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasLargerCardWithoutBorder: any | null;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          subtitleFont: string | null;
          titleFont: string | null;
          hasDividers: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          title: string | null;
          _modelApiKey: string;
          hasBigText: any | null;
          bigText: string | null;
          bigTextPosition: string | null;
          titleDescription: string | null;
          leftBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rightBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          descriptionContent: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | { id: any; _modelApiKey: string }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          typeOfLinks: string | null;
          applyFilterGrayscaleToCardImage: any | null;
          title: string | null;
          linkCollection: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          quote: string | null;
          author: string | null;
          blockSpacing: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          reviewCollection: Array<{
            id: any;
            _modelApiKey: string;
            hasDefaultCardPerView: any | null;
            cardsColor: string | null;
            cardType: string | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            sixCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            threeCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasTestimonyWithImage: any | null;
          content: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          testimony: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitlePosition: string | null;
          description: string | null;
          descriptionHtml: string | null;
          paragraph: string | null;
          hasButton: any | null;
          hasImages: any | null;
          hasAnImageAndText: any | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          externalButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            url: string | null;
          }>;
          images: Array<{
            id: any;
            title: string | null;
            description: string | null;
            image: {
              id: any;
              title: string | null;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
          background: { hex: string } | null;
          image: {
            url: string;
            alt: string | null;
            width: any | null;
            height: any | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          description: string | null;
          isMiniBanner: any | null;
          backgroundColor: { hex: string } | null;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          pageLink: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          imageMobile: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            embeddedVideo: {
              height: any;
              provider: string;
              providerUid: string;
              thumbnailUrl: string;
              title: string;
              url: string;
              width: any;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          title: string | null;
          logos: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
          galleryImages: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            month: string | null;
            flowerList: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  weekNumber: any | null;
                  weekDate: string | null;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
            >;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          title: string | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          animatedText: Array<{ id: any; text: string | null }>;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          centeredMobile: any | null;
          titlePadded: any | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          textMobile: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          isBigVersionLogo: any | null;
          isTitleFontBig: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          subtitleFontFamily: string | null;
          hasLightTheme: any | null;
          hasBiggerButton: any | null;
          hasAButtonToScrollToAComponent: any | null;
          hideInMobileVersion: any | null;
          variantStyle: string | null;
          buttonTitle: string | null;
          scrollTo: string | null;
          isShortHeight: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          bgVideo: { id: any; url: string } | null;
          bgVideoPosterImage: { url: string; alt: string | null } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogoMobile: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          titleFontFamily: string | null;
          subTitleHtml: string | null;
          subtitleAfter: any | null;
          subtitleFontFamily: string | null;
          contentPosition: string | null;
          theme: string | null;
          hasLightTheme: any | null;
          imagePosition: string | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          products: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          images: Array<{ url: string; alt: string | null }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          mainFeatureContainerContent: Array<{
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          fontFamily: string | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitlePosition: string | null;
          hasPersonalizedDescription: any | null;
          hasDescriptionAsTable: any | null;
          hasBulletPoints: any | null;
          text: string | null;
          imagePosition: string | null;
          imageWidth: string | null;
          hasSlider: any | null;
          backgroundColor: { hex: string } | null;
          colorText: { hex: string } | null;
          bulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rows: Array<{ id: any; text: string | null }>;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          product: {
            id: any;
            shopifyProduct: any | null;
            bouquetSize: string | null;
            prefixFrecuency: string | null;
            title: string | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
          } | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          gallery: Array<{
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            plantList: Array<{
              id: any;
              name: string | null;
              subtitle: string | null;
              introText: string | null;
              endingText: string | null;
              detailText: string | null;
              hasBadge: any | null;
              slug: string | null;
              badge: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              primaryImage: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              product: {
                id: any;
                slug: string | null;
                title: string | null;
                description: string | null;
                gallery: Array<{ id: any; url: string; alt: string | null }>;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  isAFlowerPlantCenterCollection: any | null;
                }>;
              } | null;
            }>;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            hasSlider: any | null;
            showProductAs: string | null;
            slug: string | null;
            title: string | null;
            centered: any | null;
            titleSubtitleFontSize: string | null;
            hideTitle: any | null;
            isTitleH1: any | null;
            description: string | null;
            hasFilterComponent: any | null;
            hideBreadcrumb: any | null;
            products: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  slug: string | null;
                  sku: string | null;
                  title: string | null;
                  thirdParty: string | null;
                  isSubscription: any | null;
                  disableDatePicker: any | null;
                  bouquetSize: string | null;
                  shopifyProduct: any | null;
                  shopifySubscriptionProducts: any | null;
                  shortDescription: string | null;
                  cardType: string | null;
                  isSuperGift: any | null;
                  isGoogleProduct: any | null;
                  isFacebookProduct: any | null;
                  hideFromSearchEngines: any | null;
                  hasNotBreadcrumb: any | null;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    tagType: string | null;
                    image: { url: string; alt: string | null } | null;
                    backgroundColor: { hex: string } | null;
                    textColor: { hex: string } | null;
                  }>;
                  tagFilter: Array<{
                    id: any;
                    title: string | null;
                    tagMessage: string | null;
                    filterType: string | null;
                    minPrice: string | null;
                    maxPrice: string | null;
                    backgroundColor: { hex: string } | null;
                  }>;
                  gallery: Array<{
                    id: any;
                    format: string;
                    url: string;
                    alt: string | null;
                  }>;
                }
              | {
                  _modelApiKey: string;
                  shopifyProduct: any | null;
                  id: any;
                  image: {
                    url: string;
                    alt: string | null;
                    responsiveImage: {
                      srcSet: string;
                      webpSrcSet: string;
                      sizes: string;
                      src: string;
                      width: any;
                      height: any;
                      aspectRatio: any;
                      alt: string | null;
                      title: string | null;
                      base64: string | null;
                    } | null;
                  } | null;
                  pageLinkUrl: Array<{
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }>;
                }
            >;
            productTags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              filterType: string | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
            image: { url: string } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          firstColumn: string | null;
          secondColumn: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainSubtitle: string | null;
          subtitlePosition: string | null;
          fontSize: string | null;
          fontFamily: string | null;
          titleFirstCol: string | null;
          firstColumn: string | null;
          titleSecondCol: string | null;
          secondColumn: string | null;
          columnWidth: string | null;
          contentPosition: string | null;
          fontSizeFirstTitle: string | null;
          fontSizeSecondTitle: string | null;
          titleFontFamily: string | null;
          textFontFamily: string | null;
          bgImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          proseContent: string | null;
          largeTypography: any | null;
          showOnMobileVersion: any | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
          description: string | null;
          showSubtitleAfterTitle: any | null;
          content: Array<{
            id: any;
            title: string | null;
            backgroundColor: { hex: string } | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          embedHtml: string | null;
          formType: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          hideFaqPage: any | null;
          title: string | null;
          questionList: Array<{
            id: any;
            answer: string | null;
            question: string | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          hasslider: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productBestSeller: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          title: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          showSideTextOnDesktop: any | null;
          bgColor: string | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productColletionLink: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            image: { url: string; alt: string | null } | null;
            landingPageLink: { slug: string | null } | null;
          }>;
        }
    >;
  }>;
  sections: Array<{
    id: any;
    _modelApiKey: string;
    title: string | null;
    isTitleH1: any | null;
    subtitle: string | null;
    subtitlePos: string | null;
    bgColor: string | null;
    sectionPadded: any | null;
    fontFamily: string | null;
    fontSize: string | null;
    text: string | null;
    bgImage: { url: string; alt: string | null } | null;
    content: Array<
      | {
          id: any;
          _modelApiKey: string;
          text: string | null;
          variant: string | null;
          hasLightTheme: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            quote: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainDescription: string | null;
          firstTextFooter: string | null;
          secondTextFooter: string | null;
          text: string | null;
          title: string | null;
          description: string | null;
          thirdText: string | null;
          color: { hex: string } | null;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          contentMobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mainButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          couponCard: Array<{
            _modelApiKey: string;
            id: any;
            productName: string | null;
            discountCode: string | null;
            discountToApply: string | null;
            text: string | null;
            disclaimer: string | null;
            image: {
              id: any;
              alt: string | null;
              format: string;
              url: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasLargerCardWithoutBorder: any | null;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          subtitleFont: string | null;
          titleFont: string | null;
          hasDividers: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          title: string | null;
          _modelApiKey: string;
          hasBigText: any | null;
          bigText: string | null;
          bigTextPosition: string | null;
          titleDescription: string | null;
          leftBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rightBulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          descriptionContent: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          backgroundImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | { id: any; _modelApiKey: string }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          typeOfLinks: string | null;
          applyFilterGrayscaleToCardImage: any | null;
          title: string | null;
          linkCollection: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          quote: string | null;
          author: string | null;
          blockSpacing: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          reviewCollection: Array<{
            id: any;
            _modelApiKey: string;
            hasDefaultCardPerView: any | null;
            cardsColor: string | null;
            cardType: string | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            sixCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            threeCardPerViewContent: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          hasTestimonyWithImage: any | null;
          content: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          testimony: Array<{
            id: any;
            quote: string | null;
            author: string | null;
            numberStars: any | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          titleHtml: string | null;
          subtitlePosition: string | null;
          description: string | null;
          descriptionHtml: string | null;
          paragraph: string | null;
          hasButton: any | null;
          hasImages: any | null;
          hasAnImageAndText: any | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          button: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          externalButton: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            url: string | null;
          }>;
          images: Array<{
            id: any;
            title: string | null;
            description: string | null;
            image: {
              id: any;
              title: string | null;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
          background: { hex: string } | null;
          image: {
            url: string;
            alt: string | null;
            width: any | null;
            height: any | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            title: string | null;
            text: string | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          description: string | null;
          isMiniBanner: any | null;
          backgroundColor: { hex: string } | null;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          pageLink: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          imageMobile: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          content: Array<{
            id: any;
            embeddedVideo: {
              height: any;
              provider: string;
              providerUid: string;
              thumbnailUrl: string;
              title: string;
              url: string;
              width: any;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          title: string | null;
          logos: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
          galleryImages: Array<{
            id: any;
            title: string | null;
            shortDescription: string | null;
            url: string | null;
            isExternal: any | null;
            coverImage: {
              id: any;
              url: string;
              alt: string | null;
              format: string;
            } | null;
            internalLink:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            month: string | null;
            flowerList: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  weekNumber: any | null;
                  weekDate: string | null;
                  flower: {
                    id: any;
                    name: string | null;
                    hasBadge: any | null;
                    slug: string | null;
                    badge: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    primaryImage: {
                      url: string;
                      alt: string | null;
                      width: any | null;
                      height: any | null;
                      focalPoint: { x: any; y: any } | null;
                    } | null;
                    product: { title: string | null } | null;
                  } | null;
                }
            >;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          title: string | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          animatedText: Array<{ id: any; text: string | null }>;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          centered: any | null;
          centeredMobile: any | null;
          titlePadded: any | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitleAfter: any | null;
          text: string | null;
          textMobile: string | null;
          theme: string | null;
          titleLogoAfter: any | null;
          isBigVersionLogo: any | null;
          isTitleFontBig: any | null;
          contentWidth: string | null;
          titleSubtitleFontSize: string | null;
          titleFontFamily: string | null;
          subtitleFontFamily: string | null;
          hasLightTheme: any | null;
          hasBiggerButton: any | null;
          hasAButtonToScrollToAComponent: any | null;
          hideInMobileVersion: any | null;
          variantStyle: string | null;
          buttonTitle: string | null;
          scrollTo: string | null;
          isShortHeight: any | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          bgVideo: { id: any; url: string } | null;
          bgVideoPosterImage: { url: string; alt: string | null } | null;
          titleLogo: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          titleLogoMobile: {
            url: string;
            alt: string | null;
            format: string;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          titleFontFamily: string | null;
          subTitleHtml: string | null;
          subtitleAfter: any | null;
          subtitleFontFamily: string | null;
          contentPosition: string | null;
          theme: string | null;
          hasLightTheme: any | null;
          imagePosition: string | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          colorButton: { hex: string } | null;
          bgImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          products: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          blockSpacing: string | null;
          images: Array<{ url: string; alt: string | null }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          mainFeatureContainerContent: Array<{
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          fontFamily: string | null;
          title: string | null;
          isTitleH1: any | null;
          subtitle: string | null;
          subtitlePosition: string | null;
          hasPersonalizedDescription: any | null;
          hasDescriptionAsTable: any | null;
          hasBulletPoints: any | null;
          text: string | null;
          imagePosition: string | null;
          imageWidth: string | null;
          hasSlider: any | null;
          backgroundColor: { hex: string } | null;
          colorText: { hex: string } | null;
          bulletPoints: Array<{
            id: any;
            title: string | null;
            description: string | null;
          }>;
          rows: Array<{ id: any; text: string | null }>;
          image: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          product: {
            id: any;
            shopifyProduct: any | null;
            bouquetSize: string | null;
            prefixFrecuency: string | null;
            title: string | null;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              tagType: string | null;
              image: { url: string; alt: string | null } | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
          } | null;
          buttons: Array<
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                url: string | null;
              }
            | {
                id: any;
                _modelApiKey: string;
                variant: string | null;
                title: string | null;
                link:
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | { _modelApiKey: string; slug: string | null }
                  | null;
              }
          >;
          gallery: Array<{
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            _modelApiKey: string;
            plantList: Array<{
              id: any;
              name: string | null;
              subtitle: string | null;
              introText: string | null;
              endingText: string | null;
              detailText: string | null;
              hasBadge: any | null;
              slug: string | null;
              badge: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              primaryImage: {
                url: string;
                alt: string | null;
                width: any | null;
                height: any | null;
                focalPoint: { x: any; y: any } | null;
              } | null;
              product: {
                id: any;
                slug: string | null;
                title: string | null;
                description: string | null;
                gallery: Array<{ id: any; url: string; alt: string | null }>;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  isAFlowerPlantCenterCollection: any | null;
                }>;
              } | null;
            }>;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          collection: {
            id: any;
            hasSlider: any | null;
            showProductAs: string | null;
            slug: string | null;
            title: string | null;
            centered: any | null;
            titleSubtitleFontSize: string | null;
            hideTitle: any | null;
            isTitleH1: any | null;
            description: string | null;
            hasFilterComponent: any | null;
            hideBreadcrumb: any | null;
            products: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  slug: string | null;
                  sku: string | null;
                  title: string | null;
                  thirdParty: string | null;
                  isSubscription: any | null;
                  disableDatePicker: any | null;
                  bouquetSize: string | null;
                  shopifyProduct: any | null;
                  shopifySubscriptionProducts: any | null;
                  shortDescription: string | null;
                  cardType: string | null;
                  isSuperGift: any | null;
                  isGoogleProduct: any | null;
                  isFacebookProduct: any | null;
                  hideFromSearchEngines: any | null;
                  hasNotBreadcrumb: any | null;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    tagType: string | null;
                    image: { url: string; alt: string | null } | null;
                    backgroundColor: { hex: string } | null;
                    textColor: { hex: string } | null;
                  }>;
                  tagFilter: Array<{
                    id: any;
                    title: string | null;
                    tagMessage: string | null;
                    filterType: string | null;
                    minPrice: string | null;
                    maxPrice: string | null;
                    backgroundColor: { hex: string } | null;
                  }>;
                  gallery: Array<{
                    id: any;
                    format: string;
                    url: string;
                    alt: string | null;
                  }>;
                }
              | {
                  _modelApiKey: string;
                  shopifyProduct: any | null;
                  id: any;
                  image: {
                    url: string;
                    alt: string | null;
                    responsiveImage: {
                      srcSet: string;
                      webpSrcSet: string;
                      sizes: string;
                      src: string;
                      width: any;
                      height: any;
                      aspectRatio: any;
                      alt: string | null;
                      title: string | null;
                      base64: string | null;
                    } | null;
                  } | null;
                  pageLinkUrl: Array<{
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }>;
                }
            >;
            productTags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
              filterType: string | null;
              backgroundColor: { hex: string } | null;
              textColor: { hex: string } | null;
            }>;
            image: { url: string } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          firstColumn: string | null;
          secondColumn: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          mainTitle: string | null;
          mainSubtitle: string | null;
          subtitlePosition: string | null;
          fontSize: string | null;
          fontFamily: string | null;
          titleFirstCol: string | null;
          firstColumn: string | null;
          titleSecondCol: string | null;
          secondColumn: string | null;
          columnWidth: string | null;
          contentPosition: string | null;
          fontSizeFirstTitle: string | null;
          fontSizeSecondTitle: string | null;
          titleFontFamily: string | null;
          textFontFamily: string | null;
          bgImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          mobileImage: {
            id: any;
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          proseContent: string | null;
          largeTypography: any | null;
          showOnMobileVersion: any | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          subtitle: string | null;
          description: string | null;
          showSubtitleAfterTitle: any | null;
          content: Array<{
            id: any;
            title: string | null;
            backgroundColor: { hex: string } | null;
            image: {
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          embedHtml: string | null;
          formType: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          hideFaqPage: any | null;
          title: string | null;
          questionList: Array<{
            id: any;
            answer: string | null;
            question: string | null;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          title: string | null;
          hasslider: any | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productBestSeller: Array<{
            id: any;
            _modelApiKey: string;
            shopifyProduct: any | null;
            slug: string | null;
            title: string | null;
            shortDescription: string | null;
            description: string | null;
            bouquetSize: string | null;
            isSubscription: any | null;
            seoDescription: string | null;
            gallery: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
            }>;
            tags: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              tagMessage: string | null;
            }>;
          }>;
        }
      | {
          id: any;
          _modelApiKey: string;
          titleHtml: string | null;
          title: string | null;
          subtitle: string | null;
          subtitleHtml: string | null;
          showSideTextOnDesktop: any | null;
          bgColor: string | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
          productColletionLink: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            image: { url: string; alt: string | null } | null;
            landingPageLink: { slug: string | null } | null;
          }>;
        }
    >;
  }>;
  seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
};

export type ProductFieldsCartFragment = {
  id: any;
  slug: string | null;
  title: string | null;
  thirdParty: string | null;
  shopifyProduct: any | null;
  shopifySubscriptionProducts: any | null;
  isSubscription: any | null;
  isUpselling: any | null;
  hasDisabledNextDayDelivery: any | null;
  hasVariant: any | null;
  variantType: string | null;
  variants: Array<{
    id: any;
    title: string | null;
    value: string | null;
    headerText: string | null;
    additionalText: string | null;
    footerText: string | null;
    listUpsellingExcludePerVariant: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    color: { hex: string } | null;
    thumbnail: { url: string; alt: string | null } | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      mobileThumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
  }>;
  tags: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    tagMessage: string | null;
    tagType: string | null;
    image: { url: string; alt: string | null } | null;
    backgroundColor: { hex: string } | null;
    textColor: { hex: string } | null;
  }>;
  gallery: Array<{
    url: string;
    alt: string | null;
    thumbUrl: string;
    mobileThumbUrl: string;
    thumbResponsive: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
    mobileThumbResponsive: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  }>;
};

export type VariantFieldsFragment = {
  id: any;
  title: string | null;
  value: string | null;
  headerText: string | null;
  additionalText: string | null;
  footerText: string | null;
  listUpsellingExcludePerVariant: Array<{
    id: any;
    title: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
  }>;
  color: { hex: string } | null;
  thumbnail: { url: string; alt: string | null } | null;
  gallery: Array<{
    id: any;
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
    thumbResponsive: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
    mobileThumbResponsive: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  }>;
};

export type ProductFieldsShortCartFragment = {
  id: any;
  slug: string | null;
  title: string | null;
  thirdParty: string | null;
  shopifyProduct: any | null;
  shopifySubscriptionProducts: any | null;
  isSubscription: any | null;
  isUpselling: any | null;
  hasDisabledNextDayDelivery: any | null;
};

export type PromotionalCardFieldsFragment = {
  _modelApiKey: string;
  shopifyProduct: any | null;
  id: any;
  image: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  pageLinkUrl: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

export type GetAllProductsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllProductsCountQuery = { meta: { count: any } };

export type GetAllProductsSlugsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
}>;

export type GetAllProductsSlugsQuery = {
  records: Array<{ slug: string | null }>;
};

export type GetProductBySlugQueryVariables = Exact<{
  slug: InputMaybe<Scalars["String"]>;
}>;

export type GetProductBySlugQuery = {
  product: {
    id: any;
    sku: string | null;
    disableDatePicker: any | null;
    _modelApiKey: string;
    isTitleDescriptionFirst: any | null;
    slug: string | null;
    title: string | null;
    thirdParty: string | null;
    hasColumnLayout: any | null;
    shortDescription: string | null;
    isDescriptionAfterTitle: any | null;
    description: string | null;
    importantInfo: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    isSubscription: any | null;
    bouquetSize: string | null;
    isSuperGift: any | null;
    isGoogleProduct: any | null;
    isFacebookProduct: any | null;
    hideFromSearchEngines: any | null;
    hasNotBreadcrumb: any | null;
    hasVariant: any | null;
    variantType: string | null;
    prefixFrecuency: string | null;
    isUpselling: any | null;
    hasDisabledNextDayDelivery: any | null;
    noPdp: any | null;
    isAvailableForNextday: any | null;
    priorityUpselling: any | null;
    deliveryOption: string | null;
    pageHeaderType: string | null;
    showPageHeader: any | null;
    showPageFooter: any | null;
    priceRange: string | null;
    seoDescription: string | null;
    backgroundColor: { hex: string } | null;
    productDetailItems: Array<{
      id: any;
      title: string | null;
      description: string | null;
    }>;
    variants: Array<{
      id: any;
      title: string | null;
      value: string | null;
      headerText: string | null;
      additionalText: string | null;
      footerText: string | null;
      listUpsellingExcludePerVariant: Array<{
        id: any;
        title: string | null;
        shopifyProduct: any | null;
        shopifySubscriptionProducts: any | null;
      }>;
      color: { hex: string } | null;
      thumbnail: { url: string; alt: string | null } | null;
      gallery: Array<{
        id: any;
        format: string;
        url: string;
        alt: string | null;
        responsiveImage: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        thumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        mobileThumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
      }>;
    }>;
    excludeNextdayProducts: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    upsellingProducts: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    listUpsellingToExclude: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    tagFilter: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagType: string | null;
    }>;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
      thumbUrl: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
    sectionsBeforeSimilarProducts: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type GetProductsByIdsQueryVariables = Exact<{
  ItemIds: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type GetProductsByIdsQuery = {
  allProducts: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    thirdParty: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    isSubscription: any | null;
    isUpselling: any | null;
    hasDisabledNextDayDelivery: any | null;
    hasVariant: any | null;
    variantType: string | null;
    variants: Array<{
      id: any;
      title: string | null;
      value: string | null;
      headerText: string | null;
      additionalText: string | null;
      footerText: string | null;
      listUpsellingExcludePerVariant: Array<{
        id: any;
        title: string | null;
        shopifyProduct: any | null;
        shopifySubscriptionProducts: any | null;
      }>;
      color: { hex: string } | null;
      thumbnail: { url: string; alt: string | null } | null;
      gallery: Array<{
        id: any;
        format: string;
        url: string;
        alt: string | null;
        responsiveImage: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        thumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        mobileThumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
      }>;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    gallery: Array<{
      url: string;
      alt: string | null;
      thumbUrl: string;
      mobileThumbUrl: string;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      mobileThumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
  }>;
};

export type GetProductsByTagsQueryVariables = Exact<{
  tagIds: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type GetProductsByTagsQuery = {
  allProducts: Array<{
    id: any;
    sku: string | null;
    disableDatePicker: any | null;
    _modelApiKey: string;
    isTitleDescriptionFirst: any | null;
    slug: string | null;
    title: string | null;
    thirdParty: string | null;
    hasColumnLayout: any | null;
    shortDescription: string | null;
    isDescriptionAfterTitle: any | null;
    description: string | null;
    importantInfo: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    isSubscription: any | null;
    bouquetSize: string | null;
    isSuperGift: any | null;
    isGoogleProduct: any | null;
    isFacebookProduct: any | null;
    hideFromSearchEngines: any | null;
    hasNotBreadcrumb: any | null;
    hasVariant: any | null;
    variantType: string | null;
    prefixFrecuency: string | null;
    isUpselling: any | null;
    hasDisabledNextDayDelivery: any | null;
    noPdp: any | null;
    isAvailableForNextday: any | null;
    priorityUpselling: any | null;
    deliveryOption: string | null;
    pageHeaderType: string | null;
    showPageHeader: any | null;
    showPageFooter: any | null;
    priceRange: string | null;
    seoDescription: string | null;
    backgroundColor: { hex: string } | null;
    productDetailItems: Array<{
      id: any;
      title: string | null;
      description: string | null;
    }>;
    variants: Array<{
      id: any;
      title: string | null;
      value: string | null;
      headerText: string | null;
      additionalText: string | null;
      footerText: string | null;
      listUpsellingExcludePerVariant: Array<{
        id: any;
        title: string | null;
        shopifyProduct: any | null;
        shopifySubscriptionProducts: any | null;
      }>;
      color: { hex: string } | null;
      thumbnail: { url: string; alt: string | null } | null;
      gallery: Array<{
        id: any;
        format: string;
        url: string;
        alt: string | null;
        responsiveImage: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        thumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
        mobileThumbResponsive: {
          srcSet: string;
          webpSrcSet: string;
          sizes: string;
          src: string;
          width: any;
          height: any;
          aspectRatio: any;
          alt: string | null;
          title: string | null;
          base64: string | null;
        } | null;
      }>;
    }>;
    excludeNextdayProducts: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    upsellingProducts: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    listUpsellingToExclude: Array<{
      id: any;
      title: string | null;
      shopifyProduct: any | null;
      shopifySubscriptionProducts: any | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    tagFilter: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagType: string | null;
    }>;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
      thumbUrl: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
    sectionsBeforeSimilarProducts: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    sections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  }>;
};

export type GetTagBySlugQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetTagBySlugQuery = {
  productTag: {
    id: any;
    slug: string | null;
    title: string | null;
    tagMessage: string | null;
    backgroundColor: { hex: string } | null;
    textColor: { hex: string } | null;
  } | null;
};

export type GetAllProductsQueryVariables = Exact<{
  perPage: InputMaybe<Scalars["IntType"]>;
  skip: InputMaybe<Scalars["IntType"]>;
  isSubscription: InputMaybe<Scalars["BooleanType"]>;
}>;

export type GetAllProductsQuery = {
  allProducts: Array<{
    id: any;
    _modelApiKey: string;
    slug: string | null;
    sku: string | null;
    title: string | null;
    thirdParty: string | null;
    isSubscription: any | null;
    disableDatePicker: any | null;
    bouquetSize: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    shortDescription: string | null;
    cardType: string | null;
    isSuperGift: any | null;
    isGoogleProduct: any | null;
    isFacebookProduct: any | null;
    hideFromSearchEngines: any | null;
    hasNotBreadcrumb: any | null;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    tagFilter: Array<{
      id: any;
      title: string | null;
      tagMessage: string | null;
      filterType: string | null;
      minPrice: string | null;
      maxPrice: string | null;
      backgroundColor: { hex: string } | null;
    }>;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
    }>;
  }>;
};

export type GetCollectionByTagsQueryVariables = Exact<{
  tagIds: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type GetCollectionByTagsQuery = {
  allProductCollections: Array<{
    id: any;
    title: string | null;
    productTags: Array<{ id: any }>;
  }>;
};

export type GetProductByCollectionQueryVariables = Exact<{
  tagIds: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type GetProductByCollectionQuery = {
  allProductCollections: Array<{ id: any; title: string | null }>;
};

export type SubscriptionPlanFragment = {
  id: any;
  title: string | null;
  isTitleH1: any | null;
  subtitle: string | null;
  text: string | null;
  bgColor: string | null;
  textColor: string | null;
  products: Array<{
    id: any;
    _modelApiKey: string;
    slug: string | null;
    sku: string | null;
    title: string | null;
    thirdParty: string | null;
    isSubscription: any | null;
    disableDatePicker: any | null;
    bouquetSize: string | null;
    shopifyProduct: any | null;
    shopifySubscriptionProducts: any | null;
    shortDescription: string | null;
    cardType: string | null;
    isSuperGift: any | null;
    isGoogleProduct: any | null;
    isFacebookProduct: any | null;
    hideFromSearchEngines: any | null;
    hasNotBreadcrumb: any | null;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    tagFilter: Array<{
      id: any;
      title: string | null;
      tagMessage: string | null;
      filterType: string | null;
      minPrice: string | null;
      maxPrice: string | null;
      backgroundColor: { hex: string } | null;
    }>;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
    }>;
  }>;
};

export type GetSubscribePageQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubscribePageQuery = {
  subscribePage: {
    title: string | null;
    subtitle: string | null;
    text: string | null;
    slug: string | null;
    subscribeContent: Array<{
      id: any;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      text: string | null;
      bgColor: string | null;
      textColor: string | null;
      products: Array<{
        id: any;
        _modelApiKey: string;
        slug: string | null;
        sku: string | null;
        title: string | null;
        thirdParty: string | null;
        isSubscription: any | null;
        disableDatePicker: any | null;
        bouquetSize: string | null;
        shopifyProduct: any | null;
        shopifySubscriptionProducts: any | null;
        shortDescription: string | null;
        cardType: string | null;
        isSuperGift: any | null;
        isGoogleProduct: any | null;
        isFacebookProduct: any | null;
        hideFromSearchEngines: any | null;
        hasNotBreadcrumb: any | null;
        tags: Array<{
          id: any;
          slug: string | null;
          title: string | null;
          tagMessage: string | null;
          tagType: string | null;
          image: { url: string; alt: string | null } | null;
          backgroundColor: { hex: string } | null;
          textColor: { hex: string } | null;
        }>;
        tagFilter: Array<{
          id: any;
          title: string | null;
          tagMessage: string | null;
          filterType: string | null;
          minPrice: string | null;
          maxPrice: string | null;
          backgroundColor: { hex: string } | null;
        }>;
        gallery: Array<{
          id: any;
          format: string;
          url: string;
          alt: string | null;
        }>;
      }>;
    }>;
    contentSections: Array<{
      id: any;
      _modelApiKey: string;
      title: string | null;
      isTitleH1: any | null;
      subtitle: string | null;
      subtitlePos: string | null;
      bgColor: string | null;
      sectionPadded: any | null;
      fontFamily: string | null;
      fontSize: string | null;
      text: string | null;
      bgImage: { url: string; alt: string | null } | null;
      content: Array<
        | {
            id: any;
            _modelApiKey: string;
            text: string | null;
            variant: string | null;
            hasLightTheme: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              quote: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainDescription: string | null;
            firstTextFooter: string | null;
            secondTextFooter: string | null;
            text: string | null;
            title: string | null;
            description: string | null;
            thirdText: string | null;
            color: { hex: string } | null;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            contentMobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mainButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            couponCard: Array<{
              _modelApiKey: string;
              id: any;
              productName: string | null;
              discountCode: string | null;
              discountToApply: string | null;
              text: string | null;
              disclaimer: string | null;
              image: {
                id: any;
                alt: string | null;
                format: string;
                url: string;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasLargerCardWithoutBorder: any | null;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            subtitleFont: string | null;
            titleFont: string | null;
            hasDividers: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            title: string | null;
            _modelApiKey: string;
            hasBigText: any | null;
            bigText: string | null;
            bigTextPosition: string | null;
            titleDescription: string | null;
            leftBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rightBulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            descriptionContent: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            backgroundImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | { id: any; _modelApiKey: string }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            typeOfLinks: string | null;
            applyFilterGrayscaleToCardImage: any | null;
            title: string | null;
            linkCollection: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            quote: string | null;
            author: string | null;
            blockSpacing: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            reviewCollection: Array<{
              id: any;
              _modelApiKey: string;
              hasDefaultCardPerView: any | null;
              cardsColor: string | null;
              cardType: string | null;
              content: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              sixCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
              threeCardPerViewContent: Array<{
                id: any;
                quote: string | null;
                author: string | null;
                numberStars: any | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            hasTestimonyWithImage: any | null;
            content: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
            image: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            testimony: Array<{
              id: any;
              quote: string | null;
              author: string | null;
              numberStars: any | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            titleHtml: string | null;
            subtitlePosition: string | null;
            description: string | null;
            descriptionHtml: string | null;
            paragraph: string | null;
            hasButton: any | null;
            hasImages: any | null;
            hasAnImageAndText: any | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            button: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            externalButton: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              url: string | null;
            }>;
            images: Array<{
              id: any;
              title: string | null;
              description: string | null;
              image: {
                id: any;
                title: string | null;
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
            background: { hex: string } | null;
            image: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              title: string | null;
              text: string | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            description: string | null;
            isMiniBanner: any | null;
            backgroundColor: { hex: string } | null;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            pageLink: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            imageMobile: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            content: Array<{
              id: any;
              embeddedVideo: {
                height: any;
                provider: string;
                providerUid: string;
                thumbnailUrl: string;
                title: string;
                url: string;
                width: any;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            title: string | null;
            logos: Array<{
              id: any;
              format: string;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
            galleryImages: Array<{
              id: any;
              title: string | null;
              shortDescription: string | null;
              url: string | null;
              isExternal: any | null;
              coverImage: {
                id: any;
                url: string;
                alt: string | null;
                format: string;
              } | null;
              internalLink:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              month: string | null;
              flowerList: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    weekNumber: any | null;
                    weekDate: string | null;
                    flower: {
                      id: any;
                      name: string | null;
                      hasBadge: any | null;
                      slug: string | null;
                      badge: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      primaryImage: {
                        url: string;
                        alt: string | null;
                        width: any | null;
                        height: any | null;
                        focalPoint: { x: any; y: any } | null;
                      } | null;
                      product: { title: string | null } | null;
                    } | null;
                  }
              >;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            title: string | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            animatedText: Array<{ id: any; text: string | null }>;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            centered: any | null;
            centeredMobile: any | null;
            titlePadded: any | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitleAfter: any | null;
            text: string | null;
            textMobile: string | null;
            theme: string | null;
            titleLogoAfter: any | null;
            isBigVersionLogo: any | null;
            isTitleFontBig: any | null;
            contentWidth: string | null;
            titleSubtitleFontSize: string | null;
            titleFontFamily: string | null;
            subtitleFontFamily: string | null;
            hasLightTheme: any | null;
            hasBiggerButton: any | null;
            hasAButtonToScrollToAComponent: any | null;
            hideInMobileVersion: any | null;
            variantStyle: string | null;
            buttonTitle: string | null;
            scrollTo: string | null;
            isShortHeight: any | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            bgVideo: { id: any; url: string } | null;
            bgVideoPosterImage: { url: string; alt: string | null } | null;
            titleLogo: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            titleLogoMobile: {
              url: string;
              alt: string | null;
              format: string;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            titleFontFamily: string | null;
            subTitleHtml: string | null;
            subtitleAfter: any | null;
            subtitleFontFamily: string | null;
            contentPosition: string | null;
            theme: string | null;
            hasLightTheme: any | null;
            imagePosition: string | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            colorButton: { hex: string } | null;
            bgImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            products: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            blockSpacing: string | null;
            images: Array<{ url: string; alt: string | null }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            mainFeatureContainerContent: Array<{
              id: any;
              _modelApiKey: string;
              fontFamily: string | null;
              title: string | null;
              isTitleH1: any | null;
              subtitle: string | null;
              subtitlePosition: string | null;
              hasPersonalizedDescription: any | null;
              hasDescriptionAsTable: any | null;
              hasBulletPoints: any | null;
              text: string | null;
              imagePosition: string | null;
              imageWidth: string | null;
              hasSlider: any | null;
              backgroundColor: { hex: string } | null;
              colorText: { hex: string } | null;
              bulletPoints: Array<{
                id: any;
                title: string | null;
                description: string | null;
              }>;
              rows: Array<{ id: any; text: string | null }>;
              image: {
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
              product: {
                id: any;
                shopifyProduct: any | null;
                bouquetSize: string | null;
                prefixFrecuency: string | null;
                title: string | null;
                tags: Array<{
                  id: any;
                  slug: string | null;
                  title: string | null;
                  tagMessage: string | null;
                  tagType: string | null;
                  image: { url: string; alt: string | null } | null;
                  backgroundColor: { hex: string } | null;
                  textColor: { hex: string } | null;
                }>;
              } | null;
              buttons: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    url: string | null;
                  }
                | {
                    id: any;
                    _modelApiKey: string;
                    variant: string | null;
                    title: string | null;
                    link:
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | { _modelApiKey: string; slug: string | null }
                      | null;
                  }
              >;
              gallery: Array<{
                id: any;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            fontFamily: string | null;
            title: string | null;
            isTitleH1: any | null;
            subtitle: string | null;
            subtitlePosition: string | null;
            hasPersonalizedDescription: any | null;
            hasDescriptionAsTable: any | null;
            hasBulletPoints: any | null;
            text: string | null;
            imagePosition: string | null;
            imageWidth: string | null;
            hasSlider: any | null;
            backgroundColor: { hex: string } | null;
            colorText: { hex: string } | null;
            bulletPoints: Array<{
              id: any;
              title: string | null;
              description: string | null;
            }>;
            rows: Array<{ id: any; text: string | null }>;
            image: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            product: {
              id: any;
              shopifyProduct: any | null;
              bouquetSize: string | null;
              prefixFrecuency: string | null;
              title: string | null;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                tagType: string | null;
                image: { url: string; alt: string | null } | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
            } | null;
            buttons: Array<
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  url: string | null;
                }
              | {
                  id: any;
                  _modelApiKey: string;
                  variant: string | null;
                  title: string | null;
                  link:
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | { _modelApiKey: string; slug: string | null }
                    | null;
                }
            >;
            gallery: Array<{
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              _modelApiKey: string;
              plantList: Array<{
                id: any;
                name: string | null;
                subtitle: string | null;
                introText: string | null;
                endingText: string | null;
                detailText: string | null;
                hasBadge: any | null;
                slug: string | null;
                badge: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                primaryImage: {
                  url: string;
                  alt: string | null;
                  width: any | null;
                  height: any | null;
                  focalPoint: { x: any; y: any } | null;
                } | null;
                product: {
                  id: any;
                  slug: string | null;
                  title: string | null;
                  description: string | null;
                  gallery: Array<{ id: any; url: string; alt: string | null }>;
                  tags: Array<{
                    id: any;
                    slug: string | null;
                    title: string | null;
                    tagMessage: string | null;
                    isAFlowerPlantCenterCollection: any | null;
                  }>;
                } | null;
              }>;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            collection: {
              id: any;
              hasSlider: any | null;
              showProductAs: string | null;
              slug: string | null;
              title: string | null;
              centered: any | null;
              titleSubtitleFontSize: string | null;
              hideTitle: any | null;
              isTitleH1: any | null;
              description: string | null;
              hasFilterComponent: any | null;
              hideBreadcrumb: any | null;
              products: Array<
                | {
                    id: any;
                    _modelApiKey: string;
                    slug: string | null;
                    sku: string | null;
                    title: string | null;
                    thirdParty: string | null;
                    isSubscription: any | null;
                    disableDatePicker: any | null;
                    bouquetSize: string | null;
                    shopifyProduct: any | null;
                    shopifySubscriptionProducts: any | null;
                    shortDescription: string | null;
                    cardType: string | null;
                    isSuperGift: any | null;
                    isGoogleProduct: any | null;
                    isFacebookProduct: any | null;
                    hideFromSearchEngines: any | null;
                    hasNotBreadcrumb: any | null;
                    tags: Array<{
                      id: any;
                      slug: string | null;
                      title: string | null;
                      tagMessage: string | null;
                      tagType: string | null;
                      image: { url: string; alt: string | null } | null;
                      backgroundColor: { hex: string } | null;
                      textColor: { hex: string } | null;
                    }>;
                    tagFilter: Array<{
                      id: any;
                      title: string | null;
                      tagMessage: string | null;
                      filterType: string | null;
                      minPrice: string | null;
                      maxPrice: string | null;
                      backgroundColor: { hex: string } | null;
                    }>;
                    gallery: Array<{
                      id: any;
                      format: string;
                      url: string;
                      alt: string | null;
                    }>;
                  }
                | {
                    _modelApiKey: string;
                    shopifyProduct: any | null;
                    id: any;
                    image: {
                      url: string;
                      alt: string | null;
                      responsiveImage: {
                        srcSet: string;
                        webpSrcSet: string;
                        sizes: string;
                        src: string;
                        width: any;
                        height: any;
                        aspectRatio: any;
                        alt: string | null;
                        title: string | null;
                        base64: string | null;
                      } | null;
                    } | null;
                    pageLinkUrl: Array<{
                      id: any;
                      _modelApiKey: string;
                      variant: string | null;
                      title: string | null;
                      link:
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | { _modelApiKey: string; slug: string | null }
                        | null;
                    }>;
                  }
              >;
              productTags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
                filterType: string | null;
                backgroundColor: { hex: string } | null;
                textColor: { hex: string } | null;
              }>;
              image: { url: string } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            firstColumn: string | null;
            secondColumn: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            mainTitle: string | null;
            mainSubtitle: string | null;
            subtitlePosition: string | null;
            fontSize: string | null;
            fontFamily: string | null;
            titleFirstCol: string | null;
            firstColumn: string | null;
            titleSecondCol: string | null;
            secondColumn: string | null;
            columnWidth: string | null;
            contentPosition: string | null;
            fontSizeFirstTitle: string | null;
            fontSizeSecondTitle: string | null;
            titleFontFamily: string | null;
            textFontFamily: string | null;
            bgImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
            mobileImage: {
              id: any;
              url: string;
              alt: string | null;
              responsiveImage: {
                srcSet: string;
                webpSrcSet: string;
                sizes: string;
                src: string;
                width: any;
                height: any;
                aspectRatio: any;
                alt: string | null;
                title: string | null;
                base64: string | null;
              } | null;
            } | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            proseContent: string | null;
            largeTypography: any | null;
            showOnMobileVersion: any | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            subtitle: string | null;
            description: string | null;
            showSubtitleAfterTitle: any | null;
            content: Array<{
              id: any;
              title: string | null;
              backgroundColor: { hex: string } | null;
              image: {
                format: string;
                url: string;
                alt: string | null;
                responsiveImage: {
                  srcSet: string;
                  webpSrcSet: string;
                  sizes: string;
                  src: string;
                  width: any;
                  height: any;
                  aspectRatio: any;
                  alt: string | null;
                  title: string | null;
                  base64: string | null;
                } | null;
              } | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            embedHtml: string | null;
            formType: string | null;
          }
        | {
            id: any;
            _modelApiKey: string;
            hideFaqPage: any | null;
            title: string | null;
            questionList: Array<{
              id: any;
              answer: string | null;
              question: string | null;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            title: string | null;
            hasslider: any | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productBestSeller: Array<{
              id: any;
              _modelApiKey: string;
              shopifyProduct: any | null;
              slug: string | null;
              title: string | null;
              shortDescription: string | null;
              description: string | null;
              bouquetSize: string | null;
              isSubscription: any | null;
              seoDescription: string | null;
              gallery: Array<{
                id: any;
                format: string;
                url: string;
                alt: string | null;
              }>;
              tags: Array<{
                id: any;
                slug: string | null;
                title: string | null;
                tagMessage: string | null;
              }>;
            }>;
          }
        | {
            id: any;
            _modelApiKey: string;
            titleHtml: string | null;
            title: string | null;
            subtitle: string | null;
            subtitleHtml: string | null;
            showSideTextOnDesktop: any | null;
            bgColor: string | null;
            pageLinkUrl: Array<{
              id: any;
              _modelApiKey: string;
              variant: string | null;
              title: string | null;
              link:
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | { _modelApiKey: string; slug: string | null }
                | null;
            }>;
            productColletionLink: Array<{
              id: any;
              slug: string | null;
              title: string | null;
              image: { url: string; alt: string | null } | null;
              landingPageLink: { slug: string | null } | null;
            }>;
          }
      >;
    }>;
    seo: Array<{ attributes: any | null; content: string | null; tag: string }>;
  } | null;
};

export type MetaTagsFragment = {
  attributes: any | null;
  content: string | null;
  tag: string;
};

export type ResponsiveImageFragment = {
  srcSet: string;
  webpSrcSet: string;
  sizes: string;
  src: string;
  width: any;
  height: any;
  aspectRatio: any;
  alt: string | null;
  title: string | null;
  base64: string | null;
};

export type PageRecordSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type NestedPageRecordSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type ProductRecordSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type SubscribePageSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type FlowerShopPageSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type PlantShopPageSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type FlowerPageSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type BlogPostSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type NestedLandigPageSlugFragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type ExternalLinksFragment = {
  id: any;
  _modelApiKey: string;
  variant: string | null;
  title: string | null;
  url: string | null;
};

export type InternalLinksFragment = {
  id: any;
  _modelApiKey: string;
  variant: string | null;
  title: string | null;
  link:
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | null;
};

type InternalMenuLinks_BlogPostRecord_Fragment = {
  _modelApiKey: string;
  slug: string | null;
};

type InternalMenuLinks_FlowerShopPageRecord_Fragment = {
  _modelApiKey: string;
  slug: string | null;
};

type InternalMenuLinks_NestedPageRecord_Fragment = {
  _modelApiKey: string;
  slug: string | null;
};

type InternalMenuLinks_PageRecord_Fragment = {
  _modelApiKey: string;
  slug: string | null;
};

type InternalMenuLinks_PlantShopPageRecord_Fragment = {
  _modelApiKey: string;
  slug: string | null;
};

type InternalMenuLinks_ProductRecord_Fragment = {
  _modelApiKey: string;
  slug: string | null;
};

type InternalMenuLinks_SubscribePageRecord_Fragment = {
  _modelApiKey: string;
  slug: string | null;
};

export type InternalMenuLinksFragment =
  | InternalMenuLinks_BlogPostRecord_Fragment
  | InternalMenuLinks_FlowerShopPageRecord_Fragment
  | InternalMenuLinks_NestedPageRecord_Fragment
  | InternalMenuLinks_PageRecord_Fragment
  | InternalMenuLinks_PlantShopPageRecord_Fragment
  | InternalMenuLinks_ProductRecord_Fragment
  | InternalMenuLinks_SubscribePageRecord_Fragment;

export type MenuLinksFragment = {
  id: any;
  title: string | null;
  shortDescription: string | null;
  url: string | null;
  isExternal: any | null;
  coverImage: {
    id: any;
    url: string;
    alt: string | null;
    format: string;
  } | null;
  internalLink:
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | null;
};

export type MenuItemsFragment = {
  id: any;
  title: string | null;
  url: string | null;
  isExternal: any | null;
  internalLink:
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | null;
  subMenuItem: Array<{
    id: any;
    item: string | null;
    _modelApiKey: string;
    leftTitle: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
    middleSubMenuItem: Array<{
      id: any;
      title: string | null;
      item: Array<{
        id: any;
        title: string | null;
        shortDescription: string | null;
        url: string | null;
        isExternal: any | null;
        coverImage: {
          id: any;
          url: string;
          alt: string | null;
          format: string;
        } | null;
        internalLink:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }>;
    }>;
    rightImage: {
      id: any;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
    rightLink: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
  }>;
};

export type SubMenuSettingFragment = {
  id: any;
  item: string | null;
  _modelApiKey: string;
  leftTitle: Array<{
    id: any;
    title: string | null;
    shortDescription: string | null;
    url: string | null;
    isExternal: any | null;
    coverImage: {
      id: any;
      url: string;
      alt: string | null;
      format: string;
    } | null;
    internalLink:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  middleSubMenuItem: Array<{
    id: any;
    title: string | null;
    item: Array<{
      id: any;
      title: string | null;
      shortDescription: string | null;
      url: string | null;
      isExternal: any | null;
      coverImage: {
        id: any;
        url: string;
        alt: string | null;
        format: string;
      } | null;
      internalLink:
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | { _modelApiKey: string; slug: string | null }
        | null;
    }>;
  }>;
  rightImage: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  rightLink: Array<{
    id: any;
    title: string | null;
    shortDescription: string | null;
    url: string | null;
    isExternal: any | null;
    coverImage: {
      id: any;
      url: string;
      alt: string | null;
      format: string;
    } | null;
    internalLink:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

export type BlogCategoryFieldsFragment = {
  id: any;
  _modelApiKey: string;
  description: string | null;
  visibleInBlogPage: any | null;
  slug: string | null;
};

export type BlockCtaFieldsFragment = {
  id: any;
  _modelApiKey: string;
  text: string | null;
  variant: string | null;
  hasLightTheme: any | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  colorButton: { hex: string } | null;
};

export type EmbeddedFormFieldsFragment = {
  id: any;
  _modelApiKey: string;
  embedHtml: string | null;
  formType: string | null;
};

export type BlockFeatTestimonialsCompaniesSliderFieldsFragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    quote: string | null;
    image: {
      id: any;
      alt: string | null;
      format: string;
      url: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type FeatureTestimonialCompaniesContentFragment = {
  id: any;
  quote: string | null;
  image: {
    id: any;
    alt: string | null;
    format: string;
    url: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockFeatureContentOverlayFieldsFragment = {
  id: any;
  _modelApiKey: string;
  mainTitle: string | null;
  mainDescription: string | null;
  firstTextFooter: string | null;
  secondTextFooter: string | null;
  text: string | null;
  title: string | null;
  description: string | null;
  thirdText: string | null;
  color: { hex: string } | null;
  backgroundImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  image: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  contentMobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mainButton: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  button: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

export type BlockFeatureDiscountCardFieldsFragment = {
  id: any;
  _modelApiKey: string;
  couponCard: Array<{
    _modelApiKey: string;
    id: any;
    productName: string | null;
    discountCode: string | null;
    discountToApply: string | null;
    text: string | null;
    disclaimer: string | null;
    image: {
      id: any;
      alt: string | null;
      format: string;
      url: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type DiscountCardFieldsFragment = {
  _modelApiKey: string;
  id: any;
  productName: string | null;
  discountCode: string | null;
  discountToApply: string | null;
  text: string | null;
  disclaimer: string | null;
  image: {
    id: any;
    alt: string | null;
    format: string;
    url: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockFeatureImageCardFieldsFragment = {
  id: any;
  _modelApiKey: string;
  hasLargerCardWithoutBorder: any | null;
  content: Array<{
    id: any;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type FeatureImageCardContentFragment = {
  id: any;
  title: string | null;
  link:
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | null;
  image: {
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockFeatureImageFullFieldsFragment = {
  id: any;
  _modelApiKey: string;
  subtitleFont: string | null;
  titleFont: string | null;
  hasDividers: any | null;
  pageLinkUrl: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  content: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type FeatureImageFullContentFragment = {
  id: any;
  title: string | null;
  text: string | null;
  image: {
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockFeatureImageTextFieldsFragment = {
  id: any;
  title: string | null;
  _modelApiKey: string;
  hasBigText: any | null;
  bigText: string | null;
  bigTextPosition: string | null;
  titleDescription: string | null;
  leftBulletPoints: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  rightBulletPoints: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  descriptionContent: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  backgroundImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type DescriptionTextFragment = {
  id: any;
  title: string | null;
  description: string | null;
};

export type BlockFeatureImageTitleFieldsFragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    title: string | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type FeatureImageTitleContentFragment = {
  id: any;
  title: string | null;
  image: {
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockInstagramFullFieldsFragment = {
  id: any;
  _modelApiKey: string;
};

export type BlockInstagramProductFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  subtitle: string | null;
};

export type BlockInstagramSliderFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  subtitle: string | null;
};

export type BlockFeatureLinkCollectionFieldsFragment = {
  id: any;
  _modelApiKey: string;
  typeOfLinks: string | null;
  applyFilterGrayscaleToCardImage: any | null;
  title: string | null;
  linkCollection: Array<{
    id: any;
    title: string | null;
    shortDescription: string | null;
    url: string | null;
    isExternal: any | null;
    coverImage: {
      id: any;
      url: string;
      alt: string | null;
      format: string;
    } | null;
    internalLink:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

export type BlockFeatureQuoteFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  quote: string | null;
  author: string | null;
  blockSpacing: string | null;
};

export type BlockFeatureReviewCollectionFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  titleHtml: string | null;
  subtitle: string | null;
  subtitleHtml: string | null;
  reviewCollection: Array<{
    id: any;
    _modelApiKey: string;
    hasDefaultCardPerView: any | null;
    cardsColor: string | null;
    cardType: string | null;
    content: Array<{
      id: any;
      quote: string | null;
      author: string | null;
      numberStars: any | null;
    }>;
    sixCardPerViewContent: Array<{
      id: any;
      quote: string | null;
      author: string | null;
      numberStars: any | null;
    }>;
    threeCardPerViewContent: Array<{
      id: any;
      quote: string | null;
      author: string | null;
      numberStars: any | null;
    }>;
  }>;
};

export type ReviewFragment = {
  id: any;
  _modelApiKey: string;
  hasDefaultCardPerView: any | null;
  cardsColor: string | null;
  cardType: string | null;
  content: Array<{
    id: any;
    quote: string | null;
    author: string | null;
    numberStars: any | null;
  }>;
  sixCardPerViewContent: Array<{
    id: any;
    quote: string | null;
    author: string | null;
    numberStars: any | null;
  }>;
  threeCardPerViewContent: Array<{
    id: any;
    quote: string | null;
    author: string | null;
    numberStars: any | null;
  }>;
};

export type FeatureTestimonialContentFragment = {
  id: any;
  quote: string | null;
  author: string | null;
  numberStars: any | null;
};

export type BlockFeatureRowImageCardFieldsFragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type FeatureImageCardOptionalContentFragment = {
  id: any;
  title: string | null;
  link:
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | null;
  image: {
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockFeatureTestimonialFieldsFragment = {
  id: any;
  _modelApiKey: string;
  hasTestimonyWithImage: any | null;
  content: Array<{
    id: any;
    quote: string | null;
    author: string | null;
    numberStars: any | null;
  }>;
  image: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  testimony: Array<{
    id: any;
    quote: string | null;
    author: string | null;
    numberStars: any | null;
  }>;
};

export type BlockFeatureTextIconFieldsFragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    title: string | null;
    text: string | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type BlockTextImageButtonFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  titleHtml: string | null;
  subtitlePosition: string | null;
  description: string | null;
  descriptionHtml: string | null;
  paragraph: string | null;
  hasButton: any | null;
  hasImages: any | null;
  hasAnImageAndText: any | null;
  titleLogo: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  button: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  externalButton: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    url: string | null;
  }>;
  images: Array<{
    id: any;
    title: string | null;
    description: string | null;
    image: {
      id: any;
      title: string | null;
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
  background: { hex: string } | null;
  image: {
    url: string;
    alt: string | null;
    width: any | null;
    height: any | null;
  } | null;
};

export type ImageFieldsFragment = {
  image: {
    id: any;
    title: string | null;
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type EmbeddedVideoFieldsFragment = {
  content: Array<{
    id: any;
    embeddedVideo: {
      height: any;
      provider: string;
      providerUid: string;
      thumbnailUrl: string;
      title: string;
      url: string;
      width: any;
    } | null;
  }>;
};

export type BlockFeatureVideoFieldsFragment = {
  id: any;
  _modelApiKey: string;
  content: Array<{
    id: any;
    embeddedVideo: {
      height: any;
      provider: string;
      providerUid: string;
      thumbnailUrl: string;
      title: string;
      url: string;
      width: any;
    } | null;
  }>;
};

export type BlockFeaturedInFieldsFragment = {
  id: any;
  _modelApiKey: string;
  blockSpacing: string | null;
  title: string | null;
  logos: Array<{
    id: any;
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  }>;
  galleryImages: Array<{
    id: any;
    title: string | null;
    shortDescription: string | null;
    url: string | null;
    isExternal: any | null;
    coverImage: {
      id: any;
      url: string;
      alt: string | null;
      format: string;
    } | null;
    internalLink:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
};

export type BlockFlowerItemFieldsFragment = {
  id: any;
  name: string | null;
  hasBadge: any | null;
  slug: string | null;
  badge: {
    url: string;
    alt: string | null;
    width: any | null;
    height: any | null;
    focalPoint: { x: any; y: any } | null;
  } | null;
  primaryImage: {
    url: string;
    alt: string | null;
    width: any | null;
    height: any | null;
    focalPoint: { x: any; y: any } | null;
  } | null;
  product: { title: string | null } | null;
};

export type BlockFlowerItemWeeklyFieldsFragment = {
  id: any;
  _modelApiKey: string;
  weekNumber: any | null;
  weekDate: string | null;
  flower: {
    id: any;
    name: string | null;
    hasBadge: any | null;
    slug: string | null;
    badge: {
      url: string;
      alt: string | null;
      width: any | null;
      height: any | null;
      focalPoint: { x: any; y: any } | null;
    } | null;
    primaryImage: {
      url: string;
      alt: string | null;
      width: any | null;
      height: any | null;
      focalPoint: { x: any; y: any } | null;
    } | null;
    product: { title: string | null } | null;
  } | null;
};

export type BlockFlowerItemMonthlyFieldsFragment = {
  id: any;
  _modelApiKey: string;
  flower: {
    id: any;
    name: string | null;
    hasBadge: any | null;
    slug: string | null;
    badge: {
      url: string;
      alt: string | null;
      width: any | null;
      height: any | null;
      focalPoint: { x: any; y: any } | null;
    } | null;
    primaryImage: {
      url: string;
      alt: string | null;
      width: any | null;
      height: any | null;
      focalPoint: { x: any; y: any } | null;
    } | null;
    product: { title: string | null } | null;
  } | null;
};

export type BlockFlowerCollectionFieldsFragment = {
  id: any;
  _modelApiKey: string;
  collection: {
    id: any;
    _modelApiKey: string;
    month: string | null;
    flowerList: Array<
      | {
          id: any;
          _modelApiKey: string;
          flower: {
            id: any;
            name: string | null;
            hasBadge: any | null;
            slug: string | null;
            badge: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            primaryImage: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            product: { title: string | null } | null;
          } | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          weekNumber: any | null;
          weekDate: string | null;
          flower: {
            id: any;
            name: string | null;
            hasBadge: any | null;
            slug: string | null;
            badge: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            primaryImage: {
              url: string;
              alt: string | null;
              width: any | null;
              height: any | null;
              focalPoint: { x: any; y: any } | null;
            } | null;
            product: { title: string | null } | null;
          } | null;
        }
    >;
  } | null;
};

export type BlockHeroFieldsFragment = {
  id: any;
  _modelApiKey: string;
  centered: any | null;
  centeredMobile: any | null;
  titlePadded: any | null;
  title: string | null;
  isTitleH1: any | null;
  subtitle: string | null;
  subtitleAfter: any | null;
  text: string | null;
  textMobile: string | null;
  theme: string | null;
  titleLogoAfter: any | null;
  isBigVersionLogo: any | null;
  isTitleFontBig: any | null;
  contentWidth: string | null;
  titleSubtitleFontSize: string | null;
  titleFontFamily: string | null;
  subtitleFontFamily: string | null;
  hasLightTheme: any | null;
  hasBiggerButton: any | null;
  hasAButtonToScrollToAComponent: any | null;
  hideInMobileVersion: any | null;
  variantStyle: string | null;
  buttonTitle: string | null;
  scrollTo: string | null;
  isShortHeight: any | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  colorButton: { hex: string } | null;
  bgImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  bgVideo: { id: any; url: string } | null;
  bgVideoPosterImage: { url: string; alt: string | null } | null;
  titleLogo: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  titleLogoMobile: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

type BlockHeroButtons_ExternalLinkRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  variant: string | null;
  title: string | null;
  url: string | null;
};

type BlockHeroButtons_InternalLinkRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  variant: string | null;
  title: string | null;
  link:
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | null;
};

export type BlockHeroButtonsFragment =
  | BlockHeroButtons_ExternalLinkRecord_Fragment
  | BlockHeroButtons_InternalLinkRecord_Fragment;

export type BlockHeroAnimatedTextFieldsFragment = {
  id: any;
  _modelApiKey: string;
  centered: any | null;
  title: string | null;
  subtitle: string | null;
  subtitleAfter: any | null;
  text: string | null;
  theme: string | null;
  titleLogoAfter: any | null;
  contentWidth: string | null;
  titleSubtitleFontSize: string | null;
  titleFontFamily: string | null;
  animatedText: Array<{ id: any; text: string | null }>;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  bgImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  titleLogo: {
    url: string;
    alt: string | null;
    format: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockHerobanner2columnFieldsFragment = {
  id: any;
  _modelApiKey: string;
  titleHtml: string | null;
  titleFontFamily: string | null;
  subTitleHtml: string | null;
  subtitleAfter: any | null;
  subtitleFontFamily: string | null;
  contentPosition: string | null;
  theme: string | null;
  hasLightTheme: any | null;
  imagePosition: string | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  colorButton: { hex: string } | null;
  bgImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  products: Array<{
    id: any;
    _modelApiKey: string;
    shopifyProduct: any | null;
    slug: string | null;
    title: string | null;
    shortDescription: string | null;
    description: string | null;
    bouquetSize: string | null;
    isSubscription: any | null;
    seoDescription: string | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
    }>;
  }>;
};

export type BlockImageGridFieldsFragment = {
  id: any;
  _modelApiKey: string;
  blockSpacing: string | null;
  images: Array<{ url: string; alt: string | null }>;
};

export type BlockPlantCollectionFieldsFragment = {
  id: any;
  _modelApiKey: string;
  collection: {
    id: any;
    _modelApiKey: string;
    plantList: Array<{
      id: any;
      name: string | null;
      subtitle: string | null;
      introText: string | null;
      endingText: string | null;
      detailText: string | null;
      hasBadge: any | null;
      slug: string | null;
      badge: {
        url: string;
        alt: string | null;
        width: any | null;
        height: any | null;
        focalPoint: { x: any; y: any } | null;
      } | null;
      primaryImage: {
        url: string;
        alt: string | null;
        width: any | null;
        height: any | null;
        focalPoint: { x: any; y: any } | null;
      } | null;
      product: {
        id: any;
        slug: string | null;
        title: string | null;
        description: string | null;
        gallery: Array<{ id: any; url: string; alt: string | null }>;
        tags: Array<{
          id: any;
          slug: string | null;
          title: string | null;
          tagMessage: string | null;
          isAFlowerPlantCenterCollection: any | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type PlantCollectionRecordFieldsFragment = {
  id: any;
  _modelApiKey: string;
  plantList: Array<{
    id: any;
    name: string | null;
    subtitle: string | null;
    introText: string | null;
    endingText: string | null;
    detailText: string | null;
    hasBadge: any | null;
    slug: string | null;
    badge: {
      url: string;
      alt: string | null;
      width: any | null;
      height: any | null;
      focalPoint: { x: any; y: any } | null;
    } | null;
    primaryImage: {
      url: string;
      alt: string | null;
      width: any | null;
      height: any | null;
      focalPoint: { x: any; y: any } | null;
    } | null;
    product: {
      id: any;
      slug: string | null;
      title: string | null;
      description: string | null;
      gallery: Array<{ id: any; url: string; alt: string | null }>;
      tags: Array<{
        id: any;
        slug: string | null;
        title: string | null;
        tagMessage: string | null;
        isAFlowerPlantCenterCollection: any | null;
      }>;
    } | null;
  }>;
};

export type PlantPageFieldsFragment = {
  id: any;
  name: string | null;
  subtitle: string | null;
  introText: string | null;
  endingText: string | null;
  detailText: string | null;
  hasBadge: any | null;
  slug: string | null;
  badge: {
    url: string;
    alt: string | null;
    width: any | null;
    height: any | null;
    focalPoint: { x: any; y: any } | null;
  } | null;
  primaryImage: {
    url: string;
    alt: string | null;
    width: any | null;
    height: any | null;
    focalPoint: { x: any; y: any } | null;
  } | null;
  product: {
    id: any;
    slug: string | null;
    title: string | null;
    description: string | null;
    gallery: Array<{ id: any; url: string; alt: string | null }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      isAFlowerPlantCenterCollection: any | null;
    }>;
  } | null;
};

export type GetProductByTagsQueryVariables = Exact<{
  tagIds: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type GetProductByTagsQuery = {
  allProducts: Array<{
    id: any;
    title: string | null;
    shopifyProduct: any | null;
    slug: string | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
      thumbUrl: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
    }>;
  }>;
};

export type BlockProductBestSellerFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  hasslider: any | null;
  pageLinkUrl: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  productBestSeller: Array<{
    id: any;
    _modelApiKey: string;
    shopifyProduct: any | null;
    slug: string | null;
    title: string | null;
    shortDescription: string | null;
    description: string | null;
    bouquetSize: string | null;
    isSubscription: any | null;
    seoDescription: string | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
    }>;
  }>;
};

export type ProductCollectionSingleFragment = {
  id: any;
  _modelApiKey: string;
  collection: {
    id: any;
    hasSlider: any | null;
    showProductAs: string | null;
    slug: string | null;
    title: string | null;
    centered: any | null;
    titleSubtitleFontSize: string | null;
    hideTitle: any | null;
    isTitleH1: any | null;
    description: string | null;
    hasFilterComponent: any | null;
    hideBreadcrumb: any | null;
    products: Array<
      | {
          id: any;
          _modelApiKey: string;
          slug: string | null;
          sku: string | null;
          title: string | null;
          thirdParty: string | null;
          isSubscription: any | null;
          disableDatePicker: any | null;
          bouquetSize: string | null;
          shopifyProduct: any | null;
          shopifySubscriptionProducts: any | null;
          shortDescription: string | null;
          cardType: string | null;
          isSuperGift: any | null;
          isGoogleProduct: any | null;
          isFacebookProduct: any | null;
          hideFromSearchEngines: any | null;
          hasNotBreadcrumb: any | null;
          tags: Array<{
            id: any;
            slug: string | null;
            title: string | null;
            tagMessage: string | null;
            tagType: string | null;
            image: { url: string; alt: string | null } | null;
            backgroundColor: { hex: string } | null;
            textColor: { hex: string } | null;
          }>;
          tagFilter: Array<{
            id: any;
            title: string | null;
            tagMessage: string | null;
            filterType: string | null;
            minPrice: string | null;
            maxPrice: string | null;
            backgroundColor: { hex: string } | null;
          }>;
          gallery: Array<{
            id: any;
            format: string;
            url: string;
            alt: string | null;
          }>;
        }
      | {
          _modelApiKey: string;
          shopifyProduct: any | null;
          id: any;
          image: {
            url: string;
            alt: string | null;
            responsiveImage: {
              srcSet: string;
              webpSrcSet: string;
              sizes: string;
              src: string;
              width: any;
              height: any;
              aspectRatio: any;
              alt: string | null;
              title: string | null;
              base64: string | null;
            } | null;
          } | null;
          pageLinkUrl: Array<{
            id: any;
            _modelApiKey: string;
            variant: string | null;
            title: string | null;
            link:
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | { _modelApiKey: string; slug: string | null }
              | null;
          }>;
        }
    >;
    productTags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      filterType: string | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
    image: { url: string } | null;
  } | null;
};

export type BlockProductCollectionSliderFieldsFragment = {
  id: any;
  _modelApiKey: string;
  titleHtml: string | null;
  title: string | null;
  subtitle: string | null;
  subtitleHtml: string | null;
  showSideTextOnDesktop: any | null;
  bgColor: string | null;
  pageLinkUrl: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  productColletionLink: Array<{
    id: any;
    slug: string | null;
    title: string | null;
    image: { url: string; alt: string | null } | null;
    landingPageLink: { slug: string | null } | null;
  }>;
};

export type BlockProseFieldsFragment = {
  id: any;
  _modelApiKey: string;
  proseContent: string | null;
  largeTypography: any | null;
  showOnMobileVersion: any | null;
};

export type BlockProseColumnFieldsFragment = {
  id: any;
  _modelApiKey: string;
  firstColumn: string | null;
  secondColumn: string | null;
};

export type BlockProseColumnTitleBgimageFieldsFragment = {
  id: any;
  _modelApiKey: string;
  mainTitle: string | null;
  mainSubtitle: string | null;
  subtitlePosition: string | null;
  fontSize: string | null;
  fontFamily: string | null;
  titleFirstCol: string | null;
  firstColumn: string | null;
  titleSecondCol: string | null;
  secondColumn: string | null;
  columnWidth: string | null;
  contentPosition: string | null;
  fontSizeFirstTitle: string | null;
  fontSizeSecondTitle: string | null;
  titleFontFamily: string | null;
  textFontFamily: string | null;
  bgImage: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  mobileImage: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockRowCardImageTitleRecordFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  subtitle: string | null;
  description: string | null;
  showSubtitleAfterTitle: any | null;
  content: Array<{
    id: any;
    title: string | null;
    backgroundColor: { hex: string } | null;
    image: {
      format: string;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
  }>;
};

export type ImageTitleBackgroundColorRecordContentFragment = {
  id: any;
  title: string | null;
  backgroundColor: { hex: string } | null;
  image: {
    format: string;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type GetProductsByFiltersByTagsQueryVariables = Exact<{
  tagIds: InputMaybe<Array<Scalars["ItemId"]> | Scalars["ItemId"]>;
}>;

export type GetProductsByFiltersByTagsQuery = {
  allProducts: Array<{
    id: any;
    title: string | null;
    shopifyProduct: any | null;
    slug: string | null;
    gallery: Array<{
      id: any;
      format: string;
      url: string;
      alt: string | null;
      thumbUrl: string;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
      thumbResponsive: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
    }>;
  }>;
};

export type BlockFeatureTextImageModuleFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  description: string | null;
  isMiniBanner: any | null;
  backgroundColor: { hex: string } | null;
  image: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  pageLink: Array<{
    id: any;
    _modelApiKey: string;
    variant: string | null;
    title: string | null;
    link:
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | { _modelApiKey: string; slug: string | null }
      | null;
  }>;
  imageMobile: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
};

export type BlockMainFeatureFieldsFragment = {
  id: any;
  _modelApiKey: string;
  fontFamily: string | null;
  title: string | null;
  isTitleH1: any | null;
  subtitle: string | null;
  subtitlePosition: string | null;
  hasPersonalizedDescription: any | null;
  hasDescriptionAsTable: any | null;
  hasBulletPoints: any | null;
  text: string | null;
  imagePosition: string | null;
  imageWidth: string | null;
  hasSlider: any | null;
  backgroundColor: { hex: string } | null;
  colorText: { hex: string } | null;
  bulletPoints: Array<{
    id: any;
    title: string | null;
    description: string | null;
  }>;
  rows: Array<{ id: any; text: string | null }>;
  image: {
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  } | null;
  product: {
    id: any;
    shopifyProduct: any | null;
    bouquetSize: string | null;
    prefixFrecuency: string | null;
    title: string | null;
    tags: Array<{
      id: any;
      slug: string | null;
      title: string | null;
      tagMessage: string | null;
      tagType: string | null;
      image: { url: string; alt: string | null } | null;
      backgroundColor: { hex: string } | null;
      textColor: { hex: string } | null;
    }>;
  } | null;
  buttons: Array<
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        url: string | null;
      }
    | {
        id: any;
        _modelApiKey: string;
        variant: string | null;
        title: string | null;
        link:
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | { _modelApiKey: string; slug: string | null }
          | null;
      }
  >;
  gallery: Array<{
    id: any;
    url: string;
    alt: string | null;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: any;
      height: any;
      aspectRatio: any;
      alt: string | null;
      title: string | null;
      base64: string | null;
    } | null;
  }>;
};

type BlockMainFeatureButtons_ExternalLinkRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  variant: string | null;
  title: string | null;
  url: string | null;
};

type BlockMainFeatureButtons_InternalLinkRecord_Fragment = {
  id: any;
  _modelApiKey: string;
  variant: string | null;
  title: string | null;
  link:
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | { _modelApiKey: string; slug: string | null }
    | null;
};

export type BlockMainFeatureButtonsFragment =
  | BlockMainFeatureButtons_ExternalLinkRecord_Fragment
  | BlockMainFeatureButtons_InternalLinkRecord_Fragment;

export type TitleTextFragment = {
  id: any;
  title: string | null;
  description: string | null;
};

export type TextFragment = { id: any; text: string | null };

export type BlockMainFeatureContainerFieldsFragment = {
  id: any;
  _modelApiKey: string;
  title: string | null;
  mainFeatureContainerContent: Array<{
    id: any;
    _modelApiKey: string;
    fontFamily: string | null;
    title: string | null;
    isTitleH1: any | null;
    subtitle: string | null;
    subtitlePosition: string | null;
    hasPersonalizedDescription: any | null;
    hasDescriptionAsTable: any | null;
    hasBulletPoints: any | null;
    text: string | null;
    imagePosition: string | null;
    imageWidth: string | null;
    hasSlider: any | null;
    backgroundColor: { hex: string } | null;
    colorText: { hex: string } | null;
    bulletPoints: Array<{
      id: any;
      title: string | null;
      description: string | null;
    }>;
    rows: Array<{ id: any; text: string | null }>;
    image: {
      id: any;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    } | null;
    product: {
      id: any;
      shopifyProduct: any | null;
      bouquetSize: string | null;
      prefixFrecuency: string | null;
      title: string | null;
      tags: Array<{
        id: any;
        slug: string | null;
        title: string | null;
        tagMessage: string | null;
        tagType: string | null;
        image: { url: string; alt: string | null } | null;
        backgroundColor: { hex: string } | null;
        textColor: { hex: string } | null;
      }>;
    } | null;
    buttons: Array<
      | {
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          url: string | null;
        }
      | {
          id: any;
          _modelApiKey: string;
          variant: string | null;
          title: string | null;
          link:
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | { _modelApiKey: string; slug: string | null }
            | null;
        }
    >;
    gallery: Array<{
      id: any;
      url: string;
      alt: string | null;
      responsiveImage: {
        srcSet: string;
        webpSrcSet: string;
        sizes: string;
        src: string;
        width: any;
        height: any;
        aspectRatio: any;
        alt: string | null;
        title: string | null;
        base64: string | null;
      } | null;
    }>;
  }>;
};

export const MetaTagsFragmentDoc = gql`
  fragment MetaTags on Tag {
    attributes
    content
    tag
  }
`;
export const BlogPageFieldsFragmentDoc = gql`
  fragment blogPageFields on BlogPageRecord {
    id
    slug
    title
    subtitle
    description
    seo: _seoMetaTags {
      ...MetaTags
    }
  }
  ${MetaTagsFragmentDoc}
`;
export const BlogCategoryFieldsFragmentDoc = gql`
  fragment BlogCategoryFields on BlogCategoryRecord {
    id
    _modelApiKey
    description
    visibleInBlogPage
    slug
  }
`;
export const BlogPostPrimaryFieldsFragmentDoc = gql`
  fragment blogPostPrimaryFields on BlogPostRecord {
    highlighText: highlightedText
    category {
      ...BlogCategoryFields
    }
    subCategory {
      ...BlogCategoryFields
    }
    slug
    title
    id
    createdAt
    primaryImage {
      url
      alt
    }
    publicationDate
    hideFromSearchEngines
    hasNewTemplate
  }
  ${BlogCategoryFieldsFragmentDoc}
`;
export const BlockProseFieldsFragmentDoc = gql`
  fragment BlockProseFields on BlockProseRecord {
    id
    _modelApiKey
    proseContent(markdown: true)
    largeTypography
    showOnMobileVersion
  }
`;
export const PageRecordSlugFragmentDoc = gql`
  fragment PageRecordSlug on PageRecord {
    _modelApiKey
    slug
  }
`;
export const ProductRecordSlugFragmentDoc = gql`
  fragment ProductRecordSlug on ProductRecord {
    _modelApiKey
    slug
  }
`;
export const SubscribePageSlugFragmentDoc = gql`
  fragment SubscribePageSlug on SubscribePageRecord {
    _modelApiKey
    slug
  }
`;
export const FlowerShopPageSlugFragmentDoc = gql`
  fragment FlowerShopPageSlug on FlowerShopPageRecord {
    _modelApiKey
    slug
  }
`;
export const PlantShopPageSlugFragmentDoc = gql`
  fragment PlantShopPageSlug on PlantShopPageRecord {
    _modelApiKey
    slug
  }
`;
export const BlogPostSlugFragmentDoc = gql`
  fragment BlogPostSlug on BlogPostRecord {
    _modelApiKey
    slug
  }
`;
export const NestedLandigPageSlugFragmentDoc = gql`
  fragment NestedLandigPageSlug on NestedPageRecord {
    _modelApiKey
    slug
  }
`;
export const InternalLinksFragmentDoc = gql`
  fragment InternalLinks on InternalLinkRecord {
    id
    _modelApiKey
    variant
    title
    link {
      ...PageRecordSlug
      ...ProductRecordSlug
      ...SubscribePageSlug
      ...FlowerShopPageSlug
      ...PlantShopPageSlug
      ...BlogPostSlug
      ...NestedLandigPageSlug
    }
  }
  ${PageRecordSlugFragmentDoc}
  ${ProductRecordSlugFragmentDoc}
  ${SubscribePageSlugFragmentDoc}
  ${FlowerShopPageSlugFragmentDoc}
  ${PlantShopPageSlugFragmentDoc}
  ${BlogPostSlugFragmentDoc}
  ${NestedLandigPageSlugFragmentDoc}
`;
export const ExternalLinksFragmentDoc = gql`
  fragment ExternalLinks on ExternalLinkRecord {
    id
    _modelApiKey
    variant
    title
    url
  }
`;
export const BlockHeroButtonsFragmentDoc = gql`
  fragment BlockHeroButtons on BlockHeroModelButtonsField {
    ...InternalLinks
    ...ExternalLinks
  }
  ${InternalLinksFragmentDoc}
  ${ExternalLinksFragmentDoc}
`;
export const ResponsiveImageFragmentDoc = gql`
  fragment responsiveImage on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    base64
  }
`;
export const BlockHeroFieldsFragmentDoc = gql`
  fragment BlockHeroFields on BlockHeroRecord {
    id
    _modelApiKey
    centered
    centeredMobile
    titlePadded
    title
    isTitleH1
    subtitle
    subtitleAfter
    text(markdown: true)
    textMobile(markdown: true)
    theme
    titleLogoAfter
    isBigVersionLogo
    isTitleFontBig
    contentWidth
    titleSubtitleFontSize
    titleFontFamily
    subtitleFontFamily
    hasLightTheme
    buttons {
      ...BlockHeroButtons
    }
    colorButton {
      hex
    }
    hasBiggerButton
    hasAButtonToScrollToAComponent
    hideInMobileVersion
    variantStyle
    buttonTitle
    scrollTo
    bgImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    mobileImage {
      url
      alt
      responsiveImage(imgixParams: { h: 300 }) {
        ...responsiveImage
      }
    }
    bgVideo {
      id
      url
    }
    bgVideoPosterImage {
      url
      alt
    }
    isShortHeight
    titleLogo {
      url
      alt
      format
      responsiveImage(imgixParams: { w: 320 }) {
        ...responsiveImage
      }
    }
    titleLogoMobile {
      url
      alt
      format
      responsiveImage(imgixParams: { w: 320 }) {
        ...responsiveImage
      }
    }
  }
  ${BlockHeroButtonsFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const BlockHeroAnimatedTextFieldsFragmentDoc = gql`
  fragment BlockHeroAnimatedTextFields on BlockHeroAnimatedTextRecord {
    id
    _modelApiKey
    centered
    title
    animatedText {
      id
      text
    }
    subtitle
    subtitleAfter
    text(markdown: true)
    theme
    titleLogoAfter
    contentWidth
    titleSubtitleFontSize
    titleFontFamily
    buttons {
      ...BlockHeroButtons
    }
    bgImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    mobileImage {
      url
      alt
      responsiveImage(imgixParams: { h: 300 }) {
        ...responsiveImage
      }
    }
    titleLogo {
      url
      alt
      format
      responsiveImage(imgixParams: { w: 320 }) {
        ...responsiveImage
      }
    }
  }
  ${BlockHeroButtonsFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const TitleTextFragmentDoc = gql`
  fragment TitleText on TitleTextRecord {
    id
    title
    description(markdown: true)
  }
`;
export const TextFragmentDoc = gql`
  fragment Text on TextRecord {
    id
    text
  }
`;
export const BlockMainFeatureButtonsFragmentDoc = gql`
  fragment BlockMainFeatureButtons on BlockMainFeatureModelButtonsField {
    ...InternalLinks
    ...ExternalLinks
  }
  ${InternalLinksFragmentDoc}
  ${ExternalLinksFragmentDoc}
`;
export const BlockMainFeatureFieldsFragmentDoc = gql`
  fragment BlockMainFeatureFields on BlockMainFeatureRecord {
    id
    _modelApiKey
    backgroundColor {
      hex
    }
    colorText {
      hex
    }
    fontFamily
    title
    isTitleH1
    subtitle
    subtitlePosition
    hasPersonalizedDescription
    hasDescriptionAsTable
    hasBulletPoints
    bulletPoints {
      ...TitleText
    }
    rows {
      ...Text
    }
    text(markdown: true)
    imagePosition
    imageWidth
    image {
      id
      url
      alt
      responsiveImage(imgixParams: { ar: "5:3", w: 680 }) {
        ...responsiveImage
      }
    }
    product {
      id
      shopifyProduct
      bouquetSize
      prefixFrecuency
      title
      tags {
        id
        slug
        title
        tagMessage
        image {
          url
          alt
        }
        backgroundColor {
          hex
        }
        textColor {
          hex
        }
        tagType
      }
    }
    buttons {
      ...BlockMainFeatureButtons
    }
    hasSlider
    gallery {
      id
      url
      alt
      responsiveImage(imgixParams: { ar: "5:3", w: 680 }) {
        ...responsiveImage
      }
    }
  }
  ${TitleTextFragmentDoc}
  ${TextFragmentDoc}
  ${ResponsiveImageFragmentDoc}
  ${BlockMainFeatureButtonsFragmentDoc}
`;
export const FlowerPageSlugFragmentDoc = gql`
  fragment FlowerPageSlug on FlowerPageRecord {
    _modelApiKey
    slug
  }
`;
export const FeatureImageCardContentFragmentDoc = gql`
  fragment FeatureImageCardContent on FeatureImageCardRecord {
    id
    title
    link {
      ...PageRecordSlug
      ...ProductRecordSlug
      ...SubscribePageSlug
      ...FlowerShopPageSlug
      ...PlantShopPageSlug
      ...FlowerPageSlug
    }
    image {
      format
      url
      alt
      responsiveImage(imgixParams: { w: 400, h: 275 }) {
        ...responsiveImage
      }
    }
  }
  ${PageRecordSlugFragmentDoc}
  ${ProductRecordSlugFragmentDoc}
  ${SubscribePageSlugFragmentDoc}
  ${FlowerShopPageSlugFragmentDoc}
  ${PlantShopPageSlugFragmentDoc}
  ${FlowerPageSlugFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const BlockFeatureImageCardFieldsFragmentDoc = gql`
  fragment BlockFeatureImageCardFields on BlockFeatureImageCardRecord {
    id
    _modelApiKey
    hasLargerCardWithoutBorder
    content {
      ...FeatureImageCardContent
    }
  }
  ${FeatureImageCardContentFragmentDoc}
`;
export const FeatureImageFullContentFragmentDoc = gql`
  fragment FeatureImageFullContent on FeatureImageFullRecord {
    id
    title
    text
    image {
      format
      url
      alt
      responsiveImage(imgixParams: { w: 400 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const BlockFeatureImageFullFieldsFragmentDoc = gql`
  fragment BlockFeatureImageFullFields on BlockFeatureImageFullRecord {
    id
    _modelApiKey
    subtitleFont
    titleFont
    hasDividers
    pageLinkUrl {
      ...InternalLinks
    }
    content {
      ...FeatureImageFullContent
    }
  }
  ${InternalLinksFragmentDoc}
  ${FeatureImageFullContentFragmentDoc}
`;
export const FeatureImageTitleContentFragmentDoc = gql`
  fragment FeatureImageTitleContent on FeatureImageTitleRecord {
    id
    title
    image {
      format
      url
      alt
      responsiveImage(imgixParams: { w: 400 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const BlockFeatureImageTitleFieldsFragmentDoc = gql`
  fragment BlockFeatureImageTitleFields on BlockFeatureImageTitleRecord {
    id
    _modelApiKey
    content {
      ...FeatureImageTitleContent
    }
  }
  ${FeatureImageTitleContentFragmentDoc}
`;
export const FeatureTestimonialContentFragmentDoc = gql`
  fragment FeatureTestimonialContent on FeatureTestimonialRecord {
    id
    quote
    author
    numberStars
  }
`;
export const BlockFeatureTestimonialFieldsFragmentDoc = gql`
  fragment BlockFeatureTestimonialFields on BlockFeatureTestimonialRecord {
    id
    _modelApiKey
    content {
      ...FeatureTestimonialContent
    }
    hasTestimonyWithImage
    image {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    testimony {
      ...FeatureTestimonialContent
    }
  }
  ${FeatureTestimonialContentFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const InternalMenuLinksFragmentDoc = gql`
  fragment InternalMenuLinks on LinkModelInternalLinkField {
    ...FlowerShopPageSlug
    ...PlantShopPageSlug
    ...SubscribePageSlug
    ...PageRecordSlug
    ...ProductRecordSlug
    ...BlogPostSlug
    ...NestedLandigPageSlug
  }
  ${FlowerShopPageSlugFragmentDoc}
  ${PlantShopPageSlugFragmentDoc}
  ${SubscribePageSlugFragmentDoc}
  ${PageRecordSlugFragmentDoc}
  ${ProductRecordSlugFragmentDoc}
  ${BlogPostSlugFragmentDoc}
  ${NestedLandigPageSlugFragmentDoc}
`;
export const MenuLinksFragmentDoc = gql`
  fragment MenuLinks on LinkRecord {
    id
    title
    shortDescription
    coverImage {
      id
      url
      alt
      format
    }
    url
    isExternal
    internalLink {
      ...InternalMenuLinks
    }
  }
  ${InternalMenuLinksFragmentDoc}
`;
export const BlockFeaturedInFieldsFragmentDoc = gql`
  fragment BlockFeaturedInFields on BlockFeaturedInRecord {
    id
    _modelApiKey
    blockSpacing
    title
    logos {
      id
      format
      url
      alt
      responsiveImage(imgixParams: { w: 120 }) {
        ...responsiveImage
      }
    }
    galleryImages {
      ...MenuLinks
    }
  }
  ${ResponsiveImageFragmentDoc}
  ${MenuLinksFragmentDoc}
`;
export const BlockFeatureQuoteFieldsFragmentDoc = gql`
  fragment BlockFeatureQuoteFields on BlockFeatureQuoteRecord {
    id
    _modelApiKey
    title
    quote
    author
    blockSpacing
  }
`;
export const BlockImageGridFieldsFragmentDoc = gql`
  fragment BlockImageGridFields on BlockImageGridRecord {
    id
    _modelApiKey
    images {
      url
      alt
    }
    blockSpacing
  }
`;
export const ProductFieldsShortFragmentDoc = gql`
  fragment ProductFieldsShort on ProductRecord {
    id
    _modelApiKey
    slug
    sku
    title
    thirdParty
    isSubscription
    disableDatePicker
    bouquetSize
    shopifyProduct
    shopifySubscriptionProducts
    shortDescription
    cardType
    isSuperGift
    isGoogleProduct
    isFacebookProduct
    hideFromSearchEngines
    hasNotBreadcrumb
    tags {
      id
      slug
      title
      tagMessage
      image {
        url
        alt
      }
      backgroundColor {
        hex
      }
      textColor {
        hex
      }
      tagType
    }
    tagFilter {
      id
      title
      tagMessage
      filterType
      minPrice
      maxPrice
      backgroundColor {
        hex
      }
    }
    gallery {
      id
      format
      url
      alt
    }
  }
`;
export const PromotionalCardFieldsFragmentDoc = gql`
  fragment PromotionalCardFields on PromotionalCardRecord {
    _modelApiKey
    shopifyProduct
    id
    image {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    pageLinkUrl {
      ...InternalLinks
    }
  }
  ${ResponsiveImageFragmentDoc}
  ${InternalLinksFragmentDoc}
`;
export const ProductCollectionFieldsFragmentDoc = gql`
  fragment ProductCollectionFields on ProductCollectionRecord {
    id
    hasSlider
    showProductAs
    slug
    title
    centered
    titleSubtitleFontSize
    hideTitle
    isTitleH1
    description
    hasFilterComponent
    hideBreadcrumb
    products {
      ...ProductFieldsShort
      ...PromotionalCardFields
    }
    productTags {
      id
      slug
      title
      tagMessage
      filterType
      backgroundColor {
        hex
      }
      textColor {
        hex
      }
    }
    image {
      url
    }
  }
  ${ProductFieldsShortFragmentDoc}
  ${PromotionalCardFieldsFragmentDoc}
`;
export const ProductCollectionSingleFragmentDoc = gql`
  fragment ProductCollectionSingle on BlockProductCollectionRecord {
    id
    _modelApiKey
    collection {
      ...ProductCollectionFields
    }
  }
  ${ProductCollectionFieldsFragmentDoc}
`;
export const BlockFlowerItemFieldsFragmentDoc = gql`
  fragment BlockFlowerItemFields on FlowerPageRecord {
    id
    name
    hasBadge
    badge {
      url
      alt
      width
      height
      focalPoint {
        x
        y
      }
    }
    slug
    primaryImage {
      url
      alt
      width
      height
      focalPoint {
        x
        y
      }
    }
    product {
      title
    }
  }
`;
export const BlockFlowerItemWeeklyFieldsFragmentDoc = gql`
  fragment BlockFlowerItemWeeklyFields on FlowerItemWeeklyRecord {
    id
    _modelApiKey
    weekNumber
    weekDate
    flower {
      ...BlockFlowerItemFields
    }
  }
  ${BlockFlowerItemFieldsFragmentDoc}
`;
export const BlockFlowerItemMonthlyFieldsFragmentDoc = gql`
  fragment BlockFlowerItemMonthlyFields on FlowerItemMonthlyRecord {
    id
    _modelApiKey
    flower {
      ...BlockFlowerItemFields
    }
  }
  ${BlockFlowerItemFieldsFragmentDoc}
`;
export const BlockFlowerCollectionFieldsFragmentDoc = gql`
  fragment BlockFlowerCollectionFields on BlockFlowerCollectionRecord {
    id
    _modelApiKey
    collection {
      id
      _modelApiKey
      month
      flowerList {
        ... on FlowerItemWeeklyRecord {
          ...BlockFlowerItemWeeklyFields
        }
        ... on FlowerItemMonthlyRecord {
          ...BlockFlowerItemMonthlyFields
        }
      }
    }
  }
  ${BlockFlowerItemWeeklyFieldsFragmentDoc}
  ${BlockFlowerItemMonthlyFieldsFragmentDoc}
`;
export const EmbeddedVideoFieldsFragmentDoc = gql`
  fragment embeddedVideoFields on BlockFeatureVideoRecord {
    content {
      id
      embeddedVideo {
        height
        provider
        providerUid
        thumbnailUrl
        title
        url
        width
      }
    }
  }
`;
export const BlockFeatureVideoFieldsFragmentDoc = gql`
  fragment BlockFeatureVideoFields on BlockFeatureVideoRecord {
    id
    _modelApiKey
    ...embeddedVideoFields
  }
  ${EmbeddedVideoFieldsFragmentDoc}
`;
export const FeatureImageCardOptionalContentFragmentDoc = gql`
  fragment FeatureImageCardOptionalContent on FeatureImageCardOptionalRecord {
    id
    title
    link {
      ...PageRecordSlug
      ...ProductRecordSlug
      ...SubscribePageSlug
      ...FlowerShopPageSlug
      ...PlantShopPageSlug
    }
    image {
      format
      url
      alt
      responsiveImage(imgixParams: { w: 400, h: 275 }) {
        ...responsiveImage
      }
    }
  }
  ${PageRecordSlugFragmentDoc}
  ${ProductRecordSlugFragmentDoc}
  ${SubscribePageSlugFragmentDoc}
  ${FlowerShopPageSlugFragmentDoc}
  ${PlantShopPageSlugFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const BlockFeatureRowImageCardFieldsFragmentDoc = gql`
  fragment BlockFeatureRowImageCardFields on BlockFeatureRowImageCardRecord {
    id
    _modelApiKey
    content {
      ...FeatureImageCardOptionalContent
    }
  }
  ${FeatureImageCardOptionalContentFragmentDoc}
`;
export const BlockProseColumnFieldsFragmentDoc = gql`
  fragment BlockProseColumnFields on BlockProseColumnRecord {
    id
    _modelApiKey
    firstColumn(markdown: true)
    secondColumn(markdown: true)
  }
`;
export const BlockProseColumnTitleBgimageFieldsFragmentDoc = gql`
  fragment BlockProseColumnTitleBgimageFields on BlockProseColumnTitleBgimageRecord {
    id
    _modelApiKey
    mainTitle
    mainSubtitle
    subtitlePosition
    fontSize
    fontFamily
    titleFirstCol
    firstColumn(markdown: true)
    titleSecondCol
    secondColumn(markdown: true)
    bgImage {
      id
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    mobileImage {
      id
      url
      alt
      responsiveImage(imgixParams: { h: 300 }) {
        ...responsiveImage
      }
    }
    columnWidth
    contentPosition
    fontSizeFirstTitle
    fontSizeSecondTitle
    titleFontFamily
    textFontFamily
  }
  ${ResponsiveImageFragmentDoc}
`;
export const BlockInstagramFullFieldsFragmentDoc = gql`
  fragment BlockInstagramFullFields on BlockFeatureInstagramFullRecord {
    id
    _modelApiKey
  }
`;
export const BlockInstagramSliderFieldsFragmentDoc = gql`
  fragment BlockInstagramSliderFields on BlockFeatureInstagramSliderRecord {
    id
    _modelApiKey
    title
    subtitle
  }
`;
export const BlockInstagramProductFieldsFragmentDoc = gql`
  fragment BlockInstagramProductFields on BlockFeatureInstagramProductRecord {
    id
    _modelApiKey
    title
    subtitle
  }
`;
export const PlantPageFieldsFragmentDoc = gql`
  fragment PlantPageFields on PlantPageRecord {
    id
    name
    subtitle
    introText
    endingText
    detailText
    hasBadge
    badge {
      url
      alt
      width
      height
      focalPoint {
        x
        y
      }
    }
    slug
    primaryImage {
      url
      alt
      width
      height
      focalPoint {
        x
        y
      }
    }
    product {
      id
      slug
      title
      description
      gallery {
        id
        url
        alt
      }
      tags {
        id
        slug
        title
        tagMessage
        isAFlowerPlantCenterCollection
      }
    }
  }
`;
export const PlantCollectionRecordFieldsFragmentDoc = gql`
  fragment PlantCollectionRecordFields on PlantCollectionRecord {
    id
    _modelApiKey
    plantList {
      ...PlantPageFields
    }
  }
  ${PlantPageFieldsFragmentDoc}
`;
export const BlockPlantCollectionFieldsFragmentDoc = gql`
  fragment BlockPlantCollectionFields on BlockPlantCollectionRecord {
    id
    _modelApiKey
    collection {
      ...PlantCollectionRecordFields
    }
  }
  ${PlantCollectionRecordFieldsFragmentDoc}
`;
export const ReviewFragmentDoc = gql`
  fragment Review on ReviewRecord {
    id
    _modelApiKey
    hasDefaultCardPerView
    cardsColor
    cardType
    content {
      ...FeatureTestimonialContent
    }
    sixCardPerViewContent {
      ...FeatureTestimonialContent
    }
    threeCardPerViewContent {
      ...FeatureTestimonialContent
    }
  }
  ${FeatureTestimonialContentFragmentDoc}
`;
export const BlockFeatureReviewCollectionFieldsFragmentDoc = gql`
  fragment BlockFeatureReviewCollectionFields on BlockFeatureReviewCollectionRecord {
    id
    _modelApiKey
    title
    titleHtml(markdown: true)
    subtitle
    subtitleHtml(markdown: true)
    reviewCollection {
      ...Review
    }
  }
  ${ReviewFragmentDoc}
`;
export const ImageTitleBackgroundColorRecordContentFragmentDoc = gql`
  fragment ImageTitleBackgroundColorRecordContent on ImageTitleBackgroundColorRecord {
    id
    title
    backgroundColor {
      hex
    }
    image {
      format
      url
      alt
      responsiveImage(imgixParams: { w: 400, h: 275 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const BlockRowCardImageTitleRecordFieldsFragmentDoc = gql`
  fragment BlockRowCardImageTitleRecordFields on BlockRowCardImageTitleRecord {
    id
    _modelApiKey
    title
    subtitle
    description
    showSubtitleAfterTitle
    content {
      ...ImageTitleBackgroundColorRecordContent
    }
  }
  ${ImageTitleBackgroundColorRecordContentFragmentDoc}
`;
export const FeatureTestimonialCompaniesContentFragmentDoc = gql`
  fragment FeatureTestimonialCompaniesContent on ImageTestimonialRecord {
    id
    quote
    image {
      id
      alt
      format
      url
      responsiveImage(imgixParams: { w: 300 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const BlockFeatTestimonialsCompaniesSliderFieldsFragmentDoc = gql`
  fragment BlockFeatTestimonialsCompaniesSliderFields on BlockFeatTestimonialsCompaniesSliderRecord {
    id
    _modelApiKey
    content {
      ...FeatureTestimonialCompaniesContent
    }
  }
  ${FeatureTestimonialCompaniesContentFragmentDoc}
`;
export const EmbeddedFormFieldsFragmentDoc = gql`
  fragment EmbeddedFormFields on EmbeddedFormRecord {
    id
    _modelApiKey
    embedHtml
    formType
  }
`;
export const BlockMainFeatureContainerFieldsFragmentDoc = gql`
  fragment BlockMainFeatureContainerFields on BlockMainFeatureContainerRecord {
    id
    _modelApiKey
    title
    mainFeatureContainerContent: content {
      ...BlockMainFeatureFields
    }
  }
  ${BlockMainFeatureFieldsFragmentDoc}
`;
export const BlockFeatureLinkCollectionFieldsFragmentDoc = gql`
  fragment BlockFeatureLinkCollectionFields on BlockFeatureLinkCollectionRecord {
    id
    _modelApiKey
    linkCollection {
      ...MenuLinks
    }
    typeOfLinks
    applyFilterGrayscaleToCardImage
    title
  }
  ${MenuLinksFragmentDoc}
`;
export const DiscountCardFieldsFragmentDoc = gql`
  fragment DiscountCardFields on DiscountCardRecord {
    _modelApiKey
    id
    productName
    discountCode
    discountToApply
    text
    disclaimer
    image {
      id
      alt
      format
      url
      responsiveImage(imgixParams: { w: 300 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const BlockFeatureDiscountCardFieldsFragmentDoc = gql`
  fragment BlockFeatureDiscountCardFields on BlockFeatureDiscountCardRecord {
    id
    _modelApiKey
    couponCard {
      ...DiscountCardFields
    }
  }
  ${DiscountCardFieldsFragmentDoc}
`;
export const FaqsCollectionFieldsFragmentDoc = gql`
  fragment faqsCollectionFields on FaqsCollectionRecord {
    id
    _modelApiKey
    hideFaqPage
    title
    questionList {
      id
      answer(markdown: true)
      question
    }
  }
`;
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on FeatureImageTitleDescriptionRecord {
    image {
      id
      title
      id
      format
      url
      alt
      responsiveImage(imgixParams: { w: 400, h: 275 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const BlockTextImageButtonFieldsFragmentDoc = gql`
  fragment BlockTextImageButtonFields on BlockFeatureText2imagesButtonRecord {
    id
    _modelApiKey
    titleLogo {
      url
      alt
      format
      responsiveImage(imgixParams: { w: 320 }) {
        ...responsiveImage
      }
    }
    title
    titleHtml(markdown: true)
    subtitlePosition
    description
    descriptionHtml(markdown: true)
    paragraph(markdown: true)
    hasButton
    button {
      ...InternalLinks
    }
    externalButton {
      ...ExternalLinks
    }
    hasImages
    images {
      id
      title
      description(markdown: true)
      ...ImageFields
    }
    hasAnImageAndText
    background {
      hex
    }
    image {
      url
      alt
      width
      height
    }
  }
  ${ResponsiveImageFragmentDoc}
  ${InternalLinksFragmentDoc}
  ${ExternalLinksFragmentDoc}
  ${ImageFieldsFragmentDoc}
`;
export const DescriptionTextFragmentDoc = gql`
  fragment DescriptionText on TitleTextRecord {
    id
    title
    description(markdown: true)
  }
`;
export const BlockFeatureImageTextFieldsFragmentDoc = gql`
  fragment blockFeatureImageTextFields on BlockFeatureImageTextRecord {
    id
    title
    _modelApiKey
    hasBigText
    bigText(markdown: true)
    bigTextPosition
    leftBulletPoints {
      ...DescriptionText
    }
    rightBulletPoints {
      ...DescriptionText
    }
    titleDescription
    descriptionContent {
      ...DescriptionText
    }
    backgroundImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    mobileImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
  }
  ${DescriptionTextFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const BlockFeatureContentOverlayFieldsFragmentDoc = gql`
  fragment BlockFeatureContentOverlayFields on BlockFeatureContentOverlayRecord {
    id
    _modelApiKey
    mainTitle
    mainDescription
    firstTextFooter
    secondTextFooter
    text(markdown: true)
    title
    description
    color {
      hex
    }
    thirdText
    backgroundImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    mobileImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    image {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    contentMobileImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    mainButton {
      ...InternalLinks
    }
    button {
      ...InternalLinks
    }
  }
  ${ResponsiveImageFragmentDoc}
  ${InternalLinksFragmentDoc}
`;
export const BlockFeatureTextIconFieldsFragmentDoc = gql`
  fragment BlockFeatureTextIconFields on BlockFeatureTextIconRecord {
    id
    _modelApiKey
    content {
      ...FeatureImageFullContent
    }
  }
  ${FeatureImageFullContentFragmentDoc}
`;
export const BlockProductCollectionSliderFieldsFragmentDoc = gql`
  fragment BlockProductCollectionSliderFields on ProductCollectionSliderRecord {
    id
    _modelApiKey
    titleHtml(markdown: true)
    title
    subtitle
    subtitleHtml(markdown: true)
    showSideTextOnDesktop
    bgColor
    pageLinkUrl {
      ...InternalLinks
    }
    productColletionLink {
      id
      slug
      title
      image {
        url
        alt
      }
      landingPageLink {
        slug
      }
    }
  }
  ${InternalLinksFragmentDoc}
`;
export const BlockProductBestSellerFieldsFragmentDoc = gql`
  fragment BlockProductBestSellerFields on ProductBestSellerRecord {
    id
    _modelApiKey
    title
    hasslider
    pageLinkUrl {
      ...InternalLinks
    }
    productBestSeller {
      id
      _modelApiKey
      shopifyProduct
      slug
      title
      shortDescription(markdown: true)
      description(markdown: true)
      seoDescription: description(markdown: false)
      bouquetSize
      isSubscription
      gallery {
        id
        format
        url
        alt
      }
      tags {
        id
        slug
        title
        tagMessage
      }
    }
  }
  ${InternalLinksFragmentDoc}
`;
export const BlockFeatureTextImageModuleFieldsFragmentDoc = gql`
  fragment BlockFeatureTextImageModuleFields on BlockFeatureTextImageModuleRecord {
    id
    _modelApiKey
    backgroundColor {
      hex
    }
    title
    description
    image {
      id
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    isMiniBanner
    pageLink {
      ...InternalLinks
    }
    imageMobile {
      id
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
  ${InternalLinksFragmentDoc}
`;
export const BlockHerobanner2columnFieldsFragmentDoc = gql`
  fragment blockHerobanner2columnFields on BlockHerobanner2columnRecord {
    id
    _modelApiKey
    titleHtml(markdown: true)
    titleFontFamily
    subTitleHtml(markdown: true)
    subtitleAfter
    subtitleFontFamily
    contentPosition
    theme
    hasLightTheme
    buttons {
      ...BlockHeroButtons
    }
    colorButton {
      hex
    }
    imagePosition
    bgImage {
      url
      alt
      responsiveImage(imgixParams: { h: 600 }) {
        ...responsiveImage
      }
    }
    mobileImage {
      url
      alt
      responsiveImage(imgixParams: { h: 300 }) {
        ...responsiveImage
      }
    }
    products {
      id
      _modelApiKey
      shopifyProduct
      slug
      title
      shortDescription(markdown: true)
      description(markdown: true)
      seoDescription: description(markdown: false)
      bouquetSize
      isSubscription
      gallery {
        id
        format
        url
        alt
      }
      tags {
        id
        slug
        title
        tagMessage
      }
    }
  }
  ${BlockHeroButtonsFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const BlockCtaFieldsFragmentDoc = gql`
  fragment blockCtaFields on BlockCtaRecord {
    id
    _modelApiKey
    text
    variant
    hasLightTheme
    buttons {
      ...BlockHeroButtons
    }
    colorButton {
      hex
    }
  }
  ${BlockHeroButtonsFragmentDoc}
`;
export const ContentModulesFragmentDoc = gql`
  fragment ContentModules on ContentSectionModelContentField {
    ... on BlockProseRecord {
      ...BlockProseFields
    }
    ... on BlockHeroRecord {
      ...BlockHeroFields
    }
    ... on BlockHeroAnimatedTextRecord {
      ...BlockHeroAnimatedTextFields
    }
    ... on BlockMainFeatureRecord {
      ...BlockMainFeatureFields
    }
    ... on BlockFeatureImageCardRecord {
      ...BlockFeatureImageCardFields
    }
    ... on BlockFeatureImageFullRecord {
      ...BlockFeatureImageFullFields
    }
    ... on BlockFeatureImageTitleRecord {
      ...BlockFeatureImageTitleFields
    }
    ... on BlockFeatureTestimonialRecord {
      ...BlockFeatureTestimonialFields
    }
    ... on BlockFeaturedInRecord {
      ...BlockFeaturedInFields
    }
    ... on BlockFeatureQuoteRecord {
      ...BlockFeatureQuoteFields
    }
    ... on BlockImageGridRecord {
      ...BlockImageGridFields
    }
    ... on BlockProductCollectionRecord {
      ...ProductCollectionSingle
    }
    ... on BlockFlowerCollectionRecord {
      ...BlockFlowerCollectionFields
    }
    ... on BlockFeatureVideoRecord {
      ...BlockFeatureVideoFields
    }
    ... on BlockFeatureRowImageCardRecord {
      ...BlockFeatureRowImageCardFields
    }
    ... on BlockProseColumnRecord {
      ...BlockProseColumnFields
    }
    ... on BlockProseColumnTitleBgimageRecord {
      ...BlockProseColumnTitleBgimageFields
    }
    ... on BlockFeatureInstagramFullRecord {
      ...BlockInstagramFullFields
    }
    ... on BlockFeatureInstagramSliderRecord {
      ...BlockInstagramSliderFields
    }
    ... on BlockFeatureInstagramProductRecord {
      ...BlockInstagramProductFields
    }
    ... on BlockPlantCollectionRecord {
      ...BlockPlantCollectionFields
    }
    ... on BlockFeatureReviewCollectionRecord {
      ...BlockFeatureReviewCollectionFields
    }
    ... on BlockRowCardImageTitleRecord {
      ...BlockRowCardImageTitleRecordFields
    }
    ... on BlockFeatTestimonialsCompaniesSliderRecord {
      ...BlockFeatTestimonialsCompaniesSliderFields
    }
    ... on EmbeddedFormRecord {
      ...EmbeddedFormFields
    }
    ... on BlockMainFeatureContainerRecord {
      ...BlockMainFeatureContainerFields
    }
    ... on BlockFeatureLinkCollectionRecord {
      ...BlockFeatureLinkCollectionFields
    }
    ... on BlockFeatureDiscountCardRecord {
      ...BlockFeatureDiscountCardFields
    }
    ... on FaqsCollectionRecord {
      ...faqsCollectionFields
    }
    ... on BlockFeatureText2imagesButtonRecord {
      ...BlockTextImageButtonFields
    }
    ... on BlockFeatureImageTextRecord {
      ...blockFeatureImageTextFields
    }
    ... on BlockFeatureContentOverlayRecord {
      ...BlockFeatureContentOverlayFields
    }
    ... on BlockFeatureTextIconRecord {
      ...BlockFeatureTextIconFields
    }
    ... on ProductCollectionSliderRecord {
      ...BlockProductCollectionSliderFields
    }
    ... on ProductBestSellerRecord {
      ...BlockProductBestSellerFields
    }
    ... on BlockFeatureTextImageModuleRecord {
      ...BlockFeatureTextImageModuleFields
    }
    ... on BlockHerobanner2columnRecord {
      ...blockHerobanner2columnFields
    }
    ... on BlockCtaRecord {
      ...blockCtaFields
    }
  }
  ${BlockProseFieldsFragmentDoc}
  ${BlockHeroFieldsFragmentDoc}
  ${BlockHeroAnimatedTextFieldsFragmentDoc}
  ${BlockMainFeatureFieldsFragmentDoc}
  ${BlockFeatureImageCardFieldsFragmentDoc}
  ${BlockFeatureImageFullFieldsFragmentDoc}
  ${BlockFeatureImageTitleFieldsFragmentDoc}
  ${BlockFeatureTestimonialFieldsFragmentDoc}
  ${BlockFeaturedInFieldsFragmentDoc}
  ${BlockFeatureQuoteFieldsFragmentDoc}
  ${BlockImageGridFieldsFragmentDoc}
  ${ProductCollectionSingleFragmentDoc}
  ${BlockFlowerCollectionFieldsFragmentDoc}
  ${BlockFeatureVideoFieldsFragmentDoc}
  ${BlockFeatureRowImageCardFieldsFragmentDoc}
  ${BlockProseColumnFieldsFragmentDoc}
  ${BlockProseColumnTitleBgimageFieldsFragmentDoc}
  ${BlockInstagramFullFieldsFragmentDoc}
  ${BlockInstagramSliderFieldsFragmentDoc}
  ${BlockInstagramProductFieldsFragmentDoc}
  ${BlockPlantCollectionFieldsFragmentDoc}
  ${BlockFeatureReviewCollectionFieldsFragmentDoc}
  ${BlockRowCardImageTitleRecordFieldsFragmentDoc}
  ${BlockFeatTestimonialsCompaniesSliderFieldsFragmentDoc}
  ${EmbeddedFormFieldsFragmentDoc}
  ${BlockMainFeatureContainerFieldsFragmentDoc}
  ${BlockFeatureLinkCollectionFieldsFragmentDoc}
  ${BlockFeatureDiscountCardFieldsFragmentDoc}
  ${FaqsCollectionFieldsFragmentDoc}
  ${BlockTextImageButtonFieldsFragmentDoc}
  ${BlockFeatureImageTextFieldsFragmentDoc}
  ${BlockFeatureContentOverlayFieldsFragmentDoc}
  ${BlockFeatureTextIconFieldsFragmentDoc}
  ${BlockProductCollectionSliderFieldsFragmentDoc}
  ${BlockProductBestSellerFieldsFragmentDoc}
  ${BlockFeatureTextImageModuleFieldsFragmentDoc}
  ${BlockHerobanner2columnFieldsFragmentDoc}
  ${BlockCtaFieldsFragmentDoc}
`;
export const ContentSectionFieldsFragmentDoc = gql`
  fragment ContentSectionFields on ContentSectionRecord {
    id
    _modelApiKey
    title
    isTitleH1
    subtitle
    subtitlePos
    bgImage {
      url
      alt
    }
    bgColor
    sectionPadded
    fontFamily
    fontSize
    text(markdown: true)
    content {
      ...ContentModules
    }
  }
  ${ContentModulesFragmentDoc}
`;
export const BlogPostSecondaryFieldsFragmentDoc = gql`
  fragment blogPostSecondaryFields on BlogPostRecord {
    sections {
      ...ContentSectionFields
    }
    image {
      url
      alt
    }
    text(markdown: true)
    titleNewTemplate
    firstText(markdown: true)
    secondText(markdown: true)
    textBeforeImages(markdown: true)
    imageCardFirstBlock {
      ...FeatureImageCardContent
    }
    thirdText(markdown: true)
    highlightedText(markdown: true)
    secondTextBeforeImages(markdown: true)
    imageCardSecondBlock {
      ...FeatureImageCardContent
    }
    textAfterImages(markdown: true)
    secondaryImage {
      url
      alt
    }
    button {
      ...InternalLinks
    }
    seo: _seoMetaTags {
      ...MetaTags
    }
  }
  ${ContentSectionFieldsFragmentDoc}
  ${FeatureImageCardContentFragmentDoc}
  ${InternalLinksFragmentDoc}
  ${MetaTagsFragmentDoc}
`;
export const TitleTextContactFragmentDoc = gql`
  fragment TitleTextContact on TitleTextRecord {
    id
    title
    description(markdown: true)
  }
`;
export const InfoChipFragmentDoc = gql`
  fragment InfoChip on FlowerPageRecord {
    infoChip {
      id
      image {
        url
        alt
      }
      title
      text
    }
  }
`;
export const FlowerFieldsFragmentDoc = gql`
  fragment FlowerFields on FlowerPageRecord {
    id
    slug
    introText(markdown: true)
    detailText(markdown: true)
    endingText(markdown: true)
    ...InfoChip
    name
    subtitle
    primaryImage {
      url
      alt
    }
    product {
      title
    }
    secondaryImage {
      url
      alt
    }
    sections {
      ...ContentSectionFields
    }
    seo: _seoMetaTags {
      ...MetaTags
    }
    __typename
  }
  ${InfoChipFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;
export const NestedPageChildrenFieldsFragmentDoc = gql`
  fragment NestedPageChildrenFields on NestedPageRecord {
    title
    subtitle
    slug
    shortDescription
    coverImage {
      url
      alt
      format
      responsiveImage(imgixParams: { w: 320 }) {
        ...responsiveImage
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const InfoChipPlantFragmentDoc = gql`
  fragment InfoChipPlant on PlantPageRecord {
    infoChip {
      id
      image {
        url
        alt
      }
      title
      text
    }
  }
`;
export const PlantFieldsFragmentDoc = gql`
  fragment PlantFields on PlantPageRecord {
    id
    slug
    introText(markdown: true)
    detailText(markdown: true)
    endingText(markdown: true)
    ...InfoChipPlant
    name
    subtitle
    primaryImage {
      url
      alt
    }
    product {
      title
      gallery {
        id
        url
        alt
      }
    }
    secondaryImage {
      url
      alt
    }
    sections {
      ...ContentSectionFields
    }
    seo: _seoMetaTags {
      ...MetaTags
    }
    __typename
  }
  ${InfoChipPlantFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;
export const PressArticlesFieldsFragmentDoc = gql`
  fragment pressArticlesFields on PressArticleRecord {
    id
    image {
      url
      alt
    }
    date
    title
    link
    shortDescription
  }
`;
export const ProductFieldsHeaderFragmentDoc = gql`
  fragment ProductFieldsHeader on ProductRecord {
    id
    slug
    title
    tags {
      slug
    }
    shopifyProduct
    shopifySubscriptionProducts
  }
`;
export const VariantFieldsFragmentDoc = gql`
  fragment VariantFields on VariantRecord {
    id
    title
    value
    listUpsellingExcludePerVariant {
      id
      title
      shopifyProduct
    }
    color {
      hex
    }
    thumbnail {
      url
      alt
    }
    gallery {
      id
      format
      url
      alt
      responsiveImage(
        imgixParams: {
          h: 250
          crop: [focalpoint]
          fit: crop
          ar: "4:3"
          fpX: "0.4"
        }
      ) {
        ...responsiveImage
      }
      thumbResponsive: responsiveImage(
        imgixParams: { w: 150, fit: crop, ar: "4:3" }
      ) {
        ...responsiveImage
      }
      mobileThumbResponsive: responsiveImage(
        imgixParams: { w: 50, fit: crop, ar: "4:3" }
      ) {
        ...responsiveImage
      }
    }
    headerText
    additionalText
    footerText
    listUpsellingExcludePerVariant {
      id
      title
      shopifyProduct
      shopifySubscriptionProducts
    }
  }
  ${ResponsiveImageFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on ProductRecord {
    id
    sku
    disableDatePicker
    backgroundColor {
      hex
    }
    _modelApiKey
    isTitleDescriptionFirst
    slug
    title
    thirdParty
    hasColumnLayout
    shortDescription(markdown: true)
    isDescriptionAfterTitle
    description(markdown: true)
    importantInfo(markdown: true)
    seoDescription: description(markdown: false)
    productDetailItems {
      ...TitleText
    }
    shopifyProduct
    shopifySubscriptionProducts
    isSubscription
    bouquetSize
    isSuperGift
    isGoogleProduct
    isFacebookProduct
    hideFromSearchEngines
    hasNotBreadcrumb
    hasVariant
    variantType
    variants {
      ...VariantFields
    }
    prefixFrecuency
    isUpselling
    hasDisabledNextDayDelivery
    noPdp
    isAvailableForNextday
    priorityUpselling
    deliveryOption
    excludeNextdayProducts {
      id
      title
      shopifyProduct
      shopifySubscriptionProducts
    }
    upsellingProducts {
      id
      title
      shopifyProduct
      shopifySubscriptionProducts
    }
    listUpsellingToExclude {
      id
      title
      shopifyProduct
      shopifySubscriptionProducts
    }
    pageHeaderType
    showPageHeader
    showPageFooter
    tags {
      id
      slug
      title
      tagMessage
      image {
        url
        alt
      }
      backgroundColor {
        hex
      }
      textColor {
        hex
      }
      tagType
    }
    priceRange
    tagFilter {
      id
      slug
      title
      tagType
    }
    gallery {
      id
      format
      url
      alt
      thumbUrl: url(
        imgixParams: {
          h: 150
          w: 150
          fit: crop
          crop: [focalpoint]
          ar: "4:3"
          fpX: "0.4"
        }
      )
      responsiveImage(imgixParams: { w: 600 }) {
        ...responsiveImage
      }
      thumbResponsive: responsiveImage(imgixParams: { w: 150 }) {
        ...responsiveImage
      }
    }
    sectionsBeforeSimilarProducts {
      ...ContentSectionFields
    }
    sections {
      ...ContentSectionFields
    }
    seo: _seoMetaTags {
      ...MetaTags
    }
  }
  ${TitleTextFragmentDoc}
  ${VariantFieldsFragmentDoc}
  ${ResponsiveImageFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;
export const ProductFieldsCartFragmentDoc = gql`
  fragment ProductFieldsCart on ProductRecord {
    id
    slug
    title
    thirdParty
    shopifyProduct
    shopifySubscriptionProducts
    isSubscription
    isUpselling
    hasDisabledNextDayDelivery
    hasVariant
    variantType
    variants {
      ...VariantFields
    }
    tags {
      id
      slug
      title
      tagMessage
      image {
        url
        alt
      }
      backgroundColor {
        hex
      }
      textColor {
        hex
      }
      tagType
    }
    gallery {
      url
      alt
      thumbUrl: url(imgixParams: { w: 150, fit: crop, ar: "4:3" })
      thumbResponsive: responsiveImage(
        imgixParams: { w: 150, fit: crop, ar: "4:3" }
      ) {
        ...responsiveImage
      }
      mobileThumbUrl: url(imgixParams: { w: 50, fit: crop, ar: "4:3" })
      mobileThumbResponsive: responsiveImage(
        imgixParams: { w: 50, fit: crop, ar: "4:3" }
      ) {
        ...responsiveImage
      }
    }
  }
  ${VariantFieldsFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const ProductFieldsShortCartFragmentDoc = gql`
  fragment ProductFieldsShortCart on ProductRecord {
    id
    slug
    title
    thirdParty
    shopifyProduct
    shopifySubscriptionProducts
    isSubscription
    isUpselling
    hasDisabledNextDayDelivery
  }
`;
export const SubscriptionPlanFragmentDoc = gql`
  fragment SubscriptionPlan on SubscriptionPlanRecord {
    id
    title
    isTitleH1
    subtitle
    text
    bgColor
    textColor
    products {
      ...ProductFieldsShort
    }
  }
  ${ProductFieldsShortFragmentDoc}
`;
export const NestedPageRecordSlugFragmentDoc = gql`
  fragment NestedPageRecordSlug on NestedPageRecord {
    _modelApiKey
    slug
  }
`;
export const SubMenuSettingFragmentDoc = gql`
  fragment SubMenuSetting on SubMenuSettingRecord {
    id
    item
    _modelApiKey
    leftTitle {
      ...MenuLinks
    }
    middleSubMenuItem {
      id
      title
      item {
        id
        title
        ...MenuLinks
      }
    }
    rightImage {
      id
      url
      alt
      responsiveImage(imgixParams: { w: 600 }) {
        ...responsiveImage
      }
    }
    rightLink {
      ...MenuLinks
    }
  }
  ${MenuLinksFragmentDoc}
  ${ResponsiveImageFragmentDoc}
`;
export const MenuItemsFragmentDoc = gql`
  fragment MenuItems on LinkCollectionItemRecord {
    id
    title
    url
    isExternal
    internalLink {
      ...InternalMenuLinks
    }
    subMenuItem {
      ...SubMenuSetting
    }
  }
  ${InternalMenuLinksFragmentDoc}
  ${SubMenuSettingFragmentDoc}
`;
export const GetBlogCategoriesCountDocument = gql`
  query GetBlogCategoriesCount {
    meta: _allBlogCategoriesMeta {
      count
    }
  }
`;

/**
 * __useGetBlogCategoriesCountQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoriesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoriesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoriesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlogCategoriesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlogCategoriesCountQuery,
    GetBlogCategoriesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBlogCategoriesCountQuery,
    GetBlogCategoriesCountQueryVariables
  >(GetBlogCategoriesCountDocument, options);
}
export function useGetBlogCategoriesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlogCategoriesCountQuery,
    GetBlogCategoriesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBlogCategoriesCountQuery,
    GetBlogCategoriesCountQueryVariables
  >(GetBlogCategoriesCountDocument, options);
}
export type GetBlogCategoriesCountQueryHookResult = ReturnType<
  typeof useGetBlogCategoriesCountQuery
>;
export type GetBlogCategoriesCountLazyQueryHookResult = ReturnType<
  typeof useGetBlogCategoriesCountLazyQuery
>;
export type GetBlogCategoriesCountQueryResult = Apollo.QueryResult<
  GetBlogCategoriesCountQuery,
  GetBlogCategoriesCountQueryVariables
>;
export const GetAllBlogCategoriesSlugsDocument = gql`
  query GetAllBlogCategoriesSlugs($perPage: IntType, $skip: IntType) {
    records: allBlogCategories(
      first: $perPage
      skip: $skip
      filter: { visibleInBlogPage: { eq: true } }
    ) {
      slug
    }
  }
`;

/**
 * __useGetAllBlogCategoriesSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllBlogCategoriesSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogCategoriesSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlogCategoriesSlugsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllBlogCategoriesSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllBlogCategoriesSlugsQuery,
    GetAllBlogCategoriesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllBlogCategoriesSlugsQuery,
    GetAllBlogCategoriesSlugsQueryVariables
  >(GetAllBlogCategoriesSlugsDocument, options);
}
export function useGetAllBlogCategoriesSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllBlogCategoriesSlugsQuery,
    GetAllBlogCategoriesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllBlogCategoriesSlugsQuery,
    GetAllBlogCategoriesSlugsQueryVariables
  >(GetAllBlogCategoriesSlugsDocument, options);
}
export type GetAllBlogCategoriesSlugsQueryHookResult = ReturnType<
  typeof useGetAllBlogCategoriesSlugsQuery
>;
export type GetAllBlogCategoriesSlugsLazyQueryHookResult = ReturnType<
  typeof useGetAllBlogCategoriesSlugsLazyQuery
>;
export type GetAllBlogCategoriesSlugsQueryResult = Apollo.QueryResult<
  GetAllBlogCategoriesSlugsQuery,
  GetAllBlogCategoriesSlugsQueryVariables
>;
export const GetBlogCategoryBySlugDocument = gql`
  query GetBlogCategoryBySlug($slug: String) {
    blogCategory(filter: { slug: { eq: $slug } }) {
      slug
      description
      id
      visibleInBlogPage
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetBlogCategoryBySlugQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoryBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoryBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoryBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetBlogCategoryBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlogCategoryBySlugQuery,
    GetBlogCategoryBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBlogCategoryBySlugQuery,
    GetBlogCategoryBySlugQueryVariables
  >(GetBlogCategoryBySlugDocument, options);
}
export function useGetBlogCategoryBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlogCategoryBySlugQuery,
    GetBlogCategoryBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBlogCategoryBySlugQuery,
    GetBlogCategoryBySlugQueryVariables
  >(GetBlogCategoryBySlugDocument, options);
}
export type GetBlogCategoryBySlugQueryHookResult = ReturnType<
  typeof useGetBlogCategoryBySlugQuery
>;
export type GetBlogCategoryBySlugLazyQueryHookResult = ReturnType<
  typeof useGetBlogCategoryBySlugLazyQuery
>;
export type GetBlogCategoryBySlugQueryResult = Apollo.QueryResult<
  GetBlogCategoryBySlugQuery,
  GetBlogCategoryBySlugQueryVariables
>;
export const GetAllBlogCategoriesDocument = gql`
  query GetAllBlogCategories {
    allBlogCategories(filter: { visibleInBlogPage: { eq: true } }) {
      id
      description
      slug
      visibleInBlogPage
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetAllBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlogCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBlogCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllBlogCategoriesQuery,
    GetAllBlogCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllBlogCategoriesQuery,
    GetAllBlogCategoriesQueryVariables
  >(GetAllBlogCategoriesDocument, options);
}
export function useGetAllBlogCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllBlogCategoriesQuery,
    GetAllBlogCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllBlogCategoriesQuery,
    GetAllBlogCategoriesQueryVariables
  >(GetAllBlogCategoriesDocument, options);
}
export type GetAllBlogCategoriesQueryHookResult = ReturnType<
  typeof useGetAllBlogCategoriesQuery
>;
export type GetAllBlogCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetAllBlogCategoriesLazyQuery
>;
export type GetAllBlogCategoriesQueryResult = Apollo.QueryResult<
  GetAllBlogCategoriesQuery,
  GetAllBlogCategoriesQueryVariables
>;
export const GetBlogPageDocument = gql`
  query GetBlogPage {
    blogPage {
      ...blogPageFields
    }
  }
  ${BlogPageFieldsFragmentDoc}
`;

/**
 * __useGetBlogPageQuery__
 *
 * To run a query within a React component, call `useGetBlogPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlogPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlogPageQuery,
    GetBlogPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBlogPageQuery, GetBlogPageQueryVariables>(
    GetBlogPageDocument,
    options
  );
}
export function useGetBlogPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlogPageQuery,
    GetBlogPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBlogPageQuery, GetBlogPageQueryVariables>(
    GetBlogPageDocument,
    options
  );
}
export type GetBlogPageQueryHookResult = ReturnType<typeof useGetBlogPageQuery>;
export type GetBlogPageLazyQueryHookResult = ReturnType<
  typeof useGetBlogPageLazyQuery
>;
export type GetBlogPageQueryResult = Apollo.QueryResult<
  GetBlogPageQuery,
  GetBlogPageQueryVariables
>;
export const GetAllBlogPostsCountDocument = gql`
  query GetAllBlogPostsCount($categoryId: ItemId) {
    meta: _allBlogPostsMeta(
      filter: {
        category: { eq: $categoryId }
        hideFromSearchEngines: { eq: false }
      }
    ) {
      count
    }
  }
`;

/**
 * __useGetAllBlogPostsCountQuery__
 *
 * To run a query within a React component, call `useGetAllBlogPostsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogPostsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlogPostsCountQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetAllBlogPostsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllBlogPostsCountQuery,
    GetAllBlogPostsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllBlogPostsCountQuery,
    GetAllBlogPostsCountQueryVariables
  >(GetAllBlogPostsCountDocument, options);
}
export function useGetAllBlogPostsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllBlogPostsCountQuery,
    GetAllBlogPostsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllBlogPostsCountQuery,
    GetAllBlogPostsCountQueryVariables
  >(GetAllBlogPostsCountDocument, options);
}
export type GetAllBlogPostsCountQueryHookResult = ReturnType<
  typeof useGetAllBlogPostsCountQuery
>;
export type GetAllBlogPostsCountLazyQueryHookResult = ReturnType<
  typeof useGetAllBlogPostsCountLazyQuery
>;
export type GetAllBlogPostsCountQueryResult = Apollo.QueryResult<
  GetAllBlogPostsCountQuery,
  GetAllBlogPostsCountQueryVariables
>;
export const GetAllBlogPostsSlugsDocument = gql`
  query GetAllBlogPostsSlugs($perPage: IntType, $skip: IntType) {
    records: allBlogPosts(first: $perPage, skip: $skip) {
      slug
    }
  }
`;

/**
 * __useGetAllBlogPostsSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllBlogPostsSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogPostsSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlogPostsSlugsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllBlogPostsSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllBlogPostsSlugsQuery,
    GetAllBlogPostsSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllBlogPostsSlugsQuery,
    GetAllBlogPostsSlugsQueryVariables
  >(GetAllBlogPostsSlugsDocument, options);
}
export function useGetAllBlogPostsSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllBlogPostsSlugsQuery,
    GetAllBlogPostsSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllBlogPostsSlugsQuery,
    GetAllBlogPostsSlugsQueryVariables
  >(GetAllBlogPostsSlugsDocument, options);
}
export type GetAllBlogPostsSlugsQueryHookResult = ReturnType<
  typeof useGetAllBlogPostsSlugsQuery
>;
export type GetAllBlogPostsSlugsLazyQueryHookResult = ReturnType<
  typeof useGetAllBlogPostsSlugsLazyQuery
>;
export type GetAllBlogPostsSlugsQueryResult = Apollo.QueryResult<
  GetAllBlogPostsSlugsQuery,
  GetAllBlogPostsSlugsQueryVariables
>;
export const GetBlogPostBySlugDocument = gql`
  query GetBlogPostBySlug($slug: String) {
    blogPost(filter: { slug: { eq: $slug } }) {
      ...blogPostPrimaryFields
      ...blogPostSecondaryFields
    }
  }
  ${BlogPostPrimaryFieldsFragmentDoc}
  ${BlogPostSecondaryFieldsFragmentDoc}
`;

/**
 * __useGetBlogPostBySlugQuery__
 *
 * To run a query within a React component, call `useGetBlogPostBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetBlogPostBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlogPostBySlugQuery,
    GetBlogPostBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBlogPostBySlugQuery,
    GetBlogPostBySlugQueryVariables
  >(GetBlogPostBySlugDocument, options);
}
export function useGetBlogPostBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlogPostBySlugQuery,
    GetBlogPostBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBlogPostBySlugQuery,
    GetBlogPostBySlugQueryVariables
  >(GetBlogPostBySlugDocument, options);
}
export type GetBlogPostBySlugQueryHookResult = ReturnType<
  typeof useGetBlogPostBySlugQuery
>;
export type GetBlogPostBySlugLazyQueryHookResult = ReturnType<
  typeof useGetBlogPostBySlugLazyQuery
>;
export type GetBlogPostBySlugQueryResult = Apollo.QueryResult<
  GetBlogPostBySlugQuery,
  GetBlogPostBySlugQueryVariables
>;
export const GetBlogPostsByPageDocument = gql`
  query GetBlogPostsByPage(
    $perPage: IntType
    $skip: IntType
    $categoryId: ItemId
  ) {
    allBlogPosts(
      filter: {
        category: { eq: $categoryId }
        hideFromSearchEngines: { eq: false }
      }
      first: $perPage
      skip: $skip
      orderBy: createdAt_DESC
    ) {
      ...blogPostPrimaryFields
    }
  }
  ${BlogPostPrimaryFieldsFragmentDoc}
`;

/**
 * __useGetBlogPostsByPageQuery__
 *
 * To run a query within a React component, call `useGetBlogPostsByPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostsByPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostsByPageQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetBlogPostsByPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlogPostsByPageQuery,
    GetBlogPostsByPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBlogPostsByPageQuery,
    GetBlogPostsByPageQueryVariables
  >(GetBlogPostsByPageDocument, options);
}
export function useGetBlogPostsByPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlogPostsByPageQuery,
    GetBlogPostsByPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBlogPostsByPageQuery,
    GetBlogPostsByPageQueryVariables
  >(GetBlogPostsByPageDocument, options);
}
export type GetBlogPostsByPageQueryHookResult = ReturnType<
  typeof useGetBlogPostsByPageQuery
>;
export type GetBlogPostsByPageLazyQueryHookResult = ReturnType<
  typeof useGetBlogPostsByPageLazyQuery
>;
export type GetBlogPostsByPageQueryResult = Apollo.QueryResult<
  GetBlogPostsByPageQuery,
  GetBlogPostsByPageQueryVariables
>;
export const GetUpSellingProductsDocument = gql`
  query GetUpSellingProducts {
    allProducts(filter: { isUpselling: { eq: "true" } }) {
      ...ProductFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetUpSellingProductsQuery__
 *
 * To run a query within a React component, call `useGetUpSellingProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpSellingProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpSellingProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpSellingProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUpSellingProductsQuery,
    GetUpSellingProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUpSellingProductsQuery,
    GetUpSellingProductsQueryVariables
  >(GetUpSellingProductsDocument, options);
}
export function useGetUpSellingProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpSellingProductsQuery,
    GetUpSellingProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUpSellingProductsQuery,
    GetUpSellingProductsQueryVariables
  >(GetUpSellingProductsDocument, options);
}
export type GetUpSellingProductsQueryHookResult = ReturnType<
  typeof useGetUpSellingProductsQuery
>;
export type GetUpSellingProductsLazyQueryHookResult = ReturnType<
  typeof useGetUpSellingProductsLazyQuery
>;
export type GetUpSellingProductsQueryResult = Apollo.QueryResult<
  GetUpSellingProductsQuery,
  GetUpSellingProductsQueryVariables
>;
export const GetUpSellingCartProductsDocument = gql`
  query GetUpSellingCartProducts {
    allProducts(filter: { isUpselling: { eq: "true" } }) {
      ...ProductFieldsShortCart
    }
  }
  ${ProductFieldsShortCartFragmentDoc}
`;

/**
 * __useGetUpSellingCartProductsQuery__
 *
 * To run a query within a React component, call `useGetUpSellingCartProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpSellingCartProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpSellingCartProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpSellingCartProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUpSellingCartProductsQuery,
    GetUpSellingCartProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUpSellingCartProductsQuery,
    GetUpSellingCartProductsQueryVariables
  >(GetUpSellingCartProductsDocument, options);
}
export function useGetUpSellingCartProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpSellingCartProductsQuery,
    GetUpSellingCartProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUpSellingCartProductsQuery,
    GetUpSellingCartProductsQueryVariables
  >(GetUpSellingCartProductsDocument, options);
}
export type GetUpSellingCartProductsQueryHookResult = ReturnType<
  typeof useGetUpSellingCartProductsQuery
>;
export type GetUpSellingCartProductsLazyQueryHookResult = ReturnType<
  typeof useGetUpSellingCartProductsLazyQuery
>;
export type GetUpSellingCartProductsQueryResult = Apollo.QueryResult<
  GetUpSellingCartProductsQuery,
  GetUpSellingCartProductsQueryVariables
>;
export const GetContactUsPageDocument = gql`
  query GetContactUsPage {
    contactUsPage {
      id
      title
      text(markdown: true)
      contactBlock {
        ...TitleTextContact
      }
      slug
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${TitleTextContactFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetContactUsPageQuery__
 *
 * To run a query within a React component, call `useGetContactUsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactUsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactUsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactUsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactUsPageQuery,
    GetContactUsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactUsPageQuery, GetContactUsPageQueryVariables>(
    GetContactUsPageDocument,
    options
  );
}
export function useGetContactUsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactUsPageQuery,
    GetContactUsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactUsPageQuery,
    GetContactUsPageQueryVariables
  >(GetContactUsPageDocument, options);
}
export type GetContactUsPageQueryHookResult = ReturnType<
  typeof useGetContactUsPageQuery
>;
export type GetContactUsPageLazyQueryHookResult = ReturnType<
  typeof useGetContactUsPageLazyQuery
>;
export type GetContactUsPageQueryResult = Apollo.QueryResult<
  GetContactUsPageQuery,
  GetContactUsPageQueryVariables
>;
export const GetFaQsCollectionDocument = gql`
  query GetFAQsCollection {
    allFaqsCollections(orderBy: id_ASC) {
      ...faqsCollectionFields
    }
  }
  ${FaqsCollectionFieldsFragmentDoc}
`;

/**
 * __useGetFaQsCollectionQuery__
 *
 * To run a query within a React component, call `useGetFaQsCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaQsCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaQsCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFaQsCollectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFaQsCollectionQuery,
    GetFaQsCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFaQsCollectionQuery,
    GetFaQsCollectionQueryVariables
  >(GetFaQsCollectionDocument, options);
}
export function useGetFaQsCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFaQsCollectionQuery,
    GetFaQsCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFaQsCollectionQuery,
    GetFaQsCollectionQueryVariables
  >(GetFaQsCollectionDocument, options);
}
export type GetFaQsCollectionQueryHookResult = ReturnType<
  typeof useGetFaQsCollectionQuery
>;
export type GetFaQsCollectionLazyQueryHookResult = ReturnType<
  typeof useGetFaQsCollectionLazyQuery
>;
export type GetFaQsCollectionQueryResult = Apollo.QueryResult<
  GetFaQsCollectionQuery,
  GetFaQsCollectionQueryVariables
>;
export const GetfaqsPageDocument = gql`
  query GetfaqsPage {
    faqsPage {
      id
      backgroundImage {
        id
        url
        alt
        height
        width
      }
      title
      subtitle
      slug
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetfaqsPageQuery__
 *
 * To run a query within a React component, call `useGetfaqsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetfaqsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetfaqsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetfaqsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetfaqsPageQuery,
    GetfaqsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetfaqsPageQuery, GetfaqsPageQueryVariables>(
    GetfaqsPageDocument,
    options
  );
}
export function useGetfaqsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetfaqsPageQuery,
    GetfaqsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetfaqsPageQuery, GetfaqsPageQueryVariables>(
    GetfaqsPageDocument,
    options
  );
}
export type GetfaqsPageQueryHookResult = ReturnType<typeof useGetfaqsPageQuery>;
export type GetfaqsPageLazyQueryHookResult = ReturnType<
  typeof useGetfaqsPageLazyQuery
>;
export type GetfaqsPageQueryResult = Apollo.QueryResult<
  GetfaqsPageQuery,
  GetfaqsPageQueryVariables
>;
export const GetFlowerCenterDocument = gql`
  query GetFlowerCenter {
    flowerCenter {
      id
      title
      subtitle
      description
      slug
      image {
        url
        alt
      }
      sections {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetFlowerCenterQuery__
 *
 * To run a query within a React component, call `useGetFlowerCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowerCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowerCenterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFlowerCenterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFlowerCenterQuery,
    GetFlowerCenterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFlowerCenterQuery, GetFlowerCenterQueryVariables>(
    GetFlowerCenterDocument,
    options
  );
}
export function useGetFlowerCenterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlowerCenterQuery,
    GetFlowerCenterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFlowerCenterQuery,
    GetFlowerCenterQueryVariables
  >(GetFlowerCenterDocument, options);
}
export type GetFlowerCenterQueryHookResult = ReturnType<
  typeof useGetFlowerCenterQuery
>;
export type GetFlowerCenterLazyQueryHookResult = ReturnType<
  typeof useGetFlowerCenterLazyQuery
>;
export type GetFlowerCenterQueryResult = Apollo.QueryResult<
  GetFlowerCenterQuery,
  GetFlowerCenterQueryVariables
>;
export const GetAllFlowersCountDocument = gql`
  query GetAllFlowersCount {
    meta: _allFlowerPagesMeta {
      count
    }
  }
`;

/**
 * __useGetAllFlowersCountQuery__
 *
 * To run a query within a React component, call `useGetAllFlowersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFlowersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFlowersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFlowersCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllFlowersCountQuery,
    GetAllFlowersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllFlowersCountQuery,
    GetAllFlowersCountQueryVariables
  >(GetAllFlowersCountDocument, options);
}
export function useGetAllFlowersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFlowersCountQuery,
    GetAllFlowersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllFlowersCountQuery,
    GetAllFlowersCountQueryVariables
  >(GetAllFlowersCountDocument, options);
}
export type GetAllFlowersCountQueryHookResult = ReturnType<
  typeof useGetAllFlowersCountQuery
>;
export type GetAllFlowersCountLazyQueryHookResult = ReturnType<
  typeof useGetAllFlowersCountLazyQuery
>;
export type GetAllFlowersCountQueryResult = Apollo.QueryResult<
  GetAllFlowersCountQuery,
  GetAllFlowersCountQueryVariables
>;
export const GetAllFlowersSlugsDocument = gql`
  query GetAllFlowersSlugs($perPage: IntType, $skip: IntType) {
    records: allFlowerPages(first: $perPage, skip: $skip) {
      slug
    }
  }
`;

/**
 * __useGetAllFlowersSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllFlowersSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFlowersSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFlowersSlugsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllFlowersSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllFlowersSlugsQuery,
    GetAllFlowersSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllFlowersSlugsQuery,
    GetAllFlowersSlugsQueryVariables
  >(GetAllFlowersSlugsDocument, options);
}
export function useGetAllFlowersSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFlowersSlugsQuery,
    GetAllFlowersSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllFlowersSlugsQuery,
    GetAllFlowersSlugsQueryVariables
  >(GetAllFlowersSlugsDocument, options);
}
export type GetAllFlowersSlugsQueryHookResult = ReturnType<
  typeof useGetAllFlowersSlugsQuery
>;
export type GetAllFlowersSlugsLazyQueryHookResult = ReturnType<
  typeof useGetAllFlowersSlugsLazyQuery
>;
export type GetAllFlowersSlugsQueryResult = Apollo.QueryResult<
  GetAllFlowersSlugsQuery,
  GetAllFlowersSlugsQueryVariables
>;
export const GetFlowerBySlugDocument = gql`
  query GetFlowerBySlug($slug: String) {
    flowerPage(filter: { slug: { eq: $slug } }) {
      ...FlowerFields
    }
  }
  ${FlowerFieldsFragmentDoc}
`;

/**
 * __useGetFlowerBySlugQuery__
 *
 * To run a query within a React component, call `useGetFlowerBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowerBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowerBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetFlowerBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFlowerBySlugQuery,
    GetFlowerBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFlowerBySlugQuery, GetFlowerBySlugQueryVariables>(
    GetFlowerBySlugDocument,
    options
  );
}
export function useGetFlowerBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlowerBySlugQuery,
    GetFlowerBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFlowerBySlugQuery,
    GetFlowerBySlugQueryVariables
  >(GetFlowerBySlugDocument, options);
}
export type GetFlowerBySlugQueryHookResult = ReturnType<
  typeof useGetFlowerBySlugQuery
>;
export type GetFlowerBySlugLazyQueryHookResult = ReturnType<
  typeof useGetFlowerBySlugLazyQuery
>;
export type GetFlowerBySlugQueryResult = Apollo.QueryResult<
  GetFlowerBySlugQuery,
  GetFlowerBySlugQueryVariables
>;
export const GetAllFlowersDocument = gql`
  query GetAllFlowers($perPage: IntType, $skip: IntType) {
    allFlowerPages(first: $perPage, skip: $skip) {
      ...FlowerFields
    }
  }
  ${FlowerFieldsFragmentDoc}
`;

/**
 * __useGetAllFlowersQuery__
 *
 * To run a query within a React component, call `useGetAllFlowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFlowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFlowersQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllFlowersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllFlowersQuery,
    GetAllFlowersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllFlowersQuery, GetAllFlowersQueryVariables>(
    GetAllFlowersDocument,
    options
  );
}
export function useGetAllFlowersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFlowersQuery,
    GetAllFlowersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllFlowersQuery, GetAllFlowersQueryVariables>(
    GetAllFlowersDocument,
    options
  );
}
export type GetAllFlowersQueryHookResult = ReturnType<
  typeof useGetAllFlowersQuery
>;
export type GetAllFlowersLazyQueryHookResult = ReturnType<
  typeof useGetAllFlowersLazyQuery
>;
export type GetAllFlowersQueryResult = Apollo.QueryResult<
  GetAllFlowersQuery,
  GetAllFlowersQueryVariables
>;
export const GetFlowerShopPageDocument = gql`
  query GetFlowerShopPage {
    flowerShopPage {
      id
      title
      slug
      title
      text(markdown: true)
      productCollections {
        ...ProductCollectionFields
      }
      sections {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${ProductCollectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetFlowerShopPageQuery__
 *
 * To run a query within a React component, call `useGetFlowerShopPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowerShopPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowerShopPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFlowerShopPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFlowerShopPageQuery,
    GetFlowerShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFlowerShopPageQuery,
    GetFlowerShopPageQueryVariables
  >(GetFlowerShopPageDocument, options);
}
export function useGetFlowerShopPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlowerShopPageQuery,
    GetFlowerShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFlowerShopPageQuery,
    GetFlowerShopPageQueryVariables
  >(GetFlowerShopPageDocument, options);
}
export type GetFlowerShopPageQueryHookResult = ReturnType<
  typeof useGetFlowerShopPageQuery
>;
export type GetFlowerShopPageLazyQueryHookResult = ReturnType<
  typeof useGetFlowerShopPageLazyQuery
>;
export type GetFlowerShopPageQueryResult = Apollo.QueryResult<
  GetFlowerShopPageQuery,
  GetFlowerShopPageQueryVariables
>;
export const GetFlowerShopCollectionProductsByTagDocument = gql`
  query GetFlowerShopCollectionProductsByTag($tagIds: [ItemId!]) {
    allProducts(
      first: 100
      filter: { tags: { anyIn: $tagIds }, shopifyProduct: { exists: true } }
    ) {
      ...ProductFieldsShort
    }
  }
  ${ProductFieldsShortFragmentDoc}
`;

/**
 * __useGetFlowerShopCollectionProductsByTagQuery__
 *
 * To run a query within a React component, call `useGetFlowerShopCollectionProductsByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowerShopCollectionProductsByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowerShopCollectionProductsByTagQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useGetFlowerShopCollectionProductsByTagQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFlowerShopCollectionProductsByTagQuery,
    GetFlowerShopCollectionProductsByTagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFlowerShopCollectionProductsByTagQuery,
    GetFlowerShopCollectionProductsByTagQueryVariables
  >(GetFlowerShopCollectionProductsByTagDocument, options);
}
export function useGetFlowerShopCollectionProductsByTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlowerShopCollectionProductsByTagQuery,
    GetFlowerShopCollectionProductsByTagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFlowerShopCollectionProductsByTagQuery,
    GetFlowerShopCollectionProductsByTagQueryVariables
  >(GetFlowerShopCollectionProductsByTagDocument, options);
}
export type GetFlowerShopCollectionProductsByTagQueryHookResult = ReturnType<
  typeof useGetFlowerShopCollectionProductsByTagQuery
>;
export type GetFlowerShopCollectionProductsByTagLazyQueryHookResult =
  ReturnType<typeof useGetFlowerShopCollectionProductsByTagLazyQuery>;
export type GetFlowerShopCollectionProductsByTagQueryResult =
  Apollo.QueryResult<
    GetFlowerShopCollectionProductsByTagQuery,
    GetFlowerShopCollectionProductsByTagQueryVariables
  >;
export const GetHomepageDataDocument = gql`
  query GetHomepageData {
    homepage {
      id
      title
      sections {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetHomepageDataQuery__
 *
 * To run a query within a React component, call `useGetHomepageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomepageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomepageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomepageDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHomepageDataQuery,
    GetHomepageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHomepageDataQuery, GetHomepageDataQueryVariables>(
    GetHomepageDataDocument,
    options
  );
}
export function useGetHomepageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomepageDataQuery,
    GetHomepageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHomepageDataQuery,
    GetHomepageDataQueryVariables
  >(GetHomepageDataDocument, options);
}
export type GetHomepageDataQueryHookResult = ReturnType<
  typeof useGetHomepageDataQuery
>;
export type GetHomepageDataLazyQueryHookResult = ReturnType<
  typeof useGetHomepageDataLazyQuery
>;
export type GetHomepageDataQueryResult = Apollo.QueryResult<
  GetHomepageDataQuery,
  GetHomepageDataQueryVariables
>;
export const GetAllPagesCountDocument = gql`
  query GetAllPagesCount {
    meta: _allPagesMeta {
      count
    }
  }
`;

/**
 * __useGetAllPagesCountQuery__
 *
 * To run a query within a React component, call `useGetAllPagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPagesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPagesCountQuery,
    GetAllPagesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPagesCountQuery, GetAllPagesCountQueryVariables>(
    GetAllPagesCountDocument,
    options
  );
}
export function useGetAllPagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPagesCountQuery,
    GetAllPagesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPagesCountQuery,
    GetAllPagesCountQueryVariables
  >(GetAllPagesCountDocument, options);
}
export type GetAllPagesCountQueryHookResult = ReturnType<
  typeof useGetAllPagesCountQuery
>;
export type GetAllPagesCountLazyQueryHookResult = ReturnType<
  typeof useGetAllPagesCountLazyQuery
>;
export type GetAllPagesCountQueryResult = Apollo.QueryResult<
  GetAllPagesCountQuery,
  GetAllPagesCountQueryVariables
>;
export const GetAllPagesSlugsDocument = gql`
  query GetAllPagesSlugs($perPage: IntType, $skip: IntType) {
    records: allPages(first: $perPage, skip: $skip) {
      slug
    }
  }
`;

/**
 * __useGetAllPagesSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllPagesSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPagesSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPagesSlugsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllPagesSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPagesSlugsQuery,
    GetAllPagesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPagesSlugsQuery, GetAllPagesSlugsQueryVariables>(
    GetAllPagesSlugsDocument,
    options
  );
}
export function useGetAllPagesSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPagesSlugsQuery,
    GetAllPagesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPagesSlugsQuery,
    GetAllPagesSlugsQueryVariables
  >(GetAllPagesSlugsDocument, options);
}
export type GetAllPagesSlugsQueryHookResult = ReturnType<
  typeof useGetAllPagesSlugsQuery
>;
export type GetAllPagesSlugsLazyQueryHookResult = ReturnType<
  typeof useGetAllPagesSlugsLazyQuery
>;
export type GetAllPagesSlugsQueryResult = Apollo.QueryResult<
  GetAllPagesSlugsQuery,
  GetAllPagesSlugsQueryVariables
>;
export const GetNonLandingPagesSlugsDocument = gql`
  query GetNonLandingPagesSlugs {
    flowerShopPage {
      slug
    }
    plantShopPage {
      slug
    }
    subscribePage {
      slug
    }
    pressPage {
      slug
    }
    flowerCenter {
      slug
    }
    faqsPage {
      slug
    }
    plantCenter {
      slug
    }
    contactUsPage {
      slug
    }
  }
`;

/**
 * __useGetNonLandingPagesSlugsQuery__
 *
 * To run a query within a React component, call `useGetNonLandingPagesSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonLandingPagesSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonLandingPagesSlugsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNonLandingPagesSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNonLandingPagesSlugsQuery,
    GetNonLandingPagesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNonLandingPagesSlugsQuery,
    GetNonLandingPagesSlugsQueryVariables
  >(GetNonLandingPagesSlugsDocument, options);
}
export function useGetNonLandingPagesSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNonLandingPagesSlugsQuery,
    GetNonLandingPagesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNonLandingPagesSlugsQuery,
    GetNonLandingPagesSlugsQueryVariables
  >(GetNonLandingPagesSlugsDocument, options);
}
export type GetNonLandingPagesSlugsQueryHookResult = ReturnType<
  typeof useGetNonLandingPagesSlugsQuery
>;
export type GetNonLandingPagesSlugsLazyQueryHookResult = ReturnType<
  typeof useGetNonLandingPagesSlugsLazyQuery
>;
export type GetNonLandingPagesSlugsQueryResult = Apollo.QueryResult<
  GetNonLandingPagesSlugsQuery,
  GetNonLandingPagesSlugsQueryVariables
>;
export const GetPageBySlugDocument = gql`
  query getPageBySlug($slug: String!) {
    page(filter: { slug: { eq: $slug } }) {
      id
      slug
      hideFromSearchEngines
      title
      pageHeaderType
      showPageHeader
      showPageFooter
      hasCustomLogo
      logo {
        url
        alt
        format
        responsiveImage(imgixParams: { w: 320 }) {
          ...responsiveImage
        }
      }
      sections {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetPageBySlugQuery__
 *
 * To run a query within a React component, call `useGetPageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPageBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPageBySlugQuery,
    GetPageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPageBySlugQuery, GetPageBySlugQueryVariables>(
    GetPageBySlugDocument,
    options
  );
}
export function useGetPageBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPageBySlugQuery,
    GetPageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPageBySlugQuery, GetPageBySlugQueryVariables>(
    GetPageBySlugDocument,
    options
  );
}
export type GetPageBySlugQueryHookResult = ReturnType<
  typeof useGetPageBySlugQuery
>;
export type GetPageBySlugLazyQueryHookResult = ReturnType<
  typeof useGetPageBySlugLazyQuery
>;
export type GetPageBySlugQueryResult = Apollo.QueryResult<
  GetPageBySlugQuery,
  GetPageBySlugQueryVariables
>;
export const GetFilterTagsDocument = gql`
  query getFilterTags {
    filters: allProductTags(
      filter: { tagType: { eq: "Filter" }, filterType: { isBlank: "false" } }
    ) {
      id
      title
      tagMessage
      filterType
      minPrice
      maxPrice
      priority
      alwaysVisible
      backgroundColor {
        hex
      }
    }
  }
`;

/**
 * __useGetFilterTagsQuery__
 *
 * To run a query within a React component, call `useGetFilterTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFilterTagsQuery,
    GetFilterTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilterTagsQuery, GetFilterTagsQueryVariables>(
    GetFilterTagsDocument,
    options
  );
}
export function useGetFilterTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilterTagsQuery,
    GetFilterTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilterTagsQuery, GetFilterTagsQueryVariables>(
    GetFilterTagsDocument,
    options
  );
}
export type GetFilterTagsQueryHookResult = ReturnType<
  typeof useGetFilterTagsQuery
>;
export type GetFilterTagsLazyQueryHookResult = ReturnType<
  typeof useGetFilterTagsLazyQuery
>;
export type GetFilterTagsQueryResult = Apollo.QueryResult<
  GetFilterTagsQuery,
  GetFilterTagsQueryVariables
>;
export const GetLayoutDataDocument = gql`
  query GetLayoutData {
    site: _site {
      faviconMetaTags {
        ...MetaTags
      }
    }
    footer {
      extraMenuTitle
      extraItemsLinks {
        ...MenuLinks
      }
      instagram
      pinterest
      facebook
      twitter
      youtube
      rightMenuTitle
      rightMenuLinks {
        ...MenuLinks
      }
      leftMenuTitle
      leftMenuLinks {
        ...MenuLinks
      }
      middleMenuTitle
      middleMenuLinks {
        ...MenuLinks
      }
    }
    header {
      announcementMessage
      announcementTheme
      announcementLinkText
      announcementLinkUrl
      isStickyAnnouncement
      menuLinks {
        ...MenuLinks
      }
      menuItem {
        ...MenuItems
      }
    }
    subscribePage {
      slug
    }
    flowerShopPage {
      slug
    }
  }
  ${MetaTagsFragmentDoc}
  ${MenuLinksFragmentDoc}
  ${MenuItemsFragmentDoc}
`;

/**
 * __useGetLayoutDataQuery__
 *
 * To run a query within a React component, call `useGetLayoutDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayoutDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayoutDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLayoutDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLayoutDataQuery,
    GetLayoutDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLayoutDataQuery, GetLayoutDataQueryVariables>(
    GetLayoutDataDocument,
    options
  );
}
export function useGetLayoutDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLayoutDataQuery,
    GetLayoutDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLayoutDataQuery, GetLayoutDataQueryVariables>(
    GetLayoutDataDocument,
    options
  );
}
export type GetLayoutDataQueryHookResult = ReturnType<
  typeof useGetLayoutDataQuery
>;
export type GetLayoutDataLazyQueryHookResult = ReturnType<
  typeof useGetLayoutDataLazyQuery
>;
export type GetLayoutDataQueryResult = Apollo.QueryResult<
  GetLayoutDataQuery,
  GetLayoutDataQueryVariables
>;
export const GetAllNestedPagesCountDocument = gql`
  query GetAllNestedPagesCount {
    meta: _allNestedPagesMeta {
      count
    }
  }
`;

/**
 * __useGetAllNestedPagesCountQuery__
 *
 * To run a query within a React component, call `useGetAllNestedPagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNestedPagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNestedPagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNestedPagesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllNestedPagesCountQuery,
    GetAllNestedPagesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllNestedPagesCountQuery,
    GetAllNestedPagesCountQueryVariables
  >(GetAllNestedPagesCountDocument, options);
}
export function useGetAllNestedPagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllNestedPagesCountQuery,
    GetAllNestedPagesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllNestedPagesCountQuery,
    GetAllNestedPagesCountQueryVariables
  >(GetAllNestedPagesCountDocument, options);
}
export type GetAllNestedPagesCountQueryHookResult = ReturnType<
  typeof useGetAllNestedPagesCountQuery
>;
export type GetAllNestedPagesCountLazyQueryHookResult = ReturnType<
  typeof useGetAllNestedPagesCountLazyQuery
>;
export type GetAllNestedPagesCountQueryResult = Apollo.QueryResult<
  GetAllNestedPagesCountQuery,
  GetAllNestedPagesCountQueryVariables
>;
export const GetAllNestedPagesSlugsDocument = gql`
  query GetAllNestedPagesSlugs($perPage: IntType, $skip: IntType) {
    records: allNestedPages(first: $perPage, skip: $skip) {
      slug
    }
  }
`;

/**
 * __useGetAllNestedPagesSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllNestedPagesSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNestedPagesSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNestedPagesSlugsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllNestedPagesSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllNestedPagesSlugsQuery,
    GetAllNestedPagesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllNestedPagesSlugsQuery,
    GetAllNestedPagesSlugsQueryVariables
  >(GetAllNestedPagesSlugsDocument, options);
}
export function useGetAllNestedPagesSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllNestedPagesSlugsQuery,
    GetAllNestedPagesSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllNestedPagesSlugsQuery,
    GetAllNestedPagesSlugsQueryVariables
  >(GetAllNestedPagesSlugsDocument, options);
}
export type GetAllNestedPagesSlugsQueryHookResult = ReturnType<
  typeof useGetAllNestedPagesSlugsQuery
>;
export type GetAllNestedPagesSlugsLazyQueryHookResult = ReturnType<
  typeof useGetAllNestedPagesSlugsLazyQuery
>;
export type GetAllNestedPagesSlugsQueryResult = Apollo.QueryResult<
  GetAllNestedPagesSlugsQuery,
  GetAllNestedPagesSlugsQueryVariables
>;
export const GetNestedPageBySlugDocument = gql`
  query getNestedPageBySlug($slug: String!) {
    nestedPage(filter: { slug: { eq: $slug } }) {
      id
      slug
      hideFromSearchEngines
      title
      hideTitle
      isTitleH1
      subtitle
      introParagraph
      pageHeaderType
      showPageHeader
      showPageFooter
      hasCustomLogo
      logo {
        url
        alt
        format
        responsiveImage(imgixParams: { w: 320 }) {
          ...responsiveImage
        }
      }
      sectionsBefore {
        ...ContentSectionFields
      }
      children {
        ...NestedPageChildrenFields
      }
      sectionsAfter {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${NestedPageChildrenFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetNestedPageBySlugQuery__
 *
 * To run a query within a React component, call `useGetNestedPageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNestedPageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNestedPageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetNestedPageBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNestedPageBySlugQuery,
    GetNestedPageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNestedPageBySlugQuery,
    GetNestedPageBySlugQueryVariables
  >(GetNestedPageBySlugDocument, options);
}
export function useGetNestedPageBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNestedPageBySlugQuery,
    GetNestedPageBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNestedPageBySlugQuery,
    GetNestedPageBySlugQueryVariables
  >(GetNestedPageBySlugDocument, options);
}
export type GetNestedPageBySlugQueryHookResult = ReturnType<
  typeof useGetNestedPageBySlugQuery
>;
export type GetNestedPageBySlugLazyQueryHookResult = ReturnType<
  typeof useGetNestedPageBySlugLazyQuery
>;
export type GetNestedPageBySlugQueryResult = Apollo.QueryResult<
  GetNestedPageBySlugQuery,
  GetNestedPageBySlugQueryVariables
>;
export const GetPlantCenterDocument = gql`
  query GetPlantCenter {
    plantCenter {
      id
      slug
      title
      subtitle
      description
      image {
        url
        alt
      }
      sections {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetPlantCenterQuery__
 *
 * To run a query within a React component, call `useGetPlantCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantCenterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlantCenterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlantCenterQuery,
    GetPlantCenterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlantCenterQuery, GetPlantCenterQueryVariables>(
    GetPlantCenterDocument,
    options
  );
}
export function useGetPlantCenterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlantCenterQuery,
    GetPlantCenterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlantCenterQuery, GetPlantCenterQueryVariables>(
    GetPlantCenterDocument,
    options
  );
}
export type GetPlantCenterQueryHookResult = ReturnType<
  typeof useGetPlantCenterQuery
>;
export type GetPlantCenterLazyQueryHookResult = ReturnType<
  typeof useGetPlantCenterLazyQuery
>;
export type GetPlantCenterQueryResult = Apollo.QueryResult<
  GetPlantCenterQuery,
  GetPlantCenterQueryVariables
>;
export const GetAllPlantsCountDocument = gql`
  query GetAllPlantsCount {
    meta: _allPlantPagesMeta {
      count
    }
  }
`;

/**
 * __useGetAllPlantsCountQuery__
 *
 * To run a query within a React component, call `useGetAllPlantsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlantsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlantsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPlantsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPlantsCountQuery,
    GetAllPlantsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPlantsCountQuery,
    GetAllPlantsCountQueryVariables
  >(GetAllPlantsCountDocument, options);
}
export function useGetAllPlantsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPlantsCountQuery,
    GetAllPlantsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPlantsCountQuery,
    GetAllPlantsCountQueryVariables
  >(GetAllPlantsCountDocument, options);
}
export type GetAllPlantsCountQueryHookResult = ReturnType<
  typeof useGetAllPlantsCountQuery
>;
export type GetAllPlantsCountLazyQueryHookResult = ReturnType<
  typeof useGetAllPlantsCountLazyQuery
>;
export type GetAllPlantsCountQueryResult = Apollo.QueryResult<
  GetAllPlantsCountQuery,
  GetAllPlantsCountQueryVariables
>;
export const GetAllPlantsSlugsDocument = gql`
  query GetAllPlantsSlugs($perPage: IntType, $skip: IntType) {
    records: allPlantPages(first: $perPage, skip: $skip) {
      slug
    }
  }
`;

/**
 * __useGetAllPlantsSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllPlantsSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlantsSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlantsSlugsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllPlantsSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPlantsSlugsQuery,
    GetAllPlantsSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPlantsSlugsQuery,
    GetAllPlantsSlugsQueryVariables
  >(GetAllPlantsSlugsDocument, options);
}
export function useGetAllPlantsSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPlantsSlugsQuery,
    GetAllPlantsSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPlantsSlugsQuery,
    GetAllPlantsSlugsQueryVariables
  >(GetAllPlantsSlugsDocument, options);
}
export type GetAllPlantsSlugsQueryHookResult = ReturnType<
  typeof useGetAllPlantsSlugsQuery
>;
export type GetAllPlantsSlugsLazyQueryHookResult = ReturnType<
  typeof useGetAllPlantsSlugsLazyQuery
>;
export type GetAllPlantsSlugsQueryResult = Apollo.QueryResult<
  GetAllPlantsSlugsQuery,
  GetAllPlantsSlugsQueryVariables
>;
export const GetPlantBySlugDocument = gql`
  query GetPlantBySlug($slug: String) {
    plantPage(filter: { slug: { eq: $slug } }) {
      ...PlantFields
    }
  }
  ${PlantFieldsFragmentDoc}
`;

/**
 * __useGetPlantBySlugQuery__
 *
 * To run a query within a React component, call `useGetPlantBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPlantBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlantBySlugQuery,
    GetPlantBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlantBySlugQuery, GetPlantBySlugQueryVariables>(
    GetPlantBySlugDocument,
    options
  );
}
export function useGetPlantBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlantBySlugQuery,
    GetPlantBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlantBySlugQuery, GetPlantBySlugQueryVariables>(
    GetPlantBySlugDocument,
    options
  );
}
export type GetPlantBySlugQueryHookResult = ReturnType<
  typeof useGetPlantBySlugQuery
>;
export type GetPlantBySlugLazyQueryHookResult = ReturnType<
  typeof useGetPlantBySlugLazyQuery
>;
export type GetPlantBySlugQueryResult = Apollo.QueryResult<
  GetPlantBySlugQuery,
  GetPlantBySlugQueryVariables
>;
export const GetAllPlantsDocument = gql`
  query GetAllPlants($perPage: IntType, $skip: IntType) {
    allPlantPages(first: $perPage, skip: $skip) {
      ...PlantFields
    }
  }
  ${PlantFieldsFragmentDoc}
`;

/**
 * __useGetAllPlantsQuery__
 *
 * To run a query within a React component, call `useGetAllPlantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlantsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllPlantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPlantsQuery,
    GetAllPlantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPlantsQuery, GetAllPlantsQueryVariables>(
    GetAllPlantsDocument,
    options
  );
}
export function useGetAllPlantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPlantsQuery,
    GetAllPlantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPlantsQuery, GetAllPlantsQueryVariables>(
    GetAllPlantsDocument,
    options
  );
}
export type GetAllPlantsQueryHookResult = ReturnType<
  typeof useGetAllPlantsQuery
>;
export type GetAllPlantsLazyQueryHookResult = ReturnType<
  typeof useGetAllPlantsLazyQuery
>;
export type GetAllPlantsQueryResult = Apollo.QueryResult<
  GetAllPlantsQuery,
  GetAllPlantsQueryVariables
>;
export const GetPlantShopPageDocument = gql`
  query GetPlantShopPage {
    plantShopPage {
      id
      title
      slug
      title
      text(markdown: true)
      productCollections {
        ...ProductCollectionFields
      }
      sections {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${ProductCollectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetPlantShopPageQuery__
 *
 * To run a query within a React component, call `useGetPlantShopPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantShopPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantShopPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlantShopPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlantShopPageQuery,
    GetPlantShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlantShopPageQuery, GetPlantShopPageQueryVariables>(
    GetPlantShopPageDocument,
    options
  );
}
export function useGetPlantShopPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlantShopPageQuery,
    GetPlantShopPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlantShopPageQuery,
    GetPlantShopPageQueryVariables
  >(GetPlantShopPageDocument, options);
}
export type GetPlantShopPageQueryHookResult = ReturnType<
  typeof useGetPlantShopPageQuery
>;
export type GetPlantShopPageLazyQueryHookResult = ReturnType<
  typeof useGetPlantShopPageLazyQuery
>;
export type GetPlantShopPageQueryResult = Apollo.QueryResult<
  GetPlantShopPageQuery,
  GetPlantShopPageQueryVariables
>;
export const GetPressAticlesDocument = gql`
  query GetPressAticles {
    allPressArticles(
      orderBy: date_DESC
      first: 100
      filter: { isActive: { eq: "true" } }
    ) {
      ...pressArticlesFields
    }
  }
  ${PressArticlesFieldsFragmentDoc}
`;

/**
 * __useGetPressAticlesQuery__
 *
 * To run a query within a React component, call `useGetPressAticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPressAticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPressAticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPressAticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPressAticlesQuery,
    GetPressAticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPressAticlesQuery, GetPressAticlesQueryVariables>(
    GetPressAticlesDocument,
    options
  );
}
export function useGetPressAticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPressAticlesQuery,
    GetPressAticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPressAticlesQuery,
    GetPressAticlesQueryVariables
  >(GetPressAticlesDocument, options);
}
export type GetPressAticlesQueryHookResult = ReturnType<
  typeof useGetPressAticlesQuery
>;
export type GetPressAticlesLazyQueryHookResult = ReturnType<
  typeof useGetPressAticlesLazyQuery
>;
export type GetPressAticlesQueryResult = Apollo.QueryResult<
  GetPressAticlesQuery,
  GetPressAticlesQueryVariables
>;
export const GetPressPageDocument = gql`
  query GetPressPage {
    pressPage {
      id
      title
      subtitle
      contact
      slug
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetPressPageQuery__
 *
 * To run a query within a React component, call `useGetPressPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPressPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPressPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPressPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPressPageQuery,
    GetPressPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPressPageQuery, GetPressPageQueryVariables>(
    GetPressPageDocument,
    options
  );
}
export function useGetPressPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPressPageQuery,
    GetPressPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPressPageQuery, GetPressPageQueryVariables>(
    GetPressPageDocument,
    options
  );
}
export type GetPressPageQueryHookResult = ReturnType<
  typeof useGetPressPageQuery
>;
export type GetPressPageLazyQueryHookResult = ReturnType<
  typeof useGetPressPageLazyQuery
>;
export type GetPressPageQueryResult = Apollo.QueryResult<
  GetPressPageQuery,
  GetPressPageQueryVariables
>;
export const GetAllProductsCountDocument = gql`
  query GetAllProductsCount {
    meta: _allProductsMeta(filter: { shopifyProduct: { exists: true } }) {
      count
    }
  }
`;

/**
 * __useGetAllProductsCountQuery__
 *
 * To run a query within a React component, call `useGetAllProductsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllProductsCountQuery,
    GetAllProductsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllProductsCountQuery,
    GetAllProductsCountQueryVariables
  >(GetAllProductsCountDocument, options);
}
export function useGetAllProductsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllProductsCountQuery,
    GetAllProductsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllProductsCountQuery,
    GetAllProductsCountQueryVariables
  >(GetAllProductsCountDocument, options);
}
export type GetAllProductsCountQueryHookResult = ReturnType<
  typeof useGetAllProductsCountQuery
>;
export type GetAllProductsCountLazyQueryHookResult = ReturnType<
  typeof useGetAllProductsCountLazyQuery
>;
export type GetAllProductsCountQueryResult = Apollo.QueryResult<
  GetAllProductsCountQuery,
  GetAllProductsCountQueryVariables
>;
export const GetAllProductsSlugsDocument = gql`
  query GetAllProductsSlugs($perPage: IntType, $skip: IntType) {
    records: allProducts(
      filter: {
        shopifyProduct: { exists: true }
        OR: [
          { isUpselling: { eq: false } }
          { isUpselling: { eq: true }, noPdp: { eq: false } }
        ]
      }
      first: $perPage
      skip: $skip
    ) {
      slug
    }
  }
`;

/**
 * __useGetAllProductsSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsSlugsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllProductsSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllProductsSlugsQuery,
    GetAllProductsSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllProductsSlugsQuery,
    GetAllProductsSlugsQueryVariables
  >(GetAllProductsSlugsDocument, options);
}
export function useGetAllProductsSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllProductsSlugsQuery,
    GetAllProductsSlugsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllProductsSlugsQuery,
    GetAllProductsSlugsQueryVariables
  >(GetAllProductsSlugsDocument, options);
}
export type GetAllProductsSlugsQueryHookResult = ReturnType<
  typeof useGetAllProductsSlugsQuery
>;
export type GetAllProductsSlugsLazyQueryHookResult = ReturnType<
  typeof useGetAllProductsSlugsLazyQuery
>;
export type GetAllProductsSlugsQueryResult = Apollo.QueryResult<
  GetAllProductsSlugsQuery,
  GetAllProductsSlugsQueryVariables
>;
export const GetProductBySlugDocument = gql`
  query GetProductBySlug($slug: String) {
    product(filter: { slug: { eq: $slug }, shopifyProduct: { exists: true } }) {
      ...ProductFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetProductBySlugQuery__
 *
 * To run a query within a React component, call `useGetProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetProductBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductBySlugQuery,
    GetProductBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductBySlugQuery, GetProductBySlugQueryVariables>(
    GetProductBySlugDocument,
    options
  );
}
export function useGetProductBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductBySlugQuery,
    GetProductBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductBySlugQuery,
    GetProductBySlugQueryVariables
  >(GetProductBySlugDocument, options);
}
export type GetProductBySlugQueryHookResult = ReturnType<
  typeof useGetProductBySlugQuery
>;
export type GetProductBySlugLazyQueryHookResult = ReturnType<
  typeof useGetProductBySlugLazyQuery
>;
export type GetProductBySlugQueryResult = Apollo.QueryResult<
  GetProductBySlugQuery,
  GetProductBySlugQueryVariables
>;
export const GetProductsByIdsDocument = gql`
  query GetProductsByIds($ItemIds: [ItemId!]) {
    allProducts(
      filter: { id: { in: $ItemIds }, shopifyProduct: { exists: true } }
    ) {
      ...ProductFieldsCart
    }
  }
  ${ProductFieldsCartFragmentDoc}
`;

/**
 * __useGetProductsByIdsQuery__
 *
 * To run a query within a React component, call `useGetProductsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByIdsQuery({
 *   variables: {
 *      ItemIds: // value for 'ItemIds'
 *   },
 * });
 */
export function useGetProductsByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsByIdsQuery,
    GetProductsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsByIdsQuery, GetProductsByIdsQueryVariables>(
    GetProductsByIdsDocument,
    options
  );
}
export function useGetProductsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsByIdsQuery,
    GetProductsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsByIdsQuery,
    GetProductsByIdsQueryVariables
  >(GetProductsByIdsDocument, options);
}
export type GetProductsByIdsQueryHookResult = ReturnType<
  typeof useGetProductsByIdsQuery
>;
export type GetProductsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetProductsByIdsLazyQuery
>;
export type GetProductsByIdsQueryResult = Apollo.QueryResult<
  GetProductsByIdsQuery,
  GetProductsByIdsQueryVariables
>;
export const GetProductsByTagsDocument = gql`
  query GetProductsByTags($tagIds: [ItemId!]) {
    allProducts(
      filter: { tags: { anyIn: $tagIds }, shopifyProduct: { exists: true } }
    ) {
      ...ProductFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetProductsByTagsQuery__
 *
 * To run a query within a React component, call `useGetProductsByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByTagsQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useGetProductsByTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsByTagsQuery,
    GetProductsByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsByTagsQuery,
    GetProductsByTagsQueryVariables
  >(GetProductsByTagsDocument, options);
}
export function useGetProductsByTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsByTagsQuery,
    GetProductsByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsByTagsQuery,
    GetProductsByTagsQueryVariables
  >(GetProductsByTagsDocument, options);
}
export type GetProductsByTagsQueryHookResult = ReturnType<
  typeof useGetProductsByTagsQuery
>;
export type GetProductsByTagsLazyQueryHookResult = ReturnType<
  typeof useGetProductsByTagsLazyQuery
>;
export type GetProductsByTagsQueryResult = Apollo.QueryResult<
  GetProductsByTagsQuery,
  GetProductsByTagsQueryVariables
>;
export const GetTagBySlugDocument = gql`
  query GetTagBySlug($slug: String!) {
    productTag(filter: { slug: { eq: $slug } }) {
      id
      slug
      title
      tagMessage
      backgroundColor {
        hex
      }
      textColor {
        hex
      }
    }
  }
`;

/**
 * __useGetTagBySlugQuery__
 *
 * To run a query within a React component, call `useGetTagBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetTagBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTagBySlugQuery,
    GetTagBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagBySlugQuery, GetTagBySlugQueryVariables>(
    GetTagBySlugDocument,
    options
  );
}
export function useGetTagBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTagBySlugQuery,
    GetTagBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagBySlugQuery, GetTagBySlugQueryVariables>(
    GetTagBySlugDocument,
    options
  );
}
export type GetTagBySlugQueryHookResult = ReturnType<
  typeof useGetTagBySlugQuery
>;
export type GetTagBySlugLazyQueryHookResult = ReturnType<
  typeof useGetTagBySlugLazyQuery
>;
export type GetTagBySlugQueryResult = Apollo.QueryResult<
  GetTagBySlugQuery,
  GetTagBySlugQueryVariables
>;
export const GetAllProductsDocument = gql`
  query GetAllProducts(
    $perPage: IntType
    $skip: IntType
    $isSubscription: BooleanType
  ) {
    allProducts(
      first: $perPage
      skip: $skip
      filter: {
        shopifyProduct: { exists: true }
        isSubscription: { eq: $isSubscription }
      }
    ) {
      ...ProductFieldsShort
    }
  }
  ${ProductFieldsShortFragmentDoc}
`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      skip: // value for 'skip'
 *      isSubscription: // value for 'isSubscription'
 *   },
 * });
 */
export function useGetAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllProductsQuery,
    GetAllProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(
    GetAllProductsDocument,
    options
  );
}
export function useGetAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllProductsQuery,
    GetAllProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(
    GetAllProductsDocument,
    options
  );
}
export type GetAllProductsQueryHookResult = ReturnType<
  typeof useGetAllProductsQuery
>;
export type GetAllProductsLazyQueryHookResult = ReturnType<
  typeof useGetAllProductsLazyQuery
>;
export type GetAllProductsQueryResult = Apollo.QueryResult<
  GetAllProductsQuery,
  GetAllProductsQueryVariables
>;
export const GetCollectionByTagsDocument = gql`
  query GetCollectionByTags($tagIds: [ItemId!]) {
    allProductCollections(filter: { productTags: { anyIn: $tagIds } }) {
      id
      title
      productTags {
        id
      }
    }
  }
`;

/**
 * __useGetCollectionByTagsQuery__
 *
 * To run a query within a React component, call `useGetCollectionByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByTagsQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useGetCollectionByTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCollectionByTagsQuery,
    GetCollectionByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionByTagsQuery,
    GetCollectionByTagsQueryVariables
  >(GetCollectionByTagsDocument, options);
}
export function useGetCollectionByTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionByTagsQuery,
    GetCollectionByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionByTagsQuery,
    GetCollectionByTagsQueryVariables
  >(GetCollectionByTagsDocument, options);
}
export type GetCollectionByTagsQueryHookResult = ReturnType<
  typeof useGetCollectionByTagsQuery
>;
export type GetCollectionByTagsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionByTagsLazyQuery
>;
export type GetCollectionByTagsQueryResult = Apollo.QueryResult<
  GetCollectionByTagsQuery,
  GetCollectionByTagsQueryVariables
>;
export const GetProductByCollectionDocument = gql`
  query GetProductByCollection($tagIds: [ItemId!]) {
    allProductCollections(filter: { productTags: { anyIn: $tagIds } }) {
      id
      title
    }
  }
`;

/**
 * __useGetProductByCollectionQuery__
 *
 * To run a query within a React component, call `useGetProductByCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByCollectionQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useGetProductByCollectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductByCollectionQuery,
    GetProductByCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductByCollectionQuery,
    GetProductByCollectionQueryVariables
  >(GetProductByCollectionDocument, options);
}
export function useGetProductByCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductByCollectionQuery,
    GetProductByCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductByCollectionQuery,
    GetProductByCollectionQueryVariables
  >(GetProductByCollectionDocument, options);
}
export type GetProductByCollectionQueryHookResult = ReturnType<
  typeof useGetProductByCollectionQuery
>;
export type GetProductByCollectionLazyQueryHookResult = ReturnType<
  typeof useGetProductByCollectionLazyQuery
>;
export type GetProductByCollectionQueryResult = Apollo.QueryResult<
  GetProductByCollectionQuery,
  GetProductByCollectionQueryVariables
>;
export const GetSubscribePageDocument = gql`
  query GetSubscribePage {
    subscribePage {
      title
      subtitle
      text
      slug
      subscribeContent {
        ...SubscriptionPlan
      }
      contentSections {
        ...ContentSectionFields
      }
      seo: _seoMetaTags {
        ...MetaTags
      }
    }
  }
  ${SubscriptionPlanFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${MetaTagsFragmentDoc}
`;

/**
 * __useGetSubscribePageQuery__
 *
 * To run a query within a React component, call `useGetSubscribePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscribePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscribePageQuery,
    GetSubscribePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscribePageQuery, GetSubscribePageQueryVariables>(
    GetSubscribePageDocument,
    options
  );
}
export function useGetSubscribePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscribePageQuery,
    GetSubscribePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscribePageQuery,
    GetSubscribePageQueryVariables
  >(GetSubscribePageDocument, options);
}
export type GetSubscribePageQueryHookResult = ReturnType<
  typeof useGetSubscribePageQuery
>;
export type GetSubscribePageLazyQueryHookResult = ReturnType<
  typeof useGetSubscribePageLazyQuery
>;
export type GetSubscribePageQueryResult = Apollo.QueryResult<
  GetSubscribePageQuery,
  GetSubscribePageQueryVariables
>;
export const GetProductByTagsDocument = gql`
  query GetProductByTags($tagIds: [ItemId!]) {
    allProducts(
      first: 20
      filter: { tags: { anyIn: $tagIds }, shopifyProduct: { exists: true } }
    ) {
      id
      title
      shopifyProduct
      slug
      gallery {
        id
        format
        url
        alt
        thumbUrl: url(
          imgixParams: {
            h: 150
            w: 150
            fit: crop
            crop: [focalpoint]
            ar: "4:3"
            fpX: "0.4"
          }
        )
        responsiveImage(imgixParams: { w: 600 }) {
          ...responsiveImage
        }
        thumbResponsive: responsiveImage(imgixParams: { w: 150 }) {
          ...responsiveImage
        }
      }
      tags {
        id
        slug
        title
        tagMessage
        tagType
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;

/**
 * __useGetProductByTagsQuery__
 *
 * To run a query within a React component, call `useGetProductByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByTagsQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useGetProductByTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductByTagsQuery,
    GetProductByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductByTagsQuery, GetProductByTagsQueryVariables>(
    GetProductByTagsDocument,
    options
  );
}
export function useGetProductByTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductByTagsQuery,
    GetProductByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductByTagsQuery,
    GetProductByTagsQueryVariables
  >(GetProductByTagsDocument, options);
}
export type GetProductByTagsQueryHookResult = ReturnType<
  typeof useGetProductByTagsQuery
>;
export type GetProductByTagsLazyQueryHookResult = ReturnType<
  typeof useGetProductByTagsLazyQuery
>;
export type GetProductByTagsQueryResult = Apollo.QueryResult<
  GetProductByTagsQuery,
  GetProductByTagsQueryVariables
>;
export const GetProductsByFiltersByTagsDocument = gql`
  query GetProductsByFiltersByTags($tagIds: [ItemId!]) {
    allProducts(
      first: 20
      filter: {
        tagFilter: { anyIn: $tagIds }
        shopifyProduct: { exists: true }
        disableDatePicker: { eq: "false" }
        _status: { notIn: draft }
      }
    ) {
      id
      title
      shopifyProduct
      slug
      gallery {
        id
        format
        url
        alt
        thumbUrl: url(
          imgixParams: {
            h: 150
            w: 150
            fit: crop
            crop: [focalpoint]
            ar: "4:3"
            fpX: "0.4"
          }
        )
        responsiveImage(imgixParams: { w: 600 }) {
          ...responsiveImage
        }
        thumbResponsive: responsiveImage(imgixParams: { w: 150 }) {
          ...responsiveImage
        }
      }
      tags {
        id
        slug
        title
        tagMessage
        tagType
      }
    }
  }
  ${ResponsiveImageFragmentDoc}
`;

/**
 * __useGetProductsByFiltersByTagsQuery__
 *
 * To run a query within a React component, call `useGetProductsByFiltersByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByFiltersByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByFiltersByTagsQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useGetProductsByFiltersByTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsByFiltersByTagsQuery,
    GetProductsByFiltersByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsByFiltersByTagsQuery,
    GetProductsByFiltersByTagsQueryVariables
  >(GetProductsByFiltersByTagsDocument, options);
}
export function useGetProductsByFiltersByTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsByFiltersByTagsQuery,
    GetProductsByFiltersByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsByFiltersByTagsQuery,
    GetProductsByFiltersByTagsQueryVariables
  >(GetProductsByFiltersByTagsDocument, options);
}
export type GetProductsByFiltersByTagsQueryHookResult = ReturnType<
  typeof useGetProductsByFiltersByTagsQuery
>;
export type GetProductsByFiltersByTagsLazyQueryHookResult = ReturnType<
  typeof useGetProductsByFiltersByTagsLazyQuery
>;
export type GetProductsByFiltersByTagsQueryResult = Apollo.QueryResult<
  GetProductsByFiltersByTagsQuery,
  GetProductsByFiltersByTagsQueryVariables
>;
