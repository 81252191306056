import * as React from "react";
import {
  LoyaltyProgramAuthData,
  LoyaltyProgramData,
} from "src/shared/utils/loyalty-program/types";
import * as crypto from "crypto";

export const getLoyaltyProgramData = (
  customerIdentityJWT?: LoyaltyProgramAuthData
): LoyaltyProgramData => {
  let props: LoyaltyProgramData;
  const token = process.env.NEXT_PUBLIC_LOYALTYPROGRAM_TOKEN ?? "";
  if (customerIdentityJWT) {
    props = {
      ...customerIdentityJWT,
      token,
    };
  } else {
    props = { token };
  }
  return props;
};

export function createLoyaltyHash(customerId: string, apiKey: string) {
  const hash = crypto.createHmac('sha256', apiKey)
                     .update(customerId)
                     .digest('hex');
  return hash;
}
export const getLoyaltyFeatureData = (
  customerId: string
): LoyaltyProgramData => {
  let props: LoyaltyProgramData;
  const token = process.env.LOYALTY_PROGRAM_API_KEY ?? "";
  const LoyaltyHash = createLoyaltyHash(customerId,token);
  if (LoyaltyHash) {
    props = {
      customerIdentityJWT:LoyaltyHash,
      token,
    };
  } else {
    props = { token };
  }
  return props;
};

export const useInitLoyaltyProgram = (initData?: LoyaltyProgramData) => {
  const defaultInitData = React.useMemo(() => getLoyaltyProgramData(), []);
  const data = initData ?? defaultInitData;
};



