import React from "react";
import { StyledStep, StyledStepLabel } from "src/features/ProductForm/styled";
import tw, { styled } from "twin.macro";
import { ProductDatePicker } from "src/features/ProductForm/ProductDatePicker";
import {
  getSuperGiftItemTitle,
  Props as SuperGiftItemProps,
} from "src/features/SuperGift/SuperGiftItem";
import { parseDatepickerDateStr } from "src/shared/utils/products";
import { SaveItemDataFn } from "src/features/SuperGift/SuperGiftProductForm";

const invalidDateMsg =
  "Please adjust the delivery date. Make sure your dates are in logical sequence and don't overlap with each other.";
export const StyledButton = styled.button((props) => [
  tw`w-full p-2 rounded border text-xs uppercase tracking-wider font-roboto text-gray-700 border-gray-300`,
  props.disabled && tw`opacity-25 cursor-not-allowed bg-gray-100`,
]);
type Props = {
  handleSave: SaveItemDataFn;
} & SuperGiftItemProps;
export const SuperGiftItemForm: React.FC<Props> = (props) => {
  const {
    itemIndex,
    product,
    disabledDates,
    itemData,
    invalidDate,
    handleSave,
  } = props;
  const giftMessageMaxLength = 140;
  const [deliveryDate, setDeliveryDate] = React.useState(
    itemData?.deliveryDate ?? ""
  );
  const [giftMessage, setGiftMessage] = React.useState(
    itemData?.giftMessage ?? ""
  );
  const hasDeliveryDate = deliveryDate.length > 0;
  const hasGiftMessage = giftMessage.length > 0;
  const hasRequiredData = hasDeliveryDate;
  const { date: initialDate } = parseDatepickerDateStr(
    itemData?.deliveryDate ?? ""
  );
  const title = getSuperGiftItemTitle(`${itemIndex + 1}`);
  return (
    <div>
      <StyledStep css={tw`m-0`} active={!deliveryDate} done={hasDeliveryDate}>
        <StyledStepLabel css={tw`mb-2`}>
          Delivery Date{" "}
          <span css={tw`inline-block`}>{hasDeliveryDate ? "" : "*"}</span>
        </StyledStepLabel>
        <ProductDatePicker
          product={product}
          onChange={(dt) => setDeliveryDate(dt)}
          disabledDates={disabledDates}
          initialDate={initialDate}
        />
        {invalidDate && (
          <div css={tw`text-sm mt-1 font-roboto text-bloomsy-darkred`}>
            {invalidDateMsg}
          </div>
        )}
      </StyledStep>
      <StyledStep css={tw`m-0`} active done={hasGiftMessage}>
        <StyledStepLabel css={tw`mb-2`}>
          Gift Message (optional)
        </StyledStepLabel>
        <textarea
          maxLength={giftMessageMaxLength}
          css={tw`w-full`}
          placeholder="Add a short message"
          value={giftMessage}
          onChange={(e) => setGiftMessage(e.target.value)}
        />
        <div css={tw`text-xs text-gray-600`}>
          {giftMessage.length} / {giftMessageMaxLength} characters
        </div>
      </StyledStep>
      <div>
        <StyledButton
          disabled={!hasRequiredData}
          type="button"
          onClick={() =>
            handleSave(itemIndex, {
              deliveryDate,
              giftMessage,
            })
          }
        >
          <span css={tw`font-bold`}>Save</span> {title}
        </StyledButton>
      </div>
    </div>
  );
};
