/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import Cookies from "js-cookie";
import { makeVar, useReactiveVar } from "@apollo/client";
import {
  AUTO_RENEW_PROP,
  cartCookieConfig,
  CMS_PRODUCT_ID_PROP,
  DELIVERY_DATE_PROP,
  RECIPIENT_EMAIL_PROP,
  GIFT_MESSAGE_PROP,
  IS_GIFT_PROP,
  RECIPIENT_NAME_PROP,
  ONE_TIME_VARIANT_PROP,
  ONE_TIME_VARIANT_ID_PROP,
  ONE_TIME_VARIANT_EXCLUDED_IDS_PROP,
  VARIANT_TITLE_PROP,
  IS_SUBSCRIPTION_PRODUCT_PROP,
  THIRD_PARTY_PROP,
  PRODUCT_TAGS_PROP,
  IS_NEXT_DAY_PRODUCT_PROP,
} from "src/features/Cart/constants";
import {
  VariantFieldsFragment,
  GetProductsByIdsQueryResult,
  ProductFieldsCartFragment,
  ProductFieldsFragment,
  ProductFieldsShortFragment,
  useGetProductsByIdsQuery,
} from "src/generated/datocms-types";
import { getCmsAllItemIdsFromCheckoutData } from "src/features/Cart/utils";
import { updateUpsellingProducts } from "src/features/Cart/CartDrawer/GetUpSellingProduct";
import wretch from "wretch";
import { produce } from "immer";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react";
import { getShopifyIdInt } from "src/shared/helpers";
import {
  CartCreateInput,
  CartLinesAddInput,
  CartLinesRemoveInput,
  ShopifyCartData,
  ShopifyLineItem,
  ShopifyVariantNode,
} from "src/shared/types/shopify";
import { IS_PROD_ENV } from "src/constants";
import { dataLayer } from "src/features/Analytics";
import { getSession } from "next-auth/react";
import { FilterProduct, KeyValueProp } from "src/shared/types";
import { impactDataLayerEvent } from "../Analytics/utils";
import { klaviyoIdentify, klaviyoAddedToCart } from "../Analytics/klaviyo";
import { getUpsellingProducts } from "./CartDrawer/GetUpSellingProduct";
import { CartLinesUpdateInput } from "./types";

// see: pages/api/cart
export const cartApi = wretch("/api/cart")
  .content("application/json")
  .accept("application/json");

export const facebookApi = wretch("/api/facebook-conversion")
  .content("application/json")
  .accept("application/json");

const checkoutVar = makeVar<ShopifyCartData>(null);
const cartVisibleVar = makeVar(false);
export const cartBusy = makeVar(false);
const invalidDelivaryDatesCount = makeVar(0);
const invalidDeliveryDates = makeVar(new Set());
export const currentDeliveryDate = makeVar("");
export const currentIsValidZipCode = makeVar(false);
export const currentVariant = makeVar<ShopifyVariantNode | undefined>(
  undefined
);
export const currentAddToCart = makeVar<(() => addToCartFn) | null>(null);

export const currentOneTimeVariant = makeVar<VariantFieldsFragment | undefined>(
  undefined
);
export const currentUpsellingList = makeVar<
  ProductFieldsFragment[] | undefined
>(undefined);
export const currentSwitchUpsellingList = makeVar<
  ProductFieldsFragment[] | undefined
>(undefined);

export const currentSubTotal = makeVar<number>(0);
export const currentFilterSelection = makeVar<FilterProduct[] | undefined>(
  undefined
);
export const currentDisabledFilters = makeVar<FilterProduct[] | undefined>(
  undefined
);
const clearCart = () => {
  checkoutVar(null);
  Cookies.remove(cartCookieConfig.name);
};
const getCartToken = () => {
  return Cookies.get(cartCookieConfig.name);
};
const saveCheckoutExpirationToken = async (token: string) => {
  try {
    await cartApi.url("/log_checkout_expire").post({ token }).json();
    // eslint-disable-next-line no-empty
  } catch (error) {}
};
const setCartToken = (token: string) => {
  Cookies.set(cartCookieConfig.name, token, {
    expires: cartCookieConfig.expires,
  });
  saveCheckoutExpirationToken(token);
};
const updateCartToken = (token: string) => {
  Cookies.set(cartCookieConfig.name, token, {
    expires: cartCookieConfig.expires,
  });
};
const loadCart = async () => {
  const cartId = getCartToken();
  if (!cartId) return;
  try {
    cartBusy(true);
    const res = (await cartApi
      .url("/read")
      .post({ cartId })
      .json()) as ShopifyCartData;
    // something went wrong?
    if (!res?.cart) {
      clearCart();
      cartBusy(false);
      return;
    }
    checkoutVar(res);
    cartBusy(false);
  } catch (err) {
    checkoutVar(null);
    cartBusy(false);
  }
};
const createCart = async (data: CartCreateInput) => {
  try {
    cartBusy(true);
    const res = (await cartApi
      .url("/create")
      .post(data)
      .json()) as ShopifyCartData;
    checkoutVar(res);
    setCartToken(res?.cart?.id ?? "");
    cartBusy(false);
  } catch (err) {
    cartBusy(false);
  }
};
const addLineCart = async (data: CartLinesAddInput) => {
  try {
    cartBusy(true);
    const res = await cartApi.url("/add").post(data).json();
    if (res?.cart) {
      checkoutVar(res as ShopifyCartData);
      updateCartToken(data.cartId);
      await updateUpsellingProducts(checkoutVar());
    }
    cartBusy(false);
  } catch (err) {
    cartBusy(false);
  }
};
const removeLineCart = async (data: CartLinesRemoveInput) => {
  try {
    cartBusy(true);
    const res = await cartApi.url("/remove").post(data).json();
    if (res?.cart) {
      checkoutVar(res as ShopifyCartData);
      await updateUpsellingProducts(checkoutVar());
      updateCartToken(data.cartId);
    }
    cartBusy(false);
  } catch (err) {
    cartBusy(false);
  }
};
const updateLineCart = async (data: CartLinesUpdateInput) => {
  try {
    cartBusy(true);
    const res = await cartApi.url("/update").put(data).json();
    if (res?.cart) {
      checkoutVar(res as ShopifyCartData);
      await updateUpsellingProducts(checkoutVar());
      updateCartToken(data.cartId);
    }
    cartBusy(false);
  } catch (err) {
    cartBusy(false);
  }
};
const updateCart = async (
  data: ShopifyLineItem[],
  operation: "add" | "remove" | "update"
) => {
  const cartId = getCartToken();
  if (operation === "add") {
    if (!cartId) {
      const input: CartCreateInput = {
        input: {
          lines: data?.map((line) => ({
            merchandiseId: line.merchandise?.id ?? "",
            quantity: line.quantity ?? 0,
            sellingPlanId: line.sellingPlanAllocation?.sellingPlan.id,
            attributes: line.attributes,
          })),
        },
      };
      await createCart(input);
      return;
    }
    const input: CartLinesAddInput = {
      cartId,
      lines: data?.map((line) => ({
        merchandiseId: line.merchandise?.id ?? "",
        quantity: line.quantity ?? 0,
        sellingPlanId: line.sellingPlanAllocation?.sellingPlan.id,
        attributes: line.attributes,
      })),
    };
    try {
      await addLineCart(input);
      return;
    } catch (err) {
      cartBusy(false);
    }
  }
  if (operation === "remove" && cartId && data?.length > 0) {
    const input: CartLinesRemoveInput = {
      cartId,
      lineIds: data?.map((line) => line.id ?? ""),
    };
    try {
      await removeLineCart(input);
      return;
    } catch (err) {
      cartBusy(false);
    }
  }
  if (operation === "update" && cartId && data?.length > 0) {
    const input: CartLinesUpdateInput = {
      cartId,
      lines: data?.map((line) => ({
        id: line.id ?? "",
        quantity: line.quantity ?? 0,
        attributes: line.attributes,
        merchandiseId: line.merchandise?.id ?? "",
        sellingPlanId: line.sellingPlanAllocation?.sellingPlan.id,
      })),
    };
    try {
      await updateLineCart(input);
      return;
    } catch (err) {
      cartBusy(false);
    }
  }
};

const getCheckoutUrl = () => {
  const checkout = checkoutVar();
  return checkout?.cart?.checkoutUrl ?? "";
};

const addItem = async (item: ShopifyLineItem[]) => {
  await updateCart(item, "add");
};

const editItem = async (item: ShopifyLineItem[]) => {
  await updateCart(item, "update");
};

const getIsThereNextDayProductInTheCart = (newCheckout: ShopifyCartData) => {
  return (
    newCheckout?.cart.lines.edges?.some((p) =>
      p.node.attributes?.some(
        (attr) =>
          attr.key === IS_NEXT_DAY_PRODUCT_PROP &&
          attr.value?.toLocaleLowerCase() === "true"
      )
    ) ?? false
  );
};

const getAdditionalUpsellingProductsIPD = (
  listUpsellingProductsAdd: string[],
  newCheckout: ShopifyCartData,
  listUpSellingProducts: ProductFieldsFragment[]
) => {
  let additionalUpsellingProducts: string[] = [];
  const newCheckoutIds =
    newCheckout?.cart?.lines?.edges?.map(
      (li) =>
        li.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value
    ) ?? [];

  additionalUpsellingProducts = listUpSellingProducts
    .filter((product) => product.upsellingProducts.length > 0)
    .filter((product) => {
      const upSellingProductIds = product.upsellingProducts.map((us) => us.id);
      return upSellingProductIds?.some((r) => newCheckoutIds?.indexOf(r) >= 0);
    })
    .filter((up) => newCheckoutIds.some((productId) => productId === up.id))
    .map((product) => product.id);

  return [...listUpsellingProductsAdd, ...additionalUpsellingProducts];
};

const getAdditionalUpsellingForAllProducts = (
  listUpsellingProductsAdd: string[],
  newCheckout: ShopifyCartData,
  listUpSellingProducts: ProductFieldsFragment[]
) => {
  let additionalUpsellingProducts: string[] = [];
  const newCheckoutIds =
    newCheckout?.cart?.lines?.edges?.map(
      (li) =>
        li.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value
    ) ?? [];

  additionalUpsellingProducts = listUpSellingProducts
    .filter(
      (product) =>
        product.upsellingProducts.length === 0 && !product.isAvailableForNextday
    )
    .filter((up) => newCheckoutIds.some((productId) => productId === up.id))
    .map((product) => product.id);

  return [...listUpsellingProductsAdd, ...additionalUpsellingProducts];
};

const getAdditionalUpsellingProductsNextDay = (
  listUpsellingProductsAdd: string[],
  newCheckout: ShopifyCartData,
  listUpSellingProducts: ProductFieldsFragment[],
  isThereNextDayProductInTheCart: boolean
) => {
  let additionalUpsellingProducts: string[] = [];

  const newCheckoutIds =
    newCheckout?.cart?.lines?.edges?.map(
      (li) =>
        li.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value
    ) ?? [];

  if (isThereNextDayProductInTheCart) {
    additionalUpsellingProducts = (
      listUpSellingProducts
        ?.filter(
          (up) =>
            up.isAvailableForNextday &&
            !listUpsellingProductsAdd.includes(up.id)
        )
        .filter((up) =>
          newCheckoutIds.some((productId) => productId === up.id)
        ) ?? []
    ).map((up) => up.id);
  }

  return [...listUpsellingProductsAdd, ...additionalUpsellingProducts];
};

const getAdditionalUpsellingProductsNextDayExclude = (
  listUpsellingProductsAdd: string[],
  newCheckout: ShopifyCartData,
  listUpSellingProducts: ProductFieldsFragment[],
  listUpsellingProductsIPD: string[]
) => {
  let listUpsellingNextDayProductsExclude: string[] = [];

  // list next day products added to the cart
  const listNextDayProductsNotUpsellingInCart =
    newCheckout?.cart.lines.edges
      ?.filter((p) =>
        p.node.attributes?.some(
          (attr) =>
            attr.key === IS_NEXT_DAY_PRODUCT_PROP &&
            attr.value?.toLocaleLowerCase() === "true"
        )
      )
      .map(
        (p) =>
          p.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
            ?.value ?? ""
      ) ?? [];

  // list of next day products that will be excluded for some upselling products
  const listNextDayProductToExclude = listUpSellingProducts
    ?.filter(
      (up) => up.isAvailableForNextday && up.excludeNextdayProducts?.length > 0
    )
    .map((up) => up.excludeNextdayProducts)
    .flat()
    .filter((pr) =>
      listNextDayProductsNotUpsellingInCart.some((pid) => pid === pr.id)
    );

  // check if there are any next day product in the cart that are not excluded for some upselling product
  const isThereNextDayRest = listNextDayProductsNotUpsellingInCart.some(
    (pid) => !listNextDayProductToExclude?.some((pex) => pex.id === pid)
  );

  // if there are any next day product in the cart that are not excluded for some upselling product, no upselling product is excluded
  if (isThereNextDayRest) return listUpsellingProductsAdd;

  const listUpsellingNextDayProductsToExclude =
    listUpSellingProducts
      ?.filter(
        (up: ProductFieldsFragment) =>
          up.isAvailableForNextday &&
          up.excludeNextdayProducts?.length > 0 &&
          up.excludeNextdayProducts?.some((pex) =>
            listNextDayProductToExclude?.some((pr) => pr.id === pex.id)
          )
      )
      ?.map((m: ProductFieldsFragment) => m.id ?? "") ?? [];

  if ((listNextDayProductToExclude?.length ?? 0) > 0) {
    listUpsellingNextDayProductsToExclude.forEach((upid) => {
      const isExludeInAllProductInTheCart = listNextDayProductToExclude.every(
        (p) => {
          return listUpSellingProducts?.some(
            (up: ProductFieldsFragment) =>
              up.isAvailableForNextday &&
              up.excludeNextdayProducts?.length > 0 &&
              up.id === upid &&
              up.excludeNextdayProducts?.some((pex) => pex.id === p.id)
          );
        }
      );
      if (
        isExludeInAllProductInTheCart &&
        listNextDayProductToExclude.length > 0
      )
        listUpsellingNextDayProductsExclude.push(upid);
    });
  }

  // Upselling products that have to be displayed with the IPD products are not excluded
  listUpsellingNextDayProductsExclude =
    listUpsellingNextDayProductsExclude.filter(
      (upid) => !listUpsellingProductsIPD.includes(upid)
    );

  return listUpsellingProductsAdd.filter(
    (pid) => !listUpsellingNextDayProductsExclude.includes(pid)
  );
};

const getAdditionalUpsellingProductsWithoutProduct = (
  listUpsellingProductsAdd: string[],
  newCheckout: ShopifyCartData,
  listUpSellingProducts: ProductFieldsFragment[]
) => {
  const areAllCartProductsUpselling = newCheckout?.cart?.lines?.edges
    ?.filter(
      (pcart) =>
        !listUpsellingProductsAdd.includes(
          pcart.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
            ?.value ?? ""
        )
    )
    .every((pcart) => {
      return listUpSellingProducts?.some(
        (up) =>
          up.id ===
            pcart.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
              ?.value ?? ""
      );
    });

  if (
    areAllCartProductsUpselling &&
    (newCheckout?.cart?.lines?.edges?.length ?? 0) > 0
  ) {
    return [];
  }

  return listUpsellingProductsAdd;
};

const getAdditionalUpsellingProductsNextDayExcludePerVariant = (
  listUpsellingProductsAdd: string[],
  newCheckout: ShopifyCartData
) => {
  let removeAdditionalUpsellingProducts: string[] = [];

  // list product with variants added to the cart
  const newCheckoutIds =
    newCheckout?.cart.lines.edges
      ?.filter((p) =>
        p.node.attributes?.some(
          (attr) =>
            attr.key === ONE_TIME_VARIANT_ID_PROP && attr.value.length > 0
        )
      )
      .map(
        (p) =>
          p.node.attributes?.find((a) => a.key === ONE_TIME_VARIANT_ID_PROP)
            ?.value ?? ""
      ) ?? [];

  if (newCheckoutIds.length === 0) return listUpsellingProductsAdd;

  // string exlude upselling product per variants added to the cart
  const excludeUpsellingProductPerVariantStr =
    newCheckout?.cart.lines.edges
      ?.filter((p) =>
        p.node.attributes?.some(
          (attr) =>
            attr.key === ONE_TIME_VARIANT_EXCLUDED_IDS_PROP &&
            attr.value.length > 0
        )
      )
      .map(
        (p) =>
          p.node.attributes?.find(
            (a) => a.key === ONE_TIME_VARIANT_EXCLUDED_IDS_PROP
          )?.value ?? ""
      ) ?? [];

  if (excludeUpsellingProductPerVariantStr.length === 0)
    return listUpsellingProductsAdd;

  const listExcludeUpsellingProductPerVariant =
    excludeUpsellingProductPerVariantStr.map((p) => p.split(","));

  listUpsellingProductsAdd.forEach((idup) => {
    const isExcludeForEveryProduct =
      listExcludeUpsellingProductPerVariant.every((lpex) =>
        lpex.some((idp) => idp === idup)
      );

    if (
      isExcludeForEveryProduct &&
      listExcludeUpsellingProductPerVariant.length > 0 &&
      !removeAdditionalUpsellingProducts.some((id) => id === idup)
    )
      removeAdditionalUpsellingProducts.push(idup);
  });

  return listUpsellingProductsAdd.filter(
    (idp) => !removeAdditionalUpsellingProducts.includes(idp)
  );
};

const removeItem = async (item: ShopifyLineItem) => {
  const checkout = checkoutVar();
  const newCheckout = produce(checkout, (draft) => {
    if (!draft?.cart) return;
    draft.cart.lines.edges = draft?.cart.lines.edges?.filter(
      (li) => li.node.id !== item.id
    );
  });

  const upSellingProducts: ProductFieldsFragment[] = await getUpsellingProducts(
    false
  );

  const isThereNextDayProductInTheCart =
    getIsThereNextDayProductInTheCart(newCheckout);

  let listUpsellingProductsAdd: string[] = [];
  listUpsellingProductsAdd = getAdditionalUpsellingProductsIPD(
    listUpsellingProductsAdd,
    newCheckout,
    upSellingProducts
  );

  const listUpsellingProductsIPD = listUpsellingProductsAdd;

  listUpsellingProductsAdd = getAdditionalUpsellingForAllProducts(
    listUpsellingProductsAdd,
    newCheckout,
    upSellingProducts
  );

  listUpsellingProductsAdd = getAdditionalUpsellingProductsNextDay(
    listUpsellingProductsAdd,
    newCheckout,
    upSellingProducts,
    isThereNextDayProductInTheCart
  );

  listUpsellingProductsAdd = getAdditionalUpsellingProductsNextDayExclude(
    listUpsellingProductsAdd,
    newCheckout,
    upSellingProducts,
    listUpsellingProductsIPD
  );

  listUpsellingProductsAdd = getAdditionalUpsellingProductsWithoutProduct(
    listUpsellingProductsAdd,
    newCheckout,
    upSellingProducts
  );

  listUpsellingProductsAdd =
    getAdditionalUpsellingProductsNextDayExcludePerVariant(
      listUpsellingProductsAdd,
      newCheckout
    );

  const additionalItems = newCheckout?.cart?.lines?.edges?.filter(
    (li) =>
      upSellingProducts.some(
        (up) =>
          up.id ===
            li.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
              ?.value ?? ""
      ) &&
      !listUpsellingProductsAdd.includes(
        li.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value ??
          ""
      )
  );

  const data: ShopifyLineItem[] = [
    {
      id: item.id ?? "",
    },
    ...(additionalItems?.map((li) => ({
      id: li.node.id ?? "",
    })) ?? []),
  ];
  // Remove from Cart
  dataLayer({
    event: "remove_from_cart",
    ecommerce: {
      items: [
        {
          item_name: item.merchandise?.product?.title,
          item_id: item.merchandise?.sku,
          price: item.cost?.amountPerQuantity?.amount,
          item_brand: item.merchandise?.product?.vendor,
          item_category: "",
          item_category2: "",
          item_variant: item.merchandise?.title,
          quantity: item.quantity,
        },
      ],
    },
  });
  await updateCart(data, "remove");
};

export const bulkRemoveItems = async (items: ShopifyLineItem[]) => {
  const data: ShopifyLineItem[] = items.map((item) => ({
    id: item.id ?? "",
  }));
  await updateCart(data, "remove");
};

// commands
export const cartOperations = {
  loadCart,
  updateCart,
  getCheckoutUrl,
  addItem,
  editItem,
  removeItem,
};

// hooks / queries
type UseCurrentFilterSelectionHook = () => {
  currentFilterSelection: FilterProduct[] | undefined;
  addCurrentFilterSelection: (value: FilterProduct[] | undefined) => void;
};

export const useCurrentFilterSelection: UseCurrentFilterSelectionHook = () => {
  const _setCurrentFilterSelection = (value: FilterProduct[] | undefined) => {
    currentFilterSelection(value);
  };
  return {
    currentFilterSelection: useReactiveVar(currentFilterSelection),
    addCurrentFilterSelection: _setCurrentFilterSelection,
  };
};

type UseCurrentDisabledFiltersHook = () => {
  currentDisabledFilters: FilterProduct[] | undefined;
  addCurrentDisabledFilters: (value: FilterProduct[] | undefined) => void;
};

export const useCurrentDisabledFilters: UseCurrentDisabledFiltersHook = () => {
  const _setcurrentDisabledFilters = (value: FilterProduct[] | undefined) => {
    currentDisabledFilters(value);
  };
  return {
    currentDisabledFilters: useReactiveVar(currentDisabledFilters),
    addCurrentDisabledFilters: _setcurrentDisabledFilters,
  };
};

type UseCartBusyHook = () => boolean;
export const useCartBusy: UseCartBusyHook = () => {
  return useReactiveVar(cartBusy);
};

type UseOneTimeVariantHook = () => {
  currentOneTimeVariant: VariantFieldsFragment | undefined;
  addOneTimeVariant: (value: VariantFieldsFragment | undefined) => void;
};

export const useOneTimeVariant: UseOneTimeVariantHook = () => {
  const _setOneTimeVariant = (value: VariantFieldsFragment | undefined) => {
    currentOneTimeVariant(value);
  };
  return {
    currentOneTimeVariant: useReactiveVar(currentOneTimeVariant),
    addOneTimeVariant: _setOneTimeVariant,
  };
};

type UseCurrentUpsellingListHook = () => {
  currentUpsellingList: ProductFieldsFragment[] | undefined;
  addCurrentUpsellingList: (value: ProductFieldsFragment[] | undefined) => void;
};

export const useCurrentUpsellingList: UseCurrentUpsellingListHook = () => {
  const _setcurrentUpsellingList = (
    value: ProductFieldsFragment[] | undefined
  ) => {
    currentUpsellingList(value);
  };
  return {
    currentUpsellingList: useReactiveVar(currentUpsellingList),
    addCurrentUpsellingList: _setcurrentUpsellingList,
  };
};
type UseSubTotalHook = () => {
  currentSubTotal: number;
  addCurrentSubTotal: (value: number) => void;
};

export const useCurrentSubTotal: UseSubTotalHook = () => {
  const _setcurrentSubTotal = (value: number) => {
    currentSubTotal(value);
  };
  return {
    currentSubTotal: useReactiveVar(currentSubTotal),
    addCurrentSubTotal: _setcurrentSubTotal,
  };
};

type UseInvalidDeliveryDatesHook = () => {
  invalidDeliveryDates: Set<unknown>;
  invalidDelivaryDatesCount: number;
  addInvalidDelivaryDate: (lineItem: ShopifyLineItem) => void;
  removeInvalidDeliveryDate: (lineItem: ShopifyLineItem) => void;
};
export const useInvalidDeliveryDates: UseInvalidDeliveryDatesHook = () => {
  const currentInvalidDeliveryDates = useReactiveVar(invalidDeliveryDates);
  const setInvalidDelivaryDate = (lineItem: ShopifyLineItem) => {
    if (
      !currentInvalidDeliveryDates.has(
        lineItem.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value
      )
    ) {
      invalidDeliveryDates().add(
        lineItem.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value
      );
      invalidDelivaryDatesCount(currentInvalidDeliveryDates.size);
    }
  };
  const deleteInvalidDeliveryDate = (lineItem: ShopifyLineItem) => {
    if (
      currentInvalidDeliveryDates.has(
        lineItem.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value
      )
    ) {
      invalidDeliveryDates().delete(
        lineItem.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)?.value
      );
      invalidDelivaryDatesCount(currentInvalidDeliveryDates.size);
    }
  };
  return {
    invalidDeliveryDates: useReactiveVar(invalidDeliveryDates),
    addInvalidDelivaryDate: setInvalidDelivaryDate,
    removeInvalidDeliveryDate: deleteInvalidDeliveryDate,
    invalidDelivaryDatesCount: useReactiveVar(invalidDelivaryDatesCount),
  };
};
type UseProductExistInCartHook = (cmsProductId: number) => boolean;
export const useProductExistInCart: UseProductExistInCartHook = (
  cmsProductId
) => {
  const checkout = useReactiveVar(checkoutVar);
  return Boolean(
    checkout?.cart?.lines?.edges?.find(
      (li) =>
        li.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
          ?.value === cmsProductId.toString()
    )
  );
};
type UseProductListExistInCartHook = (productListId: number[]) => number;
export const useProductListExistInCart: UseProductListExistInCartHook = (
  productListId
) => {
  const checkout = useReactiveVar(checkoutVar);
  return (
    productListId.filter((p) =>
      checkout?.cart?.lines?.edges?.find(
        (li) =>
          li.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
            ?.value === p.toString()
      )
    ).length ?? 0
  );
};
type UseCartCountHook = () => number;
export const useCartCount: UseCartCountHook = () => {
  const checkout = useReactiveVar(checkoutVar);
  return checkout?.cart?.lines?.edges?.length ?? 0;
};
type UseCartVisibilityHook = () => [boolean, () => void];
export const useCartVisibility: UseCartVisibilityHook = () => {
  const isOpen = useReactiveVar(cartVisibleVar);
  const toggleCart = () => {
    cartVisibleVar(!isOpen);
  };
  return [isOpen, toggleCart];
};
type UseCheckoutDataHook = () => [ShopifyCartData, GetProductsByIdsQueryResult];
export const useCheckoutData: UseCheckoutDataHook = () => {
  const cartData = useReactiveVar(checkoutVar);
  const cmsCartDataResult = useGetProductsByIdsQuery({
    variables: {
      ItemIds: getCmsAllItemIdsFromCheckoutData(
        cartData?.cart?.lines?.edges?.map((edge) => edge.node) ?? []
      ),
    },
    skip: cartData === null,
    context: {
      preview: !IS_PROD_ENV,
    },
  });
  return [cartData, cmsCartDataResult];
};

export type HookProps = {
  product:
    | ProductFieldsFragment
    | ProductFieldsShortFragment
    | ProductFieldsCartFragment;
};
type variantNode = ShopifyVariantNode | undefined;
type addToCartFn = (lineItemOverride?: Partial<ShopifyLineItem>) => void;
type HookResult = {
  variant: variantNode;
  deliveryDate: string;
  qty: number;
  addToCart: addToCartFn;
  setVariant: (value: ShopifyVariantNode) => void;
  setDeliveryDate: (value: string) => void;
  setQty: Dispatch<SetStateAction<number>>;
  isGift: boolean;
  setIsGift: Dispatch<SetStateAction<boolean>>;
  giftEmail: string;
  setGiftEmail: Dispatch<SetStateAction<string>>;
  giftMessage: string;
  setGiftMessage: Dispatch<SetStateAction<string>>;
  isGiftMessageFormDirty: boolean;
  setIsGiftMessageFormDirty: Dispatch<SetStateAction<boolean>>;
  isAutoRenew: boolean;
  setIsAutoRenew: Dispatch<SetStateAction<boolean>>;
  recipientName: string;
  setRecipientName: Dispatch<SetStateAction<string>>;
  oneTimeVariantProp: string;
  setOneTimeVariantProp: Dispatch<SetStateAction<string>>;
  oneTimeVariantIdProp: string;
  setOneTimeVariantIdProp: Dispatch<SetStateAction<string>>;
  oneTimeVariantExcludedIdProp: string;
  setOneTimeVariantExcludedIdProp: Dispatch<SetStateAction<string>>;
  isValidZipCode: boolean;
  setIsValidZipCode: (value: boolean) => void;
};

type UseCartHook = (props: HookProps) => HookResult;

export const useProductForm: UseCartHook = ({ product }) => {
  const defaultLineProps: KeyValueProp[] = useMemo(
    () => [
      {
        key: CMS_PRODUCT_ID_PROP,
        value: product.id,
      },
    ],
    [product.id]
  );
  const [variant, _setVariant] = useState<ShopifyVariantNode>();
  const [deliveryDate, _setDeliveryDate] = useState("");
  const [qty, setQty] = useState(1);
  const [isGift, setIsGift] = useState(false);
  const [isGiftMessageFormDirty, setIsGiftMessageFormDirty] = useState(false);
  const [giftEmail, setGiftEmail] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [giftMessage, setGiftMessage] = useState("");
  const [isAutoRenew, setIsAutoRenew] = useState(false);
  const [oneTimeVariantProp, setOneTimeVariantProp] = useState("");
  const [oneTimeVariantIdProp, setOneTimeVariantIdProp] = useState("");
  const [oneTimeVariantExcludedIdProp, setOneTimeVariantExcludedIdProp] =
    useState("");
  const [isValidZipCode, _setIsValidZipCode] = useState(false);

  const setIsValidZipCode = useCallback(
    (value: boolean) => {
      _setIsValidZipCode(value);
      currentIsValidZipCode(value);
    },
    [_setIsValidZipCode]
  );
  const setDeliveryDate = useCallback(
    (value: string) => {
      _setDeliveryDate(value);
      currentDeliveryDate(value);
    },
    [_setDeliveryDate]
  );
  const setVariant = useCallback(
    (value: ShopifyVariantNode | undefined) => {
      _setVariant(value);
      currentVariant(value);
    },
    [_setVariant]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, toggleCart] = useCartVisibility();
  const addToCart = useCallback(
    async (lineItemOverride: Partial<ShopifyLineItem> = {}) => {
      const isSub = product.isSubscription;
      const variantIdInt = getShopifyIdInt(variant?.id ?? "");
      const variantTitle = variant?.title;
      let properties: KeyValueProp[] = [...defaultLineProps];
      if (product.thirdParty) {
        properties.push({
          key: THIRD_PARTY_PROP,
          value: product.thirdParty,
        });
      }
      if (
        !isSub &&
        product.shopifyProduct &&
        product.shopifyProduct.tags &&
        product.shopifyProduct.tags.length > 0
      ) {
        properties.push({
          key: PRODUCT_TAGS_PROP,
          value: product?.shopifyProduct?.tags?.join(",").toLowerCase(),
        });
      }
      if (isSub) {
        const subscriptionProduct = product.shopifySubscriptionProducts.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (sp: { variants: { edges: any[] } }) => {
            const subscriptionVariant = sp.variants.edges.find(
              (v) => v.node.id === variant?.id
            );
            return subscriptionVariant;
          }
        );
        if (
          subscriptionProduct &&
          subscriptionProduct.tags &&
          subscriptionProduct.tags.length > 0
        ) {
          properties.push({
            key: PRODUCT_TAGS_PROP,
            value: subscriptionProduct?.tags?.join(",").toLowerCase(),
          });
        }
      }
      if (variantTitle) {
        properties.push({
          key: VARIANT_TITLE_PROP,
          value: variantTitle ?? "",
        });
      }
      properties.push({
        key: IS_SUBSCRIPTION_PRODUCT_PROP,
        value: isSub ? "true" : "false",
      });
      if (deliveryDate) {
        properties.push({
          key: DELIVERY_DATE_PROP,
          value: deliveryDate,
        });
      }
      if (oneTimeVariantProp) {
        properties.push({
          key: ONE_TIME_VARIANT_PROP,
          value: oneTimeVariantProp,
        });
      }
      if (oneTimeVariantIdProp) {
        properties.push({
          key: ONE_TIME_VARIANT_ID_PROP,
          value: oneTimeVariantIdProp,
        });
      }
      if (oneTimeVariantExcludedIdProp) {
        properties.push({
          key: ONE_TIME_VARIANT_EXCLUDED_IDS_PROP,
          value: oneTimeVariantExcludedIdProp,
        });
      }
      if (isGift) {
        properties.push({
          key: IS_GIFT_PROP,
          value: "true",
        });
        if (giftMessage) {
          properties.push({
            key: GIFT_MESSAGE_PROP,
            value: giftMessage,
          });
        }
        if (giftEmail) {
          properties.push({
            key: RECIPIENT_EMAIL_PROP,
            value: giftEmail,
          });
        }
        if (isAutoRenew) {
          properties.push({
            key: AUTO_RENEW_PROP,
            value: "true",
          });
        }
        if (recipientName) {
          properties.push({
            key: RECIPIENT_NAME_PROP,
            value: recipientName,
          });
        }
      }
      let lineItem: ShopifyLineItem = {
        merchandise: {
          id: `gid://shopify/ProductVariant/${variantIdInt}`,
          sku: variant?.sku ?? "",
          title: variant?.title ?? "",
        },
        quantity: qty,
        attributes: properties,
      };
      if (variant?.sellingPlanAllocations) {
        lineItem = {
          ...lineItem,
          sellingPlanAllocation: {
            sellingPlan: {
              id: variant.sellingPlanAllocations.edges[0].node.sellingPlan.id,
            },
          },
        };
      }
      if (lineItemOverride && Object.keys(lineItemOverride).length > 0) {
        lineItem = {
          ...lineItem,
          ...lineItemOverride,
          attributes: [
            ...(lineItem?.attributes ?? []),
            ...(lineItemOverride?.attributes ?? []),
          ],
        };
      }
      const lineItems = [lineItem];
      const currentArray = currentUpsellingList() ?? [];
      for (let i = 0; i < currentArray.length; i += 1) {
        const p = currentArray[i];
        properties = [];
        const _variant: ShopifyVariantNode =
          p?.shopifyProduct?.variants.edges[0].node;
        const _variantIdInt = getShopifyIdInt(_variant?.id ?? "");
        properties.push({
          key: CMS_PRODUCT_ID_PROP,
          value: p.id,
        });
        if (
          p.shopifyProduct &&
          p.shopifyProduct.tags &&
          p.shopifyProduct.tags.length > 0
        ) {
          properties.push({
            key: PRODUCT_TAGS_PROP,
            value: p.shopifyProduct.tags?.join(",").toLowerCase(),
          });
        }
        if (p.shopifyProduct && p.shopifyProduct.title) {
          properties.push({
            key: VARIANT_TITLE_PROP,
            value: p.shopifyProduct.title ?? "",
          });
        }
        properties.push({
          key: IS_SUBSCRIPTION_PRODUCT_PROP,
          value: "false",
        });
        if (deliveryDate) {
          properties.push({
            key: DELIVERY_DATE_PROP,
            value: deliveryDate,
          });
        }
        const lineItemAddons: ShopifyLineItem = {
          merchandise: {
            id: `gid://shopify/ProductVariant/${_variantIdInt}`,
            sku: _variant?.sku ?? "",
            title: _variant?.title ?? "",
          },
          quantity: qty,
          attributes: properties,
        };
        lineItems.push(lineItemAddons);
        klaviyoAddedToCart(
          p,
          qty,
          cartOperations.getCheckoutUrl(),
          checkoutVar()
        );

        dataLayer({
          event: "add_to_cart",
          ecommerce: {
            items: [
              {
                item_name: p.title,
                item_id: lineItemAddons.merchandise?.sku,
                price: lineItemAddons.cost?.amountPerQuantity?.amount,
                item_brand: lineItemAddons.merchandise?.product?.vendor,
                item_category: "",
                item_category2: "",
                item_variant: lineItemAddons.merchandise?.title,
                quantity: lineItemAddons.quantity,
              },
            ],
          },
        });
        dataLayer({
          event: "addToCart",
          ecommerce: {
            currencyCode: "USD",
            add: {
              products: [
                {
                  //  adding a product to a shopping cart.
                  name: p.title,
                  id: _variantIdInt, // todo: use shopify SKU
                  price: _variant?.priceV2.amount,
                  variant: _variant?.title,
                  quantity: qty,
                },
              ],
            },
          },
        });
      }
      await cartOperations.addItem(lineItems);
      const session = await getSession();
      if (session) {
        klaviyoIdentify(session);
      }
      klaviyoAddedToCart(
        product,
        qty,
        cartOperations.getCheckoutUrl(),
        checkoutVar()
      );
      impactDataLayerEvent(
        session?.shopifyUser.id || "",
        session?.shopifyUser.email || ""
      );
      // When a product was added to cart
      let productTitle = product.title;
      if (product.isSubscription && product.shopifySubscriptionProducts) {
        const subscriptionProduct = product.shopifySubscriptionProducts.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (sp: { variants: { edges: any[] } }) => {
            const subscriptionVariant = sp.variants.edges.find(
              (v) => v.node.id === variant?.id
            );
            return subscriptionVariant;
          }
        );
        if (subscriptionProduct) {
          productTitle = subscriptionProduct.title;
        }
      }
      dataLayer({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_name: productTitle,
              item_id: lineItem.merchandise?.sku,
              price: lineItem.cost?.amountPerQuantity?.amount,
              item_brand: lineItem.merchandise?.product?.vendor,
              item_category: "",
              item_category2: "",
              item_variant: lineItem.merchandise?.title,
              quantity: lineItem.quantity,
            },
          ],
        },
      });
      dataLayer({
        event: "addToCart",
        ecommerce: {
          currencyCode: "USD",
          add: {
            products: [
              {
                //  adding a product to a shopping cart.
                name: product.title,
                id: variantIdInt, // todo: use shopify SKU
                price: variant?.priceV2.amount,
                variant: variant?.title,
                quantity: qty,
              },
            ],
          },
        },
      });
      try {
        await facebookApi.post({
          eventName: "AddToCart",
          productId: variantIdInt,
          currency: "USD",
          price: variant?.priceV2?.amount,
          quantity: qty,
        });
      } catch (error) {
        // ignore facebookApi error
      }
      if (!isOpen) toggleCart();
      setDeliveryDate("");
      setVariant(undefined);
      setQty(1);
    },
    [
      product,
      variant?.id,
      variant?.title,
      variant?.sku,
      variant?.sellingPlanAllocations,
      variant?.priceV2.amount,
      defaultLineProps,
      deliveryDate,
      oneTimeVariantProp,
      oneTimeVariantIdProp,
      oneTimeVariantExcludedIdProp,
      isGift,
      qty,
      isOpen,
      toggleCart,
      setDeliveryDate,
      setVariant,
      giftMessage,
      giftEmail,
      isAutoRenew,
      recipientName,
    ]
  );
  useEffect(() => {
    currentAddToCart(() => addToCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentDeliveryDate("");
    currentVariant(undefined);
    currentAddToCart(null);
  }, [product.id]);

  return {
    variant,
    deliveryDate,
    qty,
    addToCart,
    setVariant,
    setDeliveryDate,
    setQty,
    isGift,
    setIsGift,
    giftEmail,
    setGiftEmail,
    giftMessage,
    setGiftMessage,
    isGiftMessageFormDirty,
    setIsGiftMessageFormDirty,
    isAutoRenew,
    setIsAutoRenew,
    recipientName,
    setRecipientName,
    oneTimeVariantProp,
    setOneTimeVariantProp,
    oneTimeVariantIdProp,
    setOneTimeVariantIdProp,
    oneTimeVariantExcludedIdProp,
    setOneTimeVariantExcludedIdProp,
    isValidZipCode,
    setIsValidZipCode,
  };
};
