import React from "react";
import { SuperGiftItemT } from "src/features/SuperGift/SuperGiftProductForm";
import tw from "twin.macro";
import { StyledButton } from "src/features/SuperGift/SuperGiftItemForm";
import { getDatePickerDateFormatted } from "src/shared/utils/products";

type Props = {
  itemData: SuperGiftItemT;
  handleEdit?: () => void;
};
// eslint-disable-next-line react/function-component-definition
export const SuperGiftItemSummary: React.FC<Props> = ({
  itemData,
  handleEdit,
}) => {
  const hasGiftMessage =
    Boolean(itemData.giftMessage) && Boolean(itemData.giftMessage.length);
  return (
    <div>
      <div css={tw`space-y-2`}>
        <div>
          <b>Delivery Date</b>:{" "}
          {getDatePickerDateFormatted(itemData.deliveryDate)}
        </div>
        {hasGiftMessage && (
          <div>
            <b>Gift Message</b>:{" "}
            <div css={tw`font-playfair`}>{itemData.giftMessage}</div>
          </div>
        )}
      </div>
      {handleEdit && (
        <div css={tw`mt-3`}>
          <StyledButton type="button" onClick={handleEdit}>
            Edit
          </StyledButton>
        </div>
      )}
    </div>
  );
};
