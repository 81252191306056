/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { FC, useRef, useCallback, useState } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { addMinutes, format } from "date-fns";
import { MdDateRange } from "react-icons/md";
import {
  dateMatches,
  DatePickerClientConfigT,
  DisabledDate,
  loadDatePickerConfig,
} from "src/features/ProductForm/utils";
import {
  ProductFieldsCartFragment,
  ProductFieldsFragment,
} from "src/generated/datocms-types";
import { useQuery } from "react-query";
import { renderDate } from "src/shared/helpers";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { Drawer, useMediaQuery } from "@mui/material";
import { NextDayDeliveryConfig } from "src/shared/types";

export type Props = {
  product: ProductFieldsFragment | ProductFieldsCartFragment;
  onChange: (date: string) => void;
  disabledDates?: DisabledDate[];
  initialDate?: Date;
};

const Arrow = ({
  left,
  visible = true,
}: {
  left: boolean;
  visible?: boolean;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={[
        tw`w-[10px] h-[10px] fill-[#4D5562]`,
        !visible ? tw`invisible` : tw``,
      ]}
    >
      {left && (
        <path
          d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
          stroke="#4D5562"
          strokeWidth={2}
        />
      )}
      {!left && (
        <path
          d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
          stroke="#4D5562"
          strokeWidth={2}
        />
      )}
    </svg>
  );
};

export const ProductDatePicker: FC<Props> = ({
  onChange,
  product,
  disabledDates = [],
  initialDate = null,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const { isSubscription, id: productId } = product;
  const selectedDate = useRef<Date | null>(initialDate);
  const [isOpenDrawerDatepicker, setIsOpenDrawerDatepicker] = useState(false);
  const query = useQuery<DatePickerClientConfigT>(
    [{ productId, isSubscription }, "datepicker"],
    () =>
      loadDatePickerConfig({
        productId,
        isSubscription,
        userDate: new Date().toISOString(),
      })
  );
  const openDrawerDatepicker = () => setIsOpenDrawerDatepicker(true);
  const closeDrawerDatepicker = () => {
    setIsOpenDrawerDatepicker(false);
  };
  const handleClickDatePicker = () => {
    if (!isDesktop) openDrawerDatepicker();
  };

  const shouldDisableDate = useCallback(
    (date: Date | null) => {
      const month = date!.getMonth() + 1;
      const day = date!.getDate();
      const result = query.data?.shouldDisableDate(date) ?? false;
      const override = dateMatches(month, day, disabledDates);
      return override || result;
    },
    [query.data, disabledDates]
  );
  const handleOnClose = useCallback(() => {
    if (!selectedDate.current) return;
    onChange(format(selectedDate.current as Date, "MM-dd-yyyy"));
  }, [onChange, selectedDate]);
  const handleOnChange = useCallback(
    (date: Date | null) => {
      selectedDate.current = date;
      handleOnClose();

      if (!isDesktop && isOpenDrawerDatepicker) closeDrawerDatepicker();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate.current, isOpenDrawerDatepicker]
  );
  const handleOnError = useCallback(
    (err: unknown) => {
      if (err) {
        selectedDate.current = null;
      }
    },
    [selectedDate]
  );

  if (query.error) {
    return (
      <div css={tw`text-sm font-roboto text-red-700`}>
        Something went wrong, please refresh the page and try again.
      </div>
    );
  }
  if (query.isLoading) {
    return <div css={tw`text-sm font-roboto`}>Loading dates...</div>;
  }
  const datePickerConfig = query.data ?? ({} as DatePickerClientConfigT);

  if (!datePickerConfig) {
    return (
      <div css={tw`text-sm font-roboto`}>
        An error occurred, please refresh the page and try again.
      </div>
    );
  }
  const minDate = addMinutes(
    new Date(datePickerConfig.minDate),
    new Date().getTimezoneOffset()
  );
  const maxDate = addMinutes(
    new Date(datePickerConfig.maxDate),
    new Date().getTimezoneOffset()
  );

  const nextDayDeliveryConfig: NextDayDeliveryConfig = {
    enabled: datePickerConfig.nextDayShippingConfig.enabled,
    isExpired: datePickerConfig.nextDayShippingConfig.isExpired,
    isExpedited: datePickerConfig.nextDayShippingConfig.isExpedited,
  };

  return (
    <>
      <div>
        <DatePickerInput
          readOnly={!isDesktop}
          onClick={handleClickDatePicker}
          inputMode="text"
          dropdownType={isDesktop ? "popover" : "modal"}
          placeholder="Select delivery date"
          minDate={minDate}
          maxDate={maxDate}
          excludeDate={shouldDisableDate}
          onError={handleOnError}
          value={selectedDate.current}
          onChange={handleOnChange}
          maxLevel="month"
          firstDayOfWeek={0}
          valueFormat="MMMM D, YYYY"
          size="md"
          rightSection={<MdDateRange css={tw`text-gray-300`} />}
          popoverProps={{
            styles: {
              dropdown: {
                zIndex: 0,
                boxShadow:
                  "0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.18)",
              },
            },
          }}
          styles={{
            day: {
              minHeight: "50px",
              minWidth: "38px",
              borderRadius: "0%",
              "&[data-weekend]": {
                color: "black",
              },
              "&[data-selected], &[data-selected]:hover": {
                backgroundColor: "#0e9f6e",
                color: "white",
              },
            },
            input: tw`border-[#78bd72] hover:border-[#78bd72] font-roboto text-base focus:(outline-none ring-4 ring-[#78bd72] border-0)`,
            rightSection: { pointerEvents: "none" },
          }}
          renderDay={(date) =>
            renderDate(
              date,
              nextDayDeliveryConfig,
              minDate,
              maxDate,
              shouldDisableDate,
              product
            )
          }
        />
        {datePickerConfig.nextDayShippingConfig.enabled ||
        datePickerConfig.nextDayShippingConfig.enabledSameDay ? (
          <>
            {datePickerConfig.nextDayShippingConfig.isExpired === false ? (
              <div css={tw`text-sm p-3 bg-yellow-100 rounded mt-2`}>
                {datePickerConfig.nextDayShippingConfig.isExpedited ===
                false ? (
                  <span>
                    {!datePickerConfig.nextDayShippingConfig.enabledSameDay
                      ? "Next Day Delivery."
                      : "Same Day Delivery."}
                    <br />
                    Order before{" "}
                    <b>{datePickerConfig.nextDayShippingConfig.expires}</b>
                    {datePickerConfig.nextDayShippingConfig.enabledSameDay
                      ? " to get it today!"
                      : " to get it tomorrow!"}
                  </span>
                ) : (
                  <span css={tw`text-base`}>Expedited Delivery.</span>
                )}
              </div>
            ) : (
              <div css={tw`text-base p-3 bg-yellow-100 rounded mt-2`}>
                <span>Expedited Delivery.</span>
              </div>
            )}
          </>
        ) : null}
      </div>
      {!isDesktop && (
        <div>
          <Drawer
            open={isOpenDrawerDatepicker}
            anchor="left"
            onClose={closeDrawerDatepicker}
            PaperProps={{ style: { width: "100%" } }}
          >
            <nav
              css={tw`flex items-center justify-between uppercase font-bold font-roboto text-sm border-b border-[#D2D5DB] mb-6 tracking-wider leading-[48px]`}
            >
              <span css={tw`ml-6`}>
                <Arrow left />
              </span>
              <a
                href={undefined}
                onClick={closeDrawerDatepicker}
                css={tw`block h-full w-full flex justify-center items-center cursor-default`}
              >
                Back
              </a>
              <span css={tw`mr-6`}>
                <Arrow left={false} visible={false} />
              </span>
            </nav>
            <div css={tw`flex justify-center px-2`}>
              <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                excludeDate={shouldDisableDate}
                onError={handleOnError}
                defaultDate={selectedDate.current ?? minDate}
                value={selectedDate.current}
                onChange={handleOnChange}
                maxLevel="month"
                firstDayOfWeek={0}
                size="xl"
                monthLabelFormat="MMMM"
                styles={{
                  day: {
                    minHeight: "50px",
                    minWidth: "38px",
                    borderRadius: "0%",
                    "&[data-weekend]": {
                      color: "black",
                    },
                    "&[data-selected], &[data-selected]:hover": {
                      backgroundColor: "#0e9f6e",
                      color: "white",
                    },
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontFamily: "Roboto",
                  },
                  calendarHeader: {
                    justifyContent: "center",
                  },
                  calendarHeaderLevel: {
                    fontSize: "30px",
                    lineHeight: "38px",
                    fontFamily: "Roboto",
                    flex: "none",
                    width: "38%",
                  },
                  calendarHeaderControl: {
                    "& svg": {
                      width: "2rem !important",
                      height: "2rem  !important",
                    },
                  },
                }}
                renderDay={(date) =>
                  renderDate(
                    date,
                    nextDayDeliveryConfig,
                    minDate,
                    maxDate,
                    shouldDisableDate,
                    product
                  )
                }
              />
            </div>
          </Drawer>
        </div>
      )}
    </>
  );
};
