import { formatPrice } from "src/shared/helpers";
import { ShopifyLineItem, ShopifyMoneyV2 } from "src/shared/types/shopify";
import { CMS_PRODUCT_ID_PROP } from "src/features/Cart/constants";
import { SUPERGIFT_PROP } from "src/features/SuperGift/SuperGiftProductForm";
import { ProductFieldsFragment } from "src/generated/datocms-types";

// fixme: make these come from the CMS
export type VariantFrequency = {
  chargeInterval: number;
  orderInterval: number;
  unitType: string;
};
export const VARIANT_UNIT_WEEK = "week";
export const VARIANT_UNIT_MONTH = "month";
export const variantMap: Record<string, VariantFrequency> = {
  "month-to-month": {
    chargeInterval: 1,
    orderInterval: 1,
    unitType: VARIANT_UNIT_MONTH,
  },
  "3-month-prepay": {
    chargeInterval: 3,
    orderInterval: 1,
    unitType: VARIANT_UNIT_MONTH,
  },
  "6-month-prepay": {
    chargeInterval: 6,
    orderInterval: 1,
    unitType: VARIANT_UNIT_MONTH,
  },
  "12-month-prepay": {
    chargeInterval: 12,
    orderInterval: 1,
    unitType: VARIANT_UNIT_MONTH,
  },
  "week-to-week": {
    chargeInterval: 1,
    orderInterval: 1,
    unitType: VARIANT_UNIT_WEEK,
  },
  "2-weeks": {
    chargeInterval: 2,
    orderInterval: 2,
    unitType: VARIANT_UNIT_WEEK,
  },
  "4-weeks-prepay": {
    chargeInterval: 4,
    orderInterval: 2,
    unitType: VARIANT_UNIT_WEEK,
  },
  "6-weeks-prepay": {
    chargeInterval: 6,
    orderInterval: 2,
    unitType: VARIANT_UNIT_WEEK,
  },
  "3-week-prepay": {
    chargeInterval: 3,
    orderInterval: 1,
    unitType: VARIANT_UNIT_WEEK,
  },
  "3-weeks": {
    chargeInterval: 3,
    orderInterval: 1,
    unitType: VARIANT_UNIT_WEEK,
  },
  "4-week-prepay": {
    chargeInterval: 4,
    orderInterval: 1,
    unitType: VARIANT_UNIT_WEEK,
  },
  "8-week-prepay": {
    chargeInterval: 8,
    orderInterval: 1,
    unitType: VARIANT_UNIT_WEEK,
  },
  "12-week-prepay": {
    chargeInterval: 12,
    orderInterval: 1,
    unitType: VARIANT_UNIT_WEEK,
  },
  quarterly: {
    chargeInterval: 3,
    orderInterval: 3,
    unitType: VARIANT_UNIT_MONTH,
  },
  annual: {
    chargeInterval: 12,
    orderInterval: 3,
    unitType: VARIANT_UNIT_MONTH,
  },
};
export const getVariantFrequency = (
  variantTitle: string
): VariantFrequency | undefined => {
  const normalizedTitle = variantTitle
    .trimStart()
    .trimEnd()
    .replace(/\s+/g, "-")
    .toLowerCase();
  return variantMap[normalizedTitle];
};
export const getVariantFrequencyPrice = (
  variantPrice: ShopifyMoneyV2,
  variantFrequency: VariantFrequency
) => {
  const priceInt: number = Math.abs(
    parseFloat(variantPrice.amount) /
      (variantFrequency.chargeInterval / variantFrequency.orderInterval)
  );
  const price: string = formatPrice(priceInt, variantPrice.currencyCode);
  return {
    price,
    priceInt,
  };
};
export const getVariantSavePrice = (opts: {
  basePrice: number;
  baseChargeInterval: number;
  variantPrice: ShopifyMoneyV2;
  chargeInterval: number;
}) => {
  let priceInt: number;
  if (opts.basePrice === parseFloat(opts.variantPrice.amount)) {
    priceInt = 0;
  } else {
    priceInt = Math.abs(
      opts.basePrice * (opts.chargeInterval / opts.baseChargeInterval) -
        parseFloat(opts.variantPrice.amount)
    );
  }
  const price: string = formatPrice(priceInt, opts.variantPrice.currencyCode);
  return {
    price,
    priceInt,
  };
};
export const getCmsAllItemIdsFromCheckoutData = (
  lineItems: ShopifyLineItem[]
) => {
  const itemIds: string[] = [];
  lineItems.forEach((li) =>
    li.attributes?.forEach(
      (p) => p.key === CMS_PRODUCT_ID_PROP && itemIds.push(p.value)
    )
  );
  return itemIds;
};
export const getCmsItemIdFromCheckoutData = (lineItem: ShopifyLineItem) => {
  const cmsIdProp = lineItem.attributes?.find((p) => {
    return p.key === CMS_PRODUCT_ID_PROP;
  });
  return cmsIdProp?.value ?? "0";
};
export const getSuperGiftLineItem = (lineItems: ShopifyLineItem[]) => {
  return lineItems.find((lineItem) => {
    const prop = lineItem?.attributes?.find(
      (p) => p.key.indexOf(SUPERGIFT_PROP) > -1
    );
    return prop ? lineItem : false;
  });
};

export const sumSubTotal = (
  currentUpsellingList: ProductFieldsFragment[] | undefined
) => {
  const currentArray = currentUpsellingList ?? [];
  let subTotal = 0;
  for (let i = 0; i < currentArray.length; i += 1) {
    subTotal =
      Number(currentArray[i].shopifyProduct.priceRange.minVariantPrice.amount) +
      subTotal;
  }
  return subTotal;
};
