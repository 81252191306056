import { IS_PROD_ENV } from "src/constants";

const ERRORS_TO_IGNORE = [
  "TypeError: Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource.",
  "TypeError: Cancelled",
];
const getSentryConfig = () => {
  const SENTRY_DSN =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
  const SENTRY_ENV =
    process.env.NEXT_PUBLIC_APP_STAGE || process.env.NODE_ENV || "unknown";
  return {
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    ignoreErrors: ERRORS_TO_IGNORE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeSend: (event: any, eventHint: any) => {
      if (!IS_PROD_ENV) {
        // IN DEV LOG STUFF THAT WOULD BE SENT, BUT SEND NOTHING
        const { platform, extra } = event;
        // eslint-disable-next-line no-console
        console.info(`Sentry err logged: [platform: ${platform}]`);
        if (eventHint) {
          console.error(eventHint.originalException);
        }
        if (extra) {
          console.info(extra);
        }
        return null;
      }
      return event;
    },
  };
};
const globalConfig = getSentryConfig();
export const clientSentryConfig = {
  ...globalConfig,
  allowUrls: [/\.bloomsybox\.com/, /bloomsy-headless.*?\.vercel\.app/],
};
// reminder: server side is aws lambda (vercel)
export const serverSentryConfig = { ...globalConfig };
