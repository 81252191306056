import {
  GetFlowerShopCollectionProductsByTagDocument,
  GetFlowerShopCollectionProductsByTagQuery,
  GetFlowerShopCollectionProductsByTagQueryVariables,
  ProductCollectionFieldsFragment,
  ProductFieldsFragment,
  GetProductsByFiltersByTagsQuery,
  GetProductsByFiltersByTagsDocument,
  GetFilterTagsQuery,
  GetFilterTagsDocument,
  ProductFieldsShortFragment,
  GetProductByTagsQuery,
  GetProductByTagsDocument,
  GetCollectionByTagsDocument,
  GetCollectionByTagsQuery,
} from "src/generated/datocms-types";
import { initializeApollo } from "src/services/datocms";
import { productPlanTagSlugs } from "src/services/datocms/constants";
import { SeoMetaTagType } from "react-datocms";
import pMap from "p-map";
import {
  CollectionProductsMap,
  CollectionProductsRequests,
  FilterProduct,
  KeyValueProp,
  NameValueProp,
  SuperGiftInfoProp,
} from "src/shared/types";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { format } from "date-fns";
import tw from "twin.macro";
import { SUPERGIFT_PROP } from "src/features/SuperGift/SuperGiftProductForm";
import { getVariantFrequency } from "src/features/Cart/utils";
import { ShopifyProductJson, ShopifyVariantNode } from "../types/shopify";

type tagsIds = {
  id: string;
};
export type collectionResult = {
  id: string;
  title: string;
  productTags: tagsIds[];
};
export type CollectionList = {
  id: string;
  title: string;
};
export const getProductBgColor = (product: ProductFieldsFragment) => {
  const hex = product?.backgroundColor?.hex?.toString() || "";
  const defaultBackground = product.isSubscription
    ? tw`bg-bloomsy-productHeroBg`
    : tw`bg-bloomsy-productHeroBg2`;
  return hex ? { backgroundColor: hex } : defaultBackground;
};
export const getProductMeta = (tags: SeoMetaTagType[]) => {
  const titleTag = tags.find((t) => t.attributes?.property === "og:title");
  const descTag = tags.find((t) => t.attributes?.name === "description");
  return {
    title: titleTag?.attributes?.content,
    description: descTag?.attributes?.content,
  };
};
export const getProductRecurringLabel = (
  title: ProductFieldsFragment["title"],
  tags: ProductFieldsFragment["tags"]
) => {
  // todo: make recurring label come from the CMS
  let recurringFrequency = "";
  tags.forEach((t) => {
    if (t.slug === productPlanTagSlugs.MONTHLY) {
      recurringFrequency = "monthly";
    }
    if (t.slug === productPlanTagSlugs.WEEKLY) {
      if (!title) return "";
      recurringFrequency =
        title.toLowerCase().indexOf("bi-weekly") > -1 ? "bi-weekly" : "weekly";
    }
    if (t.slug === productPlanTagSlugs.QUARTERLY) {
      recurringFrequency = "quarterly";
    }
  });

  return recurringFrequency;
};

export const getCollectionProducts = async (
  productCollections: ProductCollectionFieldsFragment[],
  apolloClient: ApolloClient<NormalizedCacheObject>,
  preview: boolean
): Promise<CollectionProductsMap> => {
  const collectionProducts: CollectionProductsMap = {};
  const flowerShopCollections = productCollections ?? [];
  if (flowerShopCollections.length) {
    const requests: CollectionProductsRequests[] = [];
    flowerShopCollections.forEach((collection) => {
      const tagIds = collection.productTags.map((ct) => ct.id);
      if (!tagIds.length) return;
      requests.push({
        collectionId: collection.id,
        request: apolloClient.query<
          GetFlowerShopCollectionProductsByTagQuery,
          GetFlowerShopCollectionProductsByTagQueryVariables
        >({
          query: GetFlowerShopCollectionProductsByTagDocument,
          variables: { tagIds },
          context: { preview },
        }),
      });
    });
    await pMap(
      requests,
      async (ro) => {
        const res = await ro.request;
        collectionProducts[ro.collectionId] = res.data?.allProducts ?? [];
      },
      { concurrency: 1 }
    );
  }
  return collectionProducts;
};
export const isSuperGiftProduct = (productId: string) => {
  const superGiftProductIds = ["31369673", "106368452"];
  return superGiftProductIds.includes(productId);
};
export const parseDatepickerDateStr = (dt: string) => {
  const [month, day, year] = dt.split("-").map((s) => parseInt(s, 10));
  let date;
  if (month && day && year) {
    date = new Date(year, month - 1, day);
  }
  return { month, day, year, date };
};
export const getDatePickerDateFormatted = (dt: string) => {
  const parsedDt = parseDatepickerDateStr(dt);
  return format(
    new Date(parsedDt.year, parsedDt.month - 1, parsedDt.day),
    "MMM do, yyyy"
  );
};
export const getSuperGiftProps = (props: KeyValueProp[]) => {
  const result: Record<string, SuperGiftInfoProp> = {};
  const sgProps = props?.filter((p) => p.key.indexOf(SUPERGIFT_PROP) > -1);
  sgProps.forEach((item) => {
    const prefixPattern = new RegExp(`(${SUPERGIFT_PROP}#[0-9]+#)_(.*?)$`);
    const sgKey = item.key.replace(prefixPattern, "$1");
    const valueKey = item.key.replace(prefixPattern, "$2");
    const o = {
      [valueKey]: item.value,
    };
    result[sgKey] = {
      ...(result[sgKey] as SuperGiftInfoProp),
      ...(o as SuperGiftInfoProp),
    };
  });
  return result;
};

export const getSuperGiftNameValueProps = (props: NameValueProp[]) => {
  const result: Record<string, SuperGiftInfoProp> = {};
  const sgProps = props?.filter((p) => p.name.indexOf(SUPERGIFT_PROP) > -1);
  sgProps.forEach((item) => {
    const prefixPattern = new RegExp(`(${SUPERGIFT_PROP}#[0-9]+#)_(.*?)$`);
    const sgKey = item.name.replace(prefixPattern, "$1");
    const valueKey = item.name.replace(prefixPattern, "$2");
    const o = {
      [valueKey]: item.value,
    };
    result[sgKey] = {
      ...(result[sgKey] as SuperGiftInfoProp),
      ...(o as SuperGiftInfoProp),
    };
  });
  return result;
};

export const getIsPrepayOnly = (allVariants: {
  edges: {
    node: ShopifyVariantNode;
  }[];
}) => {
  return !allVariants?.edges.some((edge) => {
    const chargeInterval = getVariantFrequency(edge.node.title)?.chargeInterval;
    const orderInterval = getVariantFrequency(edge.node.title)?.orderInterval;
    if (!chargeInterval || !orderInterval) {
      return false;
    }
    return chargeInterval / orderInterval === 1;
  });
};

export const isWeeklySuperGiftProduct = (productId: string) => {
  const valentineSuperGiftProductIds = ["101037450"];
  return valentineSuperGiftProductIds.includes(productId);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFeedProduct = (product: any) => {
  return product.isFacebookProduct || product.isGoogleProduct;
};

export const getProductsByFilterTags = async (
  tagIds: string[],
  preview = false
) => {
  const apolloClient = initializeApollo();
  const { data: productsFilterByTagsData } =
    await apolloClient.query<GetProductsByFiltersByTagsQuery>({
      query: GetProductsByFiltersByTagsDocument,
      variables: { tagIds },
      context: {
        preview,
      },
    });

  return productsFilterByTagsData.allProducts as ProductFieldsFragment[];
};

export const getFilterTags = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  preview = false
) => {
  const { data: filterTags } = await apolloClient.query<GetFilterTagsQuery>({
    query: GetFilterTagsDocument,
    context: {
      preview,
    },
  });
  return filterTags;
};

export const setFilterList = (
  products: ProductFieldsShortFragment[],
  filterSelected: FilterProduct[]
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productFiltered: any[] = [];
  products.forEach((product) => {
    const validColor = filterSelected
      .filter((f) => f.type === "Color")
      .some((filter) => {
        const productTags = product.tagFilter?.map((pt) => pt.id.toString());

        return (
          filter.filterName !== undefined &&
          filter.color !== undefined &&
          productTags?.includes(filter.tagId.toString())
        );
      });

    const validPrice = filterSelected
      .filter((f) => f.type === "Price")
      .some((filter) => {
        const shopifyProduct: ShopifyProductJson = product.isSubscription
          ? product.shopifySubscriptionProducts[0]
          : product.shopifyProduct;
        const productMinPrice = parseFloat(
          shopifyProduct?.priceRange?.minVariantPrice?.amount
        );
        return (
          filter.minPrice !== undefined &&
          filter.maxPrice !== undefined &&
          productMinPrice >= (filter.minPrice ?? 0) &&
          productMinPrice <= (filter.maxPrice ?? 0)
        );
      });

    const validSize = filterSelected
      .filter((f) => f.type === "Plant Size")
      .some((filter) => {
        const productTags = product.tagFilter?.map((pt) => pt.id.toString());

        return (
          filter.filterName !== undefined &&
          productTags?.includes(filter.tagId.toString())
        );
      });

    if (
      (validColor ||
        filterSelected.filter((f) => f.type === "Color").length === 0) &&
      (validPrice ||
        filterSelected.filter((f) => f.type === "Price").length === 0) &&
      (validSize ||
        filterSelected.filter((f) => f.type === "Plant Size").length === 0) &&
      filterSelected &&
      filterSelected.length > 0
    )
      productFiltered.push(product);
  });

  return productFiltered;
};

export const getFilterList = (
  productCollection: CollectionProductsMap,
  collections: ProductCollectionFieldsFragment[],
  filterList: GetFilterTagsQuery
) => {
  const filterProduct: FilterProduct[] = [];
  const result =
    filterList?.filters?.filter((filter) =>
      collections.some((c) => {
        const manualProducts = (c.products ??
          []) as ProductFieldsShortFragment[];
        const allProducts = manualProducts.concat(productCollection[c.id]);
        return allProducts.some((product) => {
          if (product) {
            const productTags = product.tagFilter?.map((pt) => pt.id);
            const shopifyProduct: ShopifyProductJson = product.isSubscription
              ? product.shopifySubscriptionProducts[0]
              : product.shopifyProduct;
            const productMinPrice = parseFloat(
              shopifyProduct?.priceRange?.minVariantPrice?.amount
            );

            return (
              productTags?.includes(filter.id) ||
              (filter.minPrice &&
                filter.maxPrice &&
                productMinPrice >= parseFloat(filter.minPrice) &&
                productMinPrice <= parseFloat(filter.maxPrice))
            );
          }
          return false;
        });
      })
    ) ?? [];

  result.forEach((f) => {
    filterProduct.unshift({
      type: f.filterType ?? "",
      tagId: f.id,
      filterName: f.tagMessage,
      color: f.filterType === "Color" ? f.backgroundColor?.hex : undefined,
      minPrice: f.filterType === "Price" ? f.minPrice : undefined,
      maxPrice: f.filterType === "Price" ? f.maxPrice : undefined,
      priority: f.priority ?? 0,
    } as FilterProduct);
  });

  // eslint-disable-next-line no-console

  return filterProduct;
};

export const getFilterListForProductList = (
  products: ProductFieldsShortFragment[],
  filterList: FilterProduct[]
) => {
  const filterProduct: FilterProduct[] = [];

  filterList?.forEach((filter) => {
    const valid = products?.some((product) => {
      if (product) {
        const productTags = product.tagFilter?.map((pt) => pt.id);
        const shopifyProduct: ShopifyProductJson = product.isSubscription
          ? product.shopifySubscriptionProducts[0]
          : product.shopifyProduct;
        const productMinPrice = parseFloat(
          shopifyProduct?.priceRange?.minVariantPrice?.amount
        );

        return (
          productTags?.includes(filter.tagId) ||
          (filter.minPrice &&
            filter.maxPrice &&
            productMinPrice >= filter.minPrice &&
            productMinPrice <= filter.maxPrice)
        );
      }
      return false;
    });

    if (!valid) {
      filterProduct.unshift(filter);
    }
  });

  return filterProduct;
};

export const getFilterOption = async () => {
  const apolloClient = initializeApollo();
  const filterList = await getFilterTags(apolloClient, false);
  return filterList as GetFilterTagsQuery;
};
export const getProductsByTags = async (tagIds: string[], preview = false) => {
  const apolloClient = initializeApollo();
  const { data: productsByTagsData } =
    await apolloClient.query<GetProductByTagsQuery>({
      query: GetProductByTagsDocument,
      variables: { tagIds },
      context: {
        preview,
      },
    });

  return productsByTagsData.allProducts as ProductFieldsFragment[];
};
export const getCollectionByTags = async (
  tagIds: string[],
  preview = false
) => {
  const apolloClient = initializeApollo();
  const { data: collectionByTagsData } =
    await apolloClient.query<GetCollectionByTagsQuery>({
      query: GetCollectionByTagsDocument,
      variables: { tagIds },
      context: {
        preview,
      },
    });

  return collectionByTagsData.allProductCollections;
};

export const getCollectionProductsByTags = async (
  productCollections: collectionResult[],
  preview: boolean
): Promise<CollectionProductsMap> => {
  const apolloClient = initializeApollo();
  const collectionProducts: CollectionProductsMap = {};
  const flowerShopCollections = productCollections ?? [];

  if (flowerShopCollections.length) {
    const requests: CollectionProductsRequests[] = [];
    flowerShopCollections?.forEach((collection) => {
      const tagIds = collection?.productTags?.map((ct) => ct.id);
      if (!tagIds.length) return;
      requests.push({
        collectionId: collection.id,
        request: apolloClient.query<
          GetFlowerShopCollectionProductsByTagQuery,
          GetFlowerShopCollectionProductsByTagQueryVariables
        >({
          query: GetFlowerShopCollectionProductsByTagDocument,
          variables: { tagIds },
          context: { preview },
        }),
      });
    });
    await pMap(
      requests,
      async (ro) => {
        const res = await ro.request;
        collectionProducts[ro.collectionId] = res.data?.allProducts ?? [];
      },
      { concurrency: 1 }
    );
  }
  return collectionProducts;
};
