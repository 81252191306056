import wretch from "wretch";
import { KeyValueProp } from "src/shared/types";
import produce from "immer";
import { differenceInDays } from "date-fns";
import { ShopifyLineItem } from "src/shared/types/shopify";
import { cartOperations } from "../Cart/state";

const dateApi = wretch("/api/date")
  .content("application/json")
  .accept("application/json");

export const dateMatches = (
  month: number,
  day: number,
  datesList: DisabledDate[]
) => {
  const list = datesList.map((i) => `${i.month}-${i.day}`);
  return list.includes(`${month}-${day}`);
};
export const disableDates = (
  date: Date | null,
  config: DatePickerServerConfigT | DatePickerClientConfigT
) => {
  if (!date) return false;

  const weekDay = date.getDay();
  if (
    weekDay === 1 &&
    config.nextDayShippingConfig.enabled &&
    differenceInDays(new Date(date), new Date(config.today)) <= 7
  ) {
    return true;
  }
  const month = date.getMonth() + 1;
  const day = date.getDate();
  if (config.enabledDates.length) {
    if (dateMatches(month, day, config.enabledDates)) return false;
  }
  if ((config.disabledWeekdays ?? []).length) {
    const disabledWeekdays: number[] = config.disabledWeekdays.map(
      (dw) => dw.weekday
    );
    if (disabledWeekdays.includes(weekDay)) return true;
  }
  if ((config.disabledDates ?? []).length) {
    if (dateMatches(month, day, config.disabledDates)) return true;
  }
  return false;
};
export type DisabledWeekday = {
  weekday: number;
};
export type DisabledDate = {
  month: number;
  day: number;
  year?: number;
};
export type EnabledDate = {
  month: number;
  day: number;
  year?: number;
};
export type DatePickerServerConfigT = {
  today: Date;
  minDate: Date;
  maxDate: Date;
  disabledWeekdays: DisabledWeekday[];
  disabledDates: DisabledDate[];
  enabledDates: EnabledDate[];
  nextDayShippingConfig: {
    enabled: boolean;
    isExpired: boolean;
    isExpedited: boolean;
    expires: string;
    cutoff: Date | null;
    enabledSameDay: boolean;
  };
};
export type DatePickerClientConfigT = {
  today: Date;
  minDate: Date;
  maxDate: Date;
  shouldDisableDate: (date: Date | null) => boolean;
  displayFormat: string;
  nextDayShippingConfig: DatePickerServerConfigT["nextDayShippingConfig"];
  disabledWeekdays: DatePickerServerConfigT["disabledWeekdays"];
  disabledDates: DatePickerServerConfigT["disabledDates"];
  enabledDates: DatePickerServerConfigT["enabledDates"];
};
export const loadDatePickerConfig = async ({
  productId,
  isSubscription,
  userDate,
}: {
  productId: string;
  isSubscription: boolean;
  userDate: string;
}): Promise<DatePickerClientConfigT> => {
  const serverConfig: DatePickerServerConfigT = await dateApi
    .url("/config")
    .post({ productId, isSubscription, userDate })
    .json();
  return {
    today: serverConfig.today,
    minDate: serverConfig.minDate,
    maxDate: serverConfig.maxDate,
    shouldDisableDate: (date: Date | null) => {
      return disableDates(date, serverConfig);
    },
    displayFormat: "PPP",
    nextDayShippingConfig: serverConfig.nextDayShippingConfig,
    disabledWeekdays: serverConfig.disabledWeekdays,
    disabledDates: serverConfig.disabledDates,
    enabledDates: serverConfig.enabledDates,
  };
};

export const updateCartLineItemProperties = (
  lineItem: ShopifyLineItem,
  newProps: KeyValueProp[] = []
) => {
  return cartOperations.editItem([
    produce(lineItem, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.attributes = newProps;
    }),
  ]);
};

export const updateGiftProperties = (
  lineItem: ShopifyLineItem,
  // eslint-disable-next-line default-param-last
  newProps: KeyValueProp[] = []
) => {
  return cartOperations.editItem([
    produce(lineItem, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.attributes = newProps;
    }),
  ]);
};

export const removeLineItemProperty = (
  propertyName: string,
  lineItemProperties: KeyValueProp[]
) => {
  const propertyIndex = lineItemProperties?.findIndex(
    (prop) => prop.key === propertyName
  );
  if (propertyIndex < 0) {
    return lineItemProperties;
  }
  return [
    ...(lineItemProperties.slice(0, propertyIndex) ?? []),
    ...(lineItemProperties.slice(propertyIndex + 1) ?? []),
  ];
};

export const setLineItemProperties = (
  propertyName: string,
  newPropertyValue: string,
  lineItemProperties: KeyValueProp[]
) => {
  if (newPropertyValue.length === 0) {
    return removeLineItemProperty(propertyName, lineItemProperties);
  }
  const propertyIndex = lineItemProperties?.findIndex(
    (prop) => prop.key === propertyName
  );
  if (propertyIndex < 0) {
    return [
      ...lineItemProperties,
      {
        key: propertyName,
        value: newPropertyValue,
      },
    ] as KeyValueProp[];
  }
  return [
    ...(lineItemProperties.slice(0, propertyIndex) ?? []),
    {
      ...lineItemProperties[propertyIndex],
      value: newPropertyValue,
    },
    ...(lineItemProperties.slice(propertyIndex + 1) ?? []),
  ] as KeyValueProp[];
};
