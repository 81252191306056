/* eslint-disable react/function-component-definition */
import { useEffect, FC, Fragment } from "react";
import { AppProps } from "next/app";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "src/services/datocms";
import { cartOperations } from "src/features/Cart/state";
import { QueryClient, QueryClientProvider } from "react-query";
import { SessionProvider as AuthProvider } from "next-auth/react";
import GlobalStyles from "src/ui/GlobalStyles";
import { useInitLoyaltyProgram } from "src/shared/utils/loyalty-program/client";
import NProgress from "nprogress";
import Router from "next/router";
import "../public/nprogress.css";
import "../public/accel-pay.css";
import "keen-slider/keen-slider.min.css";
import { IdentifyCustomer } from "src/features/IdentifyCustomer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: 3, staleTime: 1000 * 20 },
  },
});
type Props = {
  err?: Error;
} & AppProps;
const MyApp: FC<Props> = ({ Component, pageProps, err }) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  useInitLoyaltyProgram();
  useEffect(() => {
    const init = async () => {
      await cartOperations.loadCart();
    };
    init();
    Router.events.on("routeChangeStart", () => NProgress.start());
    Router.events.on("routeChangeComplete", () => NProgress.done());
    Router.events.on("routeChangeError", () => NProgress.done());
  }, []);
  const theme = createTheme();
  return (
    <Fragment>
      <GlobalStyles />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthProvider
            session={pageProps.session}
            basePath="/api/customer-portal/authentication"
          >
            <ApolloProvider client={apolloClient}>
              <QueryClientProvider client={queryClient}>
                <IdentifyCustomer>
                  <Component {...pageProps} err={err} />
                </IdentifyCustomer>
              </QueryClientProvider>
            </ApolloProvider>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Fragment>
  );
};

export default MyApp;
