import { Session } from "next-auth";
import { SITE_URL } from "src/constants";
import {
  ProductFieldsCartFragment,
  ProductFieldsFragment,
  ProductFieldsShortFragment,
} from "src/generated/datocms-types";
import { slugsPrefixMap } from "src/services/datocms/constants";
import { formatShopifyPrice } from "src/shared/helpers";
import { NullableString } from "src/shared/types";
import { ShopifyCartData } from "src/shared/types/shopify";

export enum KlaviyoEvents {
  ViewedProduct = "Viewed Product",
  AddedToCart = "Added to Cart",
}
export enum KlaviyoMethods {
  Identify = "identify",
  Track = "track",
}
const BLOOMSYBOX_BRAND = "BloomsyBox";

export type AddedToCartItemType = {
  ProductID: string;
  SKU: string;
  ProductName: string;
  Quantity: string;
  ItemPrice: string;
  RowTotal: string;
};

type IdentifyEventType = {
  $email: string;
  // eslint-disable-next-line camelcase
  $first_name: string;
  // eslint-disable-next-line camelcase
  $last_name: string;
};

type ViewedProductEventType = {
  Brand: string;
  Categories: string;
  ImageURL: string;
  Name: string;
  Price: string;
  ProductID: string;
  SKU: string;
  URL: string;
};

type AddedToCartEventType = {
  $value: string;
  AddedItemProductName: string;
  AddedItemProductID: string;
  AddedItemSKU: string;
  AddedItemCategories: Array<NullableString>;
  AddedItemImageURL: string;
  AddedItemURL: string;
  AddedItemPrice: string;
  AddedItemQuantity: string;
  ItemNames: (string | undefined)[];
  CheckoutURL: string;
  Items: AddedToCartItemType[] | undefined;
};

export const klaviyoIdentify = (session: Session) => {
  const klaviyoSdk = window._learnq || [];
  const identifyEvent: IdentifyEventType = {
    $email: session.shopifyUser.email,
    $first_name: session.shopifyUser.firstName,
    $last_name: session.shopifyUser.lastName,
  };
  klaviyoSdk.push([KlaviyoMethods.Identify, identifyEvent]);
  window._learnq = klaviyoSdk;
};

export const klaviyoViewedProduct = (product: ProductFieldsFragment | null) => {
  const klaviyoSdk = window._learnq || [];
  const viewedProductEvent: ViewedProductEventType = {
    Brand: BLOOMSYBOX_BRAND,
    Categories: product?.tags.map((tag) => tag.title).join(", ") ?? "",
    ImageURL:
      `${product?.gallery[0].url}?w=400&h=400&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2` ??
      "",
    Name: product?.title ?? "",
    Price: formatShopifyPrice(
      product?.shopifyProduct.priceRange.minVariantPrice
    ),
    ProductID: product?.id,
    SKU: product?.shopifyProduct?.variants.edges[0].node.sku,
    URL: `${SITE_URL}${slugsPrefixMap.product}${product?.slug}`,
  };
  klaviyoSdk.push([
    KlaviyoMethods.Track,
    KlaviyoEvents.ViewedProduct,
    viewedProductEvent,
  ]);
  window._learnq = klaviyoSdk;
};

export const klaviyoAddedToCart = (
  product:
    | ProductFieldsFragment
    | ProductFieldsShortFragment
    | ProductFieldsCartFragment,
  quantity: number,
  checkoutUrl: string,
  cartData: ShopifyCartData | undefined
) => {
  const klaviyoSdk = window._learnq || [];
  const itemNames = cartData?.cart?.lines?.edges?.map(
    (lineItem) => lineItem?.node?.merchandise?.product?.title
  );
  const items: AddedToCartItemType[] | undefined =
    cartData?.cart?.lines?.edges?.map((lineItem) => {
      return {
        ProductID: lineItem?.node?.merchandise?.product?.id?.toString() ?? "",
        SKU: lineItem.node.merchandise?.sku ?? "",
        ProductName: lineItem?.node?.merchandise?.product?.title ?? "",
        Quantity: lineItem.node.quantity?.toString() ?? "",
        ItemPrice: lineItem?.node?.cost?.amountPerQuantity.amount ?? "",
        RowTotal: lineItem?.node?.cost?.amountPerQuantity.amount ?? "",
        ProductURL: `https://bloomsybox.myshopify.com/products/${
          lineItem?.node?.merchandise?.product?.handle ?? ""
        }`,
        ImageURL:
          lineItem?.node?.merchandise?.product?.media.edges[0].node.previewImage
            .url ?? "",
      };
    });
  const addedToCartEvent: AddedToCartEventType = {
    $value: cartData?.cart?.cost?.subtotalAmount?.amount ?? "",
    AddedItemProductName: product.title ?? "",
    AddedItemProductID: product.id ?? "",
    AddedItemSKU: product.shopifyProduct?.variants.edges[0].node.sku ?? "",
    AddedItemCategories: product?.tags.map((tag) => tag.title) ?? [""],
    AddedItemImageURL:
      `${product?.gallery[0].url}?w=400&h=400&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2` ??
      "",
    AddedItemURL: `${SITE_URL}${slugsPrefixMap.product}${product?.slug}`,
    AddedItemPrice: product?.shopifyProduct.priceRange.minVariantPrice.amount,
    AddedItemQuantity: quantity.toString(),
    ItemNames: itemNames ?? [""],
    CheckoutURL: checkoutUrl,
    Items: items,
  };
  klaviyoSdk.push([
    KlaviyoMethods.Track,
    KlaviyoEvents.AddedToCart,
    addedToCartEvent,
  ]);
  window._learnq = klaviyoSdk;
};
