/* eslint-disable no-console */
import {
  BlockHeroButtonsFragment,
  BlockMainFeatureButtonsFragment,
  ExternalLinksFragment,
  FlowerShopPageSlugFragment,
  InternalLinksFragment,
  Maybe,
  NestedPageRecordSlugFragment,
  PageRecordSlugFragment,
  ProductFieldsCartFragment,
  ProductFieldsFragment,
  ProductRecordSlugFragment,
  SubscribePageSlugFragment,
} from "src/generated/datocms-types";
import NextLink from "next/link";
import { Button, ButtonProps } from "src/shared/components/Button";
import tw from "twin.macro";
import { slugsPrefixMap } from "src/services/datocms/constants";
import { ShopifyMoneyV2, ShopifyVariantNode } from "src/shared/types/shopify";
import {
  nextdayDeliveryVariant,
  saturdayDeliveryVariant,
  saturdayNextDayDeliveryVariant,
  showSurchargeOnCalendar,
} from "src/constants";
import { isSaturday } from "date-fns";
import { CTAButton, NextDayDeliveryConfig } from "./types";

export type InternalOrExternalLink = Maybe<
  | PageRecordSlugFragment
  | ProductRecordSlugFragment
  | SubscribePageSlugFragment
  | FlowerShopPageSlugFragment
  | NestedPageRecordSlugFragment
>;
export const getInternalLink = (data: InternalOrExternalLink): string => {
  const prefix =
    slugsPrefixMap[data?._modelApiKey as keyof typeof slugsPrefixMap] ?? "#";
  return `${prefix}${data?.slug}`;
};

const renderInternalLink = (
  link: InternalLinksFragment,
  props: CTAButton,
  trackEvent?: () => void
) => {
  // todo: refactor, DRY
  const href = getInternalLink(link.link);
  const hasColorButton = !props?.colorButton?.hex;
  return (
    <NextLink key={link.id} href={href} passHref legacyBehavior>
      <Button
        variant={link.variant as keyof ButtonProps["variant"]}
        css={[
          tw`mr-0 w-full text-center sm:w-auto md:mr-3 my-2 md:my-0 last:mr-0 h-[50px] lg:h-12`,
          props?.whiteColor && tw`bg-white text-red-500 border-white`,
          props?.hasBiggerButton && tw`px-6 text-base`,
          !hasColorButton && [
            { background: props.colorButton?.hex.toString() },
            tw`text-white`,
          ],
        ]}
        onClick={() => {
          if (trackEvent) {
            trackEvent();
          }
        }}
      >
        {link.title}
      </Button>
    </NextLink>
  );
};
const renderExternalLink = (
  link: ExternalLinksFragment,
  trackEvent?: () => void
) => {
  // todo: refactor, DRY
  return (
    <Button
      key={link.id}
      href={link.url as string}
      variant={link.variant as keyof ButtonProps["variant"]}
      css={tw`mr-0 w-full text-center sm:w-auto md:mr-3 my-2 md:my-0 last:mr-0 h-12`}
      onClick={() => {
        if (trackEvent) {
          trackEvent();
        }
      }}
    >
      {link.title}
    </Button>
  );
};
export const renderInternalExternalLinks = (
  links: BlockHeroButtonsFragment[] | BlockMainFeatureButtonsFragment[],
  props?: CTAButton,
  trackEvent?: () => void
) => {
  return links?.map((l) => {
    if (!l) return null;
    if (l._modelApiKey === "internal_link") {
      const ctaProps: CTAButton = {
        whiteColor: props?.whiteColor,
        hasBiggerButton: props?.hasBiggerButton,
        colorButton: props?.colorButton,
      };
      return renderInternalLink(
        l as InternalLinksFragment,
        ctaProps,
        trackEvent
      );
    }
    if (l._modelApiKey === "external_link") {
      return renderExternalLink(l as ExternalLinksFragment, trackEvent);
    }
    return null;
  });
};
export const b64decode = (b64str: string) => {
  if (typeof window !== "undefined") {
    return window.atob(b64str);
  }
  return Buffer.from(b64str, "base64").toString("binary");
};

export const getShopifyIdInt = (base64Id: string): number => {
  if (!base64Id || base64Id.length <= 0) {
    // console.warn("getShopifyIdInt: empty base64Id");
    return 0;
  }
  let shopifyId = "";
  if (base64Id.includes("gid://shopify")) {
    shopifyId = base64Id.split("/").pop() ?? "";
  } else {
    const decoded = b64decode(base64Id);
    shopifyId = decoded.split("/").pop() ?? "";
  }
  if (!shopifyId) return 0;
  if ("?".indexOf(shopifyId) !== -1) {
    shopifyId = shopifyId.split("?").shift() ?? "";
  }
  return parseInt(shopifyId ?? "0", 10);
  // return shopifyId ?? "0";
};

const currencyCodeSymbolMap = {
  USD: "$",
};
export const formatPrice = (amount: number, currencyCode = "USD") => {
  if (amount < 0) throw new Error("negative price");
  const currencySymbol =
    currencyCodeSymbolMap[currencyCode as keyof typeof currencyCodeSymbolMap] ??
    `${currencyCode} `;
  return `${currencySymbol}${amount.toFixed(2)}`;
};
export const formatShopifyPrice = (price: ShopifyMoneyV2) => {
  if (!price) return "";
  return formatPrice(+price.amount, price.currencyCode);
};

export const renderDate = (
  date: Date,
  nextDayConfig: NextDayDeliveryConfig,
  minDate: Date,
  maxDate: Date,
  shouldDisableDate: (day: Date) => boolean,
  product: ProductFieldsFragment | ProductFieldsCartFragment
) => {
  const isNextDayDeliveryAvailable =
    nextDayConfig.enabled &&
    !nextDayConfig.isExpired &&
    !nextDayConfig.isExpedited;

  const isNextDayDeliveryAvailableSurcharge = nextDayConfig.enabled;

  const saturdayNextDaySurcharge =
    product.shopifyProduct?.variants.edges.find(
      (v: { node: ShopifyVariantNode }) => v.node.saturdayNextDayFee?.value
    )?.node?.saturdayNextDayFee?.value ??
    product.shopifyProduct?.variants.edges.find(
      (v: { node: ShopifyVariantNode }) =>
        v.node.title.includes(saturdayNextDayDeliveryVariant)
    )?.node?.shipmentFee?.value;
  const nextDaySurcharge =
    product.shopifyProduct?.variants.edges.find(
      (v: { node: ShopifyVariantNode }) => v.node.nextDayFee?.value
    )?.node?.nextDayFee?.value ??
    product.shopifyProduct?.variants.edges.find(
      (v: { node: ShopifyVariantNode }) =>
        v.node.title.includes(nextdayDeliveryVariant)
    )?.node?.shipmentFee?.value;
  const saturdaySurcharge =
    product.shopifyProduct?.variants.edges.find(
      (v: { node: ShopifyVariantNode }) => v.node.saturdayFee?.value
    )?.node?.saturdayFee?.value ??
    product.shopifyProduct?.variants.edges.find(
      (v: { node: ShopifyVariantNode }) =>
        v.node.title.includes(saturdayDeliveryVariant)
    )?.node?.shipmentFee?.value;
  const minumumDate = new Date(new Date(minDate)?.setHours(0, 0, 0, 0));

  const maximumDate = new Date(new Date(maxDate)?.setHours(0, 0, 0, 0));
  const currentDay = new Date(new Date()?.setHours(0, 0, 0, 0));
  const isCurrentDay =
    date?.getDate() === currentDay?.getDate() &&
    date?.getMonth() === currentDay?.getMonth() &&
    date?.getFullYear() === currentDay?.getFullYear();
  const isDisabledDate =
    shouldDisableDate(date) ||
    (date && date < minumumDate) ||
    (date && date > maximumDate) ||
    (date && date < currentDay);
  let surcharge;
  if (
    date?.getDate() === minumumDate?.getDate() &&
    date?.getMonth() === minumumDate?.getMonth() &&
    date?.getFullYear() === minumumDate?.getFullYear() &&
    isNextDayDeliveryAvailableSurcharge &&
    date &&
    isSaturday(date) &&
    !isDisabledDate &&
    saturdayNextDaySurcharge
  ) {
    surcharge = saturdayNextDaySurcharge;
  } else if (date && isSaturday(date) && !isDisabledDate && saturdaySurcharge) {
    surcharge = saturdaySurcharge;
  } else if (
    date?.getDate() === minumumDate?.getDate() &&
    date?.getMonth() === minumumDate?.getMonth() &&
    date?.getFullYear() === minumumDate?.getFullYear() &&
    isNextDayDeliveryAvailableSurcharge &&
    !isDisabledDate &&
    nextDaySurcharge
  ) {
    surcharge = nextDaySurcharge;
  }
  return (
    <div css={tw`flex items-center justify-center flex-col relative`}>
      <button tabIndex={0} type="button">
        <span css={tw`flex flex-col`}>
          <p>
            <span css={tw`text-base`}>{date?.getDate()}</span>
          </p>
          {showSurchargeOnCalendar && surcharge && (
            <p
              css={{
                fontSize: "0.65rem",
                fontWeight: "bold",
              }}
            >
              +${surcharge}
            </p>
          )}
        </span>
        <span />
      </button>
      {isCurrentDay && (
        <div>
          <div
            css={[tw`w-2 h-2 border-0 rounded-full  top-3/4  bg-gray-400`]}
          />
        </div>
      )}
    </div>
  );
};
