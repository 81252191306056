export const slugsPerPage = 100; // api max is 100
export const blogPostsPerPage = 9;
export const blogPageLimit = 3;
// routing page prefix if any
export const slugsPrefixMap = {
  page: "/",
  nested_page: "/",
  product: "/p/",
  google_product: "/gp/",
  subscribe_page: "/",
  flower_shop_page: "/",
  flower_page: "/f/",
  blog_post: "/blog/posts/",
  plant_page: "/pc/",
  blog_category_page: "/category/",
  plant_shop_page: "/",
};
export const productPlanTagSlugs = {
  WEEKLY: "weekly-plan",
  MONTHLY: "monthly-plan",
  QUARTERLY: "quarterly-plan",
};
export const NYBGSlug = "nybg";
export const petSafeSlug = "petsafe";
export const monthlyPlanSlug = "monthly-plan";
export const superGiftSlug = "super-gift";
export const weekyPlanSlug = "weekly-plan";
export const usaGrownSlug = "usagrown";
export const monthlyDealPlanSlug = "monthly-plan-deal";
export const inDoorSlug = "indoor";
export const outDoorrSlug = "outdoor";
export const freeShipping = "free-shipping";
export const nextDayIcon = "next-day-icon";
export const freeShippingIcon = "free-shipping-icon";
export const bundleSlug = "bundle";
export const deluxePlan = "SQ1726938";
