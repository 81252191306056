/* eslint-disable react/function-component-definition */
import { useSession } from "next-auth/react";
import React, { FC } from "react";
import sha1 from "crypto-js/sha1";
import { Session } from "next-auth";
import { dataLayer } from "../Analytics";
import { impactDataLayerEvent } from "../Analytics/utils";

type Props = {
  children: React.ReactNode;
};

const identify = (
  status: "authenticated" | "loading" | "unauthenticated",
  session: Session | null
) => {
  if (status !== "loading") {
    if (session) {
      const customerEmail = sha1(session.shopifyUser.email);
      dataLayer({
        event: "convizit_identify",
        convizit_user_id: session.shopifyUser.id,
      });
      impactDataLayerEvent(session.shopifyUser.id, customerEmail.toString());
    } else {
      dataLayer({
        event: "convizit_identify",
        convizit_user_id: "",
      });
      impactDataLayerEvent("", "");
    }
  }
};

export const IdentifyCustomer: FC<Props> = ({ children }): JSX.Element => {
  const { data: session, status } = useSession();
  identify(status, session);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
