import * as React from "react";
import tw from "twin.macro";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ProductFieldsFragment } from "src/generated/datocms-types";
import {
  HiCheck,
  HiOutlineCalendar,
  HiOutlineChevronDown,
  HiOutlineGift,
  HiOutlinePencil,
} from "react-icons/hi";
import {
  InvalidDate,
  SaveItemDataFn,
  SuperGiftItemT,
} from "src/features/SuperGift/SuperGiftProductForm";
import { DisabledDate } from "src/features/ProductForm/utils";
import { SuperGiftItemForm } from "src/features/SuperGift/SuperGiftItemForm";
import { SuperGiftItemSummary } from "src/features/SuperGift/SuperGiftItemSummary";

export const getSuperGiftItemTitle = (num: string) => `Occasion ${num}`;
export type Props = {
  itemIndex: number;
  product: ProductFieldsFragment;
  saveItemData: SaveItemDataFn;
  itemData: SuperGiftItemT;
  disabledDates: DisabledDate[];
  invalidDate: InvalidDate | undefined;
};
export const SuperGiftItem: React.FC<Props> = (props) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const { itemIndex, itemData, saveItemData, invalidDate } = props;
  const defaultTitle = getSuperGiftItemTitle(`${itemIndex + 1}`);
  const hasRequiredData = !!itemData?.deliveryDate && invalidDate === undefined;
  const handleSave = React.useCallback<SaveItemDataFn>(
    (index, newItem) => {
      saveItemData(itemIndex, newItem);
      setIsEditing(false);
    },
    [itemIndex, saveItemData]
  );
  const handleEdit = React.useCallback(() => {
    setIsEditing(true);
    saveItemData(itemIndex, { ...itemData });
  }, [itemIndex, itemData, saveItemData, setIsEditing]);

  return (
    <Accordion
      css={[
        tw`border-0`,
        invalidDate && tw`border-bloomsy-darkred`,
        // hasRequiredData && tw`border-bloomsy-green`,
      ]}
      variant="elevation"
    >
      <AccordionSummary expandIcon={<HiOutlineChevronDown />}>
        <div css={tw`w-full flex flex-row items-center`}>
          <div css={tw`flex items-center`}>
            <span css={tw`mr-2`}>
              {hasRequiredData ? <HiCheck /> : <HiOutlinePencil />}
            </span>
            <span>{defaultTitle}</span>
          </div>
          <div css={tw`ml-auto`}>
            <div css={tw`flex items-center space-x-1`}>
              {itemData?.giftMessage && (
                <span>
                  <HiOutlineGift />
                </span>
              )}
              {itemData?.deliveryDate && (
                <span>
                  <HiOutlineCalendar />
                </span>
              )}
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div css={tw`w-full`}>
          {!hasRequiredData || isEditing ? (
            <SuperGiftItemForm handleSave={handleSave} {...props} />
          ) : (
            <SuperGiftItemSummary handleEdit={handleEdit} itemData={itemData} />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
