import { keyframes } from "@emotion/react";
import { Logo } from "src/shared/components/Icons";
import tw, { styled } from "twin.macro";

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const BloomsyLoader = styled(Logo)([
  tw`w-inherit h-inherit`,
  {
    "@media (prefers-reduced-motion: no-preference)": {
      animation: `${spin} 1.5s linear infinite;`,
    },
  },
]);
export default BloomsyLoader;
