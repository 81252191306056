export const SITE_URL = "https://www.bloomsybox.com";
export const CHECKOUT_URL = "https://checkout.bloomsybox.com";
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const DAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const EST_TIMEZONE = "America/New_York";
export const TIMEZONES: Map<string, string> = new Map([
  ["EST", "America/New_York"],
  ["CST", "America/Chicago"],
  ["MST", "America/Denver"],
  ["PST", "America/Los_Angeles"],
]);
export const DATE_FORMATS = {
  default: "MMM dd, yyyy",
  monthly: "MMM, yyyy",
  deliveryDate: { display: "EEE MMM dd, yyyy", value: "MM-dd-yyyy" },
  nextDayShipping: "MMM do yyyy, hh:mm:ss a zzz",
};
// eslint-disable-next-line no-shadow
export enum NOT_SCHEDULED_DAYS {
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
  Monday = 1,
}

export const IS_PROD_ENV =
  process.env.NODE_ENV === "production" &&
  process.env.NEXT_PUBLIC_APP_STAGE === "production";

export const IMAGE_QUALITY = 75;

export const SENDER_NAME = "BloomsyBox.com";
export const nextdayDeliveryVariant = "Next Day Shipping";
export const saturdayDeliveryVariant = "Saturday Shipping";
export const saturdayNextDayDeliveryVariant = "Saturday Next-Day Shipping";
export const showSurchargeOnCalendar = true;

export const productSortOptions = {
  "unsorted-default": "Featured",
  "price-asc": "Lowest Price",
  "price-desc": "Highest Price",
};

export const subscriptionBenefits = [
  "Free Shipping",
  "Satisfaction Guaranteed",
  "Easy Cancelation",
];
